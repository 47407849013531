import React from 'react';
import Name from './Name';
import Progress from './Progress';
import Task from './Task/_Task';

function Checklist({checklist}){

  return(
    <>
      <Name name={checklist.name}/>
      <Progress completed={checklist.completed} total={checklist.total}/>
      {checklist?.tasks?.map((task) => 
        (
          <Task 
            key={`${checklist.name}-${task.taskNumber}`}
            name={checklist.name}
            taskNumber={task.taskNumber}
            completed={task.completed}
          />
        )
      )}
    </>
  );

}


export default Checklist;
