import { useEffect,useState } from 'react';
import {useTranslation} from 'react-i18next';
import Content from './Content';
import Answer from './Answer';
import auth from '../../../../../Account/Auth';
import be from '../../../../../BE';

function Surveys({answers,bookingId,etype}){
  const {t} = useTranslation(['home']);


  return (
    <>
      {(answers && answers.length !== 0) &&
      <>
        <div><i className="fa-solid fa-circle-question me-2"></i><b>{t('upcomings.details.questions')}</b></div>
        {
          Object.keys(answers).map((key) => (
            <div className="ms-3 mb-3">
              <Content key={`content-${key}`} content={answers[key].content}/>
              <Answer key={`answer-${key}`} fields={answers[key]?.answer} questionType={answers[key].questionType}/>
            </div>
          ))
        }
      </>
      }
      
  </>
  );
}

export default Surveys;
