import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Member from './Member';
import Templates from './Templates';
import Priorities from './Priorities';
import { useCreateEventTypeStore } from '../../store';
import auth from '../../Account/Auth';
import be from '../../BE';

function SelectedMembers({members}){
    const history = useHistory();
    const roundRobin = useCreateEventTypeStore((state) => state.roundRobin);
    const [loadingTemplates, setLoadingTemplates] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState({});
    const setRobinMemberTemplate = useCreateEventTypeStore((state) => state.setRobinMemberTemplate);
    const setRobinMemberPriority = useCreateEventTypeStore((state) => state.setRobinMemberPriority);
  const deleteRobinMember = useCreateEventTypeStore((state) => state.deleteRobinMember);

  const getMemberTemplates = async (member) => {
        setLoadingTemplates(member);
        setTemplates(prevTemplates => ({
            ...prevTemplates,
            [member]: []
        }));
        try {
            await auth.isAuthenticated()
                .then(async () => {
                    await be.get('team-manager', '/team/members/availabilities', { member }, true, true)
                        .then(response => {
                            setTemplates(prevTemplates => ({
                                ...prevTemplates,
                                [member]: response
                            }));
                            if (response.length > 0) {
                                setRobinMemberTemplate(member, response[0]);
                            }
                        })
                        .catch(error => {
                            console.error(`[etypecreator][roundrobin] network error while retrieving ${member} templates:`, error);
                        })
                })
                .catch((error) => {
                    console.error(`[etypecreator][roundrobin] authentication error while retrieving ${member} templates:`, error);
                    history.push({ pathname: '/login' });
                })
        } catch (error) {
            console.error(`[etypecreator][roundrobin] functional error while retrieving ${member} templates:`, error);
        } finally {
            setLoadingTemplates(null);
        }
    }

    const handleTemplateSelection = (template, member) => {
        setSelectedTemplates(prevSelectedTemplates => ({
            ...prevSelectedTemplates,
            [member]: template
        }))
        setRobinMemberTemplate(member, template);
    }

    const handlePrioritySelection = (e, member) => {
        if (member in roundRobin) {
            const priority = Number(e.target.value);
            setRobinMemberPriority(member, priority);
        }
    }

  return(
    <>
        <div className="tw-flex tw-flex-col">
          {members.map(member => (
            <div key={`member-container-${member?.username}`} className="tw-inline-flex tw-gap-4 tw-my-2">
              <Member
                key={`member-${member?.username}`}
                pic={member?.pic}
                name={member?.name}
                email={member?.email}
              />
              <Templates
                key={`templates-${member?.username}`}
                member={member?.username}
                templates={templates[member?.username] || []}
                selectedTemplate={
                  (members.includes(member?.username) && selectedTemplates[member?.username]) ||
                    (Object.keys(roundRobin).includes(member?.username) && roundRobin[member?.username]?.availability_template) ||
                    ""
                }
                loadingTemplates={loadingTemplates === member?.username}
                disabled={!roundRobin.hasOwnProperty(member?.username)}
                handleTemplateSelection={handleTemplateSelection}
                getMemberTemplates={getMemberTemplates}
              />
              <Priorities
                key={`priorities-${member?.username}`}
                member={member?.username}
                priority={roundRobin[member?.username]?.priority}
                disabled={!roundRobin.hasOwnProperty(member?.username) || !(roundRobin[member?.username]?.availability_template)}
                handlePrioritySelection={handlePrioritySelection}
              />
              <div key={`delete-${member?.username}`} className="tw-flex tw-items-center tw-justify-center tw-h-[38px] tw-mt-auto">
                <Button style={{border : "none", background : "transparent", color : "var(--colot-text)"}} onClick={() => deleteRobinMember(member?.username)}>
                  <i className="far fa-trash-alt my-auto"></i>
                </Button>
              </div>
            </div>

          ))}
        </div>
    </>
  );
}

export default SelectedMembers;
