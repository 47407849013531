import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import Checkout from './Checkout';

function Subscription({price,notifications,customerId,currentPriceId,priceId,cancel,update}){
  const {i18n,t} = useTranslation(['integrations']);
  const isActive = currentPriceId === priceId;

  const getUserLanguage = () => {
    try{
      return i18n.language.substring(0,2)
    }catch(e){
      return 'en'
    }
  }

  const cardStyle={
    background: isActive
      ? 'linear-gradient(-90deg, var(--color-success), var(--color-background-1))'
      : 'var(--color-background-1)',
    border: isActive
      ? '1px solid var(--color-success)'
      : '1px solid var(--color-border-light)' 
  }

  return(
    <Card className="w-100" style={cardStyle}>
      <Card.Body>
        <Card.Title style={{color:'var(--color-text)'}}>
          {t('whatsapp.subscription.name',{notifications})}
          {isActive && <Badge pill bg="success" style={{fontSize:12, verticalAlign:'middle', marginLeft:5}}>{t('whatsapp.subscription.badge')}</Badge>}
        </Card.Title>
        <Card.Subtitle style={{color:'var(--color-secondary)'}} className="mb-2">{getUserLanguage() === 'en' ? '$' : '€' }{t('whatsapp.subscription.price',{price})}</Card.Subtitle>
        <Card.Text></Card.Text>
        <div style={{float:'right'}}>
          {(isActive && cancel) && <Button onClick={() => window.open(cancel,'_blank')} variant="danger" size="sm" className="mx-1">{t('whatsapp.subscription.cancel')}</Button>}
          {(isActive && update) && <Button onClick={() => window.open(update,'_blank')} variant="primary" size="sm" className="mx-1">{t('whatsapp.subscription.update')}</Button>}
          {!isActive && <Checkout customerId={customerId} priceId={priceId}/>}
        </div>
      </Card.Body>
    </Card>
  );
}

export default Subscription;
