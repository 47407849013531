import {useTranslation} from 'react-i18next';
function Location({location}){
  const {t} = useTranslation(['booking']);
  return(
    <div className="my-3 d-flex align-items-center">
      
      <div className="event-info-icon">
        <i className="fa-solid fa-location-dot"></i>
      </div>

      <div>
        <div className="mx-3" style={{fontSize:12,color:'var(--color-secondary)'}}>{t('confirmed.location')}</div>
        <div className="d-flex align-items-center">
          {location?.type === "googleMeet" && <img src="/app-assets/img/meet-logo.png" alt="Google Meet" width="20px" height="20px" className="ms-3"/>}
          {location?.type === "zoom" && <img src="/app-assets/img/zoom-logo.png" alt="Zoom" width="20px" height="20px" className="ms-3"/>}
          <span className={location?.type === "custom" ? "ms-3" : "ms-2"}>{location?.value}</span>
        </div>
      </div>

    </div>
  );
}

export default Location;
