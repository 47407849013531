import Amplify, { API } from 'aws-amplify';
import auth from './Account/Auth';
import AmplifyConfDev from './amplify-config-dev.js'
import AmplifyConfProd from './amplify-config-prod.js'

class BE{
  #endpoint
  constructor(){
    this.#endpoint = this.check_env()
    if(this.#endpoint === '-dev'){Amplify.configure(AmplifyConfDev)}
    if(this.#endpoint === '-prod'){Amplify.configure(AmplifyConfProd)}
  }

  check_env(){
    let endpoint = '-dev'
    if (window.location.hostname === 'www.calendbook.com' || window.location.hostname === 'calendbook.com'){
      endpoint = '-prod'
    }
    if (window.location.hostname === 'dev.calendbook.com' || window.location.hostname === 'localhost:3000'){
      endpoint = '-dev';
    }
    return endpoint
  }

  getAccountIdFromLocalStorage() {
    const agentSession = localStorage.getItem('agentSession');
    if (agentSession) {
      const sessionObj = JSON.parse(agentSession);
      return sessionObj.accountId || null;
    }
    return null;
  }


  async get(apiName,path,queryParams,needsAuth,sameEnv,useAxiosResponse){
    //sameEnv is needed when the lambda name is the same for prod and dev environements
    //useAxiosResponse is needed o have response.status for status code handled calls
    if(!sameEnv){ apiName += this.#endpoint }
    var config = {headers : {}, queryStringParameters : {}};
    const accountId = this.getAccountIdFromLocalStorage();
    if(queryParams){ config.queryStringParameters = queryParams }
    if(needsAuth){ config.headers = { Authorization : 'Bearer ' + auth.getAccessToken() } }
    if(accountId) { config.headers['Account-X-Calendbook'] = accountId; }
    if(useAxiosResponse){ config.response = true }
    let result = API.get(apiName,path,config)
    return await result
  }

  async post(apiName,path,body,needsAuth,sameEnv,useAxiosResponse){
    if(!sameEnv){ apiName += this.#endpoint }
    var config = {headers : {}, body : {}};
    const accountId = this.getAccountIdFromLocalStorage();
    if(body){ config.body = body }
    if(needsAuth){ config.headers = { Authorization : 'Bearer ' + auth.getAccessToken() } }
    if(accountId) { config.headers['Account-X-Calendbook'] = accountId; }
    if(useAxiosResponse){ config.response = true }
    let result = API.post(apiName,path,config)
    return result
  }

  patch(apiName,path,body,needsAuth,sameEnv,useAxiosResponse){
    if(!sameEnv){ apiName += this.#endpoint }
    var config = {headers : {}, body : {}};
    const accountId = this.getAccountIdFromLocalStorage();
    if(body){ config.body = body }
    if(needsAuth){ config.headers = { Authorization : 'Bearer ' + auth.getAccessToken() } }
    if(accountId) { config.headers['Account-X-Calendbook'] = accountId; }
    if(useAxiosResponse){ config.response = true }
    return API.patch(apiName,path,config)
  }

  del(apiName,path,body,needsAuth,sameEnv){
    if(!sameEnv){ apiName += this.#endpoint }
    var config = {headers : {}, body : {}};
    const accountId = this.getAccountIdFromLocalStorage();
    if(body){ config.body = body }
    if(needsAuth){ config.headers = { Authorization : 'Bearer ' + auth.getAccessToken() } }
    if(accountId) { config.headers['Account-X-Calendbook'] = accountId; }
    return API.del(apiName,path,config)
  }

  async refresh(apiName,path){
    apiName += this.#endpoint;
    var config = {headers : {}, body : {}};
    const accountId = this.getAccountIdFromLocalStorage();
    config.headers = { Authorization : 'Bearer ' + auth.getRefreshToken() }
    if(accountId) { config.headers['Account-X-Calendbook'] = accountId; }
    let result = API.post(apiName,path,config)
    return await result
  }

}

export default new BE();
