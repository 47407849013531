export const PrivacyEng = `
Privacy Policy of www.calendbook.com 

This Website collects some Personal Data from its Users. 

This document can be printed using the print command present 
in the settings of any browser. 

Data Controller 

CALENDBOOK SRL - VIA FELICE CAVALLOTTI 67, 70032 BITONTO (BA) 
Owner contact email: privacy@calendbook.com 


Types of Data collected 

Among the Personal Data collected by this Website / App, either independently or 
through third parties parts, there are: 
Usage data; Tracking Tool; first name; last name; Data communicated while using the service; e-mail; sex; date of birth; profession; Full access.

Complete details on each type of data collected are provided in the dedicated sections of this privacy policy or through specific information texts displayed before the data is collected. 
Personal Data may be freely provided by the User or, in the case of Usage Data, collected automatically when using this Website. 
Unless otherwise specified, all Data requested by this Website are mandatory. If the User refuses to communicate them, it may be impossible for this Website to provide the Service. 
In cases where this Website indicates some Data as optional, Users are free to refrain from communicating such Data, without this having any consequence on the availability of the Service or on its operation.
Users who have doubts about which data are mandatory can contact the owner at the aforementioned email address. 
Any use of Cookies - or other tracking tools - by this Website or by the owners of third party services used by this Website, unless otherwise specified, is intended to provide the Service requested by the User, as well as for the additional purposes described in this document and in the Cookie Policy, if available. 

The User assumes responsibility for the Personal Data of third parties obtained, published or shared through this Website and guarantees to have the right to communicate or disseminate them, freeing the Owner from any liability to third parties. 

Method and place of processing of the collected data
Processing methods 

The Data Controller adopts the appropriate security measures aimed at preventing unauthorized access, disclosure, modification or destruction of Personal Data. 
The processing is carried out using IT and / or telematic tools, with organizational methods and with logic strictly related to the purposes indicated.
In addition to the Data Controller, in some cases, other subjects involved in the organization of this Website (administrative, commercial, marketing, legal, system administrators) or external subjects (such as third party technical service providers, postal couriers) may have access to the Data. , hosting providers, IT companies, communication agencies) also appointed, if necessary, as Data Processors by the Data Controller. The updated list of Managers can always be requested from the Data Controller at the email address privacy@calendbook.com 

Legal basis of the processing 

The Data Controller processes Personal Data relating to the User if the following conditions exist 

1. The User has provided the consent for one or more specific purposes;
Note: in some jurisdictions the Data Controller may be authorized to process Personal Data without the User's consent or another of the legal bases specified below, as long as the User does not object ("opt-out") to such treatment. However, this is not applicable if the processing of Personal Data is governed by European legislation on the protection of Personal Data; 
2. the processing is necessary for the execution of a contract with the User and / or for the execution of pre-contractual measures (as in the case of users who register to use the services offered by the Data Controller) 
3. the processing is necessary to fulfill a legal obligation to which the Data Controller is subject;
4. the processing is necessary for the execution of a task of public interest or for the exercise of public authority vested in the Data Controller; 
5. the processing is necessary for the pursuit of the legitimate interest of the Data Controller or third parties. 

However, it is always possible to ask the Data Controller to clarify the concrete legal basis of each treatment and in particular to specify whether the treatment is based on the law, provided for by a contract or necessary to conclude a contract. 

Place of processing

The Data are processed at the operational headquarters of the Data Controller and in any other place where the parties involved in the processing are located, with IT tools and possibly also in paper format where strictly necessary. For more information, contact the owner. 
The User's Personal Data may be transferred to a country other than the one in which the User is located. To obtain further information on the place of processing of their personal data, the User can refer to the section relating to the details on the processing of Personal Data.

The User has the right to obtain information regarding the legal basis for the transfer of Data outside the European Union or to an international organization governed by public international law or consisting of two or more countries, such as the UN, as well as regarding the security measures adopted by the Data Controller to protect the Data. 

The User can check if one of the transfers described above takes place by examining the section of this document relating to the details on the processing of Personal Data or requesting information from the Data Controller by contacting him at the indicated details in opening. 

Retention period 

The Data are processed and stored for the time required by the purposes for which they were collected.

The Personal Data collected for purposes related to the execution of the contract between the Owner and the User will be retained until the execution of this contract is completed, but can also be processed after its complete execution for administrative and / or legal requirements that make this further treatment necessary. 

Personal Data collected for purposes related to the legitimate interest of the Data Controller will be retained until this interest is satisfied. The User can obtain further information regarding the legitimate interest pursued by the Data Controller in the relevant sections of this document or by contacting the Data Controller.

When the processing is based on the User's consent, the Data Controller may keep the Personal Data longer until such consent is revoked. In particular, where personal data are collected and processed for marketing purposes, the Data Controller will keep and process said data no later than 5 years from the moment the data subject has given the last express consent for this purpose. 

Furthermore, the Data Controller may be obliged to keep Personal Data for a longer period in compliance with a legal obligation or by order of an authority.

At the end of the retention period, the Personal Data will be deleted. Therefore, at the end of this term the right of access, cancellation, rectification and the right to data portability can no longer be exercised, without prejudice to the possibility of using the aforementioned data in aggregate and anonymous form for statistical purposes. 

Purpose of the processing of the data collected 

The user data is collected to allow the owner to provide the service, comply with legal obligations, respond to requests or executive actions, protect their rights and interests (or those of users or third parties ), identify any malicious or fraudulent activities, as well as for the following purposes:
Display of contents from external platforms, Statistics, Tag Management, Payment Management, Registration and authentication, Heat mapping and session registration, Interaction with live chat platforms, Management of data collection and online surveys, User database management, Contact management and sending of messages and Hosting and backend infrastructure. 

To obtain detailed information on the purposes of the processing and on the Personal Data processed for each purpose, the User can refer to the section "Details on the processing of Personal Data". 

Details on the processing of Personal Data Personal 

Data, based on the aforementioned purposes, are collected and processed through the use of the following services:

Managing contacts and sending messages 

This type of service allows you to manage a database of email contacts, telephone contacts or contacts of any other type, used to communicate with the User. 
These services may also allow the collection of data relating to the date and time the messages are displayed by the User, as well as to the User's interaction with them, such as information on clicks on the links inserted in the messages. 

Customerly E-mail Marketing (Customerly Ltd.) 

Customerly E-mail Marketing is an address management and e-mail message sending service provided by Customerly Ltd. 

Personal data processed: surname; Usage data; e-mail; first name. 

Place of processing: Ireland - Privacy Policy.

Amazon Simple Email Service (Amazon) 

Amazon Simple Email Service is an address management and email message sending service provided by Amazon.com Inc. 

Personal data processed: email. 

Place of processing: United States - Privacy Policy. 

User database management 

This type of service allows the Owner to build user profiles starting from an email address, name or any other information that the User provides to this Website / App, as well as to track the User's activities through statistical features.
These Personal Data may also be crossed with publicly available information on the User (such as profiles on social networks) and used to build private profiles that the Owner can view and use to improve this Website. 
Some of these services may also allow the Scheduled sending of messages to the User, such as emails based on specific actions performed on this Website. 

Customerly (Customerly Ltd.) 

Customerly is a user database management service provided by Customerly Ltd. 
Customerly can also be used as a means of communication , to exchange emails or messages using this Website.

Personal Data processed: Usage data; e-mail; Tracking Tool; various types of data as specified in the privacy policy of the service. 

Place of processing: Ireland - Privacy Policy. 

Payment Management 

Unless otherwise specified, this Website / App processes all payments by credit card, bank transfer or other means through external payment service providers. In general, and unless otherwise stated, Users are requested to provide payment details and personal information directly to such payment service providers.
This Website is not involved in the collection and processing of such information, as it will only receive a notification from the relevant payment service provider that the payment has been made. 

Stripe (Stripe Inc) 

Stripe is a payment service provided by Stripe Inc. 

Personal data processed: various types of data as specified in the privacy policy of the service. 

Place of processing: United States - Privacy Policy. 

Tag management 

This type of service is functional to the centralized management of the tags or scripts used on this Website. The use of these services involves the flow of User Data through them and, where appropriate, their retention. 

Google Tag Manager (Google LLC)

Google Tag Manager is a tag management service provided by Google LLC. 

Personal Data processed: Usage data. 

Place of processing: United States - Privacy Policy. 

Management of data collection and online surveys 

This type of service allows this Website to manage the creation, implementation, administration, distribution and analysis of online forms and surveys in order to collect, save and reuse the Data of the Users who respond. 
The Personal Data collected depend on the information requested and provided by Users in the corresponding online form.

These services can be integrated with a wide range of third-party services to allow the Owner to perform subsequent actions with the processed Data - for example, contact management, sending messages, statistics, advertising and payment processing. 

Customerly Survey (Customerly Ltd.) 

Customerly Survey is a survey generator and data collection platform provided by Customerly Ltd. 

Personal data processed: surname; date of birth; Usage data; e-mail; first name; profession; sex. 

Place of processing: Ireland - Privacy Policy. 

Heat mapping and session recording

Heat mapping services are used to identify the areas of this Website with which Users interact most frequently, in order to detect which of them attract the most interest. These services allow you to monitor and analyze traffic data and are used to keep track of User behavior. 
Some of these services may log sessions and make them available for later viewing. 

Hotjar Heat Maps & Recordings (Hotjar Ltd.) 

Hotjar is a heat mapping and session recording service provided by Hotjar Ltd.
Hotjar respects generic “Do Not Track” headers. This means that the browser can tell the script not to collect any User data. This is a setting that is available in all major browsers. More information on the opt-out from Hotjar can be found here. 

Personal Data processed: Usage data; Tracking Tool; various types of data as specified in the privacy policy of the service. 

Place of processing: Malta - Privacy Policy - Opt Out. 

Hosting and backend infrastructure

This type of service has the function of hosting Data and files that allow this Website to function, allow its distribution and make available a ready-to-use infrastructure to provide specific features of this Website. 

Some services among those listed below, if present, they can work on geographically distributed servers, making it difficult to determine the actual location in which the Personal Data are stored. 

Amazon Web Services (AWS) (Amazon Web Services, Inc.) 

Amazon Web Services (AWS) is a hosting and backend service provided by Amazon Web Services, Inc. 

Personal Data processed: various types of Data as specified in the privacy policy of the service.

Place of processing: Germany - Privacy Policy; Italy - Privacy Policy. 

Interaction with live chat platforms 

This type of service allows you to interact with live chat platforms managed by third parties, directly from the pages of this Website, in order to be able to contact and be contacted by the support service of this Website. 

In the event that an interaction service with live chat platforms is installed, it is possible that, even if the Users do not use the service, it collects Usage Data relating to the pages in which it is installed. Additionally, live chat conversations may be recorded. 

Customerly Chat Widget (Customerly Ltd.)

The Customerly Chat Widget is an interaction service with the Customerly live chat platform, provided by Customerly Ltd. 

Personal data processed: surname; Data communicated while using the service; Usage data; e-mail; first name; Tracking Tool. 

Place of processing: Ireland - Privacy Policy. 

Registration and authentication 

By registering or authenticating, the User allows this Website to identify him and to give him access to dedicated services. 

Depending on what is indicated below, the registration and authentication services could be provided with the help of third parties. If this happens, this Website will be able to access some Data stored by the third party service used for registration or identification.

Some of the services listed below may also collect Personal Data for targeting and profiling purposes; to learn more, please refer to the description of each service. 

Google OAuth (Google LLC) 

Google OAuth is a registration and authentication service provided by Google LLC and connected to the Google network. 

Personal Data processed: various types of Data as specified in the privacy policy of the service. 

Place of processing: United States - Privacy Policy. 

Gmail permissions for accessing User Data (OAuth added) (Google LLC)

To provide its service, this Website uses the limited purposes for Gmail that allow you to access User Data relating to this Google service (as indicated below). This access is provided by Google LLC under strict terms. Below is what this means for such User Data. 

The use of the User Data is limited to the provision or improvement of the User interface functionality. User data will not be used to serve ads, or for retargeting and personalized or interest-based advertising.

User Data will be transferred to third parties only if necessary, to provide or improve the User interface functionality and if necessary to comply with applicable law or as part of a merger, acquisition or sale of assets upon prior notice to Users. 

The Data will not be read by human beings unless: the Owner has obtained the User's affirmative agreement for specific messages; it is necessary for security reasons and / or to comply with applicable law, or; the use is limited to internal operations and the Data (including their derivations) have been aggregated and made anonymous. 

Personal data processed: Full access. 

Place of processing: United States - Privacy Policy. 

Statistics

The services contained in this section allow the Data Controller to monitor and analyze traffic data and are used to keep track of User behavior. 

Google Analytics (Google LLC) 

Google Analytics is a web analytics service provided by Google LLC (“Google”). Google uses the Personal Data collected for the purpose of tracking and examining the use of this Website, compiling reports and sharing them with other services developed by Google. 

Google may use the Personal Data to contextualize and personalize the advertisements of its own advertising network. 

Personal Data processed: Usage data; Tracking Tool. 

Place of processing: United States - Privacy Policy - Opt Out.

Viewing content from external platforms 

This type of service allows you to view content hosted on external platforms directly from the pages of this Website and interact with them. 
This type of service may still collect data on web traffic relating to the pages where the service is installed, even when users do not use it. 

Font Awesome (Fonticons, Inc.) 

Font Awesome is a font style visualization service managed by Fonticons, Inc. that allows this Website to integrate such content within its pages. 

Personal Data processed: Usage data. 

Place of processing: United States - Privacy Policy. 

Google Fonts (Google LLC)

Google Fonts is a font style visualization service managed by Google LLC that allows this Website to integrate such contents within its pages. 

Personal Data processed: Usage data; various types of data as specified in the privacy policy of the service. 

Place of processing: United States - Privacy Policy. 

Further information on Personal Data 
Sale of goods and services online

The Personal Data collected are used for the provision of services to the User or for the sale of products, including payment and possible delivery. The Personal Data collected to complete the payment may be those relating to the credit card, the current account used for the transfer or other payment instruments provided. The payment data collected by this website depend on the payment system used. 

User rights 

Users can exercise certain rights with reference to the data processed by the owner. 
In particular, the User has the right to: 

1. Revoke the consent at any time. The User can revoke the consent to the processing of their Personal Data previously expressed;
2. Oppose the processing of their data. The User may oppose the processing of their data when it occurs on a legal basis other than consent, without prejudice to the legitimate interest of the Data Controller, who can in any case justify the processing. Further details on the right to object are indicated in the section below. 
3. Access your Data. The User has the right to obtain information on the Data processed by the Data Controller, on certain aspects of the processing and to receive a copy of the Data processed; 
4. Verify and ask for rectification. The User can verify the correctness of his / her Data and request its updating or correction;
5. Obtain the limitation of the treatment. When certain conditions are met, the User may request the limitation of the processing of their Data. In this case the Data Controller will not process the Data for any other purpose than their conservation. 
6. Obtain the cancellation or removal of your Personal Data. When certain conditions are met, the User can request the cancellation of their Data by the Owner.
7. Receive your data or have it transferred to another owner. The User has the right to receive his / her Data in a structured format, commonly used and readable by an automatic device and, where technically feasible, to obtain the transfer without obstacles to another owner. This provision is applicable when the Data is processed with automated tools and the processing is based on the User's consent, on a contract to which the User is a party or on contractual measures connected to it. 
8. Make a complaint. The User can lodge a complaint with the competent personal data protection supervisory authority or take legal action. 

Details on the right to object

When Personal Data are processed in the public interest, in the exercise of public authority vested in the Data Controller or to pursue a legitimate interest of the Data Controller, Users have the right to oppose the processing for reasons connected to their particular situation. 

Users are reminded that, if their Data are processed for direct marketing purposes, they can oppose the processing without providing any reasons. To find out if the Data Controller processes data for direct marketing purposes, Users can refer to the respective sections of this document. 

How to exercise your rights

To exercise the User's rights, Users can direct a request to the contact details of the Owner indicated in the appropriate paragraph. Further information on treatment 
Defense in court 

The User's Personal Data may be used by the Owner in court or in the preparatory stages for its eventual establishment for the defense against abuse in the use of this Website or related Services by the User. 

The User declares to be aware that the Owner may be obliged to disclose the Data by order of the public authorities. 

Specific information

At the request of the User, in addition to the information contained in this privacy policy, this Website may provide the User with additional and contextual information regarding specific Services, or the collection and processing of Personal Data. 

System 

logs and maintenance For needs related to operation and maintenance, this Website and any third party services used by it may collect system logs, which are files that record the interactions and which may also contain Personal Data, such as the IP address User. 

Information not contained in this policy

Further information in relation to the processing of Personal Data may be requested at any time from the Data Controller using the contact details. 

Response to "Do Not Track" requests 

This Website does not support "Do Not Track" requests. To find out if any third-party services used support them, the User is invited to consult the respective privacy policies. 

Changes to this privacy policy 

The Data Controller reserves the right to make changes to this privacy policy at any time by notifying Users on this page. Please therefore consult this page frequently, referring to the date of the last modification indicated at the bottom.

If the changes concern treatments whose legal basis is consent, the Data Controller will collect the User's consent again, if necessary. 

Definitions and legal references 
Personal Data (or Data) 

Any information that, directly or indirectly, also in connection with any other information, including a personal identification number, makes a natural person identified or identifiable. 

Usage Data

This is the information collected automatically through this Website (including from third-party applications integrated into this Website), including: the IP addresses or domain names of the computers used by the User who connects with this Website, the addresses in URI (Uniform Resource Identifier) ​​notation, the time of the request, the method used to forward the request to the server, the size of the file obtained in response, the numerical code indicating the status of the response from the server (successful, error, etc. .) the country of origin, the characteristics of the browser and operating system used by the visitor, the various temporal connotations of the visit (for example the time spent on each page) and the details of the itinerary followed within the Application,with particular reference to the sequence of the pages consulted, to the parameters relating to the operating system and the IT environment of the User.

User 

The individual who uses this Website who, unless otherwise specified, coincides with the Data Subject. 

Interested 

The natural person to whom the Personal Data refers. 
Data 

Processor (or Manager) 
The natural person, legal person, public administration and any other body that processes personal data on behalf of the Data Controller, as set out in this privacy policy. 

Data Controller (or Owner)

The natural or legal person, public authority, service or other body which, individually or together with others, determines the purposes and means of the processing of personal data and the tools adopted, including the security measures relating to the operation and use of this Website. The Data Controller, unless otherwise specified, is the owner of this Website. 

This Website (or this Application) 

The hardware or software tool through which the Personal Data of Users are collected and processed. 

Service 

The Service provided by this Website as defined in the relative terms (if any) on this site / application. 

European Union (or EU)

Unless otherwise specified, any reference to the European Union contained in this document is intended to be extended to all current member states of the European Union and the European Economic Area. 

Cookies 

Cookies are Tracking Tools which consist of small pieces of data stored in the User's browser. 

Tracking Tool Tracking 

Tool means any technology - eg. Cookies, unique identifiers, web beacons, integrated scripts, e-tags and fingerprinting - which allow users to be tracked, for example by collecting or saving information on the User's device. 

Full access Full 

access to your account, including permanent deletion of conversations and messages.

Legal references 

This privacy statement is drawn up on the basis of multiple legislative systems, including articles. 13 and 14 of Regulation (EU) 2016/679. 

Unless otherwise specified, this privacy policy applies exclusively to this Website. 

Last modified: June 25, 2021
`
