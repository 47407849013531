import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import be from '../../BE';
import auth from '../../Account/Auth';

const WhatsappIcon = () => (<i className="fa-brands fa-whatsapp me-2" style={{fontSize:31,color:'#25D366'}}> </i>);
const TelegramIcon = () => (<i className="fa-brands fa-telegram me-2" style={{fontSize:28,color:'#0088cc'}}> </i>);
const EmailIcon = () => (<i className="fa-solid fa-envelope me-2" style={{fontSize:27,color:'var(--color-text)'}}> </i>);

const editButtonStyle = {
  fontSize:12,
  padding:'0 5px',
  border:'1px solid var(--color-primary)',
  color:'var(--color-text)',
  background:'var(--color-background-1)',
  borderRadius:5,
  verticalAlign:'text-bottom',
}



function NotificationsPanel(){
  const history = useHistory();
  const { t } = useTranslation(['settings']);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [notifications, setNotifications] = useState(false);
const getNotifications = async () => {
      const body = {};
      setLoadingInfo(true);
      try{
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('account-jubilant','/settings/notifications',body,true,true)
            .then(response => {
              setNotifications(response);
            })
            .catch(error => {
              console.error("[settings][notifications] network error while retrieving info:",error);
            })
        })
        .catch((error) => {
          console.error("[settings][notifications] authentication error while retireving info:", error)
          history.push({pathname:'/login'})
        })
      }catch (error) { 
        console.error("[settings][notifications] functional error while retrieving info:",error);
      }finally{
        setLoadingInfo(false);
      }
    }
  useEffect(()=>{
    
    getNotifications();
  },[]) 

const saveNotification = async (_provider) => {
    try{
      const body = {provider:_provider};
      setLoadingInfo(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.post('account-jubilant','/settings/notifications',body,true,true)
            .then(response => {
              getNotifications()
            })
            .catch(error => {
              console.error("[settings][notifications] network error while retrieving info:",error);
            })
        })
        .catch((error) => {
          console.error("[settings][notifications] authentication error while retireving info:", error)
          history.push({pathname:'/login'})
        })
      }catch (error) { 
        console.error("[settings][notifications] functional error while retrieving info:",error);
        setLoadingInfo(false);
      }finally{
      }
    }


  const Action = ({provider}) => {
    switch(provider){
      case 'whatsapp':
        return <button style={editButtonStyle} onClick={(e) => {e.preventDefault();history.push('integrations#whatsapp')}}>{t('notifications.editConnection')}</button>
      case 'telegram':
        return <button style={editButtonStyle} onClick={(e) => {e.preventDefault();history.push('integrations#telegram')}}>{t('notifications.editConnection')}</button>
      case 'email':
        return null;
      default:
        return null;
    }
  }

  const Label = ({value,icon,text,action}) =>(
    <div style={{marginLeft:15,width:350,display:'flex',alignItems:'center'}}>
      <div>{icon}</div>
      <div>
        <div style={{fontWeight:700}}>{text}</div>
        <div style={{fontSize:14, color:'var(--color-secondary)'}}>{value || t('notifications.notConnected')}</div>
      </div>
      <div style={{display:'flex',width:120,justifyContent:'end',marginLeft:'auto'}}>{action}</div>
    </div>
  )

  return(

    <Tab.Pane eventKey="notifications">
      <Form className="d-flex flex-column">
        <Form.Check
          className="my-3 d-inline-flex align-items-center"
          name="notifications"
          type="radio"
          label={<Label value={notifications?.whatsapp?.value} icon={<WhatsappIcon/>} text={"Whatsapp"} action={<Action provider='whatsapp'/>}/>}
          disabled={!notifications?.whatsapp?.value || loadingInfo}
          checked={notifications?.whatsapp?.broker}
          onChange={(e) => saveNotification('whatsapp')}
        />
        <Form.Check
          className="my-3 d-inline-flex align-items-center"
          name="notifications"
          type="radio"
          label={<Label value={notifications?.telegram?.value} icon={<TelegramIcon/>} text={"Telegram"} action={<Action provider='telegram'/>}/>}
          disabled={!notifications?.telegram?.value || loadingInfo}
          checked={notifications?.telegram?.broker}
          onChange={(e) => saveNotification('telegram')}
        />
        <Form.Check
          className="my-3 d-inline-flex align-items-center"
          name="notifications"
          type="radio"
          label={<Label value={notifications?.email?.value} icon={<EmailIcon/>} text={"Email"} action={<Action provider='email'/>}/>}
          disabled={!notifications?.email?.value || loadingInfo}
          checked={notifications?.email?.broker}
          onChange={(e) => saveNotification('email')}
        />
      </Form>
    </Tab.Pane>
  );
}

export default NotificationsPanel;
