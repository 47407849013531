import { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import auth from '../Account/Auth';
import be from '../BE';

const DEBUG = (window.location.hostname === 'localhost' || window.location.hostname === 'dev.calendbook.com');
const TEST_TOKEN = "test_2ee936d492e84b8c70ff282cacf";
const LIVE_TOKEN = "live_f9e2fd79f6f00aa1b9f8a4f55ec";

const TEST_YEARLY_PREMIUM = "pri_01hnd8w440pst7jaenb4pxjeap";
const TEST_MONTHLY_PREMIUM = "pri_01hnd8x6tzhbd13qw6d7tdkyn5";
const LIVE_YEARLY_PREMIUM = "pri_01hg8swx54tmf43w05cpgk97cc";
const LIVE_MONTHLY_PREMIUM = "pri_01hg8sw20z338km4mns1n08bxg"

const TEST_YEARLY_PROFESSIONAL = "pri_01hrc18ywbwns9mev9kqk54e03";
const TEST_MONTHLY_PROFESSIONAL = "pri_01hrc188kzp7m8x2paw3x6chwq";
const LIVE_YEARLY_PROFESSIONAL = "pri_01hrc1cdmrhmnz9t7xpz1jp15y";
const LIVE_MONTHLY_PROFESSIONAL = "pri_01hrc1bfh4v2whzkvy9d4db067"

function Checkout({showCheckout, setShowCheckout,  quantity, getTeamStatus}){
  const {i18n,t} = useTranslation(['landing','common',"upgrade"]);
  const [loading, setLoading] = useState(false);
  const [paddle, setPaddle] = useState();
  const [view, setView] = useState(window.location.hash === '#agency' ? 'agency' : 'upgrade');
  const [subscription,setSubscription] = useState({});
  const [pricingCadency,setPricingCadency] = useState("yearly");
  const [customerId,setCustomerId] = useState("");

  const getUserLanguage = () => {try{return i18n.language.substring(0,2)}catch(e){return 'en'}}

  var createNestedObject = function( base, names ) {
    // Usage:
    // createNestedObject( window, ["shapes", "triangle", "points"] );
    // Now window.shapes.triangle.points is an empty object, ready to be used.
    for( var i = 0; i < names.length; i++ ) {
      base = base[ names[i] ] = base[ names[i] ] || {};
    }
  };

  const sleep = (ms) => {
    /* usage: await sleep(3000); */
    return new Promise((resolve) => setTimeout(resolve,ms));
  }

 const sendAbandonedCartEvent = async (body) => {
    await auth.isAuthenticated()
      .then(
        await be.post("payment-jubilant","/payments/abandoned",body,true,true)
        .then((response) => {
          console.log("[upgrade][subscription] sent bandoned cart event: ", response);
        })
        .catch(error => {
          console.log(error);
        })
      )
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    initializePaddle(
      {
        // environment is 'sandbox' when testing, 'production' when live
        environment: (DEBUG ? 'sandbox' : 'production'),
        checkout: {
          settings: {
            displayMode:"overlay",
            theme:'light',
            locale:getUserLanguage()
          }
        },
        token: (DEBUG ? TEST_TOKEN : LIVE_TOKEN),
        eventCallback: async function(data) {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.closed":
              setShowCheckout(false);
              const status = data?.data?.status;
              if (status === "draft"){
                const body = {
                  productId: data?.data?.items[0]?.product?.id,
                  priceId: data?.data?.items[0]?.price_id
                }
                await sendAbandonedCartEvent(body);
              }
              break;
            case "checkout.completed":
              setLoading(true)
              await sleep(5000)
              //recall team status
              await getTeamStatus();
              setShowCheckout(false);
              break;
            default:
          }
        } 
      }).then(
        (paddleInstance) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        },
      );
  }, []);

  const openCheckout = (product) => {
    const getSubscriptionPriceId = (_product) => {
      if(_product === 'premium'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_PREMIUM}
          if(pricingCadency === "yearly"){return TEST_YEARLY_PREMIUM}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_PREMIUM}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_PREMIUM}
        }
      }
      if(_product === 'professional'){
        if(DEBUG){
          if(pricingCadency === "monthly"){return TEST_MONTHLY_PROFESSIONAL}
          if(pricingCadency === "yearly"){return TEST_YEARLY_PROFESSIONAL}
        }else{
          if(pricingCadency === "monthly"){return LIVE_MONTHLY_PROFESSIONAL}
          if(pricingCadency === "yearly"){return LIVE_YEARLY_PROFESSIONAL}
        }
      }
    }
    let checkoutObj = {
      items: [
        {
          priceId: getSubscriptionPriceId(product),
          quantity: quantity
        }
      ]
    }
    if(customerId){
      createNestedObject(checkoutObj,["customer","id"]);
      checkoutObj['customer']['id'] = customerId;
    }else if(auth.getEmail()){
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = auth.getEmail();
    }else{
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = "your@email.com";
    }
    paddle?.Checkout.open(checkoutObj);
  };

  if(showCheckout){
    openCheckout('premium');
  }

  return(null);
}

export default Checkout;
