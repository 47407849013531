function RatingStars({ contact, rating, onChange, savingRating }) {
  const STARS_NUMBER = 5;

  const starLightClass = (_starIndex, _rating) => {
    const baseClass = `tw-cursor-pointer ${savingRating === contact && 'tw-text-gray-500 tw-animate-pulse'}`;
    if (_starIndex <= Number(_rating)) {
      return `${baseClass} tw-text-orange-400`;
    } else {
      return `${baseClass} tw-text-gray-400`;
    }
  };

  // Helper function to create a star component
  const renderStar = (_starIndex) => {
    return (
      <div key={`${contact}-star-${_starIndex}`} className="hover:tw--translate-y-0.5">
        <input
          type="radio"
          name={`rating-${contact}`} // Ensure name is unique for each contact
          className="!tw-hidden"
          id={`star-${contact}-${_starIndex}`}
          value={_starIndex}
          disabled={savingRating === contact}
          onChange={() => {
            console.log(`Star ${_starIndex} onChange fired for contact:`, contact);
            onChange(contact, _starIndex);
          }}
        />
        <label
          htmlFor={`star-${contact}-${_starIndex}`}
          className={starLightClass(_starIndex, rating)}
        >
          ★
        </label>
      </div>
    );
  };

  return (
    <div key={`${contact}-rating-container`} className="tw-flex tw-z-100">
      {Array.from({ length: STARS_NUMBER }, (_, index) => renderStar(index + 1))}
    </div>
  );
}

export default RatingStars;

