import React from 'react';
import { useTranslation } from 'react-i18next';

function Subtitle({name,taskNumber}){

  const { t } = useTranslation(['onboardingChecklist']);
  return(
    <>
      <div>
        {t(`${name}.${taskNumber}.subtitle`)}
      </div>
    </>
  );

}


export default Subtitle;
