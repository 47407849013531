import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

function toHyperlink(text)
{
  //eslint-disable-next-line
  var pattern1 = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  var str1 = text.replace(pattern1, "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>");
  //eslint-disable-next-line
  var pattern2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
  var str2 = str1.replace(pattern2, '$1<a target="_blank" rel="noopener noreferrer" href="http://$2">$2</a>');

  return str2;
}

// need to be wary of script injection here
// (for example if I have http://"><script>doevil()</script>)
function EventDescription({description}){
  const text = DOMPurify.sanitize(description)
  const { t } = useTranslation(['booking']);

  return(
    <>
      <div className="fw-bold">{t('details.description')}</div>
      <div style={{color:'var(--color-secondary)'}} dangerouslySetInnerHTML={{__html: toHyperlink(text)}} />
    </>
  );
}

export default EventDescription;
