import {useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import {useCreateEventTypeStore} from '../../store';
import {useTranslation} from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import be from '../../BE';
import auth from '../../Account/Auth';

function Payments(){
  const {t} = useTranslation(['eventCreator']);
  const [stripeUser,setStripeUser] = useState({});
  const [loadingStripeUser,setLoadingStripeUser] = useState(false)
  const [loadingPriceChange,setLoadingPriceChange] = useState(false)
  const [status,setStatus] = useState(0);
  const general = useCreateEventTypeStore(state => state.general);
  const paidEvent = useCreateEventTypeStore(state => state.paidEvent);
  const setPrice = useCreateEventTypeStore(state => state.setPrice);
  const setPriceId = useCreateEventTypeStore(state => state.setPriceId);
  const setProductId = useCreateEventTypeStore(state => state.setProductId);

  useEffect(() => {
    console.info("[etype][stripe] loading user info")
    const getStripeUserProfile = async () => {
      setLoadingStripeUser(true)
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('stripe-connect','/connect/userProfile',false,true,true)
            .then(response => {
              if(response.email !== null){
                setStripeUser(response);
                console.info("[etype][stripe] Set stripe user profile:", response)
              }else{
                setStripeUser({});
                console.info("[etype][stripe] Fetched empty user profile:",response);
              }
            })
            .catch((e) => {
              console.info("[etype][stripe] Error fetching user profile:",e);
            });
        })
        .catch((e) => {
          console.info("[etype][stripe] Error authenticating user:",e);
        });
      setLoadingStripeUser(false)
    }
    getStripeUserProfile();
  },[])

  // could send 0 / 00 / 000, later we should avoid that
  const backendFormatter = (number) => {
    console.info(`[etype][payment] formatting price:`)
    let result = number;
    if (number !== undefined){
      if(number.includes(",")){
        let decimal = number.split(',')[1]
        if (decimal.length === 0){number += '0'}
        if (decimal.length < 2){number += '0'}
        result = number.replace(",","")
      }else{
        result = number?.replace(/^0+/, '').replace('.','') + "00";
      }
    }else{
      result = '0';
    }
    console.info(`FE: ${number}\nBE: ${result}`)
    return result
  }

  const frontendFormatter = (number) => {
    console.info('[etype][payment] parsing be price:');
    let result = '';
    if(number !== undefined){
      result = Number(number)/100;
    }
    console.info(result);
    return result;
  };

  const createPayment = async () => {
    const createEventKey = (eventName) => {
      const validation = /[.,\/#!$%\^&\*;:{}=\-_`~()@?£^']/g;
      let eventKey = eventName.toLowerCase().replace(/ /g, "");
      eventKey = eventKey.replace(validation, "");
      return eventKey;
    }
    setLoadingPriceChange(true)
    setStatus(0)

    if(!general?.name){
      setLoadingPriceChange(false)
      setStatus(-1)
      return;
    }

    await auth.isAuthenticated()
      .then(async () => {
        let body = {
          amount:paidEvent?.amount,
          eventName:general?.name,
          eventKey:createEventKey(general?.name)
        }
        // if productId is available i'm editing price, so productId is needed
        if(paidEvent?.productId){
          body.productId = paidEvent?.productId
        }
        await be.post('stripe-connect','/connect/price',body,true,true)
          .then(response => {
            setPriceId(response?.priceId);
            setProductId(response?.productId);
            setStatus(200)
          })
          .catch((e) => {
            console.info("[etype][stripe] Error creating payment id:",e);
            setStatus(e?.response.status)
          });
      })
      .catch((e) => {
        console.info("[etype][stripe] Error authenticating user:",e);
      });
    setLoadingPriceChange(false)
  }

  const currencyStyle = {
    border:'1px solid var(--color-border)',
    borderRadius:'0',
    minWidth:'200px',
    background:"var(--color-background-2)",
    color:'var(--color-text)',
    paddingLeft:15
  }

  const buttonStyle = {
    minWidth:'45px'
  }

  const responseFeedbackStyle = {
    fontSize:14
  }

  return(
    <>
      <Card id="payments" style={{color:'var(--text-color)',background:"var(--color-background-1)"}} className="mb-2">
        <Card.Header className="h3">
          {t('payments.header.title')} <Badge pill >{t('payments.header.badge')}</Badge>
        </Card.Header>
        <Card.Body>
          {loadingStripeUser
            ?
              <>
                {t('payments.loading')}
                <Spinner
                  animation="border"
                  size="sm"
                  className="m-0 ms-sm-2"/>
                </>
            :
              <>
                {Object.keys(stripeUser).length !== 0
                  ?
                    <>
      
                      <div className="my-3">{t('payments.header.subtitle')}</div>

                      <InputGroup className="mb-3">
                        {stripeUser?.currency && getSymbolFromCurrency(stripeUser?.currency) !== undefined && 
                          <InputGroup.Text>{getSymbolFromCurrency(stripeUser?.currency)}</InputGroup.Text>
                        }
                        <CurrencyInput
                          id="event-type-price"
                          name="event-type-price"
                          placeholder={t('payments.priceInput.placeholder')}
                          decimalSeparator=","
                          groupSeparator="."
                          defaultValue={frontendFormatter(paidEvent?.amount)}
                          value={frontendFormatter(paidEvent?.amount)}
                          onValueChange={(value) => {if(value >= 1) setPrice(backendFormatter(value))}}
                          onBlur={createPayment}
                          style={currencyStyle}
                        />
                        <Button variant="primary" style={buttonStyle} onClick={createPayment} disabled={loadingPriceChange}>

                          <span className="d-none d-sm-inline-block">
                            {paidEvent?.productId
                              ? <>{t('payments.priceInput.button.edit')}</>
                              : <>{t('payments.priceInput.button.create')}</>
                            }
                          </span> 

                          {!loadingPriceChange &&
                          <i className="fa-solid fa-arrow-right m-0 ms-sm-2"></i>
                          }

                          {loadingPriceChange && 
                            <Spinner
                              animation="border"
                              size="sm"
                              className="m-0 ms-sm-2"/>
                          }
                        </Button>
                      </InputGroup>
                      {(status === -1) &&
                      <p className="text-danger" style={responseFeedbackStyle}>{t('payments.feedbacks.setName')}</p>
                      }
                      {(status > 400 && status <= 500) &&
                      <p className="text-danger" style={responseFeedbackStyle}>{t('payments.feedbacks.error')}</p>
                      }
                      {(status === 400) &&
                        <p className="text-danger" style={responseFeedbackStyle}>{t('payments.feedbacks.400')}</p>
                      }
                      {status === 200 &&
                        <p className="text-success" style={responseFeedbackStyle}>{t('payments.feedbacks.200')}</p>
                      }

                      <small>
                      <div>{t('payments.fees.calendbook')}</div>
                        <div><a href="https://stripe.com/it/pricing" target="_blank" rel="noopener noreferrer">{t('payments.fees.stripe')}</a></div>
                      </small>
                    </>
                  :
                    <>
                      {t('payments.connect.connectStripe')} <Link to="/integrations/#stripe">{t('payments.connect.link')}</Link>
                      {t('payments.connect.setPayments')}
                    </>
                }
              </>
          }
          
        </Card.Body>
      </Card>
    </>
  );
}

export default Payments;
