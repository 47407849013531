import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SaveBar ({backButton,saving}) {
  
  const {t} = useTranslation(['eventCreator']);

  const BackButton = () => {
    return <>
      <Link to={{pathname: '/'}}>
        <Button variant="secondary" className="float-start my-2">
          <i className="fas fa-long-arrow-alt-left"></i> 
        </Button>
      </Link>
    </>
  }

  const SaveButton = ({saving}) => {
    return <Button
        type="submit"
        className="float-end my-2"
        style={{minWidth:120}}
      >
          {saving
            ?
              <Spinner
                animation="border"
                size="sm"
                className="my-auto"
              />
            :
              t('saveButton')}
      </Button>
  }

  return (
    <Row>
      {backButton &&
        <Col>
          <BackButton/>
        </Col>
      }

      <Col>
        <SaveButton saving={saving}/>
      </Col>
    </Row>
  );
}

export default SaveBar;
