export const stripeTermsText = `
Booking Cancellation Policy:

We want to provide clear guidelines regarding booking cancellations for both guests and owners. Please take note of the following:

Guest Cancellation:

If a guest decides to cancel their booking, no refunds will be provided. We understand that plans can change, but we encourage guests to carefully consider their commitments before confirming a booking. This helps maintain fairness for owners who rely on confirmed bookings.

Owner Cancellation:

If the owner cancels the booking, they have the option to issue a refund to the guest. We prioritize reliability and trustworthiness, and expect owners to fulfill their commitments to guests. However, please note that in exceptional circumstances, an owner may need to cancel due to unforeseen events or circumstances beyond their control. In such cases, we work closely with both parties to find a fair resolution.

By using our app, users agree to abide by this booking cancellation policy, which serves as the agreement between the app provider and its users. It is essential for both guests and owners to understand and respect these guidelines to ensure a smooth and reliable booking experience.

Should you have any questions or require further assistance, our dedicated customer support team is always ready to help.
`
