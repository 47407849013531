import { useTranslation } from 'react-i18next';
import './view-toggler.css';

function _ViewToggler({activeView,toggleView}) {
  const {t} = useTranslation(['home']);
  return (
    <center>
      <input 
        id="upcomings-view"
        className="view-toggle toggle-left"
        name="view-type"
        value="false"
        type="radio"
        defaultChecked={activeView === 'upcomings' ? true : false}
      />
      <label
        htmlFor="upcomings-view"
        className="view-toggle-label"
        onClick={() => toggleView('upcomings')}
      >
        {t('viewToggler.upcomings')}
      </label>
      
      <input
        id="event-types-view"
        className="view-toggle toggle-right"
        name="view-type"
        value="true"
        type="radio"
        defaultChecked={activeView === 'eventTypes' ? true : false}
      />
      <label
        htmlFor="event-types-view"
        className="view-toggle-label"
        onClick={() => toggleView('eventTypes')}
      >
        {t('viewToggler.eventTypes')}
      </label>
    </center>
  );

}

export default _ViewToggler;
