import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function Name (){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setName = useCreateEventTypeStore((state) => state.setName);

  return(
    <Form.Group as={Row} controlId="event-name" className="my-3 ms-2 me-2">
      <Form.Label column sm={2}>{t('general.name')}</Form.Label>
      <Col sm={10} lg={6}>
        <Form.Control
          className="dark-field"
          name="name"
          value={general?.name}
          onChange={(e) => setName(e.target.value)}
          maxLength={70}
          required
        />
      </Col>
    </Form.Group>
  );
}

export default Name;
