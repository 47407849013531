import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup'
import be from '../../../BE';
import auth from '../../../Account/Auth';


function FullName(){
  const {t} = useTranslation(['settings']);
  const [fullName, setFullName] = useState(auth.getFullName());
  const [processing,setProcessing] = useState(false)
  const [status, setStatus] = useState(0);

  const responseFeedbackStyle = {
    fontSize:14
  }

  const handleChange = (event) => {
    setStatus(0);
    setFullName(event.target.value);
  }

  const isValidName = () => {
    const regex = /^(\p{L}+ ?)*\p{L}+$/gu
    return regex.test(fullName)
  }
  async function setOwnerFullName(e) {
    if(!isValidName()){
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setProcessing(true);
      setStatus(0);
      await auth.isAuthenticated()
        .then(async () => {
          await be.post("account","/setOwnerFullName",{name : fullName},true)
            .then((r) => setStatus(200))
            .then(async () => await auth.refresh())
            //.then(() => window.location.reload(false))
            .catch((e) => {setStatus(e?.response?.status)})
        })
        .catch(error => console.log(error))
      setProcessing(false);
    }
  }
    return(
    <>
      <h4>{t("fullName.title")}</h4>
      <Form noValidate validated={isValidName()} onSubmit={setOwnerFullName}>
        <InputGroup hasValidation>
          <FormControl
            type="text"
            name="full-name"
            id="full-name-field"
            aria-describedby="full-name"
            value={fullName}
            onChange={handleChange}
            isInvalid={!isValidName()}
          />
          <Button disabled={processing} type='submit'>
            {processing 
              ? 
                <Spinner size="sm" animation="border" variant="light" /> 
                : 
                <i className="fas fa-long-arrow-alt-right"></i>
            }
          </Button>
          <Form.Control.Feedback type="invalid">
            <span>{t("fullName.invalid.message")}</span>
            <br/>
            <span>{t("fullName.invalid.hint")}</span> 
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
      {(status > 400 && status <= 500) &&
        <p className="text-danger" style={responseFeedbackStyle}>{t("fullName.error")}</p>
      }
      {status === 200 &&
        <p className="text-success" style={responseFeedbackStyle}>{t("fullName.success")}</p>
      }

    </>
  );
}

export default FullName;
