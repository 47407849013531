import React, { useEffect } from "react";
import { PrivacyIta } from "./privacy-ita";
import { PrivacyEng } from "./privacy-eng";
import { useTranslation } from "react-i18next";

function Privacy() {
  try {
    window.tidioChatApi.show();
  } catch {}
  const { i18n } = useTranslation();

  useEffect(() => {
    document.title = "Calendbook - Privacy";
  }, []);
  return (
    <div style={{ margin: "50px" }}>
      <pre
        style={{
          "white-space": "pre-wrap",
          "white-space": "-moz-pre-wrap",
          "white-space": "-o-pre-wrap",
          "white-space": "-pre-wrap",
          "word-wrap": "break-word",
          "white-space": "break-spaces",
          "overflow-x": "auto",
        }}
      >
        {i18n.language.substring(0, 2) === "it" ? PrivacyIta : PrivacyEng}
      </pre>
    </div>
  );
}

export default Privacy;
