import React, { useEffect } from "react";
import { UserProvider } from "./UserContext";
import { ThemeProvider } from "./ThemeContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import Logout from "./Logout";
import Privacy from "./Privacy/Privacy";
import Refund from "./Refund/Refund";
import Terms from "./Terms/Terms";
import StripeTerms from "./Integrations/Stripe/Terms/StripeTerms";
import Navbar_ from "./Navbar/Navbar_";
import _Footer from "./Footer/_Footer";
import _Home from "./Home/_Home";
import EventTypeCreator from "./EventTypeCreator/_EventTypeCreator";
import Booking from "./BookingPage/Booking";
import BookingConfirmation from "./BookingConfirmation/BookingConfirmation";
import _Settings from "./Settings/_Settings";
import _Landing from "./Landing/_Landing";
import _PublicEvents from "./PublicEvents/_PublicEvents";
import Integrations from "./Integrations/_Integrations";
import WixIntegrationLanding from "./Integrations/Public/Wix/Wix";
import Embed from "./Embed/_Embed";
import Team from "./Team/_Team";
import TeamInvitePage from "./Team/InvitePage/InvitePage";
import Agency from "./Agency/Agency";
import InvitePage from "./Agency/InvitePage/InvitePage";
import Upgrade from "./Upgrade/Upgrade";
import CalendarConnections from "./CalendarConnections/_CalendarConnections";
import Onboarding from "./Onboarding/Onboarding";
import Leads from "./Leads/Leads";
import ScrollToTop from "./Utilities/ScrollToTop";
import { useIframeStore } from "./store";
import { isIframe } from "./Utilities/iframeUtils";
import { setRootAsIframe } from "./Utilities/iframeUtils";
import { getIframeReferrer } from "./Utilities/iframeUtils";
import { Toaster } from "react-hot-toast";
import { initFlowbite } from "flowbite";
import auth from "./Account/Auth";

function Analytics() {
  const location = useLocation();

  useEffect(() => {
    // Retrieve user properties
    const userId = auth.getProperty({
      storageKey: "userSession",
      path: ["profileObj", "username"],
      fallback: null,
    });

    const userEmail = auth.getProperty({
      storageKey: "userSession",
      path: ["profileObj", "email"],
      fallback: null,
    });

    const userPlanStatus = auth.getProperty({
      storageKey: "userSession",
      path: ["profileObj", "planStatus", "status"],
      fallback: "free",
    });

    // Set user ID and properties if user is logged in
    if (userId) {
      // Set user ID
      ReactGA.set({ user_id: userId });

      // Set additional user properties
      ReactGA.gtag("set", "user_properties", {
        email: userEmail,
        plan_status: userPlanStatus,
      });
    } else {
      // Clear user ID and properties if not logged in
      ReactGA.set({ user_id: null });
      // Optionally clear other user properties
      ReactGA.gtag("set", "user_properties", {});
    }

    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);

  return null;
}

function App() {
  const isIframePage = isIframe();
  const isOnboarding = window.location.pathname === "/onboarding";
  const shouldShowNavs = useIframeStore((state) => state.shouldShowNavs);
  const setShouldShowNavs = useIframeStore((state) => state.setShouldShowNavs);

  useEffect(() => {
    // needed to load flowbite.js behaviours
    initFlowbite();

    // logic to show/hide navbar and footer
    if (isIframePage) {
      if (getIframeReferrer() !== "wix-dashboard") {
        setRootAsIframe();
        setShouldShowNavs(false);
      } else if (getIframeReferrer() === "wix-dashboard") {
        setShouldShowNavs(true);
      }
    } else if (isOnboarding) {
      setShouldShowNavs(false);
    } else {
      setShouldShowNavs(true);
    }
  }, [shouldShowNavs]);

  return (
    <>
      <UserProvider>
        <Router>
          <ScrollToTop>
            <ThemeProvider>
              {shouldShowNavs && <Navbar_ />}
              <Analytics />
              <Switch>
                <Route exact path="/" component={_Home}></Route>
                <Route path="/login" component={_Landing}></Route>
                <Route path="/logout" component={Logout}></Route>
                <Route path="/onboarding" component={Onboarding}></Route>
                <Route path="/upgrade" component={Upgrade}></Route>
                <Route path="/settings" component={_Settings}></Route>
                <Route path="/privacy" component={Privacy}></Route>
                <Route path="/refund" component={Refund}></Route>
                <Route path="/terms" component={Terms}></Route>
                <Route path="/stripe-terms" component={StripeTerms}></Route>
                <Route
                  path="/eventcreator"
                  component={EventTypeCreator}
                ></Route>
                <Route
                  path="/eventeditor"
                  render={(props) => <EventTypeCreator {...props} />}
                ></Route>
                <Route
                  exact
                  path="/integrations/wix"
                  component={WixIntegrationLanding}
                ></Route>
                <Route path="/integrations" component={Integrations}></Route>
                <Route path="/embed" component={Embed}></Route>
                <Route path="/leads" component={Leads}></Route>
                <Route
                  path="/calendarconnections"
                  component={CalendarConnections}
                ></Route>
                <Route exact path="/team" component={Team}></Route>
                <Route
                  exact
                  path="/team/invitee"
                  component={TeamInvitePage}
                ></Route>
                <Route exact path="/agency" component={Agency}></Route>
                <Route
                  exact
                  path="/agency/invitee"
                  component={InvitePage}
                ></Route>
                <Route exact path="/404" component={() => "404 NOT FOUND"} />
                <Route exact path="/:user" component={_PublicEvents} />
                <Route
                  exact
                  path="/:user/:event"
                  render={(props) => <Booking {...props} mode="book" />}
                />
                <Route
                  exact
                  path="/operations/reschedule/:bookingid"
                  render={(props) => <Booking {...props} mode="reschedule" />}
                />
                <Route
                  exact
                  path="/operations/delete/:bookingid"
                  render={(props) => <Booking {...props} mode="delete" />}
                />
                <Route
                  exact
                  path="/operations/confirm/:bookingid"
                  render={(props) => <BookingConfirmation {...props} />}
                />
                <Route path="*" component={() => "404 NOT FOUND"} />
              </Switch>
            </ThemeProvider>
            {shouldShowNavs && <_Footer />}
          </ScrollToTop>
        </Router>
      </UserProvider>
      <Toaster />
    </>
  );
}

export default App;
