import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCreateEventTypeStore} from '../../store';
import Email from './Email';
import SalesForza from './SalesForza';
import Reminders from './Reminders';
import UpgradePath from '../../Utilities/UpgradePath/UpgradePath';

function Notifications () {
  const {t} = useTranslation(['eventCreator']);
  const notifications = useCreateEventTypeStore(state => state.notifications);
  const addReminder = useCreateEventTypeStore((state) => state.addReminder);

  return (
    <>

      <Card style={{color:'var(--text-color)',background:"var(--color-background-1)"}} className="mb-2">

        <Card.Header className="h3">
          {t('advanced.header')}
        </Card.Header>

        <Card.Body>
          <UpgradePath 
            featureName="custom-email-notifications"
            position="right">
            <Form.Group as={Row} controlId="event-emails" className="my-3 mx-2">
              <Form.Label column sm={12} lg={2}>{t('advanced.emailNotifications')}</Form.Label>
              <Col xs={12} lg={6}>
                <Email/>
              </Col>
            </Form.Group>
          </UpgradePath>

          <UpgradePath 
            featureName="salesforza-email-notifications"
            position="right">
            <Form.Group as={Row} controlId="salesforza-emails" className="my-3 mx-2">
              <Form.Label column sm={12} lg={2}>{t('advanced.salesForzaEmailNotifications')}</Form.Label>
              <Col xs={12} lg={6}>
                <SalesForza/>
              </Col>
            </Form.Group>
          </UpgradePath>

          <UpgradePath 
            featureName="custom-reminders"
            position="right">
            <Form.Group as={Row} controlId="event-reminders" className="my-3 mx-2">
              <Form.Label column sm={12} lg={2}>{t('advanced.reminders')}</Form.Label>
              <Col sm={12} lg={10}>
                <Row> <small style={{color:'var(--color-secondary)'}}>{t('advanced.remindersHint')}</small> </Row>
                <Row> 
                  {notifications?.reminders.length >0 && <Col> {notifications?.reminders.map((reminder,index) => ( <Reminders state={reminder} index={index}/> ))} </Col>}
                  {notifications?.reminders && notifications?.reminders.length < 10 && <Col xs={2} className="mt-2"> <div className="d-grid gap-2"> <Button className="text-start" style={{border : "none", background : "transparent", color : "var(--color-text)"}}  onClick={(e) => {e.preventDefault(); addReminder({method:'email',quantity:'5',unit:'minutes'})}}> <i className="fas fa-plus"></i> </Button> </div> </Col>}
                </Row>
              </Col>
            </Form.Group>
          </UpgradePath>
        </Card.Body>

      </Card>
    </>
);
}

export default Notifications;
