import React from 'react';
import { useTranslation } from 'react-i18next';
import {Nav} from 'react-bootstrap';

function AvailabilitiesTab(){
  const { t } = useTranslation(['settings']);
  return(
    <Nav.Item>
      <Nav.Link eventKey="availabilities" style={{fontSize:14}} className="text-center text-md-start">
        <i style={{width:20}} className="fa-solid fa-calendar text-center me-2"></i>Availabilities
      </Nav.Link>
    </Nav.Item>
  );
}

export default AvailabilitiesTab;
