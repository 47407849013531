import {useTranslation} from 'react-i18next';

const titleStyle = {
  fontSize:35,
  fontWeight:900,
}
const subtitleStyle = {
  fontSize:18,
}

function Header({owner,deleted,needsApproval}){
  const {t} = useTranslation(['booking']);
  return(
    <>
      {deleted 
        ? <div style={titleStyle} className="mb-2">{t('confirmed.header.canceled')} {owner}</div>
        : <div style={titleStyle} className="mb-2">{t('confirmed.header.scheduled')} {owner}</div>
      }
      <div style={subtitleStyle}>{t('confirmed.header.subtitle')}</div>
      {needsApproval &&
        <div style={subtitleStyle}><b>{t('confirmed.header.approval.disclaimerNote')} </b>{t('confirmed.header.approval.disclaimerText')}</div>
      }
    </>
  );
}

export default Header
