import Button from 'react-bootstrap/Button';
import {useCreateEventTypeStore} from '../../../../store';

function AnswerType({type,chosenType,order,id,existingQuestions,editing}){
  const setQuestionType = useCreateEventTypeStore((state) => state.setQuestionType);

  const getVariant = (_type,_chosenType) => {
    let variant = 'outline-primary';
    if(
        (_type === "radio" && _chosenType === 1) ||
        (_type === "checkbox" && _chosenType === 2) ||
        (_type === "text" && _chosenType === 3) ||
        (_type === "phone" && _chosenType === 4) ||
        (_type === "telegram" && _chosenType === 5) ||
        (_type === "whatsapp" && _chosenType === 6)
      ){
        variant = 'primary';
      }
    return variant;
  }

  const getTypeFromString = (_type) => {
    if(_type === "radio") return 1; 
    if(_type === "checkbox") return 2; 
    if(_type === "text") return 3; 
    if(_type === "phone") return 4; 
    if(_type === "telegram") return 5; 
    if(_type === "whatsapp") return 6; 
  }

  const getTypeFromNumber = (_chosenType) => {
    if(_chosenType === 1) return "radio";
    if(_chosenType === 2) return "checkbox";
    if(_chosenType === 3) return "text";
    if(_chosenType === 4) return "phone";
    if(_chosenType === 5) return "telegram";
    if(_chosenType === 6) return "whatsapp";
  }

  const shouldDisplay = () => {
    if(editing){
      let alreadyExists = existingQuestions.includes(id)
      if(alreadyExists){
        let isChosenType = type === getTypeFromNumber(chosenType)
        return isChosenType;
      }else{
        return true;
      }
    }else{
      return true;
    }
  }

  return(
    <>
      {shouldDisplay()  &&
        <Button size="sm" className="me-2" disabled={existingQuestions.includes(id)} variant={getVariant(type,chosenType)} onClick={() => setQuestionType(getTypeFromString(type),id)}>
          <span className="text-capitalize">{type}</span>
        </Button>
      }
    
    </>
  );
}
export default AnswerType
