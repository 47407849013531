import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';



function Private({enabled,secret}){
  const { t } = useTranslation(['eventType']);
  var color = "var(--color-background-2)";
  if(!enabled){
    color = "var(--color-background-3)"
  }
  return(
    <>
      {secret &&
      <span className="event-type-label" style={{backgroundColor:color,border:"1px solid",borderColor:"var(--color-border)"}}>
          <i className="fas fa-eye-slash me-2"></i> 
          {t('private')}
        </span>
      }
    </>
  );
}

export default Private;
