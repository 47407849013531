import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import auth from '../Account/Auth';

function UserStatus(){
  const role = auth.getProperty({
    storageKey: 'userSession',
    path: ['profileObj','planStatus','status'],
    fallback: 'unknown',
  });

  const getPlanColor = (_role) => {
    switch(_role){
      case "LTD": return "text-success";
      case "professional": return "text-primary";
      case "premium": return "text-warning";
      case "free": return "text-info";
      default: return "text-secondary"
    }
  }

  return(
    <span style={{fontSize:12}} className={getPlanColor(role) + " text-capitalize"}>
      <b>{role} plan</b>
    </span>
  );
}

export default UserStatus;
