import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function CustomDuration(){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setDuration = useCreateEventTypeStore((state) => state.setDuration);

  // attivo la checkbox nascosta con id #custom
  // per indicare che sto impostando durata custom
  // poi chiamo l'handling normalmente
  const handleClick = (event) => {
    document.getElementById('custom').checked = true;
    setDuration("15")
  }

  return(
<Form.Group as={Row} id="custom-event-duration" className="my-3 ms-2 me-2">
      <Form.Label column sm={2}>{t('general.customDuration')}</Form.Label>
      <Col sm={10} lg={6}>
             <div>
          <Form.Check
            className="custom-duration d-none mx-0 ps-0 pe-1 col-3"
            inline type="radio"
            id="custom"
            name="duration"
            label="custom:"
            onChange={(e) => setDuration("15")}
            defaultChecked={
              general?.duration !== "15" &&
                general?.duration !== "30" &&
                general?.duration !== "45" &&
                general?.duration !== "60"
            }
          />
          <Form.Check
            className="custom-duration mx-0 ps-0 pe-1 col-3 d-inline-flex"
            inline type="number" min="5"
            max="720"
            id="custom-value"
            name="duration"
            defaultValue={general?.duration}
            onClick={handleClick}
            onChange={(e) => setDuration(e.target.value)}
            required
          />
        </div>
      </Col>
    </Form.Group>

  );
}

export default CustomDuration;
