import React from 'react';
import {useCreateEventTypeStore} from '../../../store';
import {useTranslation} from 'react-i18next';
import * as utils from '../../../Utilities/datetimeUtils.js';
import Form from 'react-bootstrap/Form';

function DayCheckbox ({day}) {
  const {t} = useTranslation(['eventCreator']);
  const availability = useCreateEventTypeStore(state => state.availability);
  const toggleDayAvailability = useCreateEventTypeStore((state) => state.toggleDayAvailability);
  const setDayRange = useCreateEventTypeStore((state) => state.setDayRange);

  return(
    <Form.Check
      name={day}
      id={day}
      value={day}
      type="checkbox"
      label={t(`availability.days.${day}`)}
      checked={availability.days[day]}
      onChange={() => {
        toggleDayAvailability(day);
        // if availability check is becoming true but there are no ranges
        if (availability.ranges[day].length === 0 && availability.days[day] === true){
          // add new range for that day
          let index = availability.ranges[day].length
          let interval = {start:'09:00', end:'19:00'}
          // interval = utils.addIntervalCheck(availability,day,index,interval)
          setDayRange(day,index,interval)
        }}
      }
    />
  );
}

export default DayCheckbox;
