import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function Team(){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setTeam = useCreateEventTypeStore((state) => state.setTeam);

    return(
        <Form.Group as={Row} controlId="event-is-team" className="my-3 ms-2 me-2">
            <Form.Label column sm={2}>{t('general.team.title')}</Form.Label>
            <Col sm={10} lg={6}>
                <Form.Check
                    type="checkbox"
                    label={t('general.team.label')}
                    name="is-team-checkbox"
                    checked={general?.isTeam}
                    onChange={(e) => setTeam(e.target.checked)}
                />
            </Col>
        </Form.Group>       
    );
}

export default Team;
