import React, { useEffect } from "react";
import { Tab, Nav, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./settings.css";

import AccountTab from "./Tabs/AccountTab";
import AgencyTab from "./Tabs/AgencyTab";
import SubscriptionTab from "./Tabs/SubscriptionTab";
import NotificationsTab from "./Tabs/NotificationsTab";
import AvailabilitiesTab from "./Tabs/AvailabilitiesTab";

import AccountPanel from "./Panels/AccountPanel";
import AgencyPanel from "./Panels/AgencyPanel";
import SubscriptionPanel from "./Panels/SubscriptionPanel";
import NotificationsPanel from "./Panels/NotificationsPanel";
import AvailabilitiesPanel from "./Panels/Availabilities/AvailabilitiesPanel";

function _Settings() {
  try {
    window.tidioChatApi.show();
  } catch {}

  let activeKey = "account";
  const tab = window.location.href.split("#")[1];

  if (tab === "subscription") {
    activeKey = "subscription";
  }
  if (tab === "agency") {
    activeKey = "agency";
  }
  if (tab === "account") {
    activeKey = "account";
  }
  if (tab === "notifications") {
    activeKey = "notifications";
  }
  if (tab === "availabilities") {
    activeKey = "availabilities";
  }

  useEffect(() => {
    document.title = `Calendbook - Settings - ${activeKey}`;
  }, [activeKey]);

  return (
    <>
      <Container className="mt-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey}>
          <Row>
            <Col sm={3}>
              <Nav fill variant="pills" className="flex-row flex-md-column">
                <AccountTab />
                <AgencyTab />
                <SubscriptionTab />
                <NotificationsTab />
                <AvailabilitiesTab />
              </Nav>
            </Col>

            <Col sm={9}>
              <Tab.Content>
                <AccountPanel />
                <AgencyPanel />
                <SubscriptionPanel />
                <NotificationsPanel />
                <AvailabilitiesPanel />
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default _Settings;
