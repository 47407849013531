import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import produce from "immer";
import { customAlphabet } from "nanoid";
import * as utils from "./Utilities/datetimeUtils";
import General from "./Mocks/EventTypeCreator/General";
import Availability from "./Mocks/EventTypeCreator/Availability";
import Notifications from "./Mocks/EventTypeCreator/Notifications";
import Surveys from "./Mocks/EventTypeCreator/Surveys";
import RoundRobin from "./Mocks/EventTypeCreator/RoundRobin";

// Turn the set method into an immer proxy
const immer = (config) => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === "function" ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

var createNestedObject = function (base, names) {
  // Usage:
  // createNestedObject( window, ["shapes", "triangle", "points"] );
  // Now window.shapes.triangle.points is an empty object, ready to be used.
  for (var i = 0; i < names.length; i++) {
    base = base[names[i]] = base[names[i]] || {};
  }
};

let calendarConnectionsStore = (set) => ({
  calendarsList: [],
  connectedCalendars: 0,
  maxCalendars: 0,
  setCalendarsList: (calendars) =>
    set((state) => ({ calendarsList: calendars })),
  setConnectedCalendars: (connected) =>
    set((state) => ({ connectedCalendars: connected })),
  setMaxCalendars: (max) => set((state) => ({ maxCalendars: max })),
  setCalendarSync: (index, sync) =>
    set((state) => {
      state.calendarsList[index].sync = sync;
    }),
  setCalendarAccess: (index, subIndex, access) =>
    set((state) => {
      if (subIndex >= 0) {
        state.calendarsList[index].subCalendars[subIndex].access = access;
      } else {
        state.calendarsList[index].access = access;
      }
    }),
  setCalendarPic: (index, pic) =>
    set((state) => {
      state.calendarsList[index].calendarPic = pic;
    }),
  setCalendarConnectionStatus: (index, status) =>
    set((state) => {
      state.calendarsList[index].connectionStatus = status;
    }),
  setCalendarEmail: (index, email) =>
    set((state) => {
      state.calendarsList[index].email = email;
    }),
  addCalendar: (calendar) =>
    set((state) => {
      state.calendarsList.push(calendar);
      state.connectedCalendars++;
    }),
  deleteCalendar: (email) =>
    set((state) => {
      state.calendarsList = state.calendarsList.filter((x) => {
        return x.email !== email;
      });
      state.connectedCalendars--;
    }),
  setSubCalendarEnabled: (calendarIndex, subCalendarIndex, bool) =>
    set((state) => {
      state.calendarsList[calendarIndex].subCalendars[
        subCalendarIndex
      ].enabled = bool;
    }),
});

let webhooksConnectionsStore = (set) => ({
  webhooksList: [],
  setWebhooksList: (webhooks) => set((state) => ({ webhooksList: webhooks })),
  addWebhook: (webhook) =>
    set((state) => {
      state.webhooksList = state.webhooksList.filter((x) => {
        return x.hook !== webhook?.hook;
      });
      state.webhooksList.push(webhook);
    }),
  deleteWebhook: (hook) =>
    set((state) => {
      console.log("delete: ", hook);
      state.webhooksList = state.webhooksList.filter((x) => {
        return x?.hook !== hook;
      });
    }),
});

let createEventTypeStore = (set, get) => ({
  editKey: "",
  general: General,
  availability: Availability,
  notifications: Notifications,
  surveys: {},
  paidEvent: {},
  roundRobin: RoundRobin,
  setEditKey: (_key) =>
    set((state) => {
      state.editKey = _key;
    }),
  setGeneral: (_general) =>
    set((state) => {
      state.general = _general;
    }),
  setAvailability: (_availability) =>
    set((state) => {
      state.availability = _availability;
    }),
  setNotifications: (_notifications) =>
    set((state) => {
      state.notifications = _notifications;
    }),
  setSurveys: (_surveys) =>
    set((state) => {
      state.surveys = _surveys;
    }),
  setPaidEvent: (_paidEvent) =>
    set((state) => {
      state.paidEvent = _paidEvent;
    }),
  setRoundRobin: (_roundRobin) =>
    set((state) => {
      state.roundRobin = _roundRobin;
    }),
  setName: (_name) =>
    set((state) => {
      state.general.name = _name;
    }),
  setDescription: (_description) =>
    set((state) => {
      state.general.description = _description;
    }),
  setLocation: (_location) =>
    set((state) => {
      state.general.location = _location;
    }),
  setColor: (_color) =>
    set((state) => {
      state.general.color = _color;
    }),
  setDuration: (_duration) =>
    set((state) => {
      state.general.duration = _duration;
    }),
  setPrevBuffer: (_buffer) =>
    set((state) => {
      state.general.prevBuffer = _buffer;
    }),
  setNextBuffer: (_buffer) =>
    set((state) => {
      state.general.nextBuffer = _buffer;
    }),
  setRedirectionUrl: (_url) =>
    set((state) => {
      state.general.redirectionUrl = _url;
    }),
  setTeam: (_isTeam) =>
    set((state) => {
      state.general.isTeam = _isTeam;
    }),
  setBookingType: (_bookingType) =>
    set((state) => {
      state.general.bookingType = _bookingType;
    }),
  toggleDayAvailability: (_day) =>
    set((state) => {
      state.availability.template = "";
      state.availability.days[_day] = !state.availability.days[_day];
      if (
        state.availability.ranges[_day].length === 0 &&
        state.availability.days[_day] === true
      ) {
        // add new range for that day
        let index = state.availability.ranges[_day].length;
        let interval = { start: "09:00", end: "19:00" };
        interval = utils.addIntervalCheck(
          state.availability,
          _day,
          index,
          interval
        );
        state.availability.ranges[_day][index] = interval;
      }
    }),
  setDayRange: (_day, _index, _interval) =>
    set((state) => {
      state.availability.template = "";
      if (
        state.availability.ranges[_day].length === 0 &&
        state.availability.days[_day] === false
      ) {
        state.availability.days[_day] = true;
      }
      state.availability.ranges[_day][_index] = _interval;
    }),
  deleteDayRange: (_day, _index) =>
    set((state) => {
      state.availability.template = "";
      let newRanges = state.availability.ranges[_day];
      newRanges.splice(_index, 1);
      state.availability.ranges[_day] = newRanges;
      if (state.availability.ranges[_day].length === 0) {
        state.availability.days[_day] = false;
      }
    }),
  // In your Zustand store setup

  // Function to add a new disabled date range
  addDisabledDateRange: () =>
    set((state) => {
      state.availability.template = "";
      if (!state.availability.disabledDateRaw) {
        state.availability.disabledDateRaw = [];
      }
      state.availability.disabledDateRaw.push({ start: "", end: "" });
      // No return statement needed
    }),

  // Function to update the start date of a range at a given index
  setDisabledDateStart: (index, date) =>
    set((state) => {
      state.availability.template = "";
      if (!state.availability.disabledDateRaw) {
        state.availability.disabledDateRaw = [];
      }
      if (!state.availability.disabledDateRaw[index]) {
        state.availability.disabledDateRaw[index] = { start: "", end: "" };
      }
      state.availability.disabledDateRaw[index].start = date;
      // No return statement needed
    }),

  // Function to update the end date of a range at a given index
  setDisabledDateEnd: (index, date) =>
    set((state) => {
      state.availability.template = "";
      if (!state.availability.disabledDateRaw) {
        state.availability.disabledDateRaw = [];
      }
      if (!state.availability.disabledDateRaw[index]) {
        state.availability.disabledDateRaw[index] = { start: "", end: "" };
      }
      state.availability.disabledDateRaw[index].end = date;
      // No return statement needed
    }),

  // Function to remove a disabled date range at a given index
  removeDisabledDateRange: (index) =>
    set((state) => {
      state.availability.template = "";
      if (state.availability.disabledDateRaw) {
        state.availability.disabledDateRaw.splice(index, 1);
      }
      // No return statement needed
    }),

  setTimeslotHop: (_hop) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "hop"]);
      state.availability.hop = _hop;
    }),
  setBookingLimitQuantity: (_quantity) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "bookingLimit", "quantity"]);
      state.availability.bookingLimit.quantity = _quantity;
    }),
  setBookingLimitUnit: (_unit) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "bookingLimit", "unit"]);
      state.availability.bookingLimit.unit = _unit;
    }),
  setNoticeQuantity: (_quantity) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "notice", "quantity"]);
      state.availability.notice.quantity = _quantity;
    }),
  setNoticeUnit: (_unit) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "notice", "unit"]);
      state.availability.notice.unit = _unit;
    }),
  setLastAvailabilityDate: (_date) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "lastAvailability", "date"]);
      state.availability.lastAvailability.date = _date;
    }),
  setLastAvailabilityTime: (_time) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "lastAvailability", "time"]);
      state.availability.lastAvailability.time = _time;
    }),
  setApproval: (_needsApproval) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "needsApproval"]);
      state.availability.needsApproval = _needsApproval;
    }),
  setCalendar: (_calendar) =>
    set((state) => {
      state.availability.template = "";
      createNestedObject(state, ["availability", "calendar"]);
      state.availability.calendar = _calendar;
    }),
  setEmailNotification: (_email) =>
    set((state) => {
      state.notifications.notifications.guest = _email;
    }),
  setSalesforza: (_bool) =>
    set((state) => {
      state.notifications.notifications.salesforza = _bool;
    }),
  setReminder: (_index, _reminder) =>
    set((state) => {
      state.notifications.reminders[_index] = _reminder;
    }),
  addReminder: (_reminder) =>
    set((state) => {
      state.notifications.reminders.push(_reminder);
    }),
  deleteReminder: (_index) =>
    set((state) => {
      let newReminders = state.notifications.reminders;
      newReminders.splice(_index, 1);
      state.notifications.reminders = newReminders;
    }),
  addQuestion: () =>
    set((state) => {
      const nanoid = customAlphabet("1234567890qwertyuiopasdfghjklzxcvbnm", 16);
      const newKey = nanoid();
      let len = Object.keys(state.surveys).length;
      let localSurveys = JSON.parse(JSON.stringify(Surveys));
      localSurveys.order = len + 1;
      state.surveys[newKey] = localSurveys;
    }),
  deleteQuestion: (_order, _id) =>
    set((state) => {
      // Delete question using key id
      delete state.surveys[_id];
      // Reordering 'order' property for every question
      let len = Object.keys(state.surveys).length;
      for (let i = 1; i <= len; i++) {
        let key = Object.keys(state.surveys)[i - 1];
        state.surveys[key]["order"] = i;
      }
    }),
  setQuestionContent: (_content, _id) =>
    set((state) => {
      state.surveys[_id].content = _content;
    }),
  setQuestionRequired: (_id) =>
    set((state) => {
      state.surveys[_id].required = !state.surveys[_id].required;
    }),
  setQuestionType: (_type, _id) =>
    set((state) => {
      const nanoid = customAlphabet("1234567890qwertyuiopasdfghjklzxcvbnm", 8);
      state.surveys[_id].questionType = _type;
      // empty qstFields if Text type is selected
      if (_type === 3) {
        state.surveys[_id].qstFields = {};
      }
      // generate 1 new empty field if checkbox
      if (_type === 2) {
        state.surveys[_id].qstFields = {};
        state.surveys[_id].qstFields[nanoid()] = "";
      }
      // generate 2 new empty fields if radio
      if (_type === 1) {
        state.surveys[_id].qstFields = {};
        state.surveys[_id].qstFields[nanoid()] = "";
        state.surveys[_id].qstFields[nanoid()] = "";
      }
    }),
  setFieldContent: (_content, _questionId, _fieldId) =>
    set((state) => {
      state.surveys[_questionId]["qstFields"][_fieldId] = _content;
    }),
  deleteField: (_questionId, _fieldId) =>
    set((state) => {
      delete state.surveys[_questionId]["qstFields"][_fieldId];
    }),
  addField: (_questionId) =>
    set((state) => {
      if (Object.keys(state.surveys[_questionId]["qstFields"]).length < 10) {
        const nanoid = customAlphabet(
          "1234567890qwertyuiopasdfghjklzxcvbnm",
          8
        );
        const newKey = nanoid();
        state.surveys[_questionId]["qstFields"][newKey] = "";
      }
    }),
  setPrice: (_price) =>
    set((state) => {
      state.paidEvent["amount"] = _price;
    }),
  setPriceId: (_priceId) =>
    set((state) => {
      state.paidEvent["priceId"] = _priceId;
    }),
  setProductId: (_productId) =>
    set((state) => {
      state.paidEvent["productId"] = _productId;
    }),
  setSelectedTemplate: (_template) =>
    set((state) => {
      createNestedObject(state, ["availability", "template"]);
      state.availability.template = _template;
    }),
  setRobinType: (_type) =>
    set((state) => {
      createNestedObject(state, ["roundRobin", "config", "robinType"]);
      state.roundRobin.config.robinType = _type;
    }),
  getRobinMembers: () => {
    const state = get();
    return Object.keys(state.roundRobin)
      .filter((key) => key !== "config")
      .map((key) => state.roundRobin[key]);
  },
  setRobinMembers: (newMembers) =>
    set((state) => {
      Object.keys(newMembers).forEach((key) => {
        if (key !== "config") {
          state.roundRobin[key] = newMembers[key];
        }
      });
    }),
  setRobinMember: (_member) =>
    set((state) => {
      createNestedObject(state, ["roundRobin", _member]);
      state.roundRobin[_member] = _member;
    }),
  addRobinMember: (_memberKey, _memberProperties) =>
    set((state) => {
      createNestedObject(state, ["roundRobin", _memberKey]);
      state.roundRobin[_memberKey] = _memberProperties;
      state.roundRobin[_memberKey]["availability_template"] = "";
      state.roundRobin[_memberKey]["priority"] = 2;
    }),
  deleteRobinMember: (_member) =>
    set((state) => {
      delete state.roundRobin[_member];
    }),
  setRobinMemberTemplate: (_member, _template) =>
    set((state) => {
      createNestedObject(state, [
        "roundRobin",
        _member,
        "availability_template",
      ]);
      state.roundRobin[_member]["availability_template"] = _template;
    }),
  setRobinMemberPriority: (_member, _priority) =>
    set((state) => {
      createNestedObject(state, ["roundRobin", _member, "priority"]);
      state.roundRobin[_member]["priority"] = _priority;
    }),
});

let createBookingStore = (set) => ({
  guest: { timeFormat: "12h" },
  details: {},
  calendar: {},
  timeSlots: {},
  summary: {},
  surveys: {},
  surveyAnswers: {},
  setGuestDay: (_day) =>
    set((state) => {
      state.guest.day = _day;
    }),
  setGuestTime: (_time) =>
    set((state) => {
      state.guest.time = _time;
    }),
  setGuestTimeFormat: (_timeFormat) =>
    set((state) => {
      state.guest.timeFormat = _timeFormat;
    }),
  setGuestTimezone: (_timezone) =>
    set((state) => {
      state.guest.timezone = _timezone;
    }),
  setGuestName: (_name) =>
    set((state) => {
      state.guest.name = _name;
    }),
  setGuestEmail: (_email) =>
    set((state) => {
      state.guest.email = _email;
    }),
  setGuestNotes: (_notes) =>
    set((state) => {
      state.guest.notes = _notes;
    }),
  setGuestSatellite: (_satellite) =>
    set((state) => {
      state.guest.satellite = _satellite;
    }),
  setGuestReason: (_reason) =>
    set((state) => {
      state.guest.reason = _reason;
    }),
  setDetails: (_details) =>
    set((state) => {
      state.details = _details;
    }),
  setCalendar: (_calendar) =>
    set((state) => {
      state.calendar = _calendar;
    }),
  setTimeSlots: (_timeSlots) =>
    set((state) => {
      state.timeSlots = _timeSlots;
    }),
  setSummary: (_summary) =>
    set((state) => {
      state.summary = _summary;
    }),
  setSurveys: (_surveys) =>
    set((state) => {
      state.surveys = _surveys;
    }),
  setSurveyAnswers: (_answers) =>
    set((state) => {
      state.surveyAnswers = _answers;
    }),
  setTextAnswer: (_value, _questionId) =>
    set((state) => {
      state.surveyAnswers[_questionId] = _value;
    }),
  updateTextAnswer: (_value, _questionId) =>
    set((state) => {
      state.summary.surveys[_questionId].answer = [_value];
    }),
  setRadioAnswer: (_fieldId, _questionId) =>
    set((state) => {
      state.surveyAnswers[_questionId] = [_fieldId];
    }),
  updateRadioAnswer: (_fieldId, _questionId, _value) =>
    set((state) => {
      state.summary.surveys[_questionId].answer = [_value];
      state.summary.surveys[_questionId].answerValue = [_fieldId];
    }),
  setCheckboxAnswer: (_fieldId, _isChecked, _questionId) =>
    set((state) => {
      if (_isChecked) {
        if (state.surveyAnswers.hasOwnProperty(_questionId)) {
          state.surveyAnswers[_questionId].push(_fieldId);
        } else {
          state.surveyAnswers[_questionId] = [_fieldId];
        }
      } else {
        state.surveyAnswers[_questionId] = state.surveyAnswers[
          _questionId
        ].filter((x) => {
          return x !== _fieldId;
        });
      }
    }),
  updateCheckboxAnswer: (_fieldId, _isChecked, _questionId, _value) =>
    set((state) => {
      if (_isChecked) {
        if (state.summary.surveys[_questionId].answerValue.length > 0) {
          state.summary.surveys[_questionId].answer.push(_value);
          state.summary.surveys[_questionId].answerValue.push(_fieldId);
        } else {
          state.summary.surveys[_questionId].answer = [_value];
          state.summary.surveys[_questionId].answerValue = [_fieldId];
        }
      } else {
        state.summary.surveys[_questionId].answer = state.summary.surveys[
          _questionId
        ].answer.filter((x) => {
          return x !== _value;
        });
        state.summary.surveys[_questionId].answerValue = state.summary.surveys[
          _questionId
        ].answerValue.filter((x) => {
          return x !== _fieldId;
        });
      }
    }),
});

let iframeStore = (set) => ({
  shouldShowNavs: true,
  setShouldShowNavs: (_bool) =>
    set((state) => {
      state.shouldShowNavs = _bool;
    }),
});

createBookingStore = devtools(createBookingStore);
createEventTypeStore = devtools(createEventTypeStore);
webhooksConnectionsStore = devtools(webhooksConnectionsStore);
calendarConnectionsStore = devtools(calendarConnectionsStore);
iframeStore = devtools(iframeStore);

export const useCreateBookingStore = create(immer(createBookingStore));
export const useCreateEventTypeStore = create(immer(createEventTypeStore));
export const useCalendarConnectionsStore = create(
  immer(calendarConnectionsStore)
);
export const useWebhooksConnectionsStore = create(
  immer(webhooksConnectionsStore)
);
export const useIframeStore = create(immer(iframeStore));
