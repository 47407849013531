import React from 'react';

function Icon({completed}){

  const componentStyle={
    width:30,
    height:30,
    fontSize:12
  }
  const componentClass = "d-flex justify-content-center align-items-center h-100"

  return(
    <span style={componentStyle} className={componentClass}>
      {completed && <i className="fas fa-check"></i>}
    </span>
  );
}

export default Icon;
