import Order from './Order';
import Content from './Content';
import Required from './Required';
import TextAnswer from './TextAnswer';
import PhoneAnswer from './PhoneAnswer';
import TelegramAnswer from './TelegramAnswer';
import WhatsappAnswer from './WhatsappAnswer';
import Fields from './Fields/_Fields';

function Question({questionState,questionId,mode}){
  return(
    <>
      <div className="mt-3">
        <Order order={questionState?.order}/>
        <Content content={questionState?.content}/>    
        <Required required={questionState?.required}/>
      </div>

      {mode === "reschedule"
        ?
          <>
            {/* radio and checkbox */
              (questionState?.questionType === 1 || questionState?.questionType === 2) &&
                <Fields type={questionState.questionType} fields={questionState.qstFields} questionId={questionId} required={questionState.required} answer={questionState?.answer} answerValue={questionState?.answerValue} mode={mode}/>
            }
            {/* text */
              questionState?.questionType === 3 && 
                <TextAnswer questionId={questionId} required={questionState.required} mode={mode}/>
            }
            {/* phone number */
              questionState?.questionType === 4 &&
                <PhoneAnswer questionId={questionId} required={questionState.required} mode={mode}/>
            }
            {/* telegram */
              questionState?.questionType === 5 &&
                <TelegramAnswer questionId={questionId} required={questionState.required} mode={mode}/>
            }
            {/* whatsapp */
              questionState?.questionType === 6 &&
                <WhatsappAnswer questionId={questionId} required={questionState.required} mode={mode}/>
            }
          </>
          :
          <>
            {/* radio and checkbox */
              (questionState?.questionType === 1 || questionState?.questionType === 2) &&
                <Fields type={questionState.questionType} fields={questionState.qstFields} questionId={questionId} required={questionState.required}/>
            }
            {/* text */
              questionState?.questionType === 3 && 
                <TextAnswer questionId={questionId} required={questionState.required}/>
            }
            {/* phone number */
              questionState?.questionType === 4 &&
                <PhoneAnswer questionId={questionId} required={questionState.required}/>
            }
            {/* telegram */
              questionState?.questionType === 5 &&
                <TelegramAnswer questionId={questionId} required={questionState.required}/>
            }
            {/* whatsapp */
              questionState?.questionType === 6 &&
                <WhatsappAnswer questionId={questionId} required={questionState.required}/>
            }
          </>
      }

      
    </>
  );
}

export default Question;
