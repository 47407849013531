import React from 'react';
import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import Form from 'react-bootstrap/Form';

function Email (){
  const {t} = useTranslation(['eventCreator']);
  const notifications = useCreateEventTypeStore(state => state.notifications);
  const setEmailNotification = useCreateEventTypeStore((state) => state.setEmailNotification);

  return(
    <>
      <Form.Control
        as="textarea"
        col="3"
        className="dark-field"
        placeholder={t('advanced.emailNotificationsPlaceholder')}
        name="guest"
        value={notifications?.notifications?.guest}
        onChange={(e) => setEmailNotification(e.target.value)}
        maxLength={700}
      />
    </>
  );
}

export default Email;
