import {useTranslation} from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import './event.css';

const frontendFormatter = (number) => {
    console.info('[BookingConfirmation][payment] parsing be price:');
    let result = '';
    if(number !== undefined){
      result = Number(number)/100;
    }
    console.info(result);
    return result;
  };

function Payment({isPaidEvent,paidEvent}){
  const {t} = useTranslation(['booking']);
  return(
    <>
      {isPaidEvent && paidEvent &&
        <div className="my-3 d-flex align-items-center">

          <div className="event-info-icon">
            <i className="fa-solid fa-credit-card"></i>
          </div>

          <div>
            <div className="mx-3" style={{fontSize:12,color:'var(--color-secondary)'}}>{t('confirmed.price')}Event price</div>
            <div>
              <span className={paidEvent?.currency ? "ms-3" : ""}>{paidEvent?.currency && getSymbolFromCurrency(paidEvent?.currency) !== undefined && getSymbolFromCurrency(paidEvent?.currency)}</span>
              <span className={paidEvent?.currency ? "ms-2" : "ms-3"}>{frontendFormatter(paidEvent?.amount)}</span>
            </div>
          </div>
          
        </div>
      }
    </>
  );
}

export default Payment;
