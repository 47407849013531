function EmbeddedCode ({code}) {
  const componentStyle = {
    border: '1px dashed gray',
    background:'var(--color-background-0)',
    padding:'10px'
  }
  
  return(
      <pre style={componentStyle}>
        <code>
          {code}
        </code>
      </pre>
  );
}

export default EmbeddedCode;
