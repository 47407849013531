import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';
import {useCalendarConnectionsStore} from '../store';
import auth from '../Account/Auth';
import be from '../BE.js';

function ChangePermissions({mode,access,index,email,calendars,subIndex,subCalendarId,loadingPermissions,setLoadingPermissions}){

  const { t } = useTranslation(['calendarConnections']);
  const setCalendarAccess = useCalendarConnectionsStore((state) => state.setCalendarAccess);
  const [statusCode,setStatusCode] = useState(0);
  const [loading,setLoading] = useState(false);

  const getLabel = (mode) => {
    if(mode){
      if(mode === 'check'){return t('checkConflicts');}
      else if(mode === 'add'){return t('addEvents');}
      else return '';
    }else{return '';}
  }

  const shouldCheck = (mode,access) => {
    if (mode && access){
      if (mode === 'check' && access.includes('r')){return true;}
      else if (mode === 'add' && access.includes('w')){return true;}
      else return false;
    }else{return false;}
  }

  const changeCalendarAccess = async (_index,_email,_mode,_subIndex,_subCalendarId) => {
    setStatusCode(0);
    setLoading(true);
    setLoadingPermissions(true);
    const toggleAddToCalendar = (_access) => {
      if(_access === 'w'){return 'none'}
      if(_access === 'r/w'){return 'r'}
      if(_access === 'r'){return 'r/w'}
      if(_access === 'none'){return 'w'}
      return 'none'
    }
    const toggleCheckConflictsInCalendar = (_access) => {
      if(_access === 'w'){return 'r/w'}
      if(_access === 'r/w'){return 'w'}
      if(_access === 'r'){return 'none'}
      if(_access === 'none'){return 'r'}
      return 'none'
    }

    let currentAccess = calendars[_index].access
    if(subIndex >= 0){currentAccess = calendars[_index].subCalendars[_subIndex].access}
    let newAccess = 'none';
    if(_mode === 'add'){newAccess = toggleAddToCalendar(currentAccess)}
    else if(_mode === 'check'){newAccess = toggleCheckConflictsInCalendar(currentAccess)}
    const body = {
      access:newAccess,
      email:_email,
      ...(_subCalendarId ? {subCalendarId: _subCalendarId} : {})
    }
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('calendarhandler','/changeAccessCalendar',body,true)
          .then((response) => {
            setStatusCode(200);
            setLoading(false);
            setLoadingPermissions(false);
            setCalendarAccess(index,subIndex,newAccess);
          })
          .catch((error) => {
            setStatusCode(error?.response?.status)
            setLoading(false);
            setLoadingPermissions(false);
          })
      })
      .catch(() => this.props.history.push('/login'))
  }

  return(
    <div className="d-inline-flex me-3">
      <div style={{marginLeft:7}}>
      {loading
        ?
          <Spinner animation="border" size="sm"/>
          :
          <Form.Check 
            aria-label={`check-conflicts-${email}-${mode}`}
            checked={shouldCheck(mode,access)}
            onChange={() => changeCalendarAccess(index,email,mode,subIndex,subCalendarId)}
            disabled={loadingPermissions}
          />
      }
      </div>
      <span className="ms-2"> {getLabel(mode)} </span>
      {(statusCode >= 400 && statusCode <= 500) &&
        <small className="ms-2 text-danger" style={{lineHeight:'26px'}}>{t('error')}</small>
      }
    </div>
  );
}

export default ChangePermissions;
