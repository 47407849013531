import React, { useState, useContext } from "react";
import ThemeContext from "../../ThemeContext";
import { useTranslation } from "react-i18next";
import { Row, Col, Nav, NavLink, NavItem, Dropdown } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "./profile-menu.css";
import UserPic from "../UserPic";
import UserName from "../UserName";
import UserStatus from "../UserStatus";
import auth from "../../Account/Auth";

function getFlag(language) {
  // getting only first 2 chars of the language (it, en, ...)
  language = language.toLowerCase().substring(0, 2);
  if (language === "it")
    return (
      <span role="img" aria-label="italiano">
        <img
          src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png"
          alt="italiano"
          width="16px"
        />
      </span>
    );
  if (language === "en")
    return (
      <span role="img" aria-label="english">
        <img
          src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png"
          alt="english"
          width="16px"
        />
      </span>
    );
  if (language === "es")
    return (
      <span role="img" aria-label="español">
        <img
          src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png"
          alt="español"
          width="16px"
        />
      </span>
    );
  if (language === "de")
    return (
      <span role="img" aria-label="deutsch">
        <img
          src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png"
          alt="deutsch"
          width="16px"
        />
      </span>
    );
  if (language === "fr")
    return (
      <span role="img" aria-label="francais">
        <img
          src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png"
          alt="francais"
          width="16px"
        />
      </span>
    );
}

function setDomTheme(theme) {
  const root = window.document.getElementById("root");
  const html = window.document.getElementById("html");
  root.setAttribute("data-theme", theme);
  html.setAttribute("data-theme", theme);
  window.localStorage.setItem("theme", theme);
}

function _ProfileMenu({ agent }) {
  const themeButtonStyle = {
    background: "transparent",
    border: "none",
    margin: 0,
    padding: 0,
    textAlign: "left",
    width: "100%",
  };
  const [theme, setTheme] = useContext(ThemeContext);
  const [copied, setCopied] = useState(false);
  const { t, i18n } = useTranslation(["navbar"]);
  const protocol = window.location.protocol;
  const host = window.location.host;
  const publicAddress = protocol + "//" + host + "/" + auth.getName();

  const plan = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "planStatus", "status"],
    fallback: "unknown",
  });

  function copy() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  return (
    <>
      <Nav.Item className="ms-auto me-0">
        <a title={t("btnTitles.publicPage")} href={publicAddress}>
          <div
            className="text-end my-auto mx-1 d-inline-block align-middle py-0"
            style={{ background: "#3a3b3c", borderRadius: 50 }}
          >
            <div className="d-none d-md-inline-block align-middle mx-2 ms-4 text-white">
              <div>
                <UserName />
              </div>
              {agent && (
                <div style={{ fontSize: 12 }}>
                  <span style={{ fontStyle: "italic", marginRight: 3 }}>
                    impersonated by
                  </span>
                  <span style={{ fontWeight: 500 }}>{agent.fullName}</span>
                </div>
              )}
            </div>
            <div className="d-inline-block align-middle mx-0">
              <UserPic />
            </div>
          </div>
        </a>

        <CopyToClipboard
          text={publicAddress}
          onCopy={() => {
            copy();
          }}
        >
          <div
            title={t("btnTitles.copyPublicLink")}
            className="d-inline-block align-middle mx-1"
            style={{
              background: "#3a3b3c",
              width: 40,
              height: 40,
              borderRadius: "100%",
              cursor: "pointer",
            }}
          >
            <div className="d-flex justify-content-center align-items-center h-100 h6 text-white">
              {copied ? (
                <i className="far fa-check-circle"></i>
              ) : (
                <i className="fas fa-link"></i>
              )}
            </div>
          </div>
        </CopyToClipboard>

        <Dropdown
          align="end"
          as={NavItem}
          className="d-inline-block align-middle mx-1"
          style={{
            background: "#3a3b3c",
            width: 40,
            height: 40,
            borderRadius: "100%",
          }}
        >
          <Dropdown.Toggle as={NavLink} id="user-dropdown">
            <i className="fas fa-caret-down"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "330px", zIndex: 2000 }}>
            <Dropdown.Item href="/settings" className="py-2">
              <Row sm={12}>
                <Col xs={3} className="text-center my-auto">
                  <UserPic />
                </Col>
                <Col xs={9}>
                  <Row xs={12}>
                    <Col xs={12}>
                      <UserName />
                    </Col>
                    <Col xs={12}>
                      <UserStatus />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Dropdown.Item>
            <Dropdown.Item href="/team" className="py-2">
              <i
                className="fas fa-users text-center me-3"
                style={{ width: 20 }}
              ></i>
              Team
            </Dropdown.Item>
            <Dropdown.Item href="/leads" className="py-2">
              <i
                className="fa-solid fa-address-book text-center me-3"
                style={{ width: 20 }}
              ></i>
              Leads
            </Dropdown.Item>
            {plan !== "professional" && (
              <Dropdown.Item href="/upgrade" rel="noreferrer" className="py-2">
                <i
                  className="far fa-credit-card text-center me-3"
                  style={{ width: 20 }}
                ></i>
                {t("upgrade")}
              </Dropdown.Item>
            )}
            <Dropdown.Item
              href="/integrations"
              rel="noreferrer"
              className="py-2"
            >
              <i
                className="fas fa-plug text-center me-3"
                style={{ width: 20 }}
              ></i>
              {t("integrations")}
            </Dropdown.Item>
            <Dropdown.Item
              href="/calendarconnections"
              rel="noreferrer"
              className="py-2"
            >
              <i
                className="fas fa-calendar text-center me-3"
                style={{ width: 20 }}
              ></i>
              {t("calendarConnections")}
            </Dropdown.Item>
            <Dropdown.Item href="/embed" rel="noreferrer" className="py-2">
              <i
                className="fas fa-code text-center me-3"
                style={{ width: 20 }}
              ></i>
              {t("embedPage")}
            </Dropdown.Item>
            {auth.getAgencyId() && (
              <Dropdown.Item href="/agency" rel="noreferrer" className="py-2">
                <i
                  className="fa-solid fa-user-tie text-center me-3"
                  style={{ width: 20 }}
                ></i>
                Agency
              </Dropdown.Item>
            )}
            <Dropdown.Item
              href="/settings"
              className="py-2 d-inline-flex align-items-center"
            >
              <i
                className="fas fa-cog text-center me-3"
                style={{ width: 20 }}
              ></i>
              <span>{t("settings")}</span>
              <span className="mx-2">{getFlag(i18n.language)}</span>
            </Dropdown.Item>
            <Dropdown.Item href="#" target="" rel="noreferrer" className="py-2">
              {theme === "light" ? (
                <button
                  onClick={() => {
                    setTheme("dark");
                    setDomTheme("dark");
                  }}
                  style={themeButtonStyle}
                >
                  <i
                    className="fas fa-moon text-center me-3"
                    style={{ width: 20 }}
                  ></i>
                  Dark theme
                </button>
              ) : (
                <button
                  onClick={() => {
                    setTheme("light");
                    setDomTheme("light");
                  }}
                  style={themeButtonStyle}
                >
                  <i
                    className="fas fa-sun text-center me-3"
                    style={{ width: 20 }}
                  ></i>
                  Light theme
                </button>
              )}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="/logout" className="text-secondary py-2">
              <i
                className="fas fa-sign-out-alt text-center me-3"
                style={{ width: 20 }}
              ></i>
              {t("logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
    </>
  );
}

export default _ProfileMenu;
