import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Time from './Time';
import Name from './Name';
import Guest from './Guest';
import Details from './Details/_Details';

const componentStyle = {
    display:'flex',
    height:72,
    marginLeft:20,
}
const colorStyler = (color) => {
  if(!color){color='white'}
  return {
    border:`${color} 1px solid`,
    height:'70%',
    margin:'auto 10px auto 0',
  }
}

const generalInfoStyle={
  marginTop:'auto',
  marginBottom:'auto',
  width:'100%',
}

const generalInfoSubStyle={
  width:'100%',
  whiteSpace:'nowrap',
  display:'inline-block'
}

const detailsStyle={
  marginLeft:50
}

const accordionToggleStyle={
  margin:'auto 0 auto auto',
}


function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  return (
    <button
      type="button"
      style={{ backgroundColor: 'transparent',border:'none',color:'white'}}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function Event(props){
  const [collapsed,setCollapsed] = useState(false);
  const {t} = useTranslation(['home']);
  const details = props.details;
  const color  = details.color;
  const time = props.time;
  return(
    <Accordion defaultActiveKey="">
      <div style={componentStyle}>
        <div style={colorStyler(color)}></div>

        {/* Event General Info */}
        <div style={generalInfoStyle}>
          <Guest name={details.guestname} amount={details?.paidEvent?.amount} currency={details?.paidEvent?.currency} pendingReason={details?.bookingPendingReason} action={details?.action}/>
          <div style={generalInfoSubStyle}>
            <Time time={time} duration={details.duration}/> | <Name name={details.displayName} color={details.color}/>
          </div>
        </div>

        {/* Toggle Button */}
        <div style={accordionToggleStyle} onClick={() => setCollapsed(!collapsed)}>
          <CustomToggle eventKey="0" >
            <span style={details?.action === "on_wait" ? {color:'var(--bs-warning)'} : {color:'var(--color-secondary)'}} className="d-inline-flex">
              <span style={{rotate : collapsed && '90deg',transition:'0.2s'}}>
                <i className="fas fa-caret-right" ></i>
              </span>
              <span className="d-none d-md-inline-flex ms-md-2">
                {t('upcomings.details.toggle')}
              </span>
            </span>
          </CustomToggle>
        </div>
      </div>

      {/* Accordion Content */}
      <div>
        <Accordion.Collapse eventKey="0">
          <div style={detailsStyle}>
            <Details state={details}/>
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
}

export default Event;
