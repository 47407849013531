function Subtitle({view}){
    const componentStyle={
        fontWeight:300
    }
    return(
        <p style={componentStyle}></p>
    );
}

export default Subtitle;
