import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import Question from './_Question'; // Adjust the path as necessary

const SortableQuestion = ({id, index, order, surveys, existingQuestions, existingFields, editing}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({id: order});

  const style = {
    touchAction: 'none',
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <Question
          ref={setNodeRef}
          key={`question-${id}`}
          id={id}
          index={index}
          state={surveys[id]}
          existingQuestions={existingQuestions}
          existingFields={existingFields}
          editing={editing}
        />
      </div>
    </>
  );
};

export default SortableQuestion;
