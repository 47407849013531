import { useEffect,useState } from 'react';
import { useIframeStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { initializePaddle } from '@paddle/paddle-js';
import auth from '../../Account/Auth';

const DEBUG = (window.location.hostname === 'localhost' || window.location.hostname === 'dev.calendbook.com');
const TEST_TOKEN = "test_2ee936d492e84b8c70ff282cacf";
const LIVE_TOKEN = "live_f9e2fd79f6f00aa1b9f8a4f55ec";

function Checkout({customerId, priceId,discountId}){
  const setShouldShowNavs = useIframeStore((state) => state.setShouldShowNavs);
  const {i18n,t} = useTranslation(['landing','common',"upgrade"]);
  const [loading,setLoading] = useState(false);
  const [paddle, setPaddle] = useState();

  const getUserLanguage = () => {try{return i18n.language.substring(0,2)}catch(e){return 'en'}}

  const sleep = (ms) => {
    /* usage: await sleep(3000); */
    return new Promise((resolve) => setTimeout(resolve,ms));
  }

  var createNestedObject = function( base, names ) {
    // Usage:
    // createNestedObject( window, ["shapes", "triangle", "points"] );
    // Now window.shapes.triangle.points is an empty object, ready to be used.
    for( var i = 0; i < names.length; i++ ) {
      base = base[ names[i] ] = base[ names[i] ] || {};
    }
  };

  useEffect(() => {
    initializePaddle(
      {
        // environment is 'sandbox' when testing, 'production' when live
        environment: (DEBUG ? 'sandbox' : 'production'),
        checkout: {
          settings: {
            displayMode:"inline",
            frameTarget:"checkout",
            frameStyle:"min-width:768px;",
            frameHeight:"600",
            theme:'light',
            locale:getUserLanguage()
          }
        },
        token: (DEBUG ? TEST_TOKEN : LIVE_TOKEN),
        eventCallback: async function(data) {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.closed":
              console.log("Checkout closed");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
              await sleep(1000)
              setLoading(true)
              await sleep(4000)
              // gets new updated profileObj
              await auth.refresh();
              // go to dashboard
              window.location.href = '/';
              // show navbar and footer
              setShouldShowNavs(true);
              document.getElementById("root").style.paddingBottom="500px";
              document.getElementById("root").style.minHeight="1400px";
              break;
            default:
          }
        } 
      }).then(
        (paddleInstance) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        },
      );
  }, []);

  const openCheckout = (_priceId,_discountId) => {
    let checkoutObj = {
      discountId: _discountId,
      items: [
        {
          priceId: _priceId,
          quantity: 1
        }
      ]
    }
    if(customerId){
      createNestedObject(checkoutObj,["customer","id"]);
      checkoutObj['customer']['id'] = customerId;
    }else if(auth.getEmail()){
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = auth.getEmail();
    }else{
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = "your@email.com";
    }
    paddle?.Checkout.open(checkoutObj);
  };

    openCheckout(priceId,discountId);

  return(
    <>
      {loading
        ?<div> Updating your Subscription... </div>
        :<div className="checkout"> </div>
      }
          </>
  );
}

export default Checkout;
