import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import md5 from 'js-md5';
import auth from '../../Account/Auth';
import be from '../../BE';
import axios from 'axios';

function Pic({ pic, canEdit }) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [preview, setPreview] = useState(null);
  const [zoomScale, setZoomScale] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const editor = useRef(null);
  const fileInput = useRef(null);

  const handleEditClick = () => {
    if (canEdit) {
      setIsEditing(true);
      document.getElementById('pic-input').click();
    }
  };

  const handleImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileUrl(URL.createObjectURL(file));
    } else {
      setIsEditing(false);
    }
  };

  const handleSave = async () => {
    if (editor.current) {
      setIsLoading(true);
      const dataUrl = editor.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      const arrayBuffer = await blob.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const md5Digest = md5.base64(buffer);

      try {
        await auth.isAuthenticated();
        const response = await be.post('uploader-stack', '/team/settings/profile-pic', { contentMd5: md5Digest }, true, true);

        const formData = new FormData();
        Object.keys(response?.fields).forEach((key) => {
          formData.append(key, response?.fields[key]);
        });
        formData.append('file', blob);

        await axios.post(response.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Origin, Authorization'
          },
        });
      } catch (error) {
        console.error('[Pic] Error uploading image:', error);
      } finally {
        setIsLoading(false);
        setIsEditing(false);
      }
    }
  };

  return (
    <div className="tw-relative">
      {isEditing ? (
        <div>
          <AvatarEditor
            ref={editor}
            image={selectedFileUrl || pic}
            width={96}
            height={96}
            border={0}
            borderRadius={150}
            scale={zoomScale / 10}
            rotate={0}
          />
          <input
            type="range"
            min={10}
            max={50}
            value={zoomScale}
            onChange={(e) => setZoomScale(e.target.value)}
            className="tw-w-full tw-mt-2"
          />
          <div className="tw-flex tw-mt-2">
            <button
              onClick={() => setIsEditing(false)}
              className="tw-mr-2 tw-bg-gray-200 tw-px-4 tw-py-2 tw-rounded"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  aria-hidden="true"
                  className="tw-inline tw-w-4 tw-h-4 tw-text-white tw-animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0
                    22.9766 22.3858 0.59082 50
                    0.59082C77.6142 0.59082 100
                    22.9766 100 50.5908ZM9.08144
                    50.5908C9.08144 73.1895 27.4013
                    91.5094 50 91.5094C72.5987 91.5094
                    90.9186 73.1895 90.9186
                    50.5908C90.9186 27.9921 72.5987
                    9.67226 50 9.67226C27.4013
                    9.67226 9.08144 27.9921
                    9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393
                    38.4038 97.8624 35.9116 97.0079
                    33.5539C95.2932 28.8227 92.871
                    24.3692 89.8167 20.348C85.8452
                    15.1192 80.8826 10.7238 75.2124
                    7.41289C69.5422 4.10194 63.2754
                    1.94025 56.7698 1.05124C51.7666
                    0.367541 46.6976 0.446843
                    41.7345 1.27873C39.2613
                    1.69328 37.813 4.19778 38.4501
                    6.62326C39.0873 9.04874 41.5694
                    10.4717 44.0505 10.1071C47.8511
                    9.54855 51.7191 9.52689 55.5402
                    10.0491C60.8642 10.7766 65.9928
                    12.5457 70.6331 15.2552C75.2735
                    17.9648 79.3347 21.5619 82.5849
                    25.841C84.9175 28.9121 86.7997
                    32.2913 88.1811 35.8758C89.083
                    38.2158 91.5421 39.6781 93.9676
                    39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      ) : (
        <>
          <img
            src={preview || pic || 'https://via.placeholder.com/150'}
            alt="Profile"
            className="tw-w-16 tw-h-16 tw-rounded-full tw-object-cover"
          />
          {canEdit && (
            <button
              onClick={handleEditClick}
              className="tw-absolute tw-bottom-0 tw-right-0 tw-p-1 tw-bg-gray-800 tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  aria-hidden="true"
                  className="tw-inline tw-w-4 tw-h-4 tw-text-white tw-animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142
                    100.591 50 100.591C22.3858 100.591
                    0 78.2051 0 50.5908C0 22.9766 22.3858
                    0.59082 50 0.59082C77.6142
                    0.59082 100 22.9766 100
                    50.5908ZM9.08144 50.5908C9.08144
                    73.1895 27.4013 91.5094 50
                    91.5094C72.5987 91.5094 90.9186
                    73.1895 90.9186 50.5908C90.9186
                    27.9921 72.5987 9.67226 50
                    9.67226C27.4013 9.67226 9.08144
                    27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393
                    38.4038 97.8624 35.9116 97.0079
                    33.5539C95.2932 28.8227 92.871
                    24.3692 89.8167 20.348C85.8452
                    15.1192 80.8826 10.7238 75.2124
                    7.41289C69.5422 4.10194 63.2754
                    1.94025 56.7698 1.05124C51.7666
                    0.367541 46.6976 0.446843
                    41.7345 1.27873C39.2613
                    1.69328 37.813 4.19778 38.4501
                    6.62326C39.0873 9.04874 41.5694
                    10.4717 44.0505 10.1071C47.8511
                    9.54855 51.7191 9.52689 55.5402
                    10.0491C60.8642 10.7766 65.9928
                    12.5457 70.6331 15.2552C75.2735
                    17.9648 79.3347 21.5619 82.5849
                    25.841C84.9175 28.9121 86.7997
                    32.2913 88.1811 35.8758C89.083
                    38.2158 91.5421 39.6781 93.9676
                    39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              )}
            </button>
          )}
        </>
      )}
      <input
        id="pic-input"
        type="file"
        accept="image/*"
        className="tw-hidden"
        ref={fileInput}
        onChange={handleImgChange}
      />
    </div>
  );
}

export default Pic;

