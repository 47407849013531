import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from 'react-google-login';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { useCalendarConnectionsStore } from '../store';
import auth from '../Account/Auth';
import be from '../BE';
import UpgradePath from '../Utilities/UpgradePath/UpgradePath';

function AddCalendar({connectedCalendars}){
  const { t } = useTranslation(['calendarConnections']);
  const setCalendarAccess = useCalendarConnectionsStore((state) => state.setCalendarAccess);
  const setCalendarPic = useCalendarConnectionsStore((state) => state.setCalendarPic);
  const addCalendarStore = useCalendarConnectionsStore((state) => state.addCalendar);
  const [statusCode,setStatusCode] = useState(0);
  const [loading,setLoading] = useState(false);
  const addCalendar = async (_code) => {
    // prendo la radice dell'url corrente per usare il corrispettivo path
    let radixUrl = window.location.hostname
    let path = ''
    if (radixUrl === "localhost"){path='/linkNewCalendar'}
    if (radixUrl === "dev.calendbook.com"){path='/devNewCalendar'}
    if (radixUrl === "www.calendbook.com"){path='/prodNewCalendar'}
    setStatusCode(0);
    setLoading(true);
    await auth.isAuthenticated()
      .then(async () => {
        await be.get('calendarhandler',path,{code:_code},true)
          .then((response) => {
            console.log(response)
            addCalendarStore(response);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setStatusCode(error?.response?.status)
            setLoading(false);
          });
      })
      .catch(() => this.props.history.push('/login'))
  }
  const radixUrl = window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
  const { signIn, loaded } = useGoogleLogin({
    uxMode:"redirect",
    clientId:"93252394910-584iq1mr08q684hurrfma0i9fct9cs82.apps.googleusercontent.com",
    scope:"https://www.googleapis.com/auth/calendar",
    accessType:"offline",
    redirectUri:radixUrl,
    cookiePolicy:'single_host_origin',
    responseType:"code",
    onSuccess: async (response) => {await addCalendar(response.code)}
  })

  return(
    <div className="d-inline-block float-end">
      {connectedCalendars === 0 
        ?
          <Button
            variant={
              (statusCode >= 400 && statusCode <= 500) 
                ? 'outline-danger' 
                : 'primary'
            }
            onClick={signIn}
            style={{minWidth:50}}
          >
            {loading
              ?
                <Spinner animation="border" size="sm"/>
                :
                <>
                  <i className="fas fa-user-plus me-0 me-md-2"></i>
                  <span className="d-none d-md-inline-block">{t('addCalendarBtn')}</span>
                </>
            }
          </Button>
        :
          <UpgradePath 
            featureName="multiple-calendars"
            position="center"
          >
            <Button
              variant={
                (statusCode >= 400 && statusCode <= 500) 
                  ? 'outline-danger' 
                  : 'primary'
              }
              onClick={signIn}
              style={{minWidth:50}}
            >
              {loading
                ?
                  <Spinner animation="border" size="sm"/>
                  :
                  <>
                    <i className="fas fa-user-plus me-0 me-md-2"></i>
                    <span className="d-none d-md-inline-block">{t('addCalendarBtn')}</span>
                  </>
              }
            </Button>
          </UpgradePath>
      }

    </div>
  );
}

export default AddCalendar;
