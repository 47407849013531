import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import be from '../BE';
import auth from '../Account/Auth';

async function enableEvent(key_param){
  await auth.isAuthenticated()
  .then(() => {
    const body = { key : key_param }
    be.post('eventtypes','/enable',body,true)
    .then(response => window.location.href = '/')
    .catch(error => console.log(error));
  })
  .catch(() => this.props.history.push('/login'));
}

function CopyLinkButton({id}){
  return(
    <>
      <button className="icon-button float-end" onClick={async () => (await enableEvent(id))}><i className="fas fa-power-off"></i></button>
    </>
  );
}

export default CopyLinkButton;
