import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Details from './Details';
import Filters from './Filters';
import GoogleContacts from './GoogleContacts';
import RatingStars from './RatingStars';
import NoteInput from './NoteInput';
import be from '../../BE';
import auth from '../../Account/Auth';
import i18n from '../../i18n';

function Contacts({showPlan, setShowPlan}) {
    const history = useHistory();
    const PricingCard = window.calendbookUI.PricingCard;
    const [loading, setLoading] = useState(false);
    const [savingRating, setSavingRating] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [copiedContact, setCopiedContact] = useState(false);
    const [contactsCache, setContactsCache] = useState([]); // All contacts fetched and cached
    const [totalContacts, setTotalContacts] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [actionFilter, setActionFilter] = useState([]);
    const [ratingFilter, setRatingFilter] = useState(0); // Added ratingFilter state
    const [showContacts, setShowContacts] = useState(true);
    const [currentPage, setCurrentPage] = useState(0); // 0-based index
    const [pageIndices, setPageIndices] = useState(['']); // Stores 'page' indices for each page
    const [hasMore, setHasMore] = useState(true); // Indicates if there are more contacts to fetch
    const isProd = window.location.hostname === "www.calendbook.com";
    const role = auth.getProperty({ // get user role/plan
        storageKey: 'userSession',
        path: ['profileObj','planStatus','status'],
        fallback: 'unknown',
    });

    /**
     * Fetch contacts for a given page
     * @param {number} page - Page number to fetch (0-based index)
     */
    const fetchContactsForPage = (page) => {
        const pageIndex = pageIndices[page]; // Get the 'page' parameter for this page
        let body = { limit: pageLimit };
        if (pageIndex) { body['page'] = pageIndex; }
        if (actionFilter.length) { body['action'] = actionFilter; }
        if (showContacts) {body['showContacts'] = true; }
        if (ratingFilter > 0) { body['rating'] = ratingFilter; }

        setLoading(true);

        return auth.isAuthenticated()
            .then(() => {
                return be.get('Leads', '/leads', body, true, true);
            })
            .then((response) => {
                console.log(`[leads][contacts] got contacts for page ${page + 1}`);
                const { items, total, page: nextPageIndex } = response;

                setTotalContacts(total);

                setContactsCache(prevContacts => {
                    const contactsMap = new Map(prevContacts.map(contact => [contact.contact, contact]));
                    items.forEach(item => {
                        contactsMap.set(item.contact, item);
                    });
                    return Array.from(contactsMap.values());
                });

                setPageIndices(prevPageIndices => {
                    const newPageIndices = [...prevPageIndices];
                    newPageIndices[page + 1] = nextPageIndex;
                    return newPageIndices;
                });

                if (items.length < pageLimit || contactsCache.length + items.length >= total) {
                    setHasMore(false);
                }

                return true; // Indicate success
            })
            .catch((error) => {
                console.error("[leads][contacts] error while fetching contacts:", error);
                if (error.response && error.response.status === 401) {
                    history.push({ pathname: '/login' });
                }
                return false; // Indicate failure
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const saveContactRating = async (_contact,_rating) => {
        try {
            setSavingRating(_contact);
            await auth.isAuthenticated()
                .then(async () => {
                    await be.post('Leads',`/leads/${_contact}/rating`,{rating:_rating},true,true)
                        .then((response) => {
                            // update contact rating
                            setContactsCache((prevContacts) => {
                                return prevContacts.map((contact) => {
                                    if (contact?.contact === _contact) {
                                        // Update the rating for the matched contact
                                        return {
                                            ...contact,
                                            rating: _rating,
                                        };
                                    }
                                    return contact;
                                });
                            });
                            console.log(`[leads][contact-rating] updated rating for ${_contact}`);
                        })
                        .catch(error => {
                            console.error("[leads][contact-rating] network error while saving contact rating:",error);
                        })
                })
                .catch((error) => {
                    console.error("[leads][contact-rating] authentication error while savingg contact rating:", error)
                    history.push({pathname:'/login'})
                })
        } catch (error) {
            console.error(`[leads][contact-rating] functional error while saving rating for ${_contact}`);
        } finally {
            setSavingRating(null);
        }
    };

    const saveContactNote = async (contactId, noteValue) => {
        try {
            await auth.isAuthenticated()
                .then(async () => {
                    await be.post('Leads', `/leads/${contactId}/notes/short`, { shortNote: noteValue }, true, true)
                        .then((response) => {
                            setContactsCache((prevContacts) => {
                                return prevContacts.map((contact) => {
                                    if (contact.contact === contactId) {
                                        return {
                                            ...contact,
                                            shortNote: noteValue,
                                        };
                                    }
                                    return contact;
                                });
                            });
                            console.log(`[leads][contact-note] updated note for ${contactId}`);
                        })
                        .catch((error) => {
                            console.error("[leads][contact-note] network error while updating contact note:",error);
                        })
                })
                .catch((error) => {
                    console.error("[leads][contact-note] authentication error while updating contact note:",error);
                })
        } catch (error) {
            console.error(`[leads][contact-note] functional error while saving note for ${contactId}:`, error);
        }
    };


    /**
     * Function to search for the rating given a contact.
     * @param {Array} contacts - The array of contact objects.
     * @param {string} contact - The contact identifier (e.g., phone number or email).
     * @returns {number} - The rating of the specified contact or 0 if not found.
     */
    function getRatingByContact(contacts, contact) {
        if (!Array.isArray(contacts) || typeof contact !== 'string') {
            throw new Error('Invalid input: contacts should be an array and contact should be a string.');
        }

        // Normalize contact for consistent comparisons
        const normalizedContact = contact.trim().toLowerCase();

        // Find the contact and return the rating
        const foundContact = contacts.find(
            (c) => c.contact.trim().toLowerCase() === normalizedContact
        );

        // Return the rating if the contact is found, otherwise 0
        return foundContact ? foundContact.rating : 0;
    }

    // Handle 'NEXT' button click
    const handleNext = () => {
        const nextPage = currentPage + 1;
        const startIndex = nextPage * pageLimit;

        if (startIndex < contactsCache.length) {
            setCurrentPage(nextPage);
        } else if (hasMore) {
            fetchContactsForPage(nextPage)
                .then((success) => {
                    if (success) {
                        setCurrentPage(nextPage);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching next page:", error);
                });
        } else {
            if (startIndex < contactsCache.length) {
                setCurrentPage(nextPage);
            } else {
                console.log("[leads][contacts] No more contacts to display");
            }
        }
    };

    // Handle 'PREV' button click
    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Handle changing items per page
    const handleItemsPerPageChange = (e) => {
        const newPageLimit = parseInt(e.target.value, 10);
        setPageLimit(newPageLimit);
        // Reset pagination states
        setCurrentPage(0);
        setPageIndices(['']);
        setContactsCache([]);
        setHasMore(true);
    };

    // Handle changing filters
    const handleActionFilterChange = (_filter) => {
        if (actionFilter.includes(_filter) || _filter === "") {
            // Remove the filter if it's already present
            // Or filter is an empty string id
            setActionFilter([]);
        } else {
            // Replace the current filter with the new one
            setActionFilter([_filter]);
        }
        // Clear rating filter since only one filter can be active
        setRatingFilter(0);
        // Reset pagination states
        setPageLimit(pageLimit);
        setCurrentPage(0);
        setPageIndices(['']);
        setContactsCache([]);
        setHasMore(true);
    };

    const handleRatingFilterChange = (_rating) => {
        if (_rating === 0 || ratingFilter === _rating) {
            // Clear the rating filter
            setRatingFilter(0);
        } else {
            // Set the new rating filter
            setRatingFilter(_rating);
        }
        // Clear action filter since only one filter can be active
        setActionFilter([]);
        // Reset pagination states
        setCurrentPage(0);
        setPageIndices(['']);
        setContactsCache([]);
        setHasMore(true);
    };

    // Handle changing contacts show
    const handleShowContactsChange = () => {
        if (showContacts) {
            setShowContacts(false);
        } else {
            setShowContacts(true);
        }

        // Reset pagination states
        setPageLimit(pageLimit);
        setCurrentPage(0);
        setPageIndices(['']);
        setContactsCache([]);
        setHasMore(true);
    };

    // Get color and string for every action enumeration
    const parseAction = (_action) => {
        if(_action === 'booked'){ return {color: 'green', displayString: 'Booked'} }
        if(_action === 'rescheduled'){ return {color: 'orange', displayString: 'Rescheduled'} }
        if(_action === 'cancelled'){ return {color: 'red', displayString: 'Cancelled'} }
        if(_action === 'pending_payment'){ return {color: 'orange', displayString: 'Pending Payment'} }
        if(_action === 'pending_approval'){ return {color: 'orange', displayString: 'Pending Approval'} }
        if(_action === 'rejected'){ return {color: 'red', displayString: 'Rejected'} }
        if(_action === 'google_imported'){ return {color: 'blue', displayString: 'Google Imported'} }

        return {color: 'white', displayString: '-'}
    }

    const copyContact = (_contact) => {
        navigator.clipboard.writeText(_contact);
        setCopiedContact(_contact);
        setTimeout(() => setCopiedContact(false),3000);
    }

    const setRating = (_ratingContact, _newRating) => {
        saveContactRating(_ratingContact,_newRating);
    }

    const getUserLanguage = () => {
        return i18n.language.substring(0,2);
    }

    const formatDate = (_date) => {
        // Date formatting options
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        // Getting date object from db
        var dateFormatted = new Date(_date);
        // Format: weekday, monthname daynumber, year
        dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
        // To uppercase string
        dateFormatted = dateFormatted.toString();
        return dateFormatted;
    }

    // Fetch contacts when component mounts or when pageLimit changes
    useEffect(() => {
        const init = async () => {
            await fetchContactsForPage(0);
            setCurrentPage(0);
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLimit,actionFilter,ratingFilter,showContacts]);

    // Get contacts for the current page from cache
    const startIndex = currentPage * pageLimit;
    const endIndex = startIndex + pageLimit;
    const currentContacts = contactsCache.slice(startIndex, endIndex);

    // Skeleton Array
    const skeletonArray = Array(pageLimit).fill(null);

    return (
        <>
            {showDrawer &&
            <Details
                showDrawer={showDrawer}
                setShowDrawer={setShowDrawer}
                contact={selectedContact}
                contactsCache={contactsCache}
                rating={getRatingByContact(contactsCache, selectedContact)}
                savingRating={savingRating}
                setRating={setRating}
            />
            }
            <section className="tw-py-3 sm:tw-py-5">
                {/* Ghost span, used only to build the needed color classes in the style.css */}
                <span className="tw-bg-orange-500 tw-bg-red-500 tw-bg-green-500 tw-bg-white-500 tw-bg-blue-500"></span>
                <div className="tw-px-4 tw-mx-auto tw-max-w-screen-2xl lg:tw-px-12">
                    {!isProd &&
                    <GoogleOAuthProvider clientId='941550493202-dtuj7ol0cagfjnoqvvlt8hh4hh8dskam.apps.googleusercontent.com'>
                        <GoogleContacts refresh={fetchContactsForPage}/>
                    </GoogleOAuthProvider>
                    }

                    <Filters
                        loading={loading}
                        actionFilter={actionFilter}
                        handleActionFilterChange={handleActionFilterChange}
                        ratingFilter={ratingFilter} // Pass ratingFilter to Filters component
                        handleRatingFilterChange={handleRatingFilterChange} // Pass handleRatingFilterChange
                        showContacts={showContacts}
                        handleShowContactsChange={handleShowContactsChange}
                    />

                    <div className="tw-relative tw-overflow-hidden tw-bg-white tw-shadow-md tw-dark:bg-gray-800 sm:tw-rounded-lg">
                        <div className="tw-overflow-x-auto">
                            <table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500 tw-dark:text-gray-400">

                                <thead className="tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50 tw-dark:bg-gray-700 tw-dark:text-gray-400">
                                    <tr>
                                        {/* heading checkbox - later use */}
                                        {/*
                                        <th scope="col" className="tw-p-4">
                                            <div className="tw-flex tw-items-center">
                                                <input id="checkbox-all" type="checkbox" className="tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-800 focus:tw-ring-2 tw-dark:bg-gray-700 tw-dark:border-gray-600" />
                                                <label htmlFor="checkbox-all" className="tw-sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        */}
                                        <th scope="col" className="tw-px-4 tw-py-3">Name</th>
                                        <th scope="col" className="tw-px-4 tw-py-3">Contact</th>
                                        <th scope="col" className="tw-px-4 tw-py-3">Rating</th>
                                        <th scope="col" className="tw-px-4 tw-py-3">Notes</th>
                                        <th scope="col" className="tw-px-4 tw-py-3">Status</th>
                                        <th scope="col" className="tw-px-4 tw-py-3 tw-whitespace-nowrap">Date Added</th>
                                        {currentContacts[0]?.retentionDayLeft &&
                                        <th
                                            scope="col"
                                            className={`${role !== 'professional' && 'tw-underline tw-cursor-pointer'} tw-px-4 tw-py-3 tw-whitespace-nowrap`}
                                            onClick={() => {
                                                if (!showPlan) return;
                                                const plansToAdd =
                                                    role === 'free'
                                                        ? ['premium', 'professional']
                                                        : ['premium', 'LTD'].includes(role)
                                                            ? ['professional']
                                                            : [];
                                                const newPlans = plansToAdd.filter((plan) => !showPlan.includes(plan));
                                                if (newPlans.length > 0) { setShowPlan((prevPlan) => [...prevPlan, ...newPlans]) }
                                            }}
                                        >
                                            Days Left
                                        </th>
                                        }
                                        {/* quick actions - later use */}
                                        {/*
                                        <th scope="col" className="tw-px-4 tw-py-3">
                                            <span className="tw-sr-only">Actions</span>
                                        </th>
                                        */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {/* Skeleton render logic */}
                                    {loading && skeletonArray.map((_,index) => (
                                        <tr key={`skeleton-row-${index}`} className="tw-animate-pulse tw-border-b tw-dark:border-gray-600 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-700">
                                            <td className="tw-px-4 tw-py-2"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-28 tw-h-3"></div></td>
                                            <td className="tw-px-4 tw-py-2"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-28 tw-h-3"></div></td>
                                            <td className="tw-px-4 tw-py-2"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-24 tw-h-3"></div></td>
                                            <td className="tw-px-4 tw-py-2"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-24 tw-h-3"></div></td>
                                            <td className="tw-px-4 tw-py-2"><div className="tw-inline-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-3 tw-h-3 tw-me-2 tw-rounded-full"></div><div className="tw-inline-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-28 tw-h-3"></div></td>
                                            <td className="tw-px-4 tw-py-2"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-28 tw-h-3"></div></td>
                                            {currentContacts[0]?.retentionDayLeft &&
                                            <td className="tw-px-4 tw-py-2"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-20 tw-h-3"></div></td>
                                            }
                                            {/* quick actions - later use */}
                                            {/*
                                                <td className="tw-px-4 tw-py-3"><div className="tw-flex tw-items-center tw-bg-slate-700 tw-animate-pulse tw-w-8 tw-h-3"></div></td>
                                                */}
                                        </tr>
                                    ))}

                                    {!loading && currentContacts.length === 0 &&
                                        <tr>
                                            <td colSpan={currentContacts[0]?.retentionDayLeft ? "8" : "7"} className="tw-text-center tw-px-4 tw-py-16">
                                                <div className="tw-text-4xl tw-font-black">
                                                    Get booked to collect new leads!
                                                </div>
                                                <div>
                                                    Your leads will appear here once you will get booked
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {!loading && Array.isArray(showPlan) && showPlan.length > 0 &&
                                        <tr>
                                            <td colSpan={currentContacts[0]?.retentionDayLeft ? "8" : "7"} className="tw-text-center tw-px-4 tw-py-16">
                                                <div className="tw-flex tw-justify-end">
                                                    <div className="tw-p-4 tw-cursor-pointer" onClick={() => setShowPlan([])}>
                                                        <svg aria-hidden="true" className="tw-w-5 tw-h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="tw-flex tw-gap-8 tw-mx-auto tw-justify-center">
                                                    {showPlan.includes("premium") &&
                                                    <PricingCard
                                                        variant="secondary"
                                                        plan="Premium"
                                                        description="For Premium users"
                                                        currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                                                        price={'2'}
                                                        cadency="month"
                                                        features={[
                                                            'All premium features',
                                                            'Up to 1000 leads',
                                                            'Retention Period: 365 days',
                                                            'Table view',
                                                        ]}
                                                    />
                                                    }
                                                    {showPlan.includes("professional") &&
                                                        <PricingCard
                                                            variant="primary"
                                                            plan="Professional"
                                                            description="For Advanced users"
                                                            currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                                                            price={'5'}
                                                            cadency="month"
                                                            features={[
                                                                'All professional features',
                                                                'Unlimited leads (launch offer)',
                                                                'Retention Period: unlimited (launch offer)',
                                                                'CSV Export',
                                                            ]}
                                                        />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {!loading && currentContacts && currentContacts.map((contact) => (
                                        <tr key={contact?.contact} className="tw-border-b tw-dark:border-gray-600 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-700">
                                            {/* body checkbox - later use */}
                                            {/*
                                            <td className="tw-w-4 tw-px-4 tw-py-3">
                                                <div className="tw-flex tw-items-center">
                                                    <input id="checkbox-table-search-1" type="checkbox" onClick={(e) => e.stopPropagation()} className="tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-800 focus:tw-ring-2 tw-dark:bg-gray-700 tw-dark:border-gray-600" />
                                                    <label htmlFor="checkbox-table-search-1" className="tw-sr-only">checkbox</label>
                                                </div>
                                            </td>
                                            */}
                                            <th scope="row" className="tw-px-4 tw-py-2 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap tw-dark:text-white hover:tw-text-primary-500 tw-cursor-pointer" onClick={() => {setSelectedContact(contact?.contact); setShowDrawer(true);}}>
                                                <div className="tw-flex tw-items-center">
                                                    {/* user image - later use */}
                                                    {/*
                                                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/avatar-10.png" alt="iMac Front Image" className="tw-w-auto tw-h-8 tw-mr-3 tw-rounded-full" />
                                                    */}
                                                    {contact?.name || '-'}
                                                </div>
                                            </th>

                                            <td className="tw-px-4 tw-py-2">
                                                <div onClick={() => copyContact(contact?.contact)} className="hover:tw-cursor-pointer tw-inline-flex tw-items-center tw-bg-primary-100 tw-text-primary-800 tw-text-xs tw-font-medium tw-px-2 tw-py-0.5 tw-rounded tw-dark:bg-primary-900 tw-dark:text-primary-300">
                                                    {copiedContact === contact?.contact
                                                        ?
                                                            <svg class="tw-w-3.5 tw-h-3.5 tw-mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-3.5 tw-w-3.5 tw-mr-1" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" />
                                                            </svg>
                                                    }

                                                    {contact?.contact}
                                                </div>
                                            </td>

                                            <td className="tw-px-4 tw-py-2">
                                                <div className="tw-flex tw-items-center">
                                                    <RatingStars key={`${contact?.contact}-rating-component`} contact={contact?.contact} rating={contact?.rating} onChange={setRating} savingRating={savingRating}/>
                                                </div>
                                            </td>

                                            <td className="tw-px-4 tw-py-2">
                                                <NoteInput
                                                    contactId={contact?.contact}
                                                    initialNote={contact?.shortNote}
                                                    saveNote={saveContactNote}
                                                />
                                            </td>

                                            <td className="tw-px-4 tw-py-2 hover:tw-cursor-pointer tw-font-medium tw-text-gray-900 tw-whitespace-nowrap tw-dark:text-white" onClick={() => {setSelectedContact(contact?.contact); setShowDrawer(true);}}>
                                                <div className="tw-flex tw-items-center">
                                                    {/* dot color - check colors for actions */}
                                                    <div className={`tw-w-3 tw-h-3 tw-mr-2 tw-bg-${parseAction(contact?.action)?.color}-500 tw-border tw-rounded-full`}></div>
                                                    {parseAction(contact?.action)?.displayString}
                                                </div>
                                            </td>
                                            <td className="tw-px-4 tw-py-2 hover:tw-cursor-pointer" onClick={() => {setSelectedContact(contact?.contact); setShowDrawer(true);}}>{formatDate(contact?.acquisitionDate)}</td>
                                            {contact?.retentionDayLeft &&
                                            <td className="tw-px-4 tw-py-2">{contact?.retentionDayLeft}</td>
                                            }
                                            {/* quick actions - later use */}
                                            {/*
                                                <td className="tw-px-4 tw-py-2 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap tw-dark:text-white">
                                                    <button id="dropdown-button-0" type="button" data-dropdown-toggle="dropdown-0" className="tw-inline-flex tw-items-center tw-p-1 tw-text-sm tw-font-medium tw-text-center tw-text-gray-500 tw-rounded-lg hover:tw-text-gray-800 hover:tw-bg-gray-200 tw-dark:hover:bg-gray-700 focus:tw-outline-none tw-dark:text-gray-400 tw-dark:hover:text-gray-100">
                                                        <svg className="tw-w-5 tw-h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                                        </svg>
                                                    </button>
                                                    <div id="dropdown-0" className="tw-z-10 tw-hidden tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded tw-shadow tw-w-44 tw-dark:bg-gray-700 tw-dark:divide-gray-600">
                                                        <ul className="tw-py-1 tw-text-sm tw-text-gray-700 tw-dark:text-gray-200" aria-labelledby="dropdown-button-0">
                                                            <li>
                                                                <a href="#" className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white">Show</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white">Edit</a>
                                                            </li>
                                                        </ul>
                                                        <div className="tw-py-1">
                                                            <a href="#" className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:text-gray-200 tw-dark:hover:text-white">Delete</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                */}
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                        <nav className="tw-flex tw-flex-col tw-items-start tw-justify-between tw-p-4 tw-space-y-3 md:tw-flex-row md:tw-items-center md:tw-space-y-0" aria-label="Table navigation">
                            <div className="tw-flex tw-items-center tw-space-x-3">
                                <label htmlFor="rows" className="tw-text-xs tw-font-normal tw-text-gray-500 tw-dark:text-gray-400">Rows per page</label>
                                <select id="rows" value={pageLimit} onChange={handleItemsPerPageChange} className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-primary-500 focus:tw-border-primary-500 tw-block tw-py-1.5 tw-pl-3.5 tw-pr-6 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:tw-ring-primary-500 tw-dark:focus:tw-border-primary-500">
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                                <div className="tw-text-xs tw-font-normal tw-text-gray-500 tw-dark:text-gray-400">
                                    <span className="tw-font-semibold tw-text-gray-900 tw-dark:text-white">{startIndex + 1} - {startIndex + currentContacts.length}</span>
                                    &nbsp;
                                    of
                                    &nbsp;
                                    <span className="tw-font-semibold tw-text-gray-900 tw-dark:text-white">{totalContacts}</span>
                                </div>
                            </div>
                            <ul className="tw-inline-flex tw-items-stretch tw--space-x-px">
                                <li>
                                    <button
                                        className="tw-flex tw-text-sm tw-w-20 tw-items-center tw-justify-center tw-h-full tw-py-1.5 tw-px-3 tw-ml-0 tw-text-gray-500 tw-bg-white tw-rounded-l-lg tw-border tw-border-gray-300 hover:tw-bg-gray-100 hover:tw-text-gray-700 tw-dark:bg-gray-800 tw-dark:border-gray-700 tw-dark:text-gray-400 tw-dark:hover:bg-gray-700 tw-dark:hover:text-white"
                                        onClick={handlePrev}
                                        disabled={currentPage === 0}
                                    >
                                        Previous
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="tw-flex tw-text-sm tw-w-20 tw-items-center tw-justify-center tw-h-full tw-py-1.5 tw-px-3 tw-leading-tight tw-text-gray-500 tw-bg-white tw-rounded-r-lg tw-border tw-border-gray-300 hover:tw-bg-gray-100 hover:tw-text-gray-700 tw-dark:bg-gray-800 tw-dark:border-gray-700 tw-dark:text-gray-400 tw-dark:hover:bg-gray-700 tw-dark:hover:text-white"
                                        onClick={handleNext}
                                        disabled={
                                            !hasMore && (currentPage + 1) * pageLimit >= contactsCache.length
                                        }
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contacts;
