import React, { useEffect } from "react";
import { TermsEng } from "./terms-eng";

function Terms() {
  useEffect(() => {
    document.title = "Calendbook - Terms";
  }, []);
  try {
    window.tidioChatApi.show();
  } catch {}
  return (
    <div style={{ margin: "50px" }}>
      <pre
        style={{
          "white-space": "pre-wrap",
          "white-space": "-moz-pre-wrap",
          "white-space": "-o-pre-wrap",
          "white-space": "-pre-wrap",
          "word-wrap": "break-word",
          "white-space": "break-spaces",
          "overflow-x": "auto",
        }}
      >
        {TermsEng}
      </pre>
    </div>
  );
}

export default Terms;
