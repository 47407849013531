import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



function Color({color,enabled}){
  if(!enabled){
    color='#fff';
  }
  return(
    <>
      <div className="event-type-color" style={{backgroundColor:color}}></div>
    </>
  );
}

export default Color;
