import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pricing-card.css';

function _PricingCard(props){
  const { t } = useTranslation(["features","upgrade","common"]);
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1280px)'})
  const check = <i className="fas fa-check me-2 text-primary"></i>
  const cross = <i className="fas fa-times me-2 text-secondary"></i>
  const cardStyle = { background : props.background }
  const FeatureOverlay = ({id,description,children}) => (
    <OverlayTrigger
      placement={isTabletOrMobile ? 'bottom' : 'left'}
      overlay={ <Tooltip id={id}> {description} </Tooltip> }
    >
      <div>{children}</div>
    </OverlayTrigger>
  )

  return(
    <>
      <div 
        className={props.overlay ? 'mx-auto mx-md-0 product-pricing-card overlay' : 'mx-auto mx-md-0 product-pricing-card'}
        style={cardStyle}
      >
        <div>
          {props?.planDisplayName
            ?<div className="d-inline h4 fw-bold text-capitalize"> {props?.planDisplayName} </div>
            :<div className="d-inline h4 fw-bold text-capitalize"> {props?.plan} </div>
          }
          <div className="d-inline">
            {props.limited && <Badge className="" variant="primary">{t('common:limited')}</Badge> }
          </div>
        </div>
        <div>
          {props?.price &&
          <div className="my-3">
            <span className="price">{props.currency} {props.price}</span>
            <span className="ms-1">{t('upgrade:premium.price')}</span>
          </div> 
          }
          {props?.CTALink && !props?.hideCTA &&
            <div>
              {typeof(props.CTALink) === "string" 
                ?
                  <a href={props.CTALink} target="_blank" rel="noopener noreferrer">
                    <button className="cta-btn">{t('upgrade:premium.cta')}</button>
                  </a>
                :
                <button onClick={() => props.CTALink()} className="cta-btn">{t('upgrade:premium.cta')}</button>
              }
            </div>
          }
          <div className="my-5 mx-auto">
            {Object.keys(props.features).map(feature => (
              <div key={`${props.plan}-plan-${feature}`} className="text-start my-3">
                {props.features[feature] ? check : cross}
                <div style={{display:'inline-block',borderBottom:'1px dashed var(--color-border-light)'}}>
                  <FeatureOverlay id={`${props.plan}-plan-${feature}-container`} description={t(`features:${feature}.subtitle`)}>
                    {t(`features:${feature}.variants.prefix.${props.plan}`)} {t(`features:${feature}.title`)} {t(`features:${feature}.variants.suffix.${props.plan}`)}
                  </FeatureOverlay>
                </div>
              </div>
            ))}
          </div>
          {props?.CTALink && !props?.hideCTA &&
          <div>
            {typeof(props.CTALink) === "string" 
              ?
                <a href={props.CTALink} target="_blank" rel="noopener noreferrer">
                  <button className="cta-btn">{t('upgrade:premium.cta')}</button>
                </a>
                :
                <button onClick={() => props.CTALink()} className="cta-btn">{t('upgrade:premium.cta')}</button>
            }
          </div>
          }
        </div>
      </div>
    </>
  );
}

export default _PricingCard;
