import {useTranslation} from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Content from './Content';
import Required from './Required';
import AnswerType from './AnswerType';
import Field from './Field/_Field'
import Add from './Field/Add';
import Delete from './Delete';

function Body({content,type,required,fields,order,id,existingQuestions,existingFields,editing}){
  const {t} = useTranslation(['eventCreator']);
  return(
    <Accordion.Body key={`accordion-body-${id}`} style={{color:'var(--color-text)'}}>
      <p> {t('surveys.question')} </p>
      <Content key={`accordion-body-content-${id}`} content={content} order={order} id={id}/>

      <Required key={`accordion-body-required-${id}`} required={required} order={order} id={id}/>

      <div className="mb-3">
        <p> {t('surveys.answerType')} </p>
        <AnswerType key={`accordion-body-answer-text-${id}`} type="text" chosenType={type} order={order} id={id} existingQuestions={existingQuestions} editing={editing}/>
        <AnswerType key={`accordion-body-answer-radio-${id}`} type="radio" chosenType={type} order={order} id={id} existingQuestions={existingQuestions} editing={editing}/>
        <AnswerType key={`accordion-body-answer-checkbox-${id}`} type="checkbox" chosenType={type} order={order} id={id} existingQuestions={existingQuestions} editing={editing}/>
        <AnswerType key={`accordion-body-answer-phone-${id}`} type="phone" chosenType={type} order={order} id={id} existingQuestions={existingQuestions} editing={editing}/>
        <AnswerType key={`accordion-body-answer-telegram-${id}`} type="telegram" chosenType={type} order={order} id={id} existingQuestions={existingQuestions} editing={editing}/>
        <AnswerType key={`accordion-body-answer-whatsapp-${id}`} type="whatsapp" chosenType={type} order={order} id={id} existingQuestions={existingQuestions} editing={editing}/>
      </div>

      {(type === 1 || type === 2) &&
        <div className="mb-3">
          <p> {t('surveys.answers')} </p>
          {Object.keys(fields).map((key,index) => (
            <div className="my-3">
              <Field key={`accordion-body-field-${key}-${id}`} editing={editing} fieldContent={fields[key]} order={order} index={index} questionId={id} fieldId={key} existingFields={existingFields}/>
            </div>
          ))}
          <Add order={order} id={id}/>
        </div>
      }
      
      <Delete order={order} id={id}/>
    </Accordion.Body>
  );
}

export default Body;

