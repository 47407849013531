import MonthGroup from './MonthGroup';

function YearGroup(props){
  const year = props.year;
  const yearDates = props.yearDates;
  const yearDatesArray = Object.keys(yearDates);
  return(
          yearDatesArray.map((month) => ( 
            <MonthGroup key={`${year}-${month}`} month={month} year={year} monthDates={yearDates[month]} pending={props?.pending}/>
          ))
  );
}

export default YearGroup;
