import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './invite-control.css';
import auth from '../../Account/Auth';
import be from '../../BE';
import ReactGA from "react-ga4";

function InviteControl ({ inviteId }) {
  ReactGA.send({ hitType: "pageview", page: "/agency/invite", title: "Agency Invite" });
  const [agencyName, setAgencyName] = useState('');
  const [agentUsername, setAgentUsername] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [agencyDescription, setAgencyDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [inviteResponse,setInviteResponse] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await auth.isAuthenticated()
          .then(async () => {
            await be.get('agency',"/agency/invitee",{id:inviteId},true,true)
              .then((response) => {
                setAgencyName(response?.agencyName);
                setAgencyDescription(response?.description);
                setProfilePic(response?.profilePic);
                setAgentUsername(response?.agentUsername);
              })
              .catch((error) => {
                console.log(error);
                setError(error?.message);
              });
          })
          .catch(() => history.push({pathname:'/login'}))
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
      setLoading(false);
    }
    fetchData()
  },[])

  const handleResponse = async (accept) => {
    if(accept === true){setAcceptLoading(true);}
    else{setDeclineLoading(true);}

    const body = {
      inviteeId: inviteId,
      accept: accept,
    };

    try {
      await auth.isAuthenticated()
        .then(async () => {
          await be.post('agency',"/agency/invitee",body,true,true,true)
            .then(async (response) => {
              setInviteResponse(response?.data?.accepted);
              await new Promise(resolve => setTimeout(resolve, 1500));
              history.push({pathname:'/'})
            })
            .catch((error) => {
              console.log(error);
              setError(error?.response?.data?.status);
            });
        })
        .catch(() => history.push({pathname:'/login'}))
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }  

    if(accept === true){setAcceptLoading(false);}
    else{setDeclineLoading(false);}
  };

  if (loading) return <p><center>Loading...</center></p>;
  if (error) return <p><center>Error: {error}</center></p>;

  return (
    <Modal
      show
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <div>
            <img src={profilePic || '/app-assets/img/standard-user-pic.png'} alt={agencyName} className="agency-image" />
            <span className="agency-name">{agencyName}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div><strong>{agentUsername}</strong>, from <strong>{agencyName}</strong> agency, wants to take your account. By accepting this invitation, <strong>{agencyName}</strong> will be able to manage your account.</div>
        <div style={{marginTop:20}}>Agency Description:</div>
        <div style={{fontSize:14,color:'var(--color-secondary)'}}>{agencyDescription}</div>
      </Modal.Body>
      <Modal.Footer>
        {inviteResponse !== null
          ?
            <>
              {inviteResponse === true && <div>Invite Accepted! Returning to Homepage...</div>}
              {inviteResponse === false && <div>Invite Declined! Returning to Homepage...</div>}
            </>
          :
            <>
              <Button variant="danger" onClick={() => handleResponse(false)}>
                {declineLoading && <Spinner size="sm"/>} Decline
              </Button>
              <Button variant="primary" onClick={() => handleResponse(true)}>
                {acceptLoading && <Spinner size="sm"/>} Accept
              </Button>
            </>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default InviteControl;

