import Form from 'react-bootstrap/Form';
import {useCreateBookingStore} from '../../../../store';

function TextAnswer({questionId,required,mode}){
  const setTextAnswer = useCreateBookingStore(state => state.setTextAnswer);
  const updateTextAnswer = useCreateBookingStore(state => state.updateTextAnswer);
  const summary = useCreateBookingStore(state => state.summary);

  return(
    <>
      {mode === "reschedule"
        ?
          <Form.Control
            value={summary?.surveys[questionId]?.answer || null}
            className="my-2"
            type="text"
            required={required}
            onChange={(e) => updateTextAnswer(e.target.value,questionId)}
          />
        :
          <Form.Control
            className="my-2"
            type="text"
            required={required}
            onChange={(e) => setTextAnswer(e.target.value,questionId)}
          />
      }
    </>
  );
}

export default TextAnswer;
