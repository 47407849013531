import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Duration({enabled,duration}){
  var color = "var(--color-background-2)";
  if(!enabled){color = "var(--color-background-3)"}
  return(
    <>
      <span className="event-type-label" style={{backgroundColor:color,color:"var(--color-text)"}}>
        <i className="fas fa-stopwatch me-2"></i>
        {duration} min
      </span>
    </>
  );
}

export default Duration;
