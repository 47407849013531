import React from 'react';
import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap';

const CalendBookPage = () => {
  return (
    <div>
      <header className="bg-primary text-white text-center p-5">
        <Container>
          <h1>Calendbook for Wix</h1>
          <p>Seamlessly schedule events directly on your WIX website</p>
        </Container>
      </header>
      <main className="my-5">
        <Container>
          <Row>
            <Col md={6} className="text-center mb-4">
              <h3>Login First</h3>
              <p>Ensure you're logged in via the dashboard to synchronize your events.</p>
            </Col>
            <Col md={6} className="text-center">
              <h3>Easy Setup</h3>
              <p>Open settings (cog icon), select your event type, and you're all set.</p>
            </Col>
          </Row>
        </Container>
      </main>
      <footer className="bg-light text-center p-4">
        <Container>
          <p>Explore more about CALENDBOOK. Contact us for further information!</p>
        </Container>
      </footer>
    </div>
  );
};

export default CalendBookPage;

