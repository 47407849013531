import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function Buffer (){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setPrevBuffer = useCreateEventTypeStore((state) => state.setPrevBuffer);
  const setNextBuffer = useCreateEventTypeStore((state) => state.setNextBuffer);

  return(
    <Form.Group as={Row} controlId="event-buffers" className="my-3 ms-2 me-2">
      <Form.Label column sm={2}>{t('general.buffers.title')}</Form.Label>
      <Col sm={5} lg={3} className="">
        {t('general.buffers.before')}
        <Form.Select className="dark-field my-2 my-md-0" name="prevBuffer" value={general?.prevBuffer} onChange={(e) => setPrevBuffer(e.target.value)}>
          <option>0</option>
          <option>5</option>
          <option>10</option>
          <option>15</option>
          <option>30</option>
          <option>45</option>
          <option>60</option>
        </Form.Select>
      </Col>
      <Col sm={5} lg={3} className="">
        {t('general.buffers.after')}
        <Form.Select className="dark-field my-2 my-md-0" name="nextBuffer" value={general?.nextBuffer} onChange={(e) => setNextBuffer(e.target.value)}>
          <option>0</option>
          <option>5</option>
          <option>10</option>
          <option>15</option>
          <option>30</option>
          <option>45</option>
          <option>60</option>
        </Form.Select>
      </Col>
    </Form.Group>
  );
}

export default Buffer;
