import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup'
import be from '../../../BE';
import auth from '../../../Account/Auth';

function BookingLink(){
  const {t} = useTranslation(['settings']);
  const [userSlug, setUserSlug] = useState(auth.getName());
  const [processing,setProcessing] = useState(false)
  const [status, setStatus] = useState(0);

  const responseFeedbackStyle = {
    fontSize:14
  }

  const handleChange = (event) => {
    setStatus(0);
    setUserSlug(event.target.value);
  }

  const isValidSlug = () => {
    return /^[a-zA-Z0-9]+([-]?[a-zA-Z0-9])*$/.test(userSlug)
  }

  async function setSlug(e) {
    if(!isValidSlug()){
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setProcessing(true);
      setStatus(0);
      let formattedSlug = userSlug.toLowerCase();
      await auth.isAuthenticated()
        .then(async () => {
          await be.post("account","/bookingSlug",{slug : formattedSlug},true)
            .then((r) => setStatus(200))
            .then(async () => await auth.refresh())
            //.then(() => window.location.reload(false))
            .catch((e) => {setStatus(e?.response?.status)})
        })
        .catch(error => console.log(error))
      setProcessing(false);
    }
  }

  return(
    <>
      <h4>{t('account.bookingLink')}</h4>
      <p>{t('account.bookingLinkDisclaimer')}</p>
      <Form noValidate validated={isValidSlug()} onSubmit={setSlug}>
        <InputGroup hasValidation>
          <InputGroup.Text id="booking-link-hostname">calendbook.com/</InputGroup.Text>
          <FormControl
            type="text"
            name="booking-link"
            id="booking-link-field"
            aria-describedby="booking-link-hostname"
            value={userSlug}
            onChange={handleChange}
            isInvalid={!isValidSlug()}
          />
          <Button disabled={processing} type='submit'>
            {processing 
              ? 
                <Spinner size="sm" animation="border" variant="light" /> 
                : 
                <i className="fas fa-long-arrow-alt-right"></i>
            }
          </Button>
          <Form.Control.Feedback type="invalid">
            <span>Please provide a valid username.</span>
            <br/>
            <span>Only letters and numbers divided by dash (-)</span> 
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
      {(status > 400 && status <= 500) &&
        <p className="text-danger" style={responseFeedbackStyle}>Oops, there was an error!</p>
      }
      {(status === 400) &&
        <p className="text-danger" style={responseFeedbackStyle}><b>{userSlug}</b> Already Exists!</p>
      }
      {status === 200 &&
        <p className="text-success" style={responseFeedbackStyle}>Changed!</p>
      }
    </>
  );
};

export default BookingLink;
