import Placeholder from 'react-bootstrap/Placeholder';
import EventName from './EventName';
import EventDescription from './EventDescription';
import EventLocation from './EventLocation';
import EventDuration from './EventDuration';
import Price from './Price';

function EventInfo({event,payment,loading}){
  return(
    <>
      {loading
        ?
          <>
            
          </>
        :
          <>
            <div className="mb-4"> <EventName name={event?.name}/> </div>
            <div className="mb-3"> <EventDuration duration={event?.duration}/> </div>
            <div className="mb-3"> <EventLocation location={event?.location}/> </div>
            <div className="mb-3"> <Price payment={payment}/> </div>
            <div className="mb-3"> <EventDescription description={event?.description}/> </div>
          </>
      }

    </>
  );
}

export default EventInfo;
