export default {
  API: {
    endpoints: [
        {
          name: "account-dev",
          endpoint: "https://cgq1tzvoj4.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "eventtypes-dev",
          endpoint: "https://trbwymup39.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "booking-dev",
          endpoint : "https://r8ejxwkonf.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "jubilant-booking",
          endpoint : "https://raqt4k4huc.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name : "payments-dev",
          endpoint : "https://y0wdbt6r8b.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "notifications-dev",
          endpoint: "https://53onwg35tg.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "todo-dev",
          endpoint: "https://czu363ehtf.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "calendarhandler-dev",
          endpoint: "https://4ibn4pv0ui.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "webhook-dev",
          endpoint: "https://tzg4p4cg66.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "survey-dev",
          endpoint: "https://3q8yzcpfma.execute-api.eu-central-1.amazonaws.com/dev",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "integrations-dev",
          endpoint: "https://integrations.calendbook.com",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "stripe-connect",
          endpoint: "https://3dop58y0if.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "account-jubilant",
          endpoint: "https://dk4uaonnu0.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "uploader-stack",
          endpoint: "https://9gnlf4d89b.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "team-manager",
          endpoint: "https://ymxafp6wr3.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "payment-jubilant",
          endpoint: "https://03mk8bflne.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "agency",
          endpoint: "https://c5cq7fl8xf.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "jubilant-events",
          endpoint: "https://mbi8mft72c.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "Onboarding",
          endpoint: "https://4sazvyztf1.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "Leads",
          endpoint: "https://8gfnsrxgg2.execute-api.eu-central-1.amazonaws.com/Stage",
        },
    ]
  }
};
