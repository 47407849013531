import Event from './Event/_Event';
import i18n from '../../i18n';

const componentStyle={
  width:'100%',
  marginTop:10,
}

const dayStyle={
  display:'inline-block',
  verticalAlign:'top',
}

const dayNumberStyle={
  fontWeight:'bold',
  fontSize:32,
}

const weekdayStyle={
  fontWeight:'bold',
  fontSize:16,
  textAlign:'center',
  textTransform:'lowercase',
}

const dayScheduleContainer = {
  width: 'calc(100% - 50px)',
  display: 'inline-block'
}

function getUserLanguage(){
  return i18n.language.substring(0,2);
}

function getWeekday(year,month,day){
  let date = new Date(year,month - 1,day);
  let formattingOptions = {weekday:'short'};
  date = date.toLocaleString(getUserLanguage(),formattingOptions);
  return date;

}

function DayGroup(props){
  const year = props.year;
  const month = props.month;
  const day = props.day;
  const weekday = getWeekday(year,month,day);
  const daySchedules = props.daySchedules;
  const daySchedulesArray = Object.keys(daySchedules);

  return(
    <div  style={componentStyle}>
      <div style={dayStyle}>
        <div style={dayNumberStyle}>
          {props.day}
        </div>
        <div style={weekdayStyle}>
          {weekday}
        </div>
      </div>

      <div style={dayScheduleContainer}>
        {
          daySchedulesArray.map((time) => (
            <Event
              key={`event-${year}-${month}-${year}-${time}`}
              time={time}
              details={daySchedules[time]}
              pending={props?.pending}
            />
          ))
        }
      </div>
    </div>
  );
}

export default DayGroup;
