import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import be from '../BE';
import auth from '../Account/Auth';

function Overview({showPlan, setShowPlan}){
  const history = useHistory();
  const [overview,setOverview] = useState(null);
  const [interval,setInterval] = useState("today")
  const [loading,setLoading] = useState(false);

  const getOverview = async () => {
    try{
      setLoading(true);
      await auth.isAuthenticated()
        .then(() => {
          be.get('Leads','/leads/overview',false,true,true)
            .then(response => {
              console.log("[leads][overview] got overview");
              console.log(response);
              setOverview(response);
              setShowPlan(response?.showPlan);
            })
            .catch(error => {
              console.error("[leads][overview] network error while getting overview:",error);
            })
        })
        .catch((error) => {
          console.error("[leads][overview] authentication error while getting overview:", error)
          history.push({pathname:'/login'})
        })
    }catch (error) { 
      console.error("[leads][overview] functional error while saving user:",error);
    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    getOverview();
  },[])

  return(
    <div className="tw-max-w-[400px] tw-items-center tw-justify-between tw-p-4 tw-my-8 tw-mx-auto tw-space-y-4 tw-bg-gray-100 tw-rounded-lg tw-dark:bg-gray-700 sm:tw-flex sm:tw-space-y-0">
      <div>
        <div className={`${loading && 'tw-animate-pulse'} tw-text-3xl tw-font-bold tw-text-gray-900 tw-dark:text-white`}>{overview?.total || 0}</div>
        <div className="tw-text-sm tw-text-gray-500 tw-dark:text-gray-400">Total leads</div>
      </div>
      <div>
        <div className={`${loading && 'tw-animate-pulse'} tw-text-3xl tw-font-bold tw-text-gray-900 tw-dark:text-white`}>{overview?.history?.[interval] || 0}</div>
        <div className="tw-text-sm tw-text-gray-500 tw-dark:text-gray-400">
          Leads acquired 

          {/* Dropdown Button*/}
          <button
            id="dropdownIntervalButton"
            data-dropdown-toggle="dropdownInterval"
            className="tw-inline-flex tw-items-center tw-ms-1 tw-font-bold tw-text-gray-500 tw-rounded-full hover:tw-text-primary-600 tw-dark:hover:text-blue-500 md:tw-me-0 focus:tw-ring-4 tw-dark:text-white"
            type="button"
          >
            {interval === "today" && "today"}
            {interval === "yesterday" && "yesterday"}
            {interval === "lastWeek" && "last week"}
            {interval === "lastMonth" && "last month"}
            {interval === "lastSixMonths" && "last 6 months"}
            <svg className="tw-w-2.5 tw-h-2.5 tw-ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
            </svg>
          </button>

          {/* Dropdown menu */}
          <div
            id="dropdownInterval"
            className="tw-z-10 hidden tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded-lg tw-shadow tw-w-44 tw-dark:bg-gray-700 tw-dark:divide-gray-600"
          >
            <ul className="tw-py-2 tw-text-sm tw-text-gray-700 tw-dark:text-gray-200" aria-labelledby="dropdownIntervalButton">
              <li>
                <div className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white" onClick={() => setInterval("today")}>today</div>
              </li>
              <li>
                <div className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white" onClick={() => setInterval("yesterday")}>yesterday</div>
              </li>
              <li>
                <div className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white" onClick={() => setInterval("lastWeek")}>last week</div>
              </li>
              <li>
                <div className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white" onClick={() => setInterval("lastMonth")}>last month</div>
              </li>
              <li>
                <div className="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-600 tw-dark:hover:text-white" onClick={() => setInterval("lastSixMonths")}>last 6 months</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
