import Title from './Title';
import Subtitle from './Subtitle';

function Header({view}){
    return(
        <div className="text-center my-5">
            <Title view={view}/>
            <Subtitle view={view}/>
        </div>

    );
}

export default Header;
