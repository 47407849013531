import React from 'react';
import { useTranslation } from 'react-i18next';

function Name({name}){
  const { t } = useTranslation(['onboardingChecklist']);

  return(
    <>
      {name && 
        <h5>
          {t(`${name}.name`)}
        </h5>
      }
    </>
  );

}


export default Name;
