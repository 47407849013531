import { useEffect } from 'react';
import OwnerInfo from './OwnerInfo/OwnerInfo';
import EventInfo from './EventInfo/EventInfo';
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next';
import { isIframe } from '../../Utilities/iframeUtils';
import './details.css';

function Details({event,owner,payment,loading,setBookingStep,stylingParams}){
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const { t } = useTranslation(['booking']);

  useEffect(() =>{
    // styling params from url
    const backgroundDetailsStyle = stylingParams["details-bg"];
    const textColorDetailsStyle = stylingParams["details-text-color"];
    const backgroundDetailsScheduleButtonStyle = stylingParams["details-schedule-btn-bg"];
    const textColorDetailsScheduleButtonStyle = stylingParams["details-schedule-btn-text-color"];

    // --- STYLING OPTIONS ---
    // valid only when the page is in Iframe
    if(isIframe()){
      if(backgroundDetailsStyle){
        let details = document.getElementById("details")
        if(details) { details.style.background = `${backgroundDetailsStyle}` }
      }
      if(textColorDetailsStyle){
        let details = document.getElementById("details")
        if(details) { details.style.color = `${textColorDetailsStyle}` }
      }
      if(backgroundDetailsScheduleButtonStyle){
        let detailsScheduleButton = document.getElementById("details-schedule-btn")
        if(detailsScheduleButton){ detailsScheduleButton.style.background = `${backgroundDetailsScheduleButtonStyle}` }
      }
      if(textColorDetailsScheduleButtonStyle){
        let detailsScheduleButton = document.getElementById("details-schedule-btn")
        if(detailsScheduleButton){ detailsScheduleButton.style.color = `${textColorDetailsScheduleButtonStyle}` }
      }
    } 
  },[stylingParams])

  return(
    <div className="details" id="details">
      <OwnerInfo owner={owner} loading={loading}/>
      <EventInfo event={event} payment={payment} loading={loading}/>
      {isMobile && <button className="schedule-btn" id="details-schedule-btn" onClick={() => setBookingStep(2)}>{t(`details.schedule`)}</button>}
    </div >
  );
}

export default Details;
