import {useCreateEventTypeStore} from '../../store';
import {useTranslation} from 'react-i18next';

function Add(){
  const {t} = useTranslation(['eventCreator']);
  const addQuestion = useCreateEventTypeStore((state) => state.addQuestion);

  const componentStyle = {
    backgroundColor : "transparent",
    border : "none",
    margin : 0,
    padding : 0,
    color : "var(--bs-primary)"
  }

  return(
    <button style={componentStyle} className="my-3" onClick={(e) => {e.preventDefault(); addQuestion()}}>
      {t('surveys.addQuestion')}
    </button>
  );
}

export default Add;
