import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './accordion-header.css';

function Header({content,type,required,order}){
  const getType = (type) => {
    if(type === 1) return 'radio';
    if(type === 2) return 'checkbox';
    if(type === 3) return 'text';
    if(type === 4) return 'phone';
    if(type === 5) return 'telegram';
    if(type === 6) return 'whatsapp';
  }
  return(
    <Accordion.Header>
      <span style={{color:'var(--color-text)',fontWeight:'300'}} className="me-3 d-inline-flex">
        <i className="fa-solid fa-grip-vertical my-auto me-3"></i>
        {order}
      </span>
      <Row sm={12} className="w-100">
        <Col sm={12}>
          {content}{required && '*'}
        </Col>
        <Col sm={12}>
          {<span style={{color:'var(--color-text)',fontWeight:'300',fontSize:12}} className="text-capitalize">{getType(type)}</span>}
        </Col>
      </Row>
    </Accordion.Header>
  );
}

export default Header;

