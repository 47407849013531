import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import Heading from './Heading';
import Upgrade from './Upgrade';
import Credits from './Credits';
import Subscription from './Subscription';
import be from '../../BE';
import auth from '../../Account/Auth';
import PhoneInput from 'react-phone-number-input'
import './whatsapp-input.css';

const DEBUG = (window.location.hostname !== 'www.calendbook.com');
const TEST_WHATSAPP_MONTHLY_TIER_1 = "pri_01hwda8p8x571v35agx4jqkcy1"
const TEST_WHATSAPP_MONTHLY_TIER_2 = "pri_01hwda9e6khrg3absq585a9kk5"
const TEST_WHATSAPP_MONTHLY_TIER_3 = "pri_01hwdaa0wcjtttaejz1rxd2kt3"
const LIVE_WHATSAPP_MONTHLY_TIER_1 = "pri_01hwctn81s3wmk4pg38v7fmb4p"
const LIVE_WHATSAPP_MONTHLY_TIER_2 = "pri_01hwctpencc7seksj0th0xs8ag"
const LIVE_WHATSAPP_MONTHLY_TIER_3 = "pri_01hwctq07cj5568k7fqxfqt5m3"

const ResponseFeedback = ({ status, message }) => (
  <p className={status === 200 ? 'text-success' : 'text-danger'}>{message}</p>
);

const WhatsappIcon = () => (
  <i
    className="fa-brands fa-whatsapp justify-content-center"
    style={{
      padding:'2px 5px',
      color:'#25D366',
      background:'var(--color-background-1)',
      fontSize:32,
      border:'1px solid var(--color-border-light)',
      borderRadius:'5px 0 0 5px'
    }}>
  </i>

);

function Whatsapp(){
  const history = useHistory();
  const { t } = useTranslation(['integrations']);
  const [whatsapp, setWhatsapp] = useState('');
  const [credits, setCredits] = useState(0);
  const [currentPriceId, setCurrentPriceId] = useState('');
  const [cancelLink, setCancelLink] = useState('');
  const [updateLink, setUpdateLink] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [status, setStatus] = useState(0);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [processing, setProcessing] = useState(false);

  const userPlan = auth.getProperty({
    storageKey: 'userSession',
    path: ['profileObj','planStatus','status'],
    fallback: 'free',
  })

  useEffect(()=>{
    const getWhatsapp = async () => {
      const body = {};
      setLoadingInfo(true);
      try{
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('account-jubilant','/settings/whatsapp',body,true,true)
            .then(response => {
              setInfoResponseStatus(200);
              setWhatsapp(response?.value);
              setCredits(response?.credits);
              setCurrentPriceId(response?.priceId);
              setCancelLink(response?.cancelUrl);
              setUpdateLink(response?.updatePaymentUrl);
              setCustomerId(response?.customerId);
            })
            .catch(error => {
              console.error("[integrations][whatsapp] network error while retrieving info:",error);
              setInfoResponseStatus(error?.response?.status);
            })
        })
        .catch((error) => {
          console.error("[integrations][whatsapp] authentication error while retireving info:", error)
          history.push({pathname:'/login'})
        })
      }catch (error) { 
        console.error("[integrations][whatsapp] functional error while retrieving info:",error);
        setInfoResponseStatus(500);
      }finally{
        setLoadingInfo(false);
      }
    }
    getWhatsapp();
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setStatus(0);
    try{
      await auth.isAuthenticated()
        .then(async () => {
          const body = {
            value: whatsapp.replace("https://wa.me/","")
          }
          await be.post("account-jubilant","/settings/whatsapp",body,true,true)
            .then((r) => {
              setStatus(200);
            })
            .catch((error) => {
              console.error("[integrations][whatsapp] network error while saving:",error);
              setStatus(error?.response?.status);
            })
        })
        .catch(error => {
          console.error("[integrations][whatsapp] authentication error while saving:", error)
          history.push({pathname:'/login'})
        })
    } catch (error) {
      console.error("[integrations][whatsapp] functional error while saving:",error);
      setStatus(500);
    } finally {
      setProcessing(false)
    }
  }
  return (
    <>
      <Heading/>
      <Form onSubmit={handleSubmit} className="tw-w-full">
  <div className="tw-flex tw-items-center tw-w-full tw-p-1 tw-border tw-border-gray-300 tw-rounded-lg tw-bg-white hover:tw-shadow-md focus-within:tw-shadow-lg tw-transition-all tw-duration-200 tw-ease-in-out">
    {/* WhatsApp Icon */}
    <div className="tw-pl-3 tw-flex tw-items-center">
      <i className="fa-brands fa-whatsapp tw-mr-3" style={{ color: '#25D366', fontSize: '32px' }} />
    </div>

    {/* Phone Input */}
    <PhoneInput
      required={true}
      international
      countryCallingCodeEditable={true}
      placeholder="Enter WhatsApp number"
      value={whatsapp}
      onChange={(number) => setWhatsapp(number)}
    />

    {/* Save Button */}
    <Button
      variant="primary"
      disabled={loadingInfo || processing}
      type="submit"
      className="tw-bg-blue-600 tw-text-white tw-mx-2 tw-px-5 tw-py-2 tw-h-full tw-rounded-r-lg hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 tw-transition-all tw-duration-200"
    >
      {processing ? <Spinner size="sm" animation="border" variant="light" /> : t("whatsapp.save")}
    </Button>
  </div>
</Form>
 

      {status > 400 && status <= 500 && <ResponseFeedback status={status} message={t("whatsapp.error")} />}
      {status === 200 && <ResponseFeedback status={status} message={t("whatsapp.success")} />}

      {!loadingInfo &&
        <div className="mt-3">
          {userPlan === 'professional'
            ?
              <>
                <Credits credits={credits}/>
                <div className="my-3 gap-3  d-flex flex-column flex-md-row">
                <Subscription price={10} notifications={100} customerId={customerId} currentPriceId={currentPriceId} priceId={DEBUG ? TEST_WHATSAPP_MONTHLY_TIER_1 : LIVE_WHATSAPP_MONTHLY_TIER_1} cancel={cancelLink} update={updateLink}/>
                <Subscription price={20} notifications={200} customerId={customerId} currentPriceId={currentPriceId} priceId={DEBUG ? TEST_WHATSAPP_MONTHLY_TIER_2 : LIVE_WHATSAPP_MONTHLY_TIER_2} cancel={cancelLink} update={updateLink}/>
                <Subscription price={50} notifications={500} customerId={customerId} currentPriceId={currentPriceId} priceId={DEBUG ? TEST_WHATSAPP_MONTHLY_TIER_3 : LIVE_WHATSAPP_MONTHLY_TIER_3} cancel={cancelLink} update={updateLink}/>
                </div>
              </>
            : <Upgrade/>
          }
        </div>
      }
    </>
  );
};

export default Whatsapp;
