import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function OwnerPic(props){
  return(
    <>
      <img src={props.pic} width="72px" height="72px" className="img-fluid rounded-circle" alt="avatar" />
    </>
  );
}

export default OwnerPic;
