import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

function CopyButton ({copyCode, copied}) {
  const {t} = useTranslation(['navbar'])
  return(
    <div className="d-flex justify-content-end">
      <Button onClick={() => copyCode()} style={{minWidth:150}}>
        {copied
          ?
            <i className="far fa-check-circle"></i>
            :
            <>{t('embed.modalCopyBtn')}</>
        }
      </Button>
    </div>
  );
}

export default CopyButton;
