import React, { useState } from 'react';
import './join.css';

function Join({ joinLink }) {
  const STANDARD_TEXT = "Copy Join Link";
  const SUCCESS_TEXT = "Copied!";
  const ERROR_TEXT = "Failed to Copy!";

  const [buttonText, setButtonText] = useState(STANDARD_TEXT);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(joinLink).then(() => {
      setButtonText(SUCCESS_TEXT);
      setTimeout(() => {
        setButtonText(STANDARD_TEXT);
      }, 1500);
    }, (err) => {
      console.error('Could not copy text: ', err);
      setButtonText(ERROR_TEXT);
      setTimeout(() => {
        setButtonText(STANDARD_TEXT);
      }, 1500);
    });
  };


  return (
    <div className="join">
      <button onClick={copyToClipboard}>
        <i className="far fa-clipboard"></i> {buttonText}
      </button>
    </div>
  );
}

export default Join;

