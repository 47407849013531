import PhoneInput from 'react-phone-number-input'
import './phone-answer.css';
import {useCreateBookingStore} from '../../../../store';
//import { Form } from 'react-bootstrap';
//import { IMaskInput } from 'react-imask';

function WhatsappAnswer({questionId,required,mode}){
  const setTextAnswer = useCreateBookingStore(state => state?.setTextAnswer);
  const updateTextAnswer = useCreateBookingStore(state => state.updateTextAnswer);
  const surveyAnswers = useCreateBookingStore(state => state?.surveyAnswers);
  const summary = useCreateBookingStore(state => state.summary);

  return(
    <>
      {mode === "reschedule"
        ?
          <PhoneInput
            required={required}
            international
            countryCallingCodeEditable={false}
            placeholder="Enter whatsapp number"
            value={summary?.surveys[questionId]?.answer[0] || null}
            onChange={(number) => updateTextAnswer(number,questionId)}
          />
        :
          <PhoneInput
            required={required}
            international
            countryCallingCodeEditable={false}
            placeholder="Enter whatsapp number"
            value={surveyAnswers[questionId]}
            onChange={(number) => setTextAnswer(number,questionId)}
          />

      }
    </>
  );
}

/*
function CustomMaskInput({ required, label, mask, maxLength, blocks, placeholder, onAccept }) {
  return (
    <Form.Group className="w-100 d-inline-flex">
      <i className="fa-brands fa-whatsapp justify-content-center my-auto h1 ms-2 me-3"></i>
      <IMaskInput
        required={required}
        mask={mask}
        unmask={true}
        blocks={blocks}
        maxLength={maxLength}
        placeholder={placeholder}
        onAccept={onAccept}
        style={{ width: '100%', margin: '8px 0', padding: '6px 12px' }}
      />
    </Form.Group>
  );
}

function WhatsappAnswer({questionId,required}) {
  const setTextAnswer = useCreateBookingStore(state => state?.setTextAnswer);
  return (
      <CustomMaskInput
        label="WhatsApp User Link"
        required={required}
        mask={'{https://w\\a.me/}(+countryCode) number'}
        placeholder="Enter WhatsApp number (e.g., (+1) 2025550123)"
        onAccept={(wa) => setTextAnswer(wa,questionId)}
        blocks={{
          countryCode: {
            mask: /^[0-9]{0,4}$/,
            inputMode: 'numeric' // Suitable input mode for country code
          },
          number: {
            mask: /^[0-9]{0,15}$/,
            inputMode: 'numeric' // Suitable input mode for main phone number
          }
        }}
      />
  );
}
 */

export default WhatsappAnswer;

