import React, { useState, useEffect } from 'react';
import {useWebhooksConnectionsStore} from '../../store';
import {useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';
import WebhookCard from './WebhookCard/WebhookCard';

function ConnectedWebhooks(){
  const {t,i18n} = useTranslation(['integrations','common']);
  const [processing,setProcessing] = useState(false)
  const webhooksList = useWebhooksConnectionsStore((state) => state.webhooksList);
  const setWebhooksList = useWebhooksConnectionsStore((state) => state.setWebhooksList);

  useEffect(() => {
    const getConnectedWebhooks = async () => {
      setProcessing(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.get("webhook","/getWebhook",{},true)
          .then((response) => {
            if(!Array.isArray(response))
              setWebhooksList([])
            else
              setWebhooksList(response);
          })
          .catch((response) => {console.log('error in /integrations/webhooks page: ',response);})
        })
        .catch(()=>{})
      setProcessing(false)
    }
    getConnectedWebhooks();
  },[setWebhooksList])

  
  return(
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <div className="h4" style={{fontWeight:600}}>{t('webhooks.connectedWebhooks')}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          {processing
            ?
              <Spinner size="sm" animation="border"/> 
              :
              <>
                {Array.isArray(webhooksList) &&
                  <>
                    {webhooksList.length > 0 
                      ?
                        webhooksList.map((webhook) => (
                          <WebhookCard
                            hook={webhook?.hook}
                            hookName={t(`common:${webhook?.hook}`)}
                            url={webhook?.url}
                          />
                        ))
                      :
                        <>
                          <div style={{color:'var(--color-secondary)'}}>{t('webhooks.noConnectedWebhooks')}</div>
                          {i18n.language.includes('en') &&
                            <div style={{color:'var(--color-secondary)'}}>Don't know how to do it? <a href="https://www.youtube.com/watch?v=Wgd0aFtwxAs" target="_blank" rel="noopener noreferrer">Whatch the video tutorial.</a></div>
                          }
                        </>
                    }
                  </>
                }
              </>
          }
        </Col>
      </Row>
    </>
  );
}

export default ConnectedWebhooks;
