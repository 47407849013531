import spacetime from 'spacetime';
import './time-picker.css'

function TimeSlot({dummy,datetime,timeFormat,callback}){
  return(
    <div className={dummy ? 'time-slot dummy' : 'time-slot'} onClick={callback && callback}>
      {timeFormat === "24h"
        ? <>{spacetime(datetime).format('{time-24}')}</>
        : <>{spacetime(datetime).format('{time}')}</>
      }
    </div>
  );
}

export default TimeSlot;
