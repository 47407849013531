import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CopyButton from "./CopyButton";
import EmbeddedCode from "./EmbeddedCode";
import Options from "./Options";
import PageChoice from "./PageChoice";
import auth from "../Account/Auth";
import be from "../BE";
import "./embed.css";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )})`
    : null;
}

function Embed() {
  try {
    window.tidioChatApi.show();
  } catch {}
  const { t } = useTranslation(["navbar"]);
  const protocol = window.location.protocol;
  const host = window.location.host;
  const username = auth.getName();
  const publicAddress = protocol + "//" + host + "/" + username;
  const [eventTypes, setEventTypes] = useState([]);
  const [loadingEventTypes, setLoadingEventTypes] = useState(false);
  const [selectedLink, setSelectedLink] = useState(publicAddress);
  const [copied, setCopied] = useState(false);
  const [border, setBorder] = useState("none");
  const [width, setWidth] = useState("100%");
  const [height, setHeight] = useState("100%");
  const [hideDetails, setHideDetails] = useState(false);
  const [color, setColor] = useState("var(--color-background-0)");
  var embedCode = `<!-- Calendbook public page begin -->
<iframe
  name="calendbook-embed-frame"
  src="${selectedLink}${hexToRgb(color) || hideDetails ? "?" : ""}${
    hexToRgb(color) ? `background=${hexToRgb(color)}` : ""
  }${hideDetails ? `${hexToRgb(color) && hideDetails ? "&" : ""}step=2` : ""}"
  width="${width}"
  height="${height}"
  style="border:${border}">
</iframe>
<!-- Calendbook public page end -->`;

  useEffect(() => {
    document.title = "Calendbook - Embed";
    const getEventTypes = async () => {
      setLoadingEventTypes(true);
      await auth
        .isAuthenticated()
        .then(() => {
          be.get("eventtypes", "/eventypes", "", true)
            .then((response) => {
              setEventTypes(Object.keys(response));
              setLoadingEventTypes(false);
              if (Object.keys(response) !== 0) {
                setSelectedLink(publicAddress + "/" + Object.keys(response)[0]);
              }
            })
            .catch((error) => {
              console.log(error);
              setEventTypes({});
              setLoadingEventTypes(false);
            });
        })
        .catch(() => {
          setLoadingEventTypes(false);
          setEventTypes({});
        });
    };
    getEventTypes();
  }, [setEventTypes, setLoadingEventTypes]);

  const copyCode = () => {
    navigator.clipboard.writeText(embedCode);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const isBorder = () => {
    return border !== "none";
  };

  const isResponsive = () => {
    return width === "100%" && height === "100%";
  };

  const setFixed = () => {
    setWidth("800px");
    setHeight("650px");
  };

  const setResponsive = () => {
    setWidth("100%");
    setHeight("100%");
  };

  return (
    <div className="container my-5">
      <p className="h2 text-center my-3">{t("embed.modalTitle")}</p>
      <p>{t("embed.modalDescription")}</p>
      <Row>
        <Col xs={12} md={6}>
          <PageChoice
            setSelectedLink={setSelectedLink}
            publicAddress={publicAddress}
            username={username}
            eventTypes={eventTypes}
          />
        </Col>
        <Col xs={12} md={6}>
          <Options
            color={color}
            setColor={setColor}
            isBorder={isBorder}
            setBorder={setBorder}
            isResponsive={isResponsive}
            setResponsive={setResponsive}
            setFixed={setFixed}
            hideDetails={hideDetails}
            setHideDetails={setHideDetails}
          />
        </Col>
      </Row>
      <EmbeddedCode code={embedCode} />
      <CopyButton copyCode={copyCode} copied={copied} />
    </div>
  );
}

export default Embed;
