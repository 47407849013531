import 'bootstrap/dist/css/bootstrap.min.css';

function Team({enabled}){
  var color = "var(--color-background-2)";
  if(!enabled){color = "var(--color-background-3)"}
  return(
    <>
      <span className="event-type-label" style={{backgroundColor:color,color:"var(--color-text)"}}>
        <i className="fa-solid fa-user-group me-2"></i>
        Team
      </span>
    </>
  );
}

export default Team;
