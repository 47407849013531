import { useTranslation } from 'react-i18next';

function Redirect({url}){
  const { t } = useTranslation(['booking']);
  const redirectionTime = 5000;

  if(url){
    setTimeout(() => {
      window.location.href = url;
    },redirectionTime)
  }

  return(
    <>
      {url && <div className="text-center h6 my-3">{t('confirmed.redirect')} {url} ...</div>}
    </>
  );
}

export default Redirect;
