import React from 'react';
import Status from './Status/_Status';
import TextContainer from './TextContainer/_TextContainer';
import TooltipContainer from './TooltipContainer';

function Task({taskNumber,name,completed}){

  const componentClass = "d-flex align-items-center my-2";

  return(
    <>
      <div className={componentClass}>
        <Status completed={completed}/>
        <TextContainer name={name} taskNumber={taskNumber}/>
        <TooltipContainer taskNumber={taskNumber} name={name}/>
      </div>
    </>
  );

}


export default Task;
