import {useCreateEventTypeStore} from '../../../../store';
import Form from 'react-bootstrap/Form';

function Content({content,order,id}){
  const setQuestionContent = useCreateEventTypeStore((state) => state.setQuestionContent);

  const componentStyle = {
    background : 'var(--color-background-2)',
    borderColor : 'var(--color-border)',
    color : 'var(--color-text)',
    height : 100
  }

  return(
    <Form.Control
      key={`form-control-content-${id}`}
      as="textarea"
      className="mb-3"
      value={content}
      style={componentStyle}
      onChange={(e) => setQuestionContent(e.target.value,id)}
    />
  );
}

export default Content;
