import {useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import AvatarEditor from 'react-avatar-editor';
import MD5 from 'crypto-js/md5';
import md5 from 'js-md5';
import auth from '../../../Account/Auth';
import be from '../../../BE';
import axios from 'axios';
import './banner.css';

function ProfilePic(){
  const {t} = useTranslation(['settings']);
  const savedBanner = auth.getBanner();
  const [selectedFile,setSelectedFile] = useState(null);
  const [selectedFileUrl,setSelectedFileUrl] = useState(null);
  const [editMode,setEditMode] = useState(false);
  const [preview,setPreview] = useState(null);
  const [zoomScale,setZoomScale] = useState(10);
  const [submitting,setSubmitting] = useState(false);
  const editor = useRef(null);
  const fileInput = useRef(null);

  const enterEditMode = () => {
    setEditMode(true);
    document.getElementById("banner-file-uploader").click();
  }

  const handleImgChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setSelectedFileUrl(URL.createObjectURL(e.target.files[0]));
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  };

  const handleSave = async () => {

    const sleep = (ms) => {
      /* usage: await sleep(3000); */
      return new Promise((resolve) => setTimeout(resolve,ms));
    }

    function _arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    }

    if (editor) {
      setSubmitting(true);
      const dataUrl = editor.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      const arrayBuffer = await blob.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const md5Digest = md5.base64(buffer);
      console.log("[settings][ProfilePic] uploaded file size: ", formatBytes(blob?.size));
      await auth.isAuthenticated()
        .then(async () => {
          await be.post('uploader-stack','/upload/company/logo',{contentMd5:md5Digest},true,true)
            .then(async (response) => {
              const formData = new FormData();
              Object.keys(response?.fields).forEach(key => {
                formData.append(key, response?.fields[key]);
              });
              formData.append("file", blob);
              await axios.post(response.url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'POST',
                  'Access-Control-Allow-Headers': 'Content-Type, Origin, Authorization'
                }
              })
              .then(async () => await sleep(5000))
              .then(async () => await auth.refresh())
              .catch(function (error) {
                console.log(error);
              });
            })
            .catch((e) => {console.log(e)})
        })
        .catch((e) => {console.log(e)})
      setEditMode(false);
      setSubmitting(false);
    }
  };

  return(
    <div id="editor">
      <h3>{t('bannerPic.title')}</h3>
      <p>{t('bannerPic.subtitle')}</p>
      {editMode
        ? <Row>
          <Col xs={12}>
            <AvatarEditor
              ref={editor}
              image={selectedFileUrl || savedBanner}
              width={document.getElementById("editor").offsetWidth}
              height={(document.getElementById("editor").offsetWidth / 2.6)}
              border={0}
              borderRadius={15}
              scale={zoomScale / 10}
              rotate={0}
            />
            </Col>
          <Col>
            <Form.Label>Zoom</Form.Label>
            <Form.Range 
              min={10}
              max={50}
              defaultValue={zoomScale}
              value={zoomScale}
              onChange={(e) => setZoomScale(e.target.value)}
            />
            <Button disabled={submitting} variant="secondary" size="sm" className="me-2" onClick={()=>setEditMode(false)}>{t('bannerPic.cancel')}</Button>
            <Button disabled={submitting} variant="primary" size="sm" onClick={handleSave}>{submitting ? <Spinner animation="border" size="sm"/> : <>{t('bannerPic.save')}</>}</Button>
          </Col>
          </Row>
          : <div className="image-container"> <img className="current-banner-pic" alt="User Profile" src={preview || savedBanner} onClick={enterEditMode}/></div>

      }
      <input
        id="banner-file-uploader"
        type="file"
        accept="image/*"
        ref={fileInput}
        onChange={handleImgChange}
      />
    </div>
  );
}

export default ProfilePic;
