import React, {useState,useEffect} from 'react';
import {Tab, Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProfilePic from './AgencyPic/ProfilePic';
import AgencyName from './AgencyName/AgencyName';
import AgencyDescription from './AgencyDescription/AgencyDescription';
import be from '../../BE';
import auth from '../../Account/Auth';

function AgencyPanel(){
  const [loading,setLoading] = useState(false);
  const [agency, setAgency] = useState({});

  useEffect(()=>{
    const getAgency = async () =>{
      setLoading(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('agency','/agency/settings',false,true,true)
            .then(response => {
              setAgency(response);
            })
            .catch(error => {
              console.info("[settings][agency] Unable to fetch: ");
              console.error(error);
            })
          setLoading(false);
        })
        .catch((e) => {console.log(e)})
    }
    getAgency();
  },[])

  return(
    <Tab.Pane eventKey="agency">
      {auth.getAgencyId() 
        ?
          <>
            <Row className="mt-3 mb-3">
              <Col>
                <ProfilePic pic={agency?.profilePic}/>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
              <AgencyName loading={loading} agency={agency} setAgency={setAgency}/>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
              <AgencyDescription loading={loading} agency={agency} setAgency={setAgency}/>
              </Col>
            </Row>
          </>
          :
          <Row className="mt-3 mb-3">
            <Col>
              <h4>Get an Agency</h4>
              <span>you can get an agency plan and set here your preferences</span>
              <p><a href="/upgrade#agency">upgrade to get an agency</a></p>
            </Col>
          </Row>
      }
    </Tab.Pane>
  );
}

export default AgencyPanel
