import React from 'react';
import Icon from './Icon';

function Status({completed}){

  const componentStyle={
    width:30,
    height:30
  }

  const completeClass = "d-inline-block  me-2 bg-success rounded-circle"
  const incompleteClass = "d-inline-block me-2  border border-success rounded-circle"

  return(
    <>
      <div 
        style={componentStyle}
        className={completed ? completeClass : incompleteClass}
      >
        <Icon completed={completed}/>
      </div>
    </>
  );

}


export default Status;
