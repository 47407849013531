import {useEffect, useState} from 'react';
import Heading from './Heading';
import ConnectStripe from './ConnectStripe';
import DangerZone from './DangerZone/DangerZone';
import UserInfo from './UserInfo/UserInfo';
import be from '../../BE';
import auth from '../../Account/Auth';

function Stripe(){
  const [stripeUser,setStripeUser] = useState({})
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    console.info("[integrations][stripe] loading user info")
    const checkConnectionStatus = async () => {
      setLoading(true)
        await auth.isAuthenticated()
        .then(async () => {
          await be.get('stripe-connect','/connect/userProfile',false,true,true)
          .then(response => {
            if(response.email !== null){
              setStripeUser(response);
              console.info("[integrations][stripe] Set stripe user profile:", response)
            }else{
              setStripeUser({});
              console.info("[integrations][stripe] Fetched empty user profile:",response);
            }
          })
          .catch((e) => {
            console.info("[integrations][stripe] Error fetching user profile:",e);
          });
        })
        .catch((e) => {
          console.info("[integrations][stripe] Error authenticating user:",e);
        });
      setLoading(false)
    }
    checkConnectionStatus()
  },[])

  const isConnected = (_obj) => {
    return Object.keys(_obj).length > 0
  }

  return(
    <>
      <Heading/>
      {loading
        ?
          <>Loading...</>
        :
          <>
            {isConnected(stripeUser) && <UserInfo stripeUser={stripeUser}/>}
            {isConnected(stripeUser) && <DangerZone stripeUser={stripeUser} setStripeUser={setStripeUser}/>}
            {!isConnected(stripeUser) && <ConnectStripe/>}
          </>
      }
    </>
  );
}

export default Stripe;
