import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col} from 'react-bootstrap';
import CopyLinkButton from './CopyLinkButton';
import EnableEventButton from './EnableEventButton';
import Edit from './Edit';

function Down({url,enabled,id}){
  return(
    <>
      <Row>
        <Col className="text-end">
          {enabled 
            ? 
              <>
                <Edit id={id}/> 
                <CopyLinkButton url={url}/> 
              </>
              : 
              <>
                <Edit id={id}/> 
                <EnableEventButton id={id} />
              </>
          }
        </Col>
      </Row>
    </>
  );
}

export default Down;
