const componentStyle = {
  fontSize:16,
  fontWeight:300,
  whiteSpace:'nowrap',
  maxWidth:'calc(100% - 80px)',
  display:'inline-block',
  overflow:'hidden',
  '-o-text-overflow':'ellipsis',
  textOverflow:'ellipsis',
  verticalAlign:'middle',
  lineHeight:'normal'
}

function Name (props){
  const name = props.name;

  return(
    <span style={componentStyle}>
      {name}
    </span>
  );
}

export default Name;
