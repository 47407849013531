import Field from './Field';
import {useCreateBookingStore} from '../../../../../store';

function Fields({type, fields, questionId, required, answer, answerValue, mode}){
  const surveyAnswers = useCreateBookingStore(state => state.surveyAnswers);
  const summary = useCreateBookingStore(state => state.summary);

  const getType = (_type) => {
    if(_type === 1) return "radio";
    if(_type === 2) return "checkbox";
  }

  // radio buttons can have required option if under the same name
  // checkboxes should have required only if none of them is checked
  // if at least a checkbox is checked just remove the required option
  const isRequired = (_required,_type,_answersArray) => {
    if(_type === 'radio' && _required) return true;
    if(_type === 'radio' && !_required) return false;
    if(_type === 'checkbox' && !_required) return false;
    if(_type === 'checkbox' && _required){
      if(!_answersArray) return true;
      if(_answersArray.length > 0) return false;
      else return true;
    }
  }
  
  const fieldType = getType(type);
  
  let answersArray = [];
  if(mode === "reschedule"){
    answersArray = summary?.surveys[questionId]?.answerValue;
  } else {
    answersArray = surveyAnswers[questionId];
  }

  return(
    <div className="my-2">
      {Object.keys(fields).map((key) => (
        <Field
          key={`field-${key}`}
          option={fields[key]}
          type={fieldType}
          questionId={questionId}
          fieldId={key}
          required={isRequired(required,fieldType,answersArray)}
          mode={mode}
        />
      ))}
    </div>
  );
}

export default Fields;
