import React from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';

function Reschedule (props){
  const {t} = useTranslation(['home']);
  return(
    <>
      <Link to={{ pathname: `/operations/reschedule/${props.bookingid}` }}>
        <button style={{border : "none", background : "transparent", color : "var(--color-text)", textDecoration:'underline'}}>
          <i className="fas fa-clock me-1"></i> {t('upcomings.details.reschedule')}
        </button>
      </Link>
    </>
  );
}

export default Reschedule;
