import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import be from '../../BE';
import auth from '../../Account/Auth';

function GoogleContacts({refresh}) {
  const history = useHistory();
  const [loading,setLoading] = useState(false);
  const [imported,setImported] = useState(null);
  const Button = window.calendbookUI.Button;

  const backendSync = async (code) => {
    try {
      setLoading(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.post('Leads',`/contacts`,{code},true,true)
            .then((response) => {
              console.log(`[leads][google-contacts] google contacts synced`);
              setImported(true);
            })
            .catch(error => {
              console.error("[leads][google-contacts] network error while syncing google contacts:",error);
              setImported(false);
            })
        })
        .catch((error) => {
          console.error("[leads][google-contacts] authentication error while syncing google contacts:", error)
          history.push({pathname:'/login'})
        })
    } catch (error) {
      console.error(`[leads][contact-details] functional error while syncing google contacts`);
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async credentialResponse => {
      console.log("[leads][google-import] success response from google: ", credentialResponse);
      await backendSync(credentialResponse?.code);
      await refresh(0);
    },
    onError: error => {
      console.error("[leads][google-import] error response from google: ", error);     
      setImported(false);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/contacts.readonly',
  });

  return (
    <div className="tw-border tw-p-4 tw-rounded-lg tw-inline-flex tw-gap-6 tw-items-center">
      <div className="tw-justify-center">
        <img src="/app-assets/png/google-logo.png"  alt="Google" width="32" height="32"/>
      </div>
      <div>
        <div className="tw-font-bold">Sync with Google</div>
        <div className="tw-text-xs">Connect your account to easily manage your contacts</div>
        {imported === true &&
          <div className="tw-text-xs tw-text-green-500">Syncronization started! you will receive an email once complete</div>
        }
        {imported === false &&
          <div className="tw-text-xs tw-text-red-500">Syncronization Error. Please retry.</div>
        }
      </div>
      <div>
        <Button
          onClick={() => login()}
          size="xs"
          buttonText={loading ? "Syncing..." : "Enable Sync"}
          radius="rounded"
          disabled={loading}
        /> 
      </div>
    </div>
  );
}

export default GoogleContacts;



