import { useEffect } from 'react';
import { initFlowbite } from 'flowbite';

function Filters({
  loading,
  actionFilter,
  handleActionFilterChange,
  showContacts,
  handleShowContactsChange,
  ratingFilter,
  handleRatingFilterChange,
}) {

  const isProd = window.location.hostname === "www.calendbook.com";

  useEffect(() =>{
    initFlowbite();
  },[])

  return(
    <>
      <div className="tw-flex tw-justify-start tw-items-start tw-py-2">
        <div className="tw-inline-flex tw-items-center tw-gap-4">
          <button 
            id="dropdownDefault" 
            data-dropdown-toggle="dropdown"
            className="tw-bg-white hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-4 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center tw-dark:bg-primary-600 tw-dark:hover:bg-primary-700 tw-dark:focus:tw-ring-primary-800"
            type="button"
          >
            Filter
            <svg className="tw-w-4 tw-h-4 tw-ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </button>

          {actionFilter && actionFilter.length > 0 &&
            <div>
              <span className="tw-border-gray-200 tw-border tw-capitalize tw-inline-flex tw-items-center tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-black tw-bg-white tw-rounded tw-dark:bg-blue-900 tw-dark:text-blue-300">
                <button 
                  type="button" 
                  className="tw-inline-flex tw-items-center tw-p-1 tw-me-1 tw-text-sm tw-text-black tw-bg-transparent tw-rounded-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-dark:hover:bg-blue-800 tw-dark:hover:text-blue-300" 
                  aria-label="Remove"
                  onClick={() => handleActionFilterChange("")}
                >
                  <svg className="tw-w-2 tw-h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
                {actionFilter[0].split("_").join(" ")}
              </span>
            </div>
          }

          {ratingFilter && ratingFilter > 0 ? (
            <div>
              <span className="tw-border-gray-200 tw-border tw-capitalize tw-inline-flex tw-items-center tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-black tw-bg-white tw-rounded tw-dark:bg-blue-900 tw-dark:text-blue-300">
                <button
                  type="button"
                  className="tw-inline-flex tw-items-center tw-p-1 tw-me-1 tw-text-sm tw-text-black tw-bg-transparent tw-rounded-sm hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-dark:hover:bg-blue-800 tw-dark:hover:text-blue-300"
                  aria-label="Remove"
                  onClick={() => handleRatingFilterChange(0)}
                >
                  <svg className="tw-w-2 tw-h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
                <span>
                  {'Rating: ' + ratingFilter + '★'}
                </span>
              </span>
            </div>
          ) : null}


        </div>

        {/* Imported Contacts Filter */}
        {!isProd &&
        <div className="tw-flex tw-items-center tw-self-center tw-mx-4">
          <input 
            name="contacts-filter"
            id="contacts-filter" 
            type="checkbox" 
            value="" 
            checked={showContacts}
            onChange={handleShowContactsChange}
            disabled={loading}
            className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 !tw-rounded-sm tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
          />
          <label htmlFor="admitted" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
            Show Google Contacts
          </label>
        </div>
        }

        {/* Dropdown menu */}
        <div id="dropdown" className="tw-divide-y tw-divide-gray-100 tw-z-10 hidden tw-p-3 tw-bg-white tw-rounded-lg tw-shadow tw-w-72 tw-dark:bg-gray-700">
          <ul className="tw-py-2 tw-space-y-2 tw-text-sm" aria-labelledby="dropdownDefault">
            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="all" 
                  type="radio" 
                  value="" 
                  checked={actionFilter.length === 0}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-white tw-border-2  tw-rounded-full"></div>
                <label htmlFor="admitted" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  All
                </label>
              </div>
              {/* 
          filter count
          <div className="tw-text-gray-400">123</div>
          */}
            </li>
            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="booked" 
                  type="radio" 
                  value="booked" 
                  checked={actionFilter.includes('booked')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-green-400 tw-border-2 tw-border-green-400 tw-rounded-full"></div>
                <label htmlFor="admitted" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Booked
                </label>
              </div>
              {/* 
          filter count
          <div className="tw-text-gray-400">123</div>
          */}
            </li>

            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="rescheduled" 
                  type="radio" 
                  value="rescheduled" 
                  checked={actionFilter.includes('rescheduled')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-border-2 tw-rounded-full tw-border-orange-500 tw-bg-orange-500"></div>
                <label htmlFor="temporarily-admitted" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Rescheduled
                </label>
              </div>
              {/*
          filetr count
          <div className="tw-text-gray-400">22</div>
          */}
            </li>

            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="cancelled" 
                  type="radio" 
                  value="cancelled" 
                  checked={actionFilter.includes('cancelled')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-red-500 tw-border-2 tw-border-red-500 tw-rounded-full"></div>
                <label htmlFor="awaiting-verification" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Cancelled
                </label>
              </div>
              {/*
          filter count
          <div className="tw-text-gray-400">12</div>
          */}
            </li>

            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="pending_payment" 
                  type="radio" 
                  value="pending_payment" 
                  checked={actionFilter.includes('pending_payment')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-orange-500 tw-border-2 tw-border-orange-500 tw-rounded-full"></div>
                <label htmlFor="requires-recheck" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Pending Payment
                </label>
              </div>
              {/*
          filter count
          <div className="tw-text-gray-400">56</div>
          */}
            </li>

            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="pending_approval" 
                  type="radio" 
                  value="pending_approval" 
                  checked={actionFilter.includes('pending_approval')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-orange-500 tw-border-2 tw-border-orange-500 tw-rounded-full"></div>
                <label htmlFor="rejected" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Pending Approval
                </label>
              </div>
              {/*
          filter count
          <div className="tw-text-gray-400">6</div>
          */}
            </li>
            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="rejected" 
                  type="radio" 
                  value="rejected" 
                  checked={actionFilter.includes('rejected')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-red-500 tw-border-2 tw-border-red-500 tw-rounded-full"></div>
                <label htmlFor="rejected" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Rejected
                </label>
              </div>
              {/*
          filter count
          <div className="tw-text-gray-400">6</div>
          */}
            </li>
            <li className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center">
                <input 
                  name="action-filter"
                  id="google_imported" 
                  type="radio" 
                  value="google_imported" 
                  checked={actionFilter.includes('google_imported')}
                  onChange={(e) => handleActionFilterChange(e?.target?.value)}
                  disabled={loading}
                  className="disabled:tw-bg-gray-500 disabled:tw-animate-pulse tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded-full tw-text-primary-600 focus:tw-ring-primary-500 tw-dark:focus:ring-primary-600 tw-dark:ring-offset-gray-700 focus:tw-ring-2 tw-dark:bg-gray-600 tw-dark:border-gray-500" 
                />
                <div className="tw-w-3 tw-h-3 tw-ml-2 tw-bg-blue-500 tw-border-2 tw-border-blue-500 tw-rounded-full"></div>
                <label htmlFor="rejected" className="tw-ml-1.5 tw-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">
                  Google Imported
                </label>
              </div>
              {/*
          filter count
          <div className="tw-text-gray-400">6</div>
          */}
            </li>
          </ul>

          <div className="tw-py-2">
            <div className="tw-mb-2 tw-font-medium tw-text-gray-900 tw-dark:text-gray-100">Filter by Rating</div>
            <div className="tw-flex tw-items-center">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => handleRatingFilterChange(star)}
                  disabled={loading}
                  className={`tw-text-xl tw-mr-1 ${
                    ratingFilter >= star ? 'tw-text-yellow-500' : 'tw-text-gray-400'
                  } hover:tw-text-yellow-500 focus:tw-outline-none tw-transition-colors tw-duration-200`}
                >
                  ★
                </button>
              ))}
            </div>
          </div>
        </div>

      </div>

    </>
  );

}

export default Filters;
