import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateEventTypeStore } from '../../store';
import Select from 'react-select';
import auth from '../../Account/Auth';
import be from '../../BE';

const selectStyle = {
  control: (baseStyles,state) => ({
    ...baseStyles,
    width:'100%',
    minWidth:100,
    background:'var(--color-background-2)',
    color:'var(--color-text)',
    border:'1px solid var(--color-border)'
  }),
  singleValue: (baseStyles,state) => ({
    ...baseStyles,
    color:'var(--color-text)'
  }),
  menu: (baseStyles,state) => ({
    ...baseStyles, 
    width:'100%',
    minWidth:200,
    background:'var(--color-background-2)',
    color:'var(--color-text)'
  }),
  option : (baseStyles,state) => ({
    ...baseStyles, 
    background: state.isFocused ? 'var(--color-primary)' : 'var(--color-background-2)',
    color: state.isFocused ? 'white' : 'var(--color-text)'
  }),
}

function SelectMembers(){
  const history = useHistory();
  const [availableMembers, setAvailableMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const addRobinMember = useCreateEventTypeStore((state) => state.addRobinMember);

  const fetchAvailableMembers = async () => {
    setLoadingMembers(true);
    try {
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('team-manager', '/team/members/upgrade', {}, true, true)
            .then(response => {setAvailableMembers(response);})
            .catch(error => {console.error(`[etypecreator][roundrobin] network error while retrieving members:`, error);})
        })
        .catch((error) => {
          console.error(`[etypecreator][roundrobin] authentication error while retrieving members:`, error);
          history.push({ pathname: '/login' });
        })
    } catch (error) {
      console.error(`[etypecreator][roundrobin] functional error while retrieving members:`, error);
    } finally {
      setLoadingMembers(false);
    }
  }

  const getAvailableMembersOptions = (_availableMembers) => {
    return _availableMembers.map(member => ({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={member.pic} alt={member.name} style={{ width: 20, height: 20, marginRight: 10 }} />
          <span>{member.name} ({member.email})</span>
        </div>
      ),
      value: member.username
    }));
  }

  const handleMemberSelection = async (selectedOption) => {
    const getUserByKey = (key) => {
      const user = availableMembers.find(user => user.key === key);
      if (user) {
        return user;
      } else {
        return null;
      }
    }
    const memberKey = selectedOption.value;
    const memberProperties = getUserByKey(memberKey)
    addRobinMember(memberKey, memberProperties);
  }

  return(
    <div className="tw-flex tw-flex-col">
      <div><b>Members</b></div>
      <Select
        options={getAvailableMembersOptions(availableMembers)}
        onMenuOpen={fetchAvailableMembers}
        onChange={handleMemberSelection}
        isLoading={loadingMembers}
        placeholder="Select members"
        styles={selectStyle}
      />
    </div>
  );
}

export default SelectMembers;
