import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import Select from 'react-select';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import be from '../../BE';
import auth from '../../Account/Auth';
import './stripe.css';

function ConnectStripe(){

  const {t} = useTranslation(['integrations']);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [loading,setLoading] = useState(false)
  const [stripeOnboarding,setStripeOnboarding] = useState(false)

  const countries = [
    {label:"Australia", value:"AU"},
    {label:"Austria", value:"AT"},
    {label:"Belgium", value:"BE"},
    {label:"Brazil", value:"BR"},
    {label:"Bulgaria", value:"BG"},
    {label:"Canada", value:"CA"},
    {label:"Croatia", value:"HR"},
    {label:"Cyprus", value:"CY"},
    {label:"Czech Republic", value:"CZ"},
    {label:"Denmark", value:"DK"},
    {label:"Estonia", value:"EE"},
    {label:"Finland", value:"FI"},
    {label:"France", value:"FR"},
    {label:"Germany", value:"DE"},
    {label:"Gibraltar", value:"GI"},
    {label:"Greece", value:"GR"},
    {label:"Hong Kong SAR", value:"HK"},
    {label:"Hungary", value:"HU"},
    {label:"India", value:"IN"},
    {label:"Ireland", value:"IE"},
    {label:"Italy", value:"IT"},
    {label:"Japan", value:"JP"},
    {label:"Latvia", value:"LV"},
    {label:"Liechtenstein", value:"LI"},
    {label:"Lithuania", value:"LT"},
    {label:"Luxembourg", value:"LU"},
    {label:"Malaysia", value:"MY"},
    {label:"Malta", value:"MT"},
    {label:"Mexico", value:"MX"},
    {label:"Netherlands", value:"NL"},
    {label:"New zealand", value:"NZ"},
    {label:"Norway", value:"NO"},
    {label:"Poland", value:"PL"},
    {label:"Portugal", value:"PT"},
    {label:"Romania", value:"RO"},
    {label:"Singapore", value:"SG"},
    {label:"Slovakia", value:"SK"},
    {label:"Slovenia", value:"SI"},
    {label:"Spain", value:"ES"},
    {label:"Sweden", value:"SE"},
    {label:"Switzerland", value:"CH"},
    {label:"Thailand", value:"TH"},
    {label:"United Arab Emirates", value:"AE"},
    {label:"United Kingdom", value:"GB"},
    {label:"United States", value:"US"}
  ] 
  
  const selectStyle = {
    control: (baseStyles,state) => ({
      ...baseStyles,
      width:'100%',
      minWidth:200,
      background:'var(--color-background-2)',
      color:'var(--color-text)',
      border:'1px solid var(--color-border)'
    }),
    singleValue: (baseStyles,state) => ({
      ...baseStyles,
      color:'var(--color-text)'
    }),
    menu: (baseStyles,state) => ({
      ...baseStyles, 
      width:'100%',
      minWidth:200,
      background:'var(--color-background-2)',
      color:'var(--color-text)'
    }),
    option : (baseStyles,state) => ({
      ...baseStyles, 
      background: state.isFocused ? 'var(--color-primary)' : 'var(--color-background-2)',
      color: state.isFocused ? 'white' : 'var(--color-text)'
    })
  ,}

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t('stripe.connect.selectCountry.tooltip')}
    </Tooltip>
  );
  
const getStripeOnboarding = async () => {
      setLoading(true)
        await auth.isAuthenticated()
        .then(async () => {
          await be.get('stripe-connect','/connect/onboarding',{country:selectedCountry},true,true)
          .then(response => {
            setStripeOnboarding(response?.url);
            console.info("[integrations][stripe] Created stripe onboarding link:",stripeOnboarding);
            window.location.assign(response?.url);
          })
          .catch((e) => {
            console.info("[integrations][stripe] Error creating stripe onboarding link:",e);
          });
        })
        .catch((e) => {
          console.info("[integrations][stripe] Error authenticating user:",e);
        });
      setLoading(false)
    }
  return(
    <div className="mt-4">
      <div className="mb-3">
        {t('stripe.connect.title')}
      </div>

      <div className="d-block d-sm-flex align-items-center">
        <Select styles={selectStyle} placeholder={t('stripe.connect.selectCountry.placeholder')} options={countries} onChange={(selection) => setSelectedCountry(selection.value)}/>

        <div className="d-grid gap-2">
          <OverlayTrigger
            placement={window.screen.width > 576 ? "right" : "bottom"}
            delay={{ show: 250, hide: 400 }}
            overlay={!selectedCountry ? renderTooltip : <></>}
          >
            <button disabled={!selectedCountry} className="d-block d-sm-inline-block mx-sm-2 my-2 stripe-button" onClick={getStripeOnboarding}>
              <img 
                src="app-assets/img/stripe-logo.png"
                height="34"
                width="34"
                alt="STRIPE"
              />
              <span className="mx-2">{t('stripe.connect.button')}</span>
              {loading &&
                <span>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="me-3"/>
                </span>
              }
            </button>
          </OverlayTrigger>
        </div>
      </div>

      <div className="iframe-container mb-3">
        <iframe width="805" height="503" src="https://www.youtube.com/embed/OfwfA-U8lmU" title="Get paid for your time with @stripe  &amp; @calendbook  FREE users can charge for a super low $1 fee" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  );
}

export default ConnectStripe;

