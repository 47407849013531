import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function Description (){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setDescription = useCreateEventTypeStore((state) => state.setDescription);

  return(
    <Form.Group as={Row} controlId="event-description" className="my-3 ms-2 me-2">
      <Form.Label column sm={2}>{t('general.description')}</Form.Label>
      <Col sm={10} lg={6}>
        <Form.Control
          as="textarea"
          className="dark-field"
          col="3"
          name="description"
          value={general?.description}
          onChange={(e) => setDescription(e.target.value)}
          maxLength={500}
        />
      </Col>
    </Form.Group>
  );
}

export default Description;
