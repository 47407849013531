import ProfilePic from './ProfilePic';
import FullName from './FullName';
import Email from './Email';
import './user-info.css';

function UserInfo({salesForzaUser}){
  return(
    <>
      {salesForzaUser &&
        <div className="mt-4">
          <div className="user-info">
            <ProfilePic pic={salesForzaUser?.picUrl}/>
            <div className="user-details">
              <FullName fullName={salesForzaUser?.displayName}/>
              <Email email={salesForzaUser?.email}/>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default UserInfo;

