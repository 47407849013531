import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function Color (){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setColor = useCreateEventTypeStore((state) => state.setColor);

  return(
        <Form.Group as={Row} controlId="event-color" className="my-3 ms-2 me-2">
          <Form.Label column sm={2}>{t('general.color')}</Form.Label>
          <Col sm={10} lg={6}>
            <div className="text-center">
              <Form.Check className="color me-2" inline type="radio" label="" id="blue" name="color" value="#007bff" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#007bff"}/>
              <Form.Check className="color me-2" inline type="radio" label="" id="purple" name="color" value="#6f42c1" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#6f42c1"}/>
              <Form.Check className="color me-2" inline type="radio" label="" id="pink" name="color" value="#e83e8c" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#e83e8c"}/>
              <Form.Check className="color me-2" inline type="radio" label="" id="red" name="color" value="#dc3545" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#dc3545"}/>
              <Form.Check className="color me-2" inline type="radio" label="" id="orange" name="color" value="#fd7e14" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#fd7e14"}/>
              <Form.Check className="color me-2" inline type="radio" label="" id="yellow" name="color" value="#ffc107" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#ffc107"}/>
              <Form.Check className="color me-2" inline type="radio" label="" id="green" name="color" value="#28a745" onChange={(e) => setColor(e.target.value)} defaultChecked={general?.color === "#28a745"}/>
            </div>
          </Col>
        </Form.Group>

  );
}

export default Color;
