import React, {useState} from 'react';
import {useWebhooksConnectionsStore} from '../../store';
import {useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';

function AddWebhook() {
  const {t} = useTranslation(['integrations','common']);
  const [processing,setProcessing] = useState(false)
  const [validateForm,setValidateForm] = useState(false);
  const [webHookStatus,setWebHookStatus] = useState({})
  const [selectedTrigger,setSelectedTrigger] = useState("booking")
  const [webHookUrl,setWebHookUrl] = useState("")
  const addWebhook = useWebhooksConnectionsStore((state) => state.addWebhook);

  async function setWebHook(e){
    e.preventDefault();
    const form = e.currentTarget;
    if(form.checkValidity() === false){
      setValidateForm(true);
      e.stopPropagation();
    } else {
      setValidateForm(true);
      setProcessing(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.post('webhook',"/setWebhook",{hook:selectedTrigger,url:webHookUrl},true)
            .then((response) => {
              addWebhook(response);
              setWebHookStatus(response)
            })
            .catch((error) => console.log('error in /settings/webhook page: ',error));
        })
        .catch(() => this.props.history.push('/login'))
      setProcessing(false);
    }
  }

  return(
    <>
    <Row className="mt-3 mb-4">
      <Col>
        <div className="h4" style={{fontWeight:600}}>{t('webhooks.addWebhook')}</div>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form noValidate validated={validateForm} onSubmit={setWebHook}>
          <InputGroup hasValidation>
            <DropdownButton
              variant="outline-primary"
              title={t(`common:${selectedTrigger}`)}
              id="trigger-selection"
            >
              <Dropdown.Item onClick={() => setSelectedTrigger("booking")}>{t('common:booking')}</Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedTrigger("reschedule")}>{t('common:reschedule')}</Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedTrigger("delete")}>{t('common:delete')}</Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedTrigger("reminder")}>{t('common:reminder')}</Dropdown.Item>
            </DropdownButton>
            <Form.Control
              required
              type="url"
              name="webhook-url"
              id="webhook-url-input"
              placeholder="https://your-integration-website.com"
              value={webHookUrl}
              onChange={(event) => setWebHookUrl(event.target.value)}
            />
            <Button disabled={processing} type="submit">
              {processing
                ?
                  <Spinner size="sm" animation="border" variant="light"/> 
                  :
                  <span><i className="fa-solid fa-circle-plus me-2"></i> {t('webhooks.addWebhookButton')} </span>
              }
            </Button>
            <Form.Control.Feedback type="invalid">
              {t('webhooks.addWebhookValidation')}
            </Form.Control.Feedback>
          </InputGroup>
        </Form>
      </Col>
    </Row>
      </>
  );
}

export default AddWebhook;
