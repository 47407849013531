export const PrivacyIta = `
Privacy Policy di www.calendbook.com

Questo Sito Web raccoglie alcuni Dati Personali dei propri Utenti.

Questo documento può essere stampato utilizzando il comando di stampa presente 
nelle impostazioni di qualsiasi browser.

Titolare del Trattamento dei Dati

CALENDBOOK S.R.L. - VIA FELICE CAVALLOTTI 67, 70032 BITONTO (BA)
Indirizzo email del Titolare: privacy@calendbook.com 


Tipologie di Dati raccolti

Fra i Dati Personali raccolti da questo Sito Web/App, in modo autonomo o
tramite terze parti, ci sono: 
Dati di utilizzo; Strumento di Tracciamento; nome; cognome; Dati comunicati durante l'utilizzo del servizio; email; sesso; data di nascita; professione; Accesso completo.

Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.
I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questo Sito Web.
Se non diversamente specificato, tutti i Dati richiesti da questo Sito Web sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questo Sito Web fornire il Servizio. 
Nei casi in cui questo Sito Web indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori possono contattare il Titolare al suindicato indirizzo email.
L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questo Sito Web o dei titolari dei servizi terzi utilizzati da questo Sito Web, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.

L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questo Sito Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.

Modalità e luogo del trattamento dei Dati raccolti
Modalità di trattamento

Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. 
Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questo Sito Web (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento all’indirizzo email privacy@calendbook.com

Base giuridica del trattamento

Il Titolare tratta Dati Personali relativi all’Utente in caso sussista alle seguenti condizioni

1. l’Utente ha prestato il consenso per una o più finalità specifiche; 
Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;
2. il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali (come nel caso di utenti che si registrino per utilizzare i servizi offerti dal Titolare)
3. il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;
4. il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;
5. il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.

È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.

Luogo del trattamento

I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate, con strumenti informatici ed eventualmente anche in formato cartaceo laddove strettamente necessario. Per ulteriori informazioni, contatta il Titolare.
I Dati Personali dell’Utente potrebbero essere trasferiti in un Paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento dei propri dati personali l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.

L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.

L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.

Periodo di conservazione

I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.

I Dati Personali raccolti per scopi collegati all’esecuzione delcontratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto, ma possono essere trattati anche successivamente alla sua completa esecuzione per esigenze amministrative e/o legali che rendano necessario questo ulteriore trattamento.

I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.

Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. In particolare, laddove i dati personali siano raccolti e trattati per finalità di marketing, il Titolare conserverà e tratterà detti dati non oltre 5 anni dal momento in cui l’interessato ha prestato l’ultimo consenso espresso a tal fine.

Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.

Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati, ferma restando la possibilità di utilizzare i predetti Dati in forma aggregata ed anonima per finalità statistiche.

Finalità del Trattamento dei Dati raccolti

I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: 
Visualizzazione di contenuti da piattaforme esterne, Statistica, Gestione dei tag, Gestione dei pagamenti, Registrazione ed autenticazione, Heat mapping e registrazione sessioni, Interazione con le piattaforme di live chat, Gestione della raccolta dati e dei sondaggi online, Gestione dei database di Utenti, Gestione contatti e invio di messaggi e Hosting ed infrastruttura backend.

Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali trattati per ciascuna finalità, l’Utente può fare riferimento alla sezione “Dettagli sul trattamento dei Dati Personali”.

Dettagli sul trattamento dei Dati Personali

I Dati Personali, in base alle suesposte finalità, sono raccolti  e trattati mediante l’utilizzo dei seguenti servizi:

Gestione contatti e invio di messaggi

Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di qualunque altro tipo, utilizzati per comunicare con l’Utente.
Questi servizi potrebbero inoltre consentire di raccogliere dati relativi alla data e all’ora di visualizzazione dei messaggi da parte dell’Utente, così come all’interazione dell'Utente con essi, come le informazioni sui click sui collegamenti inseriti nei messaggi.

Customerly E-mail Marketing (Customerly Ltd.)

Customerly E-mail Marketing è un servizio di gestione indirizzi e invio di messaggi email fornito da Customerly Ltd.

Dati Personali trattati: cognome; Dati di utilizzo; email; nome.

Luogo del trattamento: Irlanda – Privacy Policy.

Amazon Simple Email Service (Amazon)

Amazon Simple Email Service è un servizio di gestione indirizzi e invio di messaggi email fornito da Amazon.com Inc.

Dati Personali trattati: email.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Gestione dei database di Utenti

Questo tipo di servizi permettono al Titolare di costruire profili utente partendo da un indirizzo email, il nome o qualunque altra informazione che l'Utente fornisce a questo Sito Web/App, così come di tracciare le attività dell'Utente tramite funzionalità statistiche. 
Questi Dati Personali potrebbero inoltre venire incrociati con informazioni sull'Utente disponibili pubblicamente (come i profili sui social network) ed usati per costruire profili privati che il Titolare può visualizzare ed utilizzare per migliorare questo Sito Web.
Alcuni di questi servizi potrebbero inoltre permettere l'invio programmato di messaggi all'Utente, come email basate su azioni specifiche compiute su questo Sito Web.

Customerly (Customerly Ltd.)

Customerly è un servizio di gestione delle banche dati utente fornito da Customerly Ltd.
Customerly può essere usato anche come mezzo di comunicazione, per scambiarsi email o messaggi servendosi di questo Sito Web.

Dati Personali trattati: Dati di utilizzo; email; Strumento di Tracciamento; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

Luogo del trattamento: Irlanda – Privacy Policy.

Gestione dei pagamenti

Se non diversamente specificato, questo Sito Web/App elabora tutti i pagamenti con carta di credito, bonifico bancario o altri mezzi tramite fornitori esterni di servizi di pagamento. In generale, e salvo diversa indicazione, gli Utenti sono pregati di fornire i dettagli di pagamento e le informazioni personali direttamente a tali fornitori di servizi di pagamento.
Questo Sito Web non è coinvolto nella raccolta e nell'elaborazione di tali informazioni, poiché riceverà solo una notifica da parte del fornitore di servizi di pagamento in questione circa l'avvenuto pagamento.

Stripe (Stripe Inc)

Stripe è un servizio di pagamento fornito da Stripe Inc.

Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Gestione dei tag

Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script utilizzati su questo Sito Web. L'uso di tali servizi comporta il fluire dei Dati dell'Utente attraverso gli stessi e, se del caso, la loro ritenzione.

Google Tag Manager (Google LLC)

Google Tag Manager è un servizio di gestione dei tag fornito da Google LLC.

Dati Personali trattati: Dati di utilizzo.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Gestione della raccolta dati e dei sondaggi online

Questo tipo di servizio permette a questo Sito Web di gestire la creazione, l'implementazione, l'amministrazione, la distribuzione e l'analisi di formulari e di sondaggi online al fine di raccogliere, salvare e riutilizzare i Dati degli Utenti che rispondono.
I Dati Personali raccolti dipendono dalle informazioni richieste e fornite dagli Utenti nel formulario online corrispondente.

Questi servizi possono essere integrati con un'ampia gamma di servizi di terze parti per consentire al Titolare di compiere azioni successive con i Dati trattati - ad esempio, gestione dei contatti, invio di messaggi, statistiche, pubblicità ed elaborazione dei pagamenti.

Customerly Survey (Customerly Ltd.)

Customerly Survey è un generatore di sondaggi e una piattaforma di raccolta dati fornita da Customerly Ltd.

Dati Personali trattati: cognome; data di nascita; Dati di utilizzo; email; nome; professione; sesso.

Luogo del trattamento: Irlanda – Privacy Policy.

Heat mapping e registrazione sessioni

I servizi di Heat mapping sono utilizzati per individuare le aree di questo Sito Web con le quali gli Utenti interagiscono con maggior frequenza, in modo da rilevare quali di esse attraggono maggior interesse. Questi servizi permettono di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
Alcuni di questi servizi potrebbero registrare le sessioni e renderle disponibili per visualizzarle in seguito.

Hotjar Heat Maps & Recordings (Hotjar Ltd.)

Hotjar è un servizio di heat mapping e di registrazione delle sessioni fornito da Hotjar Ltd.
Hotjar rispetta gli header generici „Do Not Track”. Questo significa che il browser può indicare allo script di non raccogliere alcun dato dell’Utente. Si tratta di un'impostazione che è disponibile in tutti i principali browser. Maggiori Informazioni sull'opt-out da Hotjar sono disponibili qui.

Dati Personali trattati: Dati di utilizzo; Strumento di Tracciamento; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

Luogo del trattamento: Malta – Privacy Policy – Opt Out.

Hosting ed infrastruttura backend

Questo tipo di servizi ha la funzione di ospitare Dati e file che permettono a questo Sito Web di funzionare, ne consentono la distribuzione e mettono a disposizione un'infrastruttura pronta all'uso per erogare specifiche funzionalità di questo Sito Web.

Alcuni servizi tra quelli elencati di seguito, se presenti, possono funzionare su server geograficamente distribuiti, rendendo difficile determinare l'effettiva ubicazione in cui sono conservati i Dati Personali.

Amazon Web Services (AWS) (Amazon Web Services, Inc.)

Amazon Web Services (AWS) è un servizio di hosting e backend fornito da Amazon Web Services, Inc.

Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

Luogo del trattamento: Germania – Privacy Policy; Italia – Privacy Policy.

Interazione con le piattaforme di live chat

Questo tipo di servizi permette di interagire con le piattaforme di live chat gestite da soggetti terzi, direttamente dalle pagine di questo Sito Web, al fine di poter contattare ed essere contattati dal servizio di supporto di questo Sito Web.

Nel caso in cui sia installato un servizio di interazione con le piattaforme di live chat, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga Dati di Utilizzo relativi alle pagine in cui è installato. Inoltre, le conversazioni della live chat potrebbero essere registrate.

Widget di Chat Customerly (Customerly Ltd.)

Il Widget di Chat Customerly è un servizio di interazione con la piattaforma di live chat Customerly, fornito da Customerly Ltd.

Dati Personali trattati: cognome; Dati comunicati durante l'utilizzo del servizio; Dati di utilizzo; email; nome; Strumento di Tracciamento.

Luogo del trattamento: Irlanda – Privacy Policy.

Registrazione ed autenticazione

Con la registrazione o l’autenticazione l’Utente consente a questo Sito Web di identificarlo e di dargli accesso a servizi dedicati.

A seconda di quanto indicato di seguito, i servizi di registrazione e di autenticazione potrebbero essere forniti con l’ausilio di terze parti. Qualora questo avvenga, questo Sito Web potrà accedere ad alcuni Dati conservati dal servizio terzo usato per la registrazione o l’identificazione.

Alcuni dei servizi di seguito indicati potrebbero raccogliere Dati Personali anche per fini di targeting e profilazione; per saperne di più, si prega di fare riferimento alla descrizione di ciascun servizio.

Google OAuth (Google LLC)

Google OAuth è un servizio di registrazione ed autenticazione fornito da Google LLC e collegato al network Google.

Dati Personali trattati: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Permessi Gmail per l’accesso ai Dati dell’Utente (aggiunta OAuth) (Google LLC)

Per fornire il suo servizio, questo Sito Web utilizza gli scopi limitati per Gmail che permettono di accedere ai Dati dell’Utente relativi a questo servizio Google (come indicato di seguito). Questo accesso è fornito da Google LLC secondo termini rigorosi. Di seguito è riportato che cosa significa questo per tali Dati dell’Utente.

L'uso dei Dati Utente è limitato alla fornitura o al miglioramento delle funzionalità di interfaccia Utente. I dati dell'Utente non saranno utilizzati per pubblicare annunci, né per il retargeting e la pubblicità personalizzata o basata sugli interessi.

I Dati dell'Utente saranno trasferiti a terzi solo se necessario, per fornire o migliorare le funzionalità di interfaccia Utente e se necessario per conformarsi alla legge applicabile o come parte di una fusione, acquisizione o vendita di beni previo preavviso agli Utenti.

I Dati non saranno letti da esseri umani a meno che: il Titolare abbia ottenuto l'accordo affermativo dell'Utente per messaggi specifici; sia necessario per motivi di sicurezza e/o per rispettare la legge applicabile, oppure; l'utilizzo venga limitato ad operazioni interne e i Dati (comprese le loro derivazioni) siano stati aggregati e resi anonimi.

Dati Personali trattati: Accesso completo.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Statistica

I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.

Google Analytics (Google LLC)

Google Analytics è un servizio di analisi web fornito da Google LLC (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da Google.

Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.

Dati Personali trattati: Dati di utilizzo; Strumento di Tracciamento.

Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out.

Visualizzazione di contenuti da piattaforme esterne

Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questo Sito Web e di interagire con essi.
Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico web relativo alle pagine dove il servizio è installato, anche quando gli utenti non lo utilizzano.

Font Awesome (Fonticons, Inc. )

Font Awesome è un servizio di visualizzazione di stili di carattere gestito da Fonticons, Inc. che permette a questo Sito Web di integrare tali contenuti all’interno delle proprie pagine.

Dati Personali trattati: Dati di utilizzo.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Google Fonts (Google LLC)

Google Fonts è un servizio di visualizzazione di stili di carattere gestito da Google LLC che permette a questo Sito Web di integrare tali contenuti all’interno delle proprie pagine.

Dati Personali trattati: Dati di utilizzo; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio.

Luogo del trattamento: Stati Uniti – Privacy Policy.

Ulteriori informazioni sui Dati Personali
Vendita di beni e servizi online

I Dati Personali raccolti sono utilizzati per l’erogazione di servizi all’Utente o per la vendita di prodotti, inclusi il pagamento e l’eventuale consegna. I Dati Personali raccolti per perfezionare il pagamento possono essere quelli relativi alla carta di credito, al conto corrente utilizzato per il bonifico o ad altri strumenti di pagamento previsti. I Dati di pagamento raccolti da questo Sito Web dipendono dal sistema di pagamento utilizzato.

Diritti dell’Utente

Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.
In particolare, l’Utente ha il diritto di:

1. Revocare il consenso in ogni momento. L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso;
2. Opporsi al trattamento dei propri Dati. L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso, fermo restando il legittimo interesse del Titolare, che può in ogni caso giustificare il trattamento. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.
3. Accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati;
4. Verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione;
5. Ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.
6. Ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.
7. Ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.
8. Proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.

Dettagli sul diritto di opposizione

Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.

Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.

Come esercitare i diritti

Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati nell’apposito paragrafo. Ulteriori informazioni sul trattamento
Difesa in giudizio

I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questo Sito Web o dei Servizi connessi da parte dell’Utente.

L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.

Informative specifiche

Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questo Sito Web potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.

Log di sistema e manutenzione

Per necessità legate al funzionamento ed alla manutenzione, questo Sito Web e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.

Informazioni non contenute in questa policy

Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.

Risposta alle richieste “Do Not Track”

Questo Sito Web non supporta le richieste “Do Not Track”. Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a consultare le rispettive privacy policy.

Modifiche a questa privacy policy

Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.

Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.

Definizioni e riferimenti legali
Dati Personali (o Dati)

Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.

Dati di Utilizzo

Sono le informazioni raccolte automaticamente attraverso questo Sito Web (anche da applicazioni di parti terze integrate in questo Sito Web), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questo Sito Web, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.

Utente

L'individuo che utilizza questo Sito Web che, salvo ove diversamente specificato, coincide con l'Interessato.

Interessato

La persona fisica cui si riferiscono i Dati Personali.

Responsabile del Trattamento (o Responsabile)

La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.

Titolare del Trattamento (o Titolare)

La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questo Sito Web. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questo Sito Web.

Questo Sito Web (o questa Applicazione)

Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.

Servizio

Il Servizio fornito da questo Sito Web così come definito nei relativi termini (se presenti) su questo sito/applicazione.

Unione Europea (o UE)

Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.

Cookie

I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.

Strumento di Tracciamento

Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.

Accesso completo

Accesso completo all'account, compresa la cancellazione permanente di conversazioni e messaggi.

Riferimenti legali

La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.

Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo Sito Web.

Ultima modifica: 25 giugno 2021
`
