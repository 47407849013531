import PartnerCard from './PartnerCard';
import partners from '../partners-list.js';

function Partners() {

  return(
    <>
      <div className="d-block d-lg-flex my-1 my-lg-4 justify-content-center">
        {Object.keys(partners) && Object.keys(partners).map(partnerKey => (
          <>
            {console.log(partnerKey)}
            {partnerKey !== "standard" &&
              <PartnerCard key={partnerKey} partnerName={partnerKey} partnerData={partners[partnerKey]}/>
            }
          </>
        ))}
      </div>
    </>
  );
}


export default Partners;
