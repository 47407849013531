import {useTranslation} from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';

const frontendFormatter = (number) => {
  console.info('[upcomings][price] parsing be price:');
  let result = '';
  if(number !== undefined){
    result = Number(number)/100;
  }
  console.info(result);
  return result;
};

function Price({amount,currency}){
  const {t} = useTranslation(['home'])
  return(
    <>
      {amount && currency &&
        <>
          <i className="fa-solid fa-credit-card me-2"></i>
          <b>{t('upcomings.details.payments')}</b>
          <div>{getSymbolFromCurrency(currency)} {frontendFormatter(amount)}</div>        
          <br/>
        </>
      }
    </>
  );
}

export default Price;
