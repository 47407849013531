import Form from 'react-bootstrap/Form';

function Priorities({ member, priority, disabled, handlePrioritySelection }){

  return(
    <div className="tw-flex tw-flex-col tw-w-36">
      <Form.Label className="tw-text-sm tw-text-gray-500">priority</Form.Label>
      <Form.Select 
        defaultValue={2}
        value={Number(priority) || 2}
        size="sm"
        aria-label="Priority Selection"
        onChange={e => handlePrioritySelection(e,member)}
        style={{maxWidth:120,height:38}}
        disabled={disabled}
      >
        <option value={1}>LOW</option>
        <option value={2}>MEDIUM</option>
        <option value={3}>HIGH</option>

      </Form.Select>
    </div>
  );
}

export default Priorities;
