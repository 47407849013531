import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';

function Upgrade(){
  const { t } = useTranslation(['integrations']);

  const history = useHistory();
  const routeChange = () =>{
    let path = `/upgrade`;
    history.push(path);
  }

  return(
    <Button variant="outline-primary" size="sm" onClick={routeChange}>
      {t('whatsapp.upgrade')}
    </Button>
  );
}

export default Upgrade;
