import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CalendarsList from './CalendarsList';

function ConnectedCalendars(props){
  const { t } = useTranslation(['calendarConnections']);
  return(
    <>
      <Row style={{height:38}}>
        <Col>
          <div className="fs-4 d-inline">{t('connectedCalendars')}</div>
          <div style={{color:'var(--color-secondary)'}} className="d-inline ms-3 align-text-bottom">{props.connectedCalendars}/{props.maxCalendars}</div>
        </Col>
        <Col xs={2} md={5} lg={5} className="pe-0">
          //addcalendar
        </Col>
      </Row>
      <Row>
        <small>Here you can add, remove and check the connection status of your calendars</small>
      </Row>
      <Row className="mt-4">
        <CalendarsList calendarsList={props.calendarsList} isLoading={props.isLoading}/>
      </Row>
    </>
  );
}

export default ConnectedCalendars;
