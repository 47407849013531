import {useState, useEffect } from 'react';
import Pic from './Pic';
import Name from './Name';
import Url from './Url';
import Bio from './Bio';
import auth from '../../Account/Auth';
import be from '../../BE';

function Settings() {
  const [teamSettings, setTeamSettings] = useState({});
  const [loadingSettings, setLoadingSettings] = useState(false);

  useEffect(() => {
    const fetchTeamSettings = async () => {
      try {
        setLoadingSettings(true);
        await auth.isAuthenticated()
          .then(async () => {
            await be
              .get('team-manager', '/team/settings', false, true, true)
              .then((response) => {
                console.log('[team][settings] got team settings');
                setTeamSettings(response);
              })
              .catch((error) => {
                console.error('[team][settings] network error while fetching team settings', error);
              });
          })
          .catch((error) => {
            console.error('[team][settings] authentication error while fetching team settings', error);
          });
      } catch (error) {
        console.error('[team][settings] functional error while fetching team settings', error);
      } finally {
        setLoadingSettings(false);
      }
    };
    fetchTeamSettings();
  }, []);

  return (
    <div className="tw-flex tw-items-center tw-space-x-4 tw-py-6">
      <div className="tw-w-full tw-flex tw-flex-col tw-space-y-2 tw-items-center">

        <div className="tw-flex tw-me-auto tw-flex-row tw-gap-4">
          {loadingSettings ? (
            <div className="tw-w-16 tw-h-16 tw-rounded-full tw-bg-gray-300 tw-animate-pulse"></div>
          ) : (
            <Pic pic={teamSettings?.profilePic} canEdit={teamSettings?.canEdit} />
          )}

          <div className="tw-flex tw-flex-col tw-content-center">
            {loadingSettings ? (
              <div className="tw-w-48 tw-h-6 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
            ) : (
              <Name name={teamSettings?.teamName} canEdit={teamSettings?.canEdit} />
            )}
            {loadingSettings ? (
              <div className="tw-w-32 tw-h-4 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
            ) : (
              <Url url={teamSettings?.teamUrl} canEdit={teamSettings?.canEdit} />
            )}
          </div>
        </div>

        <div className="tw-flex tw-mr-auto tw-w-full">
          {loadingSettings ? (
            <div className="tw-w-full tw-h-24 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
          ) : (
            <Bio bio={teamSettings?.teamBio} canEdit={teamSettings?.canEdit} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;

