import {useTranslation} from 'react-i18next';

function Dashboard({url}){
  const {t} = useTranslation(['integrations']);
  return(
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">{t('stripe.dashboard.link')}</a> {t('stripe.dashboard.subtitle')} 
    </div>
  );
}

export default Dashboard;
