import React from 'react';
import {useCreateEventTypeStore} from '../../../store';
import {useTranslation} from 'react-i18next';
import {Form, Row, Col, Button} from 'react-bootstrap';
import DayCheckbox from './DayCheckbox';
import Range from './Range';
import * as utils from '../../../Utilities/datetimeUtils.js'

function WeekView ({timeFormat}) {
  const {t} = useTranslation(['eventCreator']);
  const availability = useCreateEventTypeStore(state => state.availability);
  const deleteDayRange = useCreateEventTypeStore((state) => state.deleteDayRange);
  const toggleDayAvailability = useCreateEventTypeStore((state) => state.toggleDayAvailability);
  const setDayRange = useCreateEventTypeStore((state) => state.setDayRange);
  var days = ['mon','tue','wed','thu','fri','sat','sun']

  return(
    <Form.Group controlId="ranges">
      {
        days.map((day,dayIndex) => (
          <Row key={`availabilities-${day}`} className="mx-2">
            <Col xs={12} md={2} className={availability.ranges[day].length <= 1 ? "mt-1 py-2 my-md-auto" : "mt-1 py-2"}>
              <DayCheckbox day={day}/>
            </Col>

            <Col className="my-auto">
              {availability.ranges[day].length === 0 && 
              <Row>
                <Col className="my-2 text-muted">
                    {t(`availability.unavailable`)}
                </Col>
              </Row>
              }

              {availability.ranges[day].map((range,rangeIndex) => (
                <Row key={`ranges-${rangeIndex}-${day}`} id={`${range.start}-${range.end}-${day}`}>
                  <Col>
                    <Range wday={day} index={rangeIndex} rangeState={availability.ranges[day][rangeIndex]} disabled={!availability.days[day]} timeFormat={timeFormat}/>
                  </Col>
                  <Col xs={2} className="ps-0 my-auto">
                    <div className="d-grid gap-2">
                      <Button style={{border : "none", background : "transparent", color : "var(--colot-text)"}} onClick={() => {deleteDayRange(day,rangeIndex)}}>
                        <i className="far fa-trash-alt my-auto"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col xs={2} className={availability.ranges[day].length <= 1 ? "my-auto" : ""} style={availability.ranges[day].length > 1 ? {marginTop:"6px"} : {}}>
              <div className="d-grid gap-2">
                <Button  className="text-start" style={{border : "none", background : "transparent", color : "var(--color-text)"}} onClick={() => { 
                  let interval = {start:"08:00",end:"19:00"};
                  let index = availability.ranges[day].length;
                  // interval = utils.addIntervalCheck(availability,day,index,interval);
                  setDayRange(day,index,interval);
                  }}>
                  <i className="fas fa-plus"></i>
                </Button>
              </div>
            </Col>
            {dayIndex < 6 &&
            <Col xs={12}>
              <hr style={{color:"var(--color-ruler)", backgroundColor:"var(--color-ruler)", height: "2", width:"100%"}}/>
            </Col>
            }
          </Row>
        ))}


    </Form.Group>

  );
}
export default WeekView;
