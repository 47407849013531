import React from 'react';
import EventType from '../../EventType/EventType_'
import { useTranslation } from 'react-i18next';
import ConnectionAlert from './ConnectionAlert';

function _EventTypeContainer(props){
  
  const eventTypes = props.state;
  const { t } = useTranslation(['home']);
  
  return(
    <>
      {/*Show a connection alert if no calendar is connected*/}
      {(props?.connections?.connectedCalendars <= 0) 
        ?
          <ConnectionAlert/>
        :
          <>
            {/*Show EventTypes if any only if user connected at least 1 calendar*/}
            {Object.keys(eventTypes).length > 0
              ?
                Object.keys(eventTypes).map((key) => (
                  <div className="m-2 ms-3 w-auto px-auto" key={'eventType-' + key} id={`event-type-id-${key}`}>
                    <EventType key={key} id={key} state={eventTypes[key]} />
                  </div>
                ))
              :
                t('eventTypes.empty') 
            }
          </>
      }
    </>
  );
}

export default _EventTypeContainer;
