import DOMPurify from 'dompurify';

function toHyperlink(text){
  //eslint-disable-next-line
  var pattern1 = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  var str1 = text.replace(pattern1, '<a target="_blank" rel="noopener noreferrer" style="color:var(--color-text)" href="$1">$1</a>');
  //eslint-disable-next-line
  var pattern2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
  var str2 = str1.replace(pattern2, '$1<a target="_blank" rel="noopener noreferrer" href="http://$2">$2</a>');

  return str2;
}

function Answer({fields,questionType}){
  const getFieldsArray = (_fields,_questionType) => {
    if(_questionType === 3) return [_fields]
    else return _fields
  }

  return (
    <>
      {Array.isArray(getFieldsArray(fields,questionType)) &&
          getFieldsArray(fields,questionType).map((field) => (
            <div className="d-inline-flex">
              <span className="me-2">&#8226;</span>
              {(questionType === "5" || questionType === "6")
                ?<div dangerouslySetInnerHTML={{__html: toHyperlink(field)}}/>
                :<>{field}</>
              }
            </div>
          ))
      }
    </>
  );
}

export default Answer;
