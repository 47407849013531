function Email({email}){
  return(
    <>
      {email &&
        <div className="email">{email}</div>
      }
    </>
  );
}

export default Email;

