import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';

function DisconnectZoom({zoomUser,setZoomUser}){
  const [loading,setLoading] = useState(false)

  const disconnectZoom = async () => {
    console.info("[zoom] disconnecting zoom user account")
    setLoading(true)
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('integrations','/zoom/internal/revoke',false,true)
          .then(response => {
            // if 200 ok set zoomUser obj to {}
            setZoomUser({})
            console.log(response)
          });
      });
    setLoading(false)
    }

  return(
    <div className="mt-4">
      <div className="mb-3">
        You can disconnect your Zoom Account here:
      </div>
      
      <Button 
        variant="outline-danger"
        size="sm"
        disabled={loading}
        onClick={disconnectZoom}
      >
        Disconnect Zoom {loading && 
          <Spinner
            animation="border"
            size="sm"
            className="ms-2"/>
        }
      </Button>
    </div>
  );
}

export default DisconnectZoom;
