import {useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import be from '../../BE';
import auth from '../../Account/Auth';

function ConnectZoom(){
  const [zoomLink,setZoomLink] = useState('');
  const [loading,setLoading] = useState(false)

  const getZoomLink = async () => {
    setLoading(true)
    await auth.isAuthenticated()
      .then(async () => {
        await be.get('integrations','/zoom/init',false,true)
          .then(response => {
            setZoomLink(response?.url);
            console.info("[integrations][zoom] Created zoom link:",zoomLink);
            window.open(response?.url);
          })
          .catch((e) => {
            console.info("[integrations][zoom] Error creating zoom link:",e);
          });
      })
      .catch((e) => {
        console.info("[integrations][zoom] Error authenticating user:",e);
      });
    setLoading(false)
  }

  return(
    <div className="mt-4">

      {/*
      <Alert variant="warning">
        Dear Calendbook User! 
        <br/>
        We are currently changing our zoom connection flow and you will be able to connect your zoom account in the next few days :)
        <br/>
        For any question about this, please reach out to us in the website chat.
      </Alert>
      */}

      <div className="mb-3">
        Add Calendbook to your Zoom Account!
      </div>

      <button onClick={getZoomLink} style={{border:'none',background:'transparent'}}>
        <img 
          src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
          height="32px"
          width="150px"
          alt="Add to ZOOM"
        />
      </button>
      {loading &&
      <span>
        <Spinner
          animation="border"
          size="sm"
          className="me-3"/>
      </span>
      }
    </div>
  );
}

export default ConnectZoom;

