import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Duration from './Duration';
import Private from './Private';
import Location from './Location';
import Team from './Team';
import DisabledReason from './DisabledReason';


function Middle({reason,team,enabled,duration,secret,location}){
  return(
    <>
      <div className="my-3">
        <Duration enabled={enabled} duration={duration}/>
        <Private enabled={enabled} secret={secret}/>
        {location && <Location location={location}/>}
        {team && <Team enabled={enabled}/>}
        {!enabled && reason && <DisabledReason reason={"memberNeedsTemplate"}/>}
      </div>
    </>
  );
}

export default Middle;
