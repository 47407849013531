// Agent.js
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './agent.css';

function Agent({ name, email, profilePic, onSwitch, isSwitching, isActive }) {
  return (
    <div className="agent">
      <img src={profilePic} alt={name} className="agent-image" />
      <div className="agent-info">
        <h3>{name}</h3>
        <p>{email}</p>
      </div>
      {isActive ? null : (
        <button onClick={onSwitch} className="agent-button" disabled={isSwitching}>{isSwitching && <Spinner size="sm"/>} Switch To</button>
      )}
    </div>
  );
}

export default Agent;

