function Title({view}){
    const componentStyle={
        fontWeight:900
    }

    return(
        <>
            {view === "upgrade" && <h1 className="tw-text-3xl" style={componentStyle}>Upgrade Your Calendbook Plan</h1>}
            {view === "manage" && <h1 className="tw-text-3xl" style={componentStyle}>Manage Your Calendbook Plan</h1>}
        </>
    );
}

export default Title;
