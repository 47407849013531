import { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import PricingCard from '../../Landing/PricingCard/_PricingCard';
import auth from '../../Account/Auth';

function Pricing({openCheckout}){
  const {i18n,t} = useTranslation(['landing','common',"upgrade"]);
  const [pricingCadency,setPricingCadency] = useState("yearly");
    return(
        <Row className="my-5 mx-auto" style={{maxWidth:900}}>
              <ToggleButtonGroup type="radio" name="pricing-cadency" className="my-4" value={pricingCadency} onChange={(value) => setPricingCadency(value)}>
                <ToggleButton id="pricing-cadency-monthly" value="monthly" name="pricing-cadency" style={{borderRadius:'15px 0 0 15px'}} variant={pricingCadency === 'monthly' ? 'primary' : 'outline-primary'}>{t('upgrade:pricingCard.cadency.monthly')}</ToggleButton>
                <ToggleButton id="pricing-cadency-yearly" value="yearly" name="pricing-cadency"  style={{borderRadius:'0 15px 15px 0'}}variant={pricingCadency === 'yearly' ? 'primary' : 'outline-primary'}>{t('upgrade:pricingCard.cadency.yearly')}</ToggleButton>
              </ToggleButtonGroup>
              <Col xs={12} md={4} className="p-0 my-2">
                <PricingCard
                  background='var(--color-background-1)'
                  plan="AgencyTier1"
                  planDisplayName="Agency 3"
                  currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                  price={pricingCadency === 'monthly' ? '16' : '8'}
                  hideCTA={!auth.sessionExist()}
                  CTALink={() => openCheckout('agency-tier-1')}
                  features={{
                    premium:true,
                    companySeats:true,
                    agents:true,
                    companyTeamSeats:true,
                  }}
                />
              </Col>
              <Col xs={12} md={4} className="p-0 my-2">
                <PricingCard
                  overlay
                  background='var(--color-background-1)'
                  plan="AgencyTier2"
                  planDisplayName="Agency 5"
                  currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                  price={pricingCadency === 'monthly' ? '33' : '16'}
                  hideCTA={!auth.sessionExist()}
                  CTALink={() => openCheckout('agency-tier-2')}
                  features={{
                    premium:true,
                    companySeats:true,
                    agents:true,
                    companyTeamSeats:true,
                  }}
                />
              </Col>
              <Col xs={12} md={4} className="p-0 my-2">
                <PricingCard
                  overlay
                  background='var(--color-background-1)'
                  plan="AgencyTier3"
                  planDisplayName="Agency 10"
                  currency={i18n.language.substring(0,2) === 'en' ? '$' : '€' }
                  price={pricingCadency === 'monthly' ? '49' : '25'}
                  hideCTA={!auth.sessionExist()}
                  CTALink={() => openCheckout('agency-tier-3')}
                  features={{
                    premium:true,
                    companySeats:true,
                    agents:true,
                    companyTeamSeats:true,
                  }}
                />
              </Col>
            </Row>
    );
}

export default Pricing;
