import Card from 'react-bootstrap/Card';
import {useTranslation} from 'react-i18next';
import UpgradePath from '../../Utilities/UpgradePath/UpgradePath';
import Name from './Name';
import Description from './Description';
import Location from './Location';
import Color from './Color';
import Duration from './Duration';
import CustomDuration from './CustomDuration';
import Buffer from './Buffer';
import RedirectionUrl from './RedirectionUrl';
import Team from './Team';

function General () {
  const {t} = useTranslation(['eventCreator']);

  return(
    <>
      <Card style={{color:'var(--text-color)',background:"var(--color-background-1)"}} className="mb-2">

        <Card.Header className="h3">
          {t('general.header')}
        </Card.Header>

        <Card.Body>
          <Name/> 
          <Description/>
          <Location/>
          <Color/>
          <Duration/>
          <UpgradePath 
            featureName="custom-event-duration"
            position="right">
            <CustomDuration/>
          </UpgradePath>
          <Buffer/>
          <UpgradePath 
            featureName="redirection-url"
            position="right">
            <RedirectionUrl/>
          </UpgradePath>
          <Team/>
        </Card.Body>

      </Card>
    </>
  );
}

export default General;
