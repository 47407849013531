import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './event-type.css';
import Color from './Color';
import Up from './Up';
import Middle from './Middle';
import Down from './Down';

export class EventType_ extends Component {
  constructor(props){
    super(props);
    this.state = props.state;
  }

  render(){
    var color = '';
    (this.state.enabled ? color="var(--color-background-1)" : color="var(--color-disabled)");
    return (
      <>
        <div className="event-type-parent" style={{maxWidth:340}}>
        <Color color={this.state.color} enabled={this.state.enabled}/>
          <div  className="event-type-container" style={{  background : color}}>
            <Up id={this.props.id} name={this.state.name} isPaidEvent={this.state?.isPaidEvent} secret={this.props.state.secret} enabled={this.props.state.enabled}/>
            <Middle reason={this.state.reason} team={this?.state?.isTeam} enabled={this.state.enabled} duration={this.state.duration} secret={this.state.secret}/>
            <Down id={this.props.id} enabled={this.state.enabled} url={this.state.url}/>
          </div>
        </div>
      </>
    );
  }
}

export default EventType_;
