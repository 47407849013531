import Form from 'react-bootstrap/Form';
import {useCreateBookingStore} from '../../../../../store';

function Field({option,type,questionId,fieldId,required,mode}){
  const setRadioAnswer = useCreateBookingStore(state => state.setRadioAnswer);
  const updateRadioAnswer = useCreateBookingStore(state => state.updateRadioAnswer);
  const setCheckboxAnswer = useCreateBookingStore(state => state.setCheckboxAnswer);
  const updateCheckboxAnswer = useCreateBookingStore(state => state.updateCheckboxAnswer);
  const summary = useCreateBookingStore(state => state.summary);

  const handleRescheduleChange = (e,_fieldId,_type,_questionId,_value) => {
    if(_type === 'radio'){
      updateRadioAnswer(_fieldId, _questionId, _value);
    } else if (_type === 'checkbox') {
      const isChecked = e.target.checked
      updateCheckboxAnswer(_fieldId, isChecked, _questionId, _value)
    }
  }

  const handleChange = (e,_fieldId,_type,_questionId) => {
    if(_type === 'radio'){
      setRadioAnswer(_fieldId,_questionId)
    } else if (_type === 'checkbox') {
      const isChecked = e.target.checked
      setCheckboxAnswer(_fieldId,isChecked,_questionId)
    }
  }

  return(
    <>
      {mode === "reschedule"
        ?
          <Form.Check 
            checked={summary?.surveys[questionId]?.answerValue.includes(fieldId)}
            type={type}
            label={option}
            name={questionId}
            required={required}
            onChange={(e) => handleRescheduleChange(e,fieldId,type,questionId,option)}
          />
        :
          <Form.Check 
            type={type}
            label={option}
            name={questionId}
            required={required}
            onChange={(e) => handleChange(e,fieldId,type,questionId)}
          />
      }
    </>
  );
}

export default Field;
