import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './owner-info.css';

function OwnerWelcome({sentence}){
  const {t} = useTranslation(['publicEvents']);
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 300; // Define max length for the content
  if (sentence === undefined || sentence === null){sentence = ""}
  const sentenceShort = sentence.length > maxLength ? sentence.slice(0, maxLength) + '...' : sentence;

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return(
    <>
      <div className="bio-container">
        <span className={isExpanded ? 'expanded' : 'collapsed'}>
          {isExpanded ? sentence : sentenceShort}
        </span>
        {sentence.length > maxLength && (
          <b onClick={toggleText} className="toggle-text">
            {isExpanded ? t('showLess') : t('showMore')}
          </b>
        )}
      </div>

    </>
  );
}

export default OwnerWelcome;
