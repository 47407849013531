function ProfilePic({pic}){
  return(
    <>
      <img className="profile-pic" src={pic} alt="user-pic" width="60px" height="60px"></img>
      <img src="/app-assets/svg/zoom-logo.svg" className="zoom-logo" alt="Zoom" width="20px" height="20px"/>
    </>
  );
}

export default ProfilePic;

