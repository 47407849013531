import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import auth from '../Account/Auth';

class CopyLinkButton extends React.Component{
  state = { copied : false };

  // setta copied a true e dopo 3 sec di nuovo a false
  copied(){
    this.setState({copied:true});
    setTimeout(() => {this.setState({copied:false})}, 3000);
  }

  // so che renderizzare &nbsp; è una puntina ma bootstrap mi faceva perdere tempo,
  // devo ritornarci al prossimo refactoring
  render(){
    let radixUrl = window.location.hostname
    let proto = ''
    if (radixUrl === "localhost"){radixUrl += ':3000'; proto='http://';}
    if (radixUrl === "dev.calendbook.com"){proto='https://'}
    if (radixUrl === "www.calendbook.com"){proto='https://'}
    radixUrl = proto + radixUrl
    const fullUrl = `${radixUrl}${this.props.url}`
    return(
      <>
        <CopyToClipboard text={fullUrl} onCopy={() => {this.copied()}}>
          <button className="icon-button">
            {!this.state.copied
              ? <span><i className="fas fa-link"></i></span>
              : <span><i className="far fa-check-circle"></i></span>
            }
          </button>
        </CopyToClipboard>
      </>
    );
  }
}

export default CopyLinkButton;
