import React from 'react';
import { Form } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import {useCreateBookingStore} from '../../../../store';

function CustomMaskInput({ label, mask, maxLength, blocks, placeholder, onAccept, value }) {
  return (
    <Form.Group className="w-100 d-inline-flex">
      {/*<Form.Label>{label}</Form.Label>*/}
      <i className="fa-brands fa-telegram justify-content-center my-auto h1 ms-2 me-3"></i>
      <IMaskInput
        value={value}
        mask={mask}
        unmask={true}
        blocks={blocks}
        maxLength={maxLength}
        placeholder={placeholder}
        onAccept={onAccept}
        style={{ width: '100%', margin: '8px 0', padding: '6px 12px' }}
      />
    </Form.Group>
  );
}

function TelegramAnswer({questionId,required,mode}) {
  const setTextAnswer = useCreateBookingStore(state => state?.setTextAnswer);
  const updateTextAnswer = useCreateBookingStore(state => state.updateTextAnswer);
  const summary = useCreateBookingStore(state => state.summary);
  return (
    <>
      {mode === "reschedule"
        ?
          <CustomMaskInput
            value={summary?.surveys[questionId]?.answer[0] || null}
            label="Telegram User Link"
            required={required}
            mask={'{https://t.me/}username'}
            blocks={{
              username: {
                mask: /^[a-zA-Z0-9_]*$/,
              }
            }}
            maxLength={32}
            placeholder="Enter your Telegram username"
            onAccept={(tg) => updateTextAnswer(tg,questionId)}
          />
        :
          <CustomMaskInput
            label="Telegram User Link"
            required={required}
            mask={'{https://t.me/}username'}
            blocks={{
              username: {
                mask: /^[a-zA-Z0-9_]*$/,
              }
            }}
            maxLength={32}
            placeholder="Enter your Telegram username"
            onAccept={(tg) => setTextAnswer(tg,questionId)}
          />

      }
    </>
  );
}

export default TelegramAnswer;
