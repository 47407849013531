import TimeSlot from './TimeSlot';

function TimeSlotSkeleton({datetime,timeFormat}){
  return(
    <>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
      <TimeSlot dummy datetime={datetime} timeFormat={timeFormat}/>
    </>
  );
}

export default TimeSlotSkeleton;
