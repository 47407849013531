import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';

function ConnectSalesForza({setSalesForzaUser}){
  const {t} = useTranslation(['integrations']);
  const [email,setEmail] = useState('');
  const [loading,setLoading] = useState(false)
  const [status, setStatus] = useState(0);

  const responseFeedbackStyle = {
    fontSize:14
  }
  
  const handleChange = (event) => {
    setStatus(0);
    setEmail(event.target.value);
  }

  const isValidEmail = () => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,}){0,1}$/.test(email)
  }

  const connectSalesForza = async (e) => {
    if(!isValidEmail()){
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setLoading(true)
      setStatus(0);

      await auth.isAuthenticated()
        .then(async () => {
          await be.post('account-jubilant','/salesforza',{email: email},true,true)
            .then(response => {
              setStatus(200);
              console.info("[integrations][salesforza] connected email:",email);
              setSalesForzaUser({email})
            })
            .catch((e) => {
              setStatus(e?.response?.status)
              console.info("[integrations][salesforza] Error connecting email:",e);
            });
        })
        .catch((e) => {
          setStatus(e?.response?.status)
          console.info("[integrations][salesforza] Error authenticating user:",e);
        });
      setLoading(false)
    }
  }

  return(
    <div className="mt-4">

      <div className="mb-3">
        1. {t('salesforza.connect.extension')} <a href="https://chromewebstore.google.com/detail/salesforza-gmail-mail-mer/fnopnjoidnndbjeppcliehigoajogiba" target="_blank" rel="noopener noreferrer">link</a>
      </div>
      <div>2. {t('salesforza.connect.email')}</div>
      <Form noValidate validated={isValidEmail()} onSubmit={connectSalesForza}>
        <InputGroup hasValidation>
          <FormControl
            type="email"
            name="email"
            id="email"
            placeholder={auth.getEmail() || "email@domain.com"}
            aria-describedby="salesforza-email"
            value={email}
            onChange={handleChange}
            isInvalid={!isValidEmail()}
          />
          <Button disabled={loading} type='submit'>
            {loading 
              ? 
                <Spinner size="sm" animation="border" variant="light" /> 
                : 
                <i className="fas fa-long-arrow-alt-right"></i>
            }
          </Button>
          <Form.Control.Feedback type="invalid">
            <span>{t('salesforza.invalid')}</span>
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
      {(status >= 400 && status <= 500) &&
      <p className="text-danger" style={responseFeedbackStyle}>{t('salesforza.error')}</p>
      }
      {status === 200 &&
        <p className="text-success" style={responseFeedbackStyle}>{t('salesforza.success')}</p>
      }

      
    </div>
  );
}

export default ConnectSalesForza;

