import { useEffect, useState, useRef } from 'react';
import Heading from './Heading';
import ConnectZoom from './ConnectZoom';
import DisconnectZoom from './DisconnectZoom';
import UserInfo from './UserInfo/UserInfo';
import be from '../../BE';
import auth from '../../Account/Auth';

function Zoom() {
  // State to manage the Zoom user information
  const [ZoomUser, setZoomUser] = useState({});
  // State to manage the loading status
  const [loading, setLoading] = useState(false);
  // Ref to track whether the component is currently mounted
  const isMounted = useRef(true);

  useEffect(() => {
    // Mark the component as mounted when the effect runs
    isMounted.current = true;

    console.info("[Zoom] loading user info");

    // Function to check the connection status with Zoom
    const checkConnectionStatus = async () => {
      setLoading(true); // Start loading

      try {
        // Check if the user is authenticated
        const isAuthenticated = await auth.isAuthenticated();
        if (isAuthenticated && isMounted.current) {
          // Fetch the Zoom user profile if authenticated
          const response = await be.get('integrations', '/zoom/userProfile', false, true);
          if (response && isMounted.current) {
            setZoomUser(response); // Update the Zoom user state if component is still mounted
          }
        }
      } catch (e) {
        // Log any errors that occur during authentication or fetching user profile
        console.info("[integrations][Zoom] Error fetching user profile:", e);
      } finally {
        // Stop loading if the component is still mounted
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    // Invoke the connection status check
    checkConnectionStatus();

    // Cleanup function to mark the component as unmounted
    return () => {
      isMounted.current = false;
    };
  }, []); // Empty dependency array ensures this effect runs only once

  // Utility function to check if an object is non-empty
  const isConnected = (_obj) => {
    return Object.keys(_obj).length > 0;
  };

  return (
    <>
      {/* Render the heading */}
      <Heading />
      {/* Conditionally render content based on the loading state */}
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          {/* Conditionally render components based on whether the user is connected */}
          {isConnected(ZoomUser) && <UserInfo zoomUser={ZoomUser} />}
          {isConnected(ZoomUser) && (
            <DisconnectZoom zoomUser={ZoomUser} setZoomUser={setZoomUser} />
          )}
          {!isConnected(ZoomUser) && <ConnectZoom />}
        </>
      )}
    </>
  );
}

export default Zoom;
