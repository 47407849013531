import React, { useState, useEffect } from "react";
import Overview from "./Overview";
import Contacts from "./Contacts/Contacts";

function Leads() {
  const [showPlan, setShowPlan] = useState([]);

  useEffect(() => {
    document.title = "Calendbook - Leads";
  }, []);

  return (
    <>
      <Overview showPlan={showPlan} setShowPlan={setShowPlan} />
      <Contacts showPlan={showPlan} setShowPlan={setShowPlan} />
    </>
  );
}
export default Leads;
