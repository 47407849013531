import React, {useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Proration from './Proration';
import {useTranslation} from 'react-i18next';
import auth from '../../Account/Auth';
import be from '../../BE';
import './subscription-card.css';

function Subscription({ subscription, setSubscriptionsLoading, getSubscriptions }) {
  const {t,i18n} = useTranslation(['booking'])
  const [loadingSeatsDecreasePreview,setLoadingSeatsDecreasePreview] = useState(false)
  const [loadingSeatsIncreasePreview,setLoadingSeatsIncreasePreview] = useState(false)
  const [showProrationModal, setShowProrationModal] = useState(false);
  const [prorationDetails, setProrationDetails] = useState(null); // New state for proration details

  const user={
    profilePic : auth.getPic(),
    name : auth.getFullName(),
    email : auth.getEmail(),
  }

  const formatDate = (date) => {
    const getUserLanguage = () => {try{return i18n.language.substring(0,2)}catch(e){return 'en'}}
    // Date formatting options
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // Getting date object from db
    var dateFormatted = new Date(date);
    // Format: weekday, monthname daynumber, year
    dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
    // To uppercase string
    dateFormatted = dateFormatted.toString();

    return dateFormatted;
  }

  const areAllFieldsPresent = (obj) => {
    return Object.values(obj).every(value => value !== null && value !== undefined);
  }

  const handleProrationModalClose = async () => {
    setShowProrationModal(false);
    setProrationDetails(null);
    setSubscriptionsLoading(true);
    await auth.refresh();
    await getSubscriptions();
  };

  const seatsPreview = async (_priceId,_seats,_action) => {
    const seatsNumber = Number(_seats)
    if(seatsNumber){
      let previewSeatsNumber = seatsNumber
      if(_action === "increase"){ 
        setLoadingSeatsIncreasePreview(true)
        previewSeatsNumber += 1 
      }
      if(_action === "decrease"){
        setLoadingSeatsDecreasePreview(true)
        previewSeatsNumber -= 1
      }  
      let body = {
        priceId: _priceId,
        quantity: previewSeatsNumber
      }
      await auth.isAuthenticated()
        .then(
          await be.get("payment-jubilant","/subscriptions/preview",body,true,true)
          .then((response) => {
            setLoadingSeatsIncreasePreview(false)
            setLoadingSeatsDecreasePreview(false)
            const updateObj = {
              action: response?.action,
              amount: response?.amount,
              currency: response?.currency_code,
              priceId: _priceId,
              quantity: previewSeatsNumber
            }
            if(areAllFieldsPresent(updateObj)){
              setProrationDetails(updateObj);
              setShowProrationModal(true);
            }
            console.log(response);
          })
          .catch(error => {
            setLoadingSeatsIncreasePreview(false)
            setLoadingSeatsDecreasePreview(false)
            console.log(error);
          })
        )
        .catch(error => {
            setLoadingSeatsIncreasePreview(false)
            setLoadingSeatsDecreasePreview(false)
          console.log(error);
        })
    }

  }

  return (
    <div className="subscription-card">
      <div className="user-info">
        {user?.profilePic && <img src={user?.profilePic} alt="Profile" className="profile-pic"/>}
        <div>
          <div><strong>{user?.name}</strong></div>
          <div><small>{user?.email}</small></div>
        </div>
      </div>
      {subscription?.planName &&
        <div>
          <div><strong>Your Plan: </strong></div>
          <div>
            <span><small>{subscription?.planName}</small></span>
            {subscription?.amount && subscription?.currency && subscription?.billingCycle &&
              <div><small className="plan-billing-cycle">({Number(subscription?.amount)/100} {subscription?.currency} &times; {subscription?.billingCycle} &times; seat)</small></div>
            }
          </div>
        </div>
      }
      {subscription?.availableSeats &&
        <div>
          <div><strong>Seats: </strong></div>
          <div className="subscription-seats-info">
            {/* <button className="seats-button" onClick={() => seatsPreview(subscription?.priceId,subscription?.availableSeats,"decrease")} disabled={loadingSeatsDecreasePreview || loadingSeatsIncreasePreview || Number(subscription?.availableSeats) === 1}>{loadingSeatsDecreasePreview ? <Spinner/> : <>-</>}</button> */}
            <small>{subscription?.availableSeats}</small>
            {/* <button className="seats-button" onClick={() => seatsPreview(subscription?.priceId,subscription?.availableSeats,"increase")} disabled={loadingSeatsDecreasePreview || loadingSeatsIncreasePreview}>{loadingSeatsIncreasePreview ? <Spinner/> : <>+</>}</button> */}
          </div>
        </div>
      }
      {subscription?.nextPaymentDate &&
        <div>
          <div><strong>Next Payment Date: </strong></div>
          <div className="date"><small>{formatDate(subscription?.nextPaymentDate)}</small></div>
        </div>
      }
      {subscription?.scheduledCancelAt
        ?
          <div>
            <div><strong>Scheduled Cancellation:</strong></div>
            <div className="date"><small>{formatDate(subscription?.scheduledCancelAt)}</small></div>
          </div>
        :
        <div className="manage-subscription-container">
          {subscription?.cancelSubscriptionUrl &&
            <div>
              <a href={subscription?.cancelSubscriptionUrl} target="_blank" rel="noopener noreferrer" className="cancel-button">Cancel Subscription</a>
            </div>
          }
          {subscription?.updatePaymentMethodUrl &&
            <div>
              <a href={subscription?.updatePaymentMethodUrl} target="_blank" rel="noopener noreferrer" className="manage-button">Update Payment Method</a>
            </div>
          }
        </div>
      }
      <Proration
        show={showProrationModal}
        onHide={handleProrationModalClose}
        prorationDetails={prorationDetails}
      />
    </div>
  );
}

export default Subscription;
