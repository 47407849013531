import React from 'react';
import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import Form from 'react-bootstrap/Form';

function SalesForza (){
  const {t} = useTranslation(['eventCreator']);
  const notifications = useCreateEventTypeStore(state => state.notifications);
  const setSalesforza = useCreateEventTypeStore((state) => state.setSalesforza);

  return(
    <>
      <Form.Check
        type="checkbox"
        name="salesforza"
        label={t('advanced.salesForzaEmailNotificationsLabel')}
        checked={notifications?.notifications?.salesforza}
        onChange={(e) => setSalesforza(e.target.checked)}
      />
    </>
  );
}

export default SalesForza;
