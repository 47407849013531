import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Checkout from "./Checkout";
import auth from "../Account/Auth";
import be from "../BE";

function UpgradeModal({
  canUpgrade,
  teamId,
  showUpgrade,
  setShowUpgrade,
  getTeamStatus,
}) {
  const { t } = useTranslation(["team"]);
  const [upgradableMembers, setUpgradableMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [billingAmount, setBillingAmount] = useState(0);
  const [billingCurrency, setBillingCurrency] = useState("USD");
  const [loading, setLoading] = useState(false);
  const [loadingBilling, setLoadingBilling] = useState(false);
  const [loadingUpgrade, setLoadingUpgrade] = useState(false);
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);
  const [upgradeError, setUpgradeError] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    // Fetch the upgradable members from the backend
    const fetchUpgradableMembers = async () => {
      try {
        setLoading(true);
        await auth
          .isAuthenticated()
          .then(async () => {
            await be
              .get(
                "team-manager",
                "/team/members/to-upgrade",
                { teamId },
                true,
                true
              )
              .then((response) => {
                console.log("[team][upgrade-modal] got upgradable members");
                setUpgradableMembers(response);
                console.log(upgradableMembers);
                const initialSelectedMembers = response.map(
                  (member) => member.key
                );
                setSelectedMembers(initialSelectedMembers);
                if (!canUpgrade) {
                  fetchBillingAmount(initialSelectedMembers);
                }
              })
              .catch((error) => {
                console.error(
                  "[team][upgrade-modal] network error while fetching upgradable members:",
                  error
                );
              });
          })
          .catch((error) => {
            console.error(
              "[team][upgrade-modal] authentication error while fetching upgradable members:",
              error
            );
          });
      } catch (error) {
        console.error(
          `[team][upgrade-modal] functional error while fetching members to upgrade`
        );
      } finally {
        setLoading(false);
      }
    };
    if (showUpgrade) {
      fetchUpgradableMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUpgrade]);

  const fetchBillingAmount = async (selectedMembers) => {
    try {
      let body = {
        teamId: teamId,
        members: selectedMembers,
        operation: "upgrade",
      };
      setLoadingBilling(true);
      await auth
        .isAuthenticated()
        .then(async () => {
          await be
            .post("payment-jubilant", "/team/members/preview", body, true, true)
            .then((response) => {
              console.log("[team][upgrade-modal] got billing amount");
              setBillingAmount(response?.amount);
              setBillingCurrency(response?.currency_code);
            })
            .catch((error) => {
              console.error(
                "[team][upgrade-modal] network error while fetching billing amount:",
                error
              );
            });
        })
        .catch((error) => {
          console.error(
            "[team][upgrade-modal] authentication error while fetching billing amount:",
            error
          );
        });
    } catch (error) {
      console.error(
        `[team][upgrade-modal] functional error while fetching members to upgrade`
      );
    } finally {
      setLoadingBilling(false);
    }
  };

  const handleUpgrade = async () => {
    try {
      let body = {
        teamId: teamId,
        members: selectedMembers,
        operation: "upgrade",
      };
      setLoadingUpgrade(true);
      setUpgradeSuccess(false);
      setUpgradeError(false);
      await auth
        .isAuthenticated()
        .then(async () => {
          await be
            .post("payment-jubilant", "/team/members/upgrade", body, true, true)
            .then((response) => {
              console.log(
                "[team][upgrade-modal] upgraded selected members:",
                body?.members
              );
              setUpgradeSuccess(true);
              // Optionally, refresh the team status after successful upgrade
              getTeamStatus();
            })
            .catch((error) => {
              console.error(
                "[team][upgrade-modal] network error while upgrading members:",
                error
              );
              setUpgradeError(true);
            });
        })
        .catch((error) => {
          console.error(
            "[team][upgrade-modal] authentication error while upgrading members:",
            error
          );
          setUpgradeError(true);
        });
    } catch (error) {
      console.error(
        `[team][upgrade-modal] functional error while upgrading members`
      );
      setUpgradeError(true);
    } finally {
      setLoadingUpgrade(false);
    }
  };

  const handleCheckboxChange = (memberKey) => {
    const updatedSelectedMembers = selectedMembers.includes(memberKey)
      ? selectedMembers.filter((key) => key !== memberKey)
      : [...selectedMembers, memberKey];
    setSelectedMembers(updatedSelectedMembers);
    if (!canUpgrade) {
      fetchBillingAmount(updatedSelectedMembers);
    }
  };

  return (
    <>
      <button
        className="tw-py-2 tw-px-4 tw-text-sm tw-rounded tw-bg-black tw-text-white hover:tw-text-black tw-ease-in tw-font-semibold hover:tw-bg-white disabled:tw-text-gray-500 disabled:tw-bg-gray-700 tw-transition-colors tw-duration-300 tw-flex tw-items-center tw-justify-between"
        onClick={() => setShowUpgrade(!showUpgrade)}
      >
        {t("upgrade.btnTitle")}
      </button>

      {showUpgrade && (
        <div
          id="upgradeModal"
          tabIndex="-1"
          aria-hidden="true"
          className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-black/50"
        >
          <div className="tw-relative tw-p-4 tw-w-full tw-max-w-2xl tw-h-auto">
            {/* Modal content */}
            <div className="tw-relative tw-p-4 tw-bg-white tw-rounded-lg tw-shadow dark:tw-bg-gray-800 sm:tw-p-5">
              {/* Modal header */}
              <div className="tw-flex tw-justify-between tw-items-center tw-pb-4 tw-mb-4 tw-rounded-t tw-border-b sm:tw-mb-5 dark:tw-border-gray-600">
                <h3 className="tw-text-lg tw-font-semibold tw-text-gray-900 dark:tw-text-white">
                  {selectedMembers.length === 1 ? (
                    <>
                      {t("upgrade.header_one", {
                        count: selectedMembers.length,
                      })}
                    </>
                  ) : (
                    <>
                      {t("upgrade.header_other", {
                        count: selectedMembers.length,
                      })}
                    </>
                  )}
                </h3>
                <svg
                  onClick={() => setShowUpgrade(!showUpgrade)}
                  aria-hidden="true"
                  className="hover:tw-cursor-pointer tw-w-5 tw-h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              {/* Modal body */}
              <div className="tw-grid tw-gap-4 tw-mb-4 sm:tw-grid-cols-1">
                {!loading &&
                  upgradableMembers &&
                  upgradableMembers.length === 0 && (
                    <div>{t("upgrade.noUpgradableMembers")}</div>
                  )}
                {loading &&
                  upgradableMembers &&
                  upgradableMembers.length === 0 && (
                    <div className="tw-animate-pulse">
                      {t("upgrade.loadingUpgradable")}
                    </div>
                  )}
                {upgradableMembers &&
                  upgradableMembers.map((member) => (
                    <div
                      key={member.id}
                      className="tw-flex tw-items-center tw-gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={selectedMembers.includes(member.key)}
                        disabled={loading || loadingBilling}
                        onChange={() => handleCheckboxChange(member.key)}
                        className="tw-h-4 tw-w-4 tw-text-primary-600 tw-border-gray-300 tw-rounded focus:tw-ring-primary-500 disabled:tw-animate-pulse"
                      />
                      <img
                        width="38px"
                        height="38px"
                        src={member.pic}
                        alt="pic"
                        className="tw-text-center tw-items-center tw-rounded-full tw-border-2 tw-border-gray-300"
                      />
                      <div>
                        <label className="tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">
                          {member.name}{" "}
                          {member.username === auth.getUsername() && (
                            <span className="tw-text-xs tw-bg-blue-100 tw-text-blue-800 tw-font-medium tw-px-2 tw-py-0.5 tw-rounded">
                              {t("upgrade.you")}
                            </span>
                          )}
                        </label>
                        <div className="tw-text-xs tw-text-gray-500 dark:tw-text-gray-400">
                          {member.email}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {canUpgrade &&
                selectedMembers &&
                selectedMembers.length > 0 &&
                upgradableMembers &&
                upgradableMembers.length > 0 && (
                  <div className="tw-inline-flex tw-w-full tw-justify-between tw-items-center">
                    <div className="tw-content-center tw-text-lg tw-font-semibold tw-text-gray-900">
                      {selectedMembers.length === 1 ? (
                        <>
                          {t("upgrade.readyToUpgrade_one", {
                            count: selectedMembers.length,
                          })}
                        </>
                      ) : (
                        <>
                          {t("upgrade.readyToUpgrade_other", {
                            count: selectedMembers.length,
                          })}
                        </>
                      )}
                    </div>
                    <button
                      type="button"
                      disabled={showCheckout}
                      className="tw-text-white tw-bg-primary-700 hover:tw-bg-primary-800 tw-font-medium tw-rounded tw-text-sm tw-px-2.5 tw-py-2 tw-text-center disabled:tw-animate-pulse"
                      onClick={() => setShowCheckout(true)}
                    >
                      {loadingUpgrade ? (
                        <span>{t("upgrade.upgradeBtnLoading")}</span>
                      ) : (
                        <span>{t("upgrade.upgradeBtn")}</span>
                      )}
                    </button>
                  </div>
                )}
              {!canUpgrade &&
                selectedMembers &&
                selectedMembers.length > 0 &&
                upgradableMembers &&
                upgradableMembers.length > 0 && (
                  <>
                    {/* Billing amount */}
                    <div className="">
                      <span className="tw-text-lg tw-font-semibold tw-text-gray-900">
                        {loadingBilling ? (
                          <span className="tw-animate-pulse">
                            {t("upgrade.loadingBilling")}
                          </span>
                        ) : (
                          <div className="tw-inline-flex tw-w-full tw-justify-between tw-items-center">
                            <div className="tw-content-center">
                              {t("upgrade.billedNow", {
                                amount: billingAmount / 100,
                                currency: billingCurrency,
                              })}
                            </div>
                            <button
                              type="button"
                              disabled={loadingUpgrade}
                              className="tw-text-white tw-bg-primary-700 hover:tw-bg-primary-800 tw-font-medium tw-rounded tw-text-sm tw-px-2.5 tw-py-2 tw-text-center disabled:tw-animate-pulse"
                              onClick={handleUpgrade}
                            >
                              {loadingUpgrade ? (
                                <div className="tw-inline-flex">
                                  {t("upgrade.upgradeBtnLoading")}
                                  <div role="status" className="tw-ms-1">
                                    <svg
                                      aria-hidden="true"
                                      className="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-black"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              ) : (
                                <span>{t("upgrade.upgradeBtn")}</span>
                              )}
                            </button>
                          </div>
                        )}
                      </span>
                    </div>
                  </>
                )}
              {upgradeSuccess && (
                <div className="tw-text-green-500 tw-text-sm tw-text-end tw-mt-1">
                  {t("upgrade.success")}
                </div>
              )}
              {upgradeError && (
                <div className="tw-text-red-500 tw-text-sm tw-text-end tw-mt-1">
                  {t("upgrade.error")}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Checkout
        showCheckout={showCheckout}
        setShowCheckout={setShowCheckout}
        quantity={selectedMembers.length}
        getTeamStatus={getTeamStatus}
      />
    </>
  );
}

UpgradeModal.propTypes = {
  teamId: PropTypes.string.isRequired,
  canUpgrade: PropTypes.bool.isRequired,
  showUpgrade: PropTypes.bool.isRequired,
  setShowUpgrade: PropTypes.func.isRequired,
  getTeamStatus: PropTypes.func.isRequired,
};

export default UpgradeModal;
