import Accordion from 'react-bootstrap/Accordion';
import Header from './Header';
import Body from './Body/_Body';

function Question({id,index,state,existingQuestions,existingFields,editing}){
  const componentStyle = {
    background : 'var(--color-background-1)',
    border : '1px solid var(--color-border-light)'
  }

  return(
    <Accordion.Item key={`accordion-item-${id}`} eventKey={index} style={componentStyle}>
      <Header key={`accordion-header-${id}`} content={state?.content} type={state?.questionType} required={state?.required} order={state?.order}/>
      <Body key={`accordion-body-${id}`} id={id} content={state?.content} type={state?.questionType} required={state?.required} fields={state?.qstFields} order={state?.order} existingQuestions={existingQuestions} existingFields={existingFields} editing={editing}/>
    </Accordion.Item>
  );
}

export default Question;

