function FullName({fullName}){
  return(
    <>
      {fullName && <div className="full-name">{fullName}</div>}
    </>
  );
}

export default FullName;

