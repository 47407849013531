import {useState} from 'react';
import DisconnectStripe from './DisconnectStripe';
import DisconnectAlert from './DisconnectAlert';
import Accordion from 'react-bootstrap/Accordion';
import be from '../../../BE';
import auth from '../../../Account/Auth';

function DangerZone({stripeUser, setStripeUser}){
  const [showDisconnectAlert,setShowDisconnectAlert] = useState(false)
  const [disconnecting,setDisconnecting] = useState(false)

  const toggleShowDisconnectAlert= () => {
    setShowDisconnectAlert(!showDisconnectAlert);
  }
  
  const disconnectStripe = async () => {
    console.info("[integrations][stripe] disconnecting stripe user account")
    setDisconnecting(true)
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('stripe-connect','/connect/disconnect',false,true,true)
          .then(response => {
            setStripeUser({})
            console.info("[integrations][stripe] disconnected stripe user account")
          });
      });
    setDisconnecting(false)
  }

  return(
    <>
      <DisconnectAlert 
        showDisconnectAlert={showDisconnectAlert}
        setShowDisconnectAlert={setShowDisconnectAlert}
        toggleShowDisconnectAlert={toggleShowDisconnectAlert}
        disconnecting={disconnecting}
        disconnectStripe={disconnectStripe}
      />
      <Accordion className="mt-4">
        <Accordion.Item eventKey="0" style={{background:'var(--color-background-1'}}>
          <Accordion.Header>
            Danger Zone
          </Accordion.Header>
          <Accordion.Body>
            <DisconnectStripe
              stripeUser={stripeUser}
              setStripeUser={setStripeUser}
              toggleShowDisconnectAlert={toggleShowDisconnectAlert}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default DangerZone
