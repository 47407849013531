import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

function toHyperlink(text)
{
  //eslint-disable-next-line
  var pattern1 = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  var str1 = text.replace(pattern1, '<a target="_blank" rel="noopener noreferrer" href="$1">$1</a>');
  //eslint-disable-next-line
  var pattern2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
  var str2 = str1.replace(pattern2, '$1<a target="_blank" rel="noopener noreferrer" href="http://$2">$2</a>');

  return str2;
}

function Location (props){
  const {t} = useTranslation(['home']);
  const location = props.location;
  var locationValue = ''
  if(typeof(location) === 'string'){
    console.info("[upcomings][details] location type is 'string'...")
    locationValue = location
  }

  if(typeof(location) === 'object'){
    locationValue = location?.value
  }

  locationValue = DOMPurify.sanitize(locationValue)
  return (
    <div>
      {locationValue &&
        <>
          <i className="fas fa-map-marker-alt me-2"></i>
          <b>{t('upcomings.details.location')}</b>
          <div 
            dangerouslySetInnerHTML={{__html: toHyperlink(locationValue)}}
          />
          <br/>
        </>
      }
    </div>
  );
}

export default Location;
