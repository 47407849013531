import {useCreateEventTypeStore} from '../../../../../store';

function Delete({order,index,questionId,fieldId}){
  const deleteField = useCreateEventTypeStore((state) => state.deleteField);

  const componentStyle=  {
    backgroundColor : 'transparent',
    border : 'none',
    margin :'0 0 0 10px',
    padding : 0 
  }

  return(
    <button style={componentStyle} onClick={(e) => {e.preventDefault(); deleteField(questionId,fieldId)}}>
      <i className="fa-solid fa-trash-can" style={{color:'var(--color-text)'}}></i>
    </button>
  );
}

export default Delete;
