import Form from 'react-bootstrap/Form';
import {useTranslation} from 'react-i18next';

function PageChoice({setSelectedLink,publicAddress,username,eventTypes}){
  const {t} = useTranslation(['navbar'])
  const componentStyle = {
    backgroundColor: 'var(--color-background-1)',
    color: 'var(--color-text)',
    borderColor: 'var(--color-border-light)'
  }
  return(
    <p>
        <strong>{t('embed.pageChoice.title')}</strong>
        <Form.Select style={componentStyle} aria-label="visualized page in the embedding frame" onChange={(e) => setSelectedLink(e.target.value)}>
          {/*
            <>
              <option value={publicAddress}>
                {`${t('embed.pageChoice.selectPublic')} - (calendbook.com/${username})`}
              </option>
              {eventTypes && eventTypes.length > 0 &&
              <option disabled>{t('embed.pageChoice.selectBooking')}</option>
              }
            </>
          */}

          {Object.keys(eventTypes).length !== 0 && eventTypes.map((event) => 
            <option key={event} value={publicAddress + '/' + event}>
              {`calendbook.com/${username}/${event}`}
            </option>
          )}
        </Form.Select>
      </p>
  );
}

export default PageChoice;
