import React from 'react';
import { useTranslation } from 'react-i18next';
import {Nav} from 'react-bootstrap';

function SubscriptionTab(){
  const { t } = useTranslation(['settings']);
  return(
    <Nav.Item>
      <Nav.Link eventKey="agency" style={{fontSize:14}} className="text-center text-md-start">
        <i style={{width:20}} className="fa-solid fa-user-tie text-center me-2"></i>Agency
      </Nav.Link>
    </Nav.Item>
  );
}

export default SubscriptionTab;
