import DayGroup from './DayGroup';
import i18n from '../../i18n';

const componentStyle = {
  background: 'var(--color-background-1)',
  borderRadius:15,
  padding:20,
  marginTop:10,
  marginBottom:10
}

const headerStyle = {
  fontWeight:'bold',
  color:'var(--color-primary)', 
  textTransform:'capitalize',
}

const rulerStyle = {
  borderColor:'var(--color-ruler)',
  marginTop:10,
  marginBottom:0
}

function getUserLanguage(){
  return i18n.language.substring(0,2);
}

function getMonthName(month){
  let userLanguage = getUserLanguage();
  let formattingOptions = {month:'long'};
  let tmpDate = new Date();
  tmpDate.setMonth(month - 1);
  let monthString = tmpDate.toLocaleString(userLanguage,formattingOptions);
  return monthString;
}

function MonthGroup(props){
  const year = props.year;
  const month = props.month;
  const monthName = getMonthName(month);
  const monthDates = props.monthDates;
  const monthDatesArray = Object.keys(monthDates).sort();

  return(
    <div style={componentStyle}>
      <div style={headerStyle}>
        {monthName} {year}
      </div>
      <div>
        {
          monthDatesArray.map((day,index) => (
            <div key={`day-container-${year}-${month}-${day}`}>
              <DayGroup
                key={day}
                year={year}
                month={month}
                day={day}
                daySchedules={monthDates[day]}
                pending={props?.pending}
              />
              {index !== monthDatesArray.length - 1 &&
                <hr style={rulerStyle}/>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default MonthGroup;
