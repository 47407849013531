import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './account.css';

function Account({ accountId, name, email, profilePic, isActive, switchingToAccount, switchToAccount, deletingAccount, deleteAccount }) {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const handleDeleteClick = () => {
    if (isConfirmingDelete) {
      deleteAccount(accountId);
    } else {
      setIsConfirmingDelete(true);
      setTimeout(() => setIsConfirmingDelete(false), 3000); // Reset after 3 seconds
    }
  };

  return (
    <div className={`account ${isActive ? 'active' : ''}`}>
      <img src={profilePic || '/app-assets/img/standard-user-pic.png'} alt={name} className="account-image" />
      <div className="account-info">
        <h3>{name}</h3>
        <p>{email}</p>
      </div>
      {(isActive && !switchingToAccount) ? null : (
        <button onClick={() => switchToAccount(accountId)} className="account-button" disabled={switchingToAccount}>
          {switchingToAccount && <Spinner size="sm"/>} Switch To
        </button>
      )}
      <button onClick={handleDeleteClick} className={`account-button danger ${isConfirmingDelete ? 'confirming' : ''}`} disabled={deletingAccount === accountId}>
        {deletingAccount === accountId && <Spinner size="sm"/>}
        {isConfirmingDelete ? ' Click Again to Confirm' : 'Delete'}
      </button>
    </div>
  );
}

export default Account;
