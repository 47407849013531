import {useTranslation} from 'react-i18next';
import {useCreateBookingStore} from '../../../store';
import Question from './Question/_Question';

function Surveys({mode}){
  const {t} = useTranslation(['booking']);
  const storeSummary = useCreateBookingStore(state => state.summary);


  const hasQuestions = storeSummary?.surveys !== null  && storeSummary?.surveys !== undefined && Object.keys(storeSummary?.surveys).length !== 0

  return(
    <>
      {hasQuestions &&
        <>
          <div className="h4">{t('final.questions')}</div>
          {
            Object.keys(storeSummary?.surveys).map((key) => (
              <Question key={`question-${key}`} questionState={storeSummary?.surveys[key]} questionId={key} mode={mode}/>
            ))
          }
        </>
      }
    </>
  );
}

export default Surveys;
