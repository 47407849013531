import {useTranslation} from 'react-i18next';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function DisconnectAlert({showDisconnectAlert,toggleShowDisconnectAlert,disconnectStripe,disconnecting}){
  const {t} = useTranslation(['integrations']);
  return(
    <Toast style={{background:'var(--color-background-1'}} className="mt-3 w-100" show={showDisconnectAlert} onClose={toggleShowDisconnectAlert}>
        <Toast.Header style={{background:'var(--color-background-2'}}>
          <div className="d-inline me-auto"><strong className="text-danger">{t('stripe.dangerZone.disconnect.alert.dangerousOperation')} </strong> <span style={{color:'var(--color-secondary)'}} className="me-0 ms-sm-2">{t('stripe.dangerZone.disconnect.alert.operation')}</span></div>
        </Toast.Header>
        <Toast.Body>
          <div>{t('stripe.dangerZone.disconnect.alert.question')}</div>
          <ul>
            <li><small>{t('stripe.dangerZone.disconnect.alert.consequences.etype')}</small></li>
            <li><small>{t('stripe.dangerZone.disconnect.alert.consequences.refund')}</small></li>
          </ul>
          <div className="mt-2 d-flex">
            <Button 
              variant="outline-secondary"
              className="flex-grow-1 me-1"
              size="sm"
              onClick={toggleShowDisconnectAlert}>
                {t('stripe.dangerZone.disconnect.alert.buttons.cancel')}
            </Button>

            <Button
              disabled={disconnecting}
              variant="outline-danger"
              className="flex-grow-1 ms-1"
              size="sm"
              onClick={disconnectStripe}>
              <span>{t('stripe.dangerZone.disconnect.alert.buttons.confirm')}</span>
              <span className="vertical-align-middle">
                {disconnecting && 
                <Spinner
                  animation="border"
                  size="sm"
                  className="ms-2"/>
              }
              </span>
            </Button>
          </div>
        </Toast.Body>
      </Toast>

  );
}

export default DisconnectAlert;
