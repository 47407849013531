import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from './locales/en/translation.json';
import IT from './locales/it/translation.json';
import ES from './locales/es/translation.json';
import DE from './locales/de/translation.json';
import FR from './locales/fr/translation.json';
import auth from './Account/Auth';

const resources = {
  en: EN,
  it: IT,
  es: ES,
  de: DE,
  fr: FR
}

const detectionOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage'],
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    detection : detectionOptions,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

// set current language loading from user profileObj
// (available only for logged users)
try{i18n.changeLanguage(auth.getLanguage())}catch{}
export default i18n;
