function Heading(){
  return(
    <div>
      <div className="h3 my-3" style={{fontFamily:"Roboto",fontWeight:600}}>Calendbook & Zoom</div>
      <div style={{fontFamily:"Roboto",color:'var(--color-secondary)'}}>Connect Calendbook to your Zoom account to create video conference links for your bookings.</div>
    </div>
  );
}

export default Heading;
