import ProfilePic from './ProfilePic';
import FullName from './FullName';
import Email from './Email';
import './user-info.css';

function UserInfo({zoomUser}){
  return(
    <>
      {zoomUser &&
        <div className="mt-4">
          <div className="user-info">
            <ProfilePic pic={zoomUser.picUrl}/>
            <div className="user-details">
              <FullName fullName={zoomUser.displayName}/>
              <Email email={zoomUser.email}/>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default UserInfo;

