import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

function Duration({location}){

  const componentStyle={
    color:'var(--color-text)',
    backgroundColor:'var(--color-background-2)',
    overflow:'hidden',
    maxWidth:'60ch',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  }

  return(
    <>
      <p className="event-type-label my-2" style={componentStyle}>
        <i className="fas fa-map-marker-alt me-2"></i>
        {truncate(location,30)}
      </p>
    </>
  );
}

export default Duration;
