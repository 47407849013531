import './time-picker.css';
import { useCreateBookingStore } from '../../store';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import TimeSlot from './TimeSlot';
import TimeSlotSkeleton from './TimeSlotSkeleton';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import be from '../../BE';
import spacetime from 'spacetime';
import { isIframe } from '../../Utilities/iframeUtils';

function TimePicker({ username, eventType, cancelling, setBookingStep, stylingParams }) {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const storeGuest = useCreateBookingStore(state => state.guest);
  const setGuestTime = useCreateBookingStore(state => state.setGuestTime);
  const setGuestTimeFormat = useCreateBookingStore(state => state.setGuestTimeFormat);
  const setTimeSlots = useCreateBookingStore(state => state.setTimeSlots);
  const setGuestSatellite = useCreateBookingStore(state => state.setGuestSatellite);
  const storeTimeSlots = useCreateBookingStore(state => state.timeSlots);
  const { t } = useTranslation(['booking']);

  useEffect(() => {
    // styling params from url
    const backgroundStyle = stylingParams["timeslot-bg"];
    const textColorStyle = stylingParams["timeslot-text-color"];
    const textColorActiveStyle = stylingParams["timeslot-active-text-color"];

    // --- STYLING OPTIONS ---
    // valid only when the page is in Iframe
    if(isIframe()){
      // Create a new style element
      const style = document.createElement('style');
      style.type = 'text/css';

      // Start building the CSS string to inject
      let styleString = "\n/*--- TimeSlot Calendar Iframe Custom Colors ---*/\n";

      // Time Format
      styleString += `
        .time-format button{
          background: ${backgroundStyle};
        }
      `

      styleString += `
        .h12, .h24, .time-format > .step-back {
          color: ${textColorStyle};
        }
      `
      styleString += `
        .time-format-selected {
          color: ${textColorActiveStyle};
          border-color: ${textColorActiveStyle}
        }
      `
      styleString += `
        .time-slot, .time-slot.dummy {
          background: ${backgroundStyle};
          color: ${textColorStyle}
        }
      `
      styleString += `
        .time-slot:hover {
          color: ${textColorActiveStyle};
          border-color: ${textColorActiveStyle}
        }
      `
      // Set the generated CSS as the innerHTML of the style element
      style.innerHTML = styleString;

      // Append the style element to the head of the document
      document.head.appendChild(style);
    }

    const getTimeSlots = async (_username, _eventType, _timezone, _day) => {
      if (_username && _eventType && _timezone && _day) {
        const body = {
          ownerUsername: _username,
          eventKey: _eventType,
          timezone: _timezone,
          bookingDate: _day
        };
        setLoadingInfo(true);
        try {
          const response = await be.get('jubilant-booking', '/booking/slots', body, false, true);
          console.info('[booking][getTimeSlots] Success:', response);
          setTimeSlots(response);
          setInfoResponseStatus(200);
        } catch (error) {
          console.error('[booking][getTimeSlots] Error:', error);
          setInfoResponseStatus(error?.response?.status);
          toast.error(`Error Fetching Time Slots Availabilities`)
        } finally {
          setLoadingInfo(false);
        }
      }
    };

    const timezoneToSend = spacetime.now().goto(storeGuest?.timezone?.value).timezone().name;
    if (!cancelling) { getTimeSlots(username, eventType, timezoneToSend, storeGuest?.day); }
  }, [username, eventType, storeGuest?.day, storeGuest?.timezone, cancelling]);

  const TimeFormatButtons = () => (
    <div className="time-format">
      {isMobile && (
        <button className="step-back" onClick={() => setBookingStep(2)}>
          <i className="fa-solid fa-arrow-left-long"></i>
        </button>
      )}
      <button className={storeGuest?.timeFormat === "12h" ? "h12 time-format-selected" : "h12"} onClick={() => setGuestTimeFormat("12h")}>12h</button>
      <button className={storeGuest?.timeFormat === "24h" ? "h24 time-format-selected" : "h24"} onClick={() => setGuestTimeFormat("24h")}>24h</button>
    </div>
  );

  const TimeSlotDisplay = () => (
    <div className="time-picker">
      {loadingInfo
        ? <TimeSlotSkeleton datetime='2000-01-01:00:00+00:00' timeFormat={storeGuest?.timeFormat}/>
        : storeTimeSlots?.slotList && Object.keys(storeTimeSlots?.slotList).length !== 0
          ? Object.keys(storeTimeSlots?.slotList).map((datetime) => (
              <TimeSlot
                key={datetime}
                datetime={datetime}
                timeFormat={storeGuest?.timeFormat}
                callback={() => {
                  setGuestTime(datetime);
                  setGuestSatellite(storeTimeSlots?.slotList[datetime]);
                  setBookingStep(4);
                }}
              />
            ))
          : <center> {t('time.empty')} </center>
      }
    </div>
  );

  return (
    <div>
      <TimeFormatButtons />
      <TimeSlotDisplay />
    </div>
  );
}

export default TimePicker;
