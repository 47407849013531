import React, { useState, useEffect, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import UserContext from '../../UserContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './invite-control.css';
import auth from '../../Account/Auth';
import be from '../../BE';
import ReactGA from "react-ga4";

function InviteControl ({ inviteId }) {
  ReactGA.send({ hitType: "pageview", page: "/team/invite", title: "Team Invite" });
  const [user,setUser] = useContext(UserContext);
  const [teamInvite, setTeamInvite] = useState({});
  const [loading, setLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [inviteResponse,setInviteResponse] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  const mockResponse={
    teamName: "giovandimeo",
    totalMembers: "1",
    owner: {
        key: "giovandimeo",
        username: "giovandimeo",
        name: "giovandimeo",
        email: "giovandimeo@gmail.com",
        profile_pic: "https://jubilant-users-profile.s3.eu-central-1.amazonaws.com/assets/profile-pic/standard-user-pic.png",
        role: "owner",
        status: "connected"
    }
}

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await be.get('team-manager',"/team/invitee",{id:inviteId},false,true)
          .then((response) => {
            setTeamInvite(response);
          })
          .catch((error) => {
            console.log(error);
            setError(error?.message);
            setTeamInvite(mockResponse);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
      setLoading(false);
    }
    fetchData()
  },[])

  const handleResponse = async (accept) => {
    if(accept === true){setAcceptLoading(true);}
    else{setDeclineLoading(true);}

    const body = {
      id: inviteId,
      accept: accept,
    };

    try {
      await auth.isAuthenticated()
        .then(async () => {
          await be.post('team-manager',"/team/invitee/response",body,true,true,true)
            .then(async (response) => {
              setInviteResponse(response?.data?.accepted);
              await new Promise(resolve => setTimeout(resolve, 1500));
              history.push({pathname:'/team'})
            })
            .catch((error) => {
              console.log(error);
              setError(error?.response?.data?.status);
            });
        })
        .catch(() => history.push({pathname:'/login'}))
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }  

    if(accept === true){setAcceptLoading(false);}
    else{setDeclineLoading(false);}
  };

  if (loading) return <p><center>Loading...</center></p>;
  //if (error) return <p><center>Error: {error}</center></p>;

  return (
    <Modal
      show
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <div>
            <img src={teamInvite?.owner?.profilePic || '/app-assets/img/standard-user-pic.png'} alt={teamInvite?.teamName} className="team-image" />
            <span className="team-name">{teamInvite?.teamName}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <strong>{teamInvite?.owner?.name}</strong>, from <strong>{teamInvite?.teamName}</strong> team, invited you to join his team.
          <br />
          <i>If you're part of a different team, accepting this invite will move you from your existing team to join this one.</i>
        </div>

      </Modal.Body>
      {user.logged
        ?
      <Modal.Footer>
        {inviteResponse !== null
          ?
            <>
              {inviteResponse === true && <div>Invite Accepted! Returning to Homepage...</div>}
              {inviteResponse === false && <div>Invite Declined! Returning to Homepage...</div>}
            </>
          :
            <>
              <Button variant="danger" onClick={() => handleResponse(false)}>
                {declineLoading && <Spinner size="sm"/>} Decline
              </Button>
              <Button variant="primary" onClick={() => handleResponse(true)}>
                {acceptLoading && <Spinner size="sm"/>} Accept
              </Button>
            </>
        }
      </Modal.Footer>
      :
      <Modal.Footer className="d-block" style={{color:'var(--color-error)'}}>To accept or decline this invite, you need to <a href="/login">log in.</a> Once you are logged in, come back to this link again.</Modal.Footer>
      }
    </Modal>
  );
};

export default InviteControl;

