import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../../../store';

function Delete({order,id}){
  const {t} = useTranslation(['eventCreator']);
  const deleteQuestion = useCreateEventTypeStore((state) => state.deleteQuestion);

  const componentStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'var(--bs-red)',
    padding: 0,
    margin: '25px 0 0 0'
  }

  return(
    <button style={componentStyle} onClick={(e) => {e.preventDefault(); deleteQuestion(order,id)}}>
      <i className="fa-solid fa-trash-can me-2"></i>
      {t('surveys.deleteQuestion')}
    </button>
  );
}

export default Delete;
