import {useEffect,useState} from 'react';
import {useCreateEventTypeStore} from '../../../store';
import {useTranslation} from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const determineMinQuantity = (_unit) => {
  switch (_unit) {
    case 'day':
      return '0';
    case 'week':
      return '0';
    case 'month':
      return '0';
    default:
      return '0';
  }
}
const determineMaxQuantity = (_unit) => {
  switch (_unit) {
    case 'day':
      return '100';
    case 'week':
      return '200';
    case 'month':
      return '500';
    default:
      return '0';
  }
}

const getUnit = () => { return document.getElementById(`booking-limit-unit`)?.value }


function Notice(){
  const {t} = useTranslation(['eventCreator']);
  const [minQuantity,setMinQuantity] = useState('0');
  const [maxQuantity,setMaxQuantity] = useState('0');
  const availability = useCreateEventTypeStore(state => state.availability);
  const setBookingLimitQuantity = useCreateEventTypeStore((state) => state.setBookingLimitQuantity);
  const setBookingLimitUnit = useCreateEventTypeStore((state) => state.setBookingLimitUnit);

  useEffect(()=>{
    // setMinQuantity(determineMinQuantity(getUnit()));
    setMaxQuantity(determineMaxQuantity(getUnit()));
  },[])

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const valueToSet = inputValue === '' ? minQuantity : inputValue;
    setBookingLimitQuantity(valueToSet);
    setBookingLimitUnit(document.getElementById("booking-limit-unit").value);
  };

  const disableBookingLimit = () => {
    setBookingLimitQuantity('0');
    setBookingLimitUnit('day');
  };

    return(
        <Form.Group as={Row} controlId="event-booking-limit" className="mt-3 mx-2">
            <Form.Label column sm={2} className="mt-auto">{t('availability.bookingLimit.title')}</Form.Label>
            <Col sm={10} lg={6}>
                <Row>
                    <Row><small style={{color:'var(--color-secondary)'}}>{t('availability.bookingLimit.hint')}</small></Row>
                    <Col xs={5}>
                        <Form.Control
                          id="booking-limit-quantity"
                          className="dark-field text-center"
                          name="bookingLimit-value"
                          type="number"
                          min={minQuantity}
                          max={maxQuantity}
                          value={availability?.bookingLimit?.quantity || 0}
                          onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={2} className="d-flex justify-content-center my-auto" style={{color:'var(--color-secondary)'}}>{t('availability.bookingLimit.per')}</Col>
                    <Col xs={4} className="d-flex align-items-center">
                        <Form.Select
                          id="booking-limit-unit"
                          className="dark-field text-center"
                          aria-label="Booking Limit Unit"
                          value={availability?.bookingLimit?.unit || "day"}
                          onChange={(e) => {
                            setBookingLimitUnit(e.target.value);
                            setBookingLimitQuantity(document.getElementById("booking-limit-quantity").value)
                            // setMinQuantity(determineMinQuantity(e.target.value));
                            setMaxQuantity(determineMaxQuantity(e.target.value));
                          }}>
                            <option value="day">{t('availability.bookingLimit.units.day')}</option>
                            <option value="week">{t('availability.bookingLimit.units.week')}</option>
                            <option value="month">{t('availability.bookingLimit.units.month')}</option>
                        </Form.Select>
                        <Button
                          style={{ border: 'none', background: 'transparent', color: 'var(--color-text)' }}
                          onClick={disableBookingLimit}
                        >
                          <i className="far fa-trash-alt my-auto"></i>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Form.Group>
);
}

export default Notice;
