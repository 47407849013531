import { useTranslation } from 'react-i18next';

function Guest(props){
  const {t} = useTranslation(['home']);
  const guestName = props.guestName;
  const guestMail = props.guestMail;
  return (
    <div>
      <i className="fas fa-user me-2"></i> 
      <b>{t('upcomings.details.guests')}</b>
      <div>
        {guestName}
      </div>
      <div>
        {guestMail}
      </div>
      <br/>
    </div>
  );
}

export default Guest;
