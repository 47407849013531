import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import auth from '../Account/Auth';


function UserName(){

  return(
    <>
      <span>
        <b>
          {auth.getFullName()}
        </b>
      </span>
    </>
  );
}

export default UserName;
