import InviteControl from './InviteControl';

const InvitePage = () => {
  try{window.tidioChatApi.show()}catch{}
  const queryParams = new URLSearchParams(window.location.search);
  const inviteId = queryParams.get('id');

  return (
    <div>
      <InviteControl inviteId={inviteId} />
    </div>
  );
};

export default InvitePage;

