import {useCreateBookingStore} from '../../store';
import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';
import './calendbook-calendar.css';
import './timezone-picker.css';
import Calendar from 'react-calendar';
import spacetime from 'spacetime'
import i18n from '../../i18n';
import { isIframe } from '../../Utilities/iframeUtils';

function DatePicker({username,eventType,mode,setBookingStep,stylingParams}){
  const [loadingInfo,setLoadingInfo] = useState(false);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [selectedMonth,setSelectedMonth] = useState(null);
  const storeCalendar = useCreateBookingStore(state => state.calendar);
  const setCalendar = useCreateBookingStore(state => state.setCalendar);
  const storeGuest = useCreateBookingStore(state => state.guest);
  const setGuestTimezone = useCreateBookingStore(state => state.setGuestTimezone);
  const setGuestDay = useCreateBookingStore(state => state.setGuestDay);

  useEffect(() => {
    // styling params from url
    const textColorDateStyle = stylingParams["calendar-date-text-color"];
    const backgroundNavigationStyle = stylingParams["calendar-navigation-bg"];
    const textColorNavigationStyle = stylingParams["calendar-navigation-text-color"];
    const textColorWeekdaysStyle = stylingParams["calendar-weekdays-text-color"];
    const backgroundDayStyle = stylingParams["calendar-day-bg"];
    const textColorDayStyle = stylingParams["calendar-day-text-color"];
    const borderDayStyle = stylingParams["calendar-day-border"];
    const backgroundActiveDayStyle = stylingParams["calendar-active-day-bg"];
    const textColorActiveDayStyle = stylingParams["calendar-active-day-text-color"];
    const borderActiveDayStyle = stylingParams["calendar-active-day-border"];

    // --- STYLING OPTIONS ---
    // valid only when the page is in Iframe
    if(isIframe()){
      // Create a new style element
      const style = document.createElement('style');
      style.type = 'text/css';

      // Start building the CSS string to inject
      let styleString = "\n/*--- Booking Calendar Iframe Custom Colors ---*/\n";

      // Apply styles to the calendar date label
      if (textColorDateStyle) {
        styleString += `
        .react-calendar__navigation__label__labelText {
          color: ${textColorDateStyle};
        }
      `;
      }

      // Apply background color to the navigation buttons (prev and next)
      if (backgroundNavigationStyle) {
        styleString += `
        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__next-button {
          background-color: ${backgroundNavigationStyle};
        }
      `;
      }

      // Apply text color to the navigation buttons (prev and next)
      if (textColorNavigationStyle) {
        styleString += `
        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__next-button {
          color: ${textColorNavigationStyle};
        }
      `;
      }

      // Apply text color to weekdays labels
      if (textColorWeekdaysStyle) {
        styleString += `
        .react-calendar__month-view__weekdays {
          color: ${textColorWeekdaysStyle};
        }
      `;
      }

      // Apply styles for the calendar days (standard, hover, and active states)
      if (backgroundDayStyle || textColorDayStyle || borderDayStyle || backgroundActiveDayStyle || textColorActiveDayStyle || borderActiveDayStyle) {
        styleString += `
        /* Standard state for abbr inside react-calendar__tile */
        .react-calendar__tile abbr {
      `;
        if (backgroundDayStyle) { styleString += `background-color: ${backgroundDayStyle};\n`; }
        if (textColorDayStyle) { styleString += `color: ${textColorDayStyle};\n`; }
        if (borderDayStyle) { styleString += `border-color: ${borderDayStyle};\n`; }
        styleString += `}\n`;

        // Add hover and focus styles
        styleString += `
        /* Hover and Focus states */
        .react-calendar__tile:enabled:hover abbr,
        .react-calendar__tile:enabled:focus abbr {
      `;
        if (backgroundActiveDayStyle) { styleString += `background-color: ${backgroundActiveDayStyle};\n`; }
        if (textColorActiveDayStyle) { styleString += `color: ${textColorActiveDayStyle};\n`; }
        if (borderActiveDayStyle) { styleString += `border-color: ${borderActiveDayStyle} !important;\n`; }
        styleString += `}\n`;

        // Add active styles
        styleString += `
        /* Active state */
        .react-calendar__tile--active abbr {
      `;
        if (backgroundActiveDayStyle) { styleString += `background-color: ${backgroundActiveDayStyle};\n`; }
        if (textColorActiveDayStyle) { styleString += `color: ${textColorActiveDayStyle} !important;\n`; }
        if (borderActiveDayStyle) { styleString += `border-color: ${borderActiveDayStyle} !important;\n`; }
        styleString += `}\n`;
      }

      // Set the generated CSS as the innerHTML of the style element
      style.innerHTML = styleString;

      // Append the style element to the head of the document
      document.head.appendChild(style);
    }


    const getCalendarInfo = (_username,_eventType,_timezone) => {
      if(_username && _eventType && _timezone){
        const body = {
          ownerUsername: _username,
          eventKey: _eventType,
          timezone: _timezone
        }
        setLoadingInfo(true);
        be.get('jubilant-booking','/booking/calendar',body,false,true)
          .then(response => {
            console.info('[booking][getCalendarInfo] Success:');
            console.log(response);
            setInfoResponseStatus(200);
            setLoadingInfo(false);
            setCalendar(response);
            setGuestDay(spacetime(response?.availability.firstAvailableDate).format('iso.short'));
          })
          .catch(error => {
            console.info('[booking][getCalendarInfo] Error:');
            console.error(error);
            setInfoResponseStatus(error?.response?.status);
            setLoadingInfo(false);
            toast.error(`Error Fetching Calendar Availabilities`)
          })
      }else{
        console.info("[booking][getCalendarInfo] Data to send incomplete:")
        console.log("username: ",_username);
        console.log("event type: ",_eventType);
        console.log("timezone: ",_timezone);
      }
    }
    const timezoneToSend = spacetime.now().goto(storeGuest?.timezone?.value).timezone().name;
    if(mode !== "delete"){
      getCalendarInfo(username,eventType,timezoneToSend)
    } 
  },[setCalendar,setGuestTimezone,setGuestDay,username,eventType,storeGuest?.timezone?.value,mode,selectedMonth])

  const getUserLanguage = () => {
    return i18n.language.substring(0,2);
  }
  const getSelectedDate = (_date,_firstAvailableDate) => {
    if(_date && new Date(_date)){
      return new Date(_date);
    }else{
      return getMinDate(_firstAvailableDate);
    }
  }
  const getMinDate = (_firstAvailableDate) => {
    if(_firstAvailableDate && new Date (_firstAvailableDate)){
      return new Date(_firstAvailableDate);
    }else{
      return new Date();
    }
  }
  const getMaxDate = (_lastAvailableDate) => {
    if(_lastAvailableDate && new Date(_lastAvailableDate)){
      return new Date(_lastAvailableDate);
    }else{
      return null;
    }
  }
  const getDisabledDates = (_date,_week,_disabled) => {
    if(_disabled && _disabled?.months && _disabled?.months.length !== 0){
      if (_disabled?.months.some(month => spacetime(_date).format('iso-month') === month)){
        return true;
      }
    }

    if(_week !== undefined && _week !== null && Object.keys(_week).length !== 0){
      if (_week[spacetime(_date).format('day-short').toLowerCase()] === false){
        return true;
      }
    }
    if(_disabled && _disabled?.days && _disabled?.dayslength !== 0){
      if (_disabled?.days.some(day => spacetime(_date).format('iso-short') === day)){
        return true;
      }
    }
  }

  const firstAvailableDate = storeCalendar?.availability?.firstAvailableDate
  const lastAvailableDate = storeCalendar?.availability?.lastAvailableDate
  const weeklyAvailability = storeCalendar?.availability?.weekly?.weekDays
  const disabledDates = storeCalendar?.availability?.disabledDates

  return(
    <>
      {loadingInfo 
        ?
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Spinner />
            </div>
            <Calendar
              tileDisabled={() => true}
              locale={getUserLanguage()}
              prev2Label={null}
              next2Label={null}
              minDetail="month"
            />
          </div>
          :
          <>
            {Object.keys(storeCalendar).length !== 0 &&
            <div className="d-inline ">
              <Calendar
                minDate={getMinDate(firstAvailableDate)}
                maxDate={getMaxDate(lastAvailableDate)}
                value={getSelectedDate(storeGuest?.day,firstAvailableDate)}
                onClickDay={(date) => {setGuestDay(spacetime(date).format('iso-short'));setBookingStep(3);}}
                onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                  if(view === 'month'){
                    //setSelectedMonth(spacetime(activeStartDate).format('month'))
                    //setGuestDay(spacetime(activeStartDate).format('iso-short'))
                    console.log('Changed view to: ', activeStartDate, view)
                  }
                }}
                tileDisabled={({date, view}) => view === 'month' && getDisabledDates(date,weeklyAvailability,disabledDates)}
                locale={getUserLanguage()}
                prev2Label={null}
                next2Label={null}
                minDetail="month"
              />
            </div>
            }
          </>
      }
    </>
  );
}

export default DatePicker;
