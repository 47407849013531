import { useTranslation } from 'react-i18next';

function EventDuration({duration}){
  const { t } = useTranslation(['booking']);

  return(
    <>
      <div className="fw-bold">{t('details.duration')}</div>
      <div style={{color:'var(--color-secondary)'}}>{duration} min</div>
    </>
  );
}

export default EventDuration;
