import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RatingStars from './RatingStars';
import be from '../../BE';
import auth from '../../Account/Auth';
import i18n from '../../i18n';

function Details({contact, contactsCache, showDrawer, setShowDrawer, rating, savingRating, setRating}){
    const history = useHistory();
    const [loading,setLoading] = useState(false);
    const [contactDetails,setContactDetails] = useState({});

    const fetchContactDetails = async (_contact) => {
        try {
            setLoading(true);
            await auth.isAuthenticated()
                .then(async () => {
                    await be.get('Leads',`/leads/${_contact}`,false,true,true)
                        .then((response) => {
                            setContactDetails(response)
                            console.log(`[leads][contact-details] fetched contact details for ${contact}`);
                        })
                        .catch(error => {
                            console.error("[leads][contact-details] network error while fetching contact details:",error);
                            setShowDrawer(false);
                        })
                })
                .catch((error) => {
                    console.error("[leads][contact-details] authentication error while fetching contact details:", error)
                    setShowDrawer(false);
                    history.push({pathname:'/login'})
                })
        } catch (error) {
            console.error(`[leads][contact-details] functional error while fetching contact details for ${contact}`);
        } finally {
            setLoading(false);
        }
    };

    const getUserLanguage = () => {
        return i18n.language.substring(0,2);
    }

    const formatDate = (_date) => {
        // Date formatting options
        var dateOptions = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        var timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false // You can set this to false if you want a 24-hour format
        };
        // Getting date object from input
        var dateFormatted = new Date(_date);

        // Format date and time separately
        var formattedDate = dateFormatted.toLocaleDateString(getUserLanguage(), dateOptions);
        var formattedTime = dateFormatted.toLocaleTimeString(getUserLanguage(), timeOptions);

        // Combine both date and time
        return `${formattedDate} | ${formattedTime}`;
    }

    useEffect(() => {
        const init = async() => {
            await fetchContactDetails(contact);
        } 
        if(contact && showDrawer){
            init();
        }
    },[contact])

    return(
        <>
            <div 
                id="readUserDrawerAdvanced" 
                className={`tw-drop-shadow-xl tw-overflow-y-auto tw-fixed tw-top-0 tw-right-0 tw-z-40 tw-p-4 tw-w-full tw-max-w-sm tw-h-screen tw-bg-white tw-transition-transform ${showDrawer ? 'tw-transform-none' : 'tw-translate-x-full'}`} 
                tabIndex="-1" 
                aria-labelledby="drawer-label" 
                aria-hidden="true"
            >
                {loading
                    ?
                        <>
                            <div role="status" className="tw-max-w-sm tw-animate-pulse tw-my-6">
                                <div className="tw-h-2.5 tw-bg-gray-200 tw-rounded-full tw-dark:bg-gray-700 tw-w-48 tw-mb-4"></div>
                                <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-dark:bg-gray-700 tw-max-w-[360px] tw-mb-2.5"></div>
                                <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-dark:bg-gray-700 tw-mb-2.5"></div>
                                <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-dark:bg-gray-700 tw-max-w-[330px] tw-mb-2.5"></div>
                                <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-dark:bg-gray-700 tw-max-w-[300px] tw-mb-2.5"></div>
                                <div className="tw-h-2 tw-bg-gray-200 tw-rounded-full tw-dark:bg-gray-700 tw-max-w-[360px]"></div>
                                <span className="tw-sr-only">Loading...</span>
                            </div>
                        </>

                        :
                        <>
                            <button 
                                type="button" 
                                data-drawer-dismiss="readUserDrawerAdvanced" 
                                aria-controls="readUserDrawerAdvanced" 
                                className="tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-flex tw-items-center tw-dark:hover:bg-gray-600 tw-dark:hover:text-white"
                                onClick={() => setShowDrawer(false)}
                            >
                                <svg aria-hidden="true" className="tw-w-5 tw-h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                                <span className="tw-sr-only">Close menu</span>
                            </button>
                            <div className="tw-flex tw-items-center tw-my-4 sm:tw-mb-5">
                                
                                {/* profile pic */}
                                {/* <img className="tw-w-12 tw-h-12 tw-rounded-full sm:tw-mr-3" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/helene-engels.png" alt="Helene avatar" /> */}
                                <div>
                                    <h4 id="drawer-label" className="tw-mb-1 tw-text-xl tw-font-bold tw-leading-none tw-text-gray-900 tw-dark:text-white">{contactDetails?.properties?.fullName}</h4>
                                    <p className="tw-text-gray-500 tw-dark:text-gray-400">{contactDetails?.contact}</p>
                                    <RatingStars
                                        key={`${contact}-details-rating-component`}
                                        contact={contact}
                                        rating={rating}
                                        onChange={setRating}
                                        savingRating={savingRating}
                                    />
                                </div>
                            </div>
                            
                            <dl>
                                {contactDetails?.acquisitionDate &&
                                <>
                                    <dt className="tw-mb-2 tw-font-semibold tw-leading-none tw-text-gray-900 tw-dark:text-white">Acquisition Date</dt>
                                    <dd className="tw-mb-4 tw-font-light tw-text-gray-500 sm:tw-mb-5 tw-dark:text-gray-400">
                                        {formatDate(contactDetails?.acquisitionDate)}
                                    </dd>
                                </>
                                }
                                {contactDetails?.properties && contactDetails?.properties?.lang &&
                                    <div className="tw-flex tw-mb-4 tw-gap-2 tw-items-center">
                                        <dt className="tw-font-semibold tw-leading-none tw-text-gray-900 tw-dark:text-white">
                                            <i className="fa-solid fa-flag"></i>
                                        </dt>
                                        <dd className="tw-uppercase tw-font-light tw-text-gray-500 tw-dark:text-gray-400">
                                            {contactDetails?.properties?.lang.substring(0,2)}
                                        </dd>
                                    </div>
                                }
                                {contactDetails?.properties && contactDetails?.properties?.email && contactDetails?.properties?.email !== contactDetails?.contact &&
                                    <div className="tw-flex tw-mb-4 tw-gap-2 tw-items-center">
                                        <dt className="tw-font-semibold tw-leading-none tw-text-gray-900 tw-dark:text-white">
                                            <i className="fa-solid fa-envelope"></i>
                                        </dt>
                                        <dd className="tw-font-light tw-text-gray-500 tw-dark:text-gray-400">
                                            {contactDetails?.properties?.email}
                                        </dd>
                                    </div>
                                }
                                {contactDetails?.properties && contactDetails?.properties?.timezone &&
                                    <div className="tw-flex tw-mb-4 tw-gap-2 tw-items-center">
                                        <dt className="tw-font-semibold tw-leading-none tw-text-gray-900 tw-dark:text-white">
                                            <i className="fa-solid fa-earth-africa"></i>
                                        </dt>
                                        <dd className="tw-font-light tw-text-gray-500 tw-dark:text-gray-400">
                                            {contactDetails?.properties?.timezone}
                                        </dd>
                                    </div>
                                }
                                {contactDetails?.properties && contactDetails?.properties.username &&
                                    <div className="tw-flex tw-mb-4 tw-gap-2 tw-items-center">
                                        <dt className="tw-font-semibold tw-leading-none tw-text-gray-900 tw-dark:text-white">
                                            <i className="fa-solid fa-user"></i>
                                        </dt>
                                        <dd className="tw-font-light tw-text-gray-500 tw-dark:text-gray-400">
                                            <a className="tw-inline-flex" href={`https://www.calendbook.com/${contactDetails?.properties?.username}`}>
                                                {contactDetails?.properties?.username}
                                                <svg className="tw-ms-1 tw-w-4 tw-h-4 tw-my-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                                                </svg>
                                            </a>
                                        </dd>
                                    </div>
                                }
                                {contactDetails?.timelineEvents && Object.keys(contactDetails?.timelineEvents).length > 0 &&
                                    <>
                                        <dt className="tw-mb-2 tw-font-semibold tw-leading-none tw-text-gray-900 tw-dark:text-white">Events</dt>
                                        <dd className="tw-mb-4 tw-font-light tw-text-gray-500 sm:tw-mb-5 tw-dark:text-gray-400 tw-overflow-y-scroll tw-h-[700px]">
                                            <ol className="tw-relative tw-border-s tw-border-gray-200 tw-dark:border-gray-700">                  
                                                {Object.keys(contactDetails?.timelineEvents).map((eventKey) => (
                                                    <li key={eventKey} className="tw-mb-4 tw-ms-4">
                                                        <div className="tw-absolute tw-w-3 tw-h-3 tw-bg-gray-200 tw-rounded-full tw-mt-1.5 tw--start-1.5 tw-border tw-border-white tw-dark:border-gray-900 tw-dark:bg-gray-700"></div>
                                                        <time className="tw-mb-1 tw-text-sm tw-font-normal tw-leading-none tw-text-gray-400 tw-dark:text-gray-500">{formatDate(eventKey)}</time>
                                                        <div className="tw-capitalize tw-text-sm tw-font-semibold tw-text-gray-900 tw-dark:text-white">
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'booked' && <i className="fa-solid fa-calendar-check tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'rescheduled' && <i className="fa-solid fa-arrows-rotate tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'cancelled' && <i className="fa-solid fa-calendar-xmark tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'pending_payment' && <i className="fa-solid fa-money-bill-wave tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'pending_approval' && <i className="fa-solid fa-thumbs-up tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'rejected' && <i className="fa-solid fa-circle-xmark tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action === 'google_imported' && <i className="fa-solid fa-google tw-me-1"></i>}
                                                            {contactDetails?.timelineEvents?.[eventKey]?.action.split("_").join(" ")}
                                                        </div>
                                                        {contactDetails?.timelineEvents?.[eventKey]?.metaBooking?.paidAmount &&
                                                        <p className="tw-text-xs tw-font-bold tw-text-gray-500 tw-dark:text-gray-400">{contactDetails?.timelineEvents?.[eventKey]?.metaBooking?.paidAmount} Paid</p>
                                                        }
                                                        {contactDetails?.timelineEvents?.[eventKey]?.metaBooking?.notes &&
                                                            <p className="tw-text-xs tw-font-bold tw-text-gray-500 tw-dark:text-gray-400">Notes:<br/><span className="tw-font-normal">{contactDetails?.timelineEvents?.[eventKey]?.metaBooking?.notes}</span></p>
                                                        }
                                                    </li>
                                                ))}
                                            </ol>
                                        </dd>
                                    </>
                                }
                            </dl>
                        </>
                }

            </div>
        </>
    );
}

export default Details;
