const partners =  {
  standard : {
    light : { text : 'rgb(0, 0, 0)', border:'rgba(0, 0, 0, 0.3)', background : 'rgba(0, 0, 0, 0.1)'},
    dark : { text : 'rgb(180, 180, 180)', border : 'rgba(180, 180, 180, 0.3)', background : 'rgba(255, 255, 255, 0.1)'},
  },
  make : {
    link : "https://www.make.com",
    light : { text : 'rgb(109, 0, 201)', border : 'rgba(109, 0, 201, 0.3)', background : 'rgba(109, 0, 201, 0.1)'},
    dark : { text : 'rgb(213, 163, 255)', border : 'rgba(213, 163, 255, 0.3)', background : 'rgba(109, 0, 201, 0.3)'},
  },
  integrately : {
    link : "https://integrately.com/",
    light : { text : 'rgb(243, 90, 56)', border : 'rgba(243, 90, 56, 0.3)', background : 'rgba(243, 90, 56, 0.1)'},
    dark : { text : 'rgb(243, 90, 56)', border : 'rgba(243, 90, 56, 0.3)', background : 'rgba(243, 90, 56, 0.1)'},
  },
  pabbly : {
    link : "https://www.pabbly.com/",
    light : { text : 'rgb(34, 178, 118)', border : 'rgba(34, 178, 118, 0.3)', background : 'rgba(34, 178, 118, 0.1)'},
    dark : { text : 'rgb(34, 178, 118)', border : 'rgba(34, 178, 118, 0.3)', background : 'rgba(34, 178, 118, 0.1)'},
  },
  konnectzit : {
    link : "https://konnectzit.com/",
    light : { text : 'rgb(21, 47, 115)', border : 'rgba(21, 47, 115, 0.3)', background : 'rgba(21, 47, 115, 0.1)'},
    dark : { text : 'rgb(150, 180, 255)', border : 'rgba(150, 180, 255, 0.3)', background : 'rgba(21, 47, 115, 0.3)'},
  },
  syncspider : {
    link : "https://syncspider.com",
    light : { text : 'rgb(28, 68, 117)', border : 'rgb(28, 68, 117, 0.3)', background : 'rgba(28, 68, 117, 0.1)'},
    dark : { text : 'rgb(138, 190, 255)', border : 'rgb(138, 190, 255, 0.3)', background : 'rgba(28, 68, 117, 0.3)'},
  }    
}


export default partners;
