import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../../../../store';

function Add({order,id}){
  const {t} = useTranslation(['eventCreator']);
  const addField = useCreateEventTypeStore((state) => state.addField);

  const componentStyle = {
    backgroundColor : "transparent",
    border : "none",
    margin : 0,
    padding : 0,
    color : "var(--bs-primary)"
  }

  return(
    <div className="mt-3" style={{fontSize:14}}>
      <button style={componentStyle} onClick={(e) => {e.preventDefault();addField(id)}}>
        {t('surveys.addAnswer')}
      </button>
    </div>
  );
}

export default Add;
