import React from 'react';
import Heading from './Heading';
import AddWebhook from './AddWebhook';
import Partners from './Partners/_Partners';
import ConnectedWebhooks from './ConnectedWebhooks';

function Webhooks(){
  return (
    <>
      <Heading/>
      <Partners/>
      <AddWebhook/>
      <ConnectedWebhooks/>
    </>
  );
}

export default Webhooks;
