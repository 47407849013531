import { useRef, useEffect, useState } from 'react';

const NoteInput = ({ contactId, initialNote, saveNote }) => {
  const [note, setNote] = useState(initialNote || '');
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef(null);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    setNote(value);

    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer
    timerRef.current = setTimeout(() => {
      // Save the note
      setIsLoading(true);
      saveNote(contactId, value)
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error saving note:', error);
          setIsLoading(false);
        });
    }, 2000);
  };

  useEffect(() => {
    // Cleanup function
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="tw-flex tw-items-center">
      <input
        type="text"
        value={note}
        onChange={handleNoteChange}
        className="tw-border !tw-border-gray-300 tw-text-xs tw-px-2 tw-py-1 tw-rounded tw-w-full"
      />
      {isLoading && (
        // Spinner
        <svg
          className="tw-ml-2 tw-w-4 tw-h-4 tw-animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="tw-opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="tw-opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      )}
    </div>
  );
};

export default NoteInput;
