export const isIframe = () => {
 return window.location !== window.parent.location;
}

export const setRootAsIframe = () => {
  const root = document.getElementById("root");
  const body = document.getElementById("body");
  root.classList.add("iframe");
  body.classList.add("iframe");
}

export const getIframeReferrer = () => {
  const queryParameters= new URLSearchParams(document.location.search);
  const referrer = queryParameters.get("iframe-referrer");
  return referrer;  
}
