import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import {useHistory} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Tab, Row, Col, Button, ButtonGroup, Dropdown, DropdownButton, Accordion} from 'react-bootstrap';
import ProfilePic from './ProfilePic/ProfilePic';
import Banner from './Banner/Banner';
import FullName from './FullName/FullName';
import WelcomeSentence from './WelcomeSentence/WelcomeSentence';
import BookingLink from './BookingLink/BookingLink';
import be from '../../BE';
import auth from '../../Account/Auth';
import UpgradePath from '../../Utilities/UpgradePath/UpgradePath';

// printing console.info logs only in localhost
const DEBUG = window.location.hostname === 'localhost';

function AccountPanel(props){
  const { t, i18n } = useTranslation(['settings']);
  const history = useHistory();
  const switchLng = async (lng) => {i18n.changeLanguage(lng); await setUserLanguage(lng);}
  const [selectedTimezone, setSelectedTimezone] = useState(getUserTimezone())
  const [revokeAlert,setRevokeAlert] = useState(false)
  const [deleteAlert,setDeleteAlert] = useState(false)

  const rulerStyle = {
    borderColor:'var(--color-ruler)',
    marginTop:10,
    marginBottom:0
  }

  function getFlag(language){
    // getting only first 2 chars of the language (it, en, ...)
    language = language.toLowerCase().substring(0,2)
    if(language === 'it') return <span role="img" aria-label="italiano" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png" alt="italiano" width="16px"/> it</span>
    if(language === 'en') return <span role="img" aria-label="english" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png" alt="english" width="16px"/> en</span>
    if(language === 'es') return <span role="img" aria-label="español" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png" alt="español" width="16px"/> es </span>
    if(language === 'de') return <span role="img" aria-label="deutsch" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png" alt="deutsch" width="16px"/> de </span>
    if(language === 'fr') return <span role="img" aria-label="francais" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png" alt="francais" width="16px"/> fr </span>
  }

  async function setUserLanguage(language){
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('account',"/setOwnerLanguage",{language:language},true)
          .then(() => auth.refresh())
          .catch((error) => console.log(error));
      })
      .catch(() => history.push({pathname:'/login'}))
  }

  async function deleteAccount() {
    await auth.isAuthenticated()
      .then(async () => {
        await be.get("account","/delete",false,true)
          .then(response => {
            auth.logout();
            window.location.href = '/'
          })
          .catch(error => console.log(error));
      })
      .catch(() => history.push({pathname:'/login'}))
  }

  async function setOwnerTimezone(timezone){
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('account',"/setOwnerTimezone",{timezone:timezone},true)
          .then(async () => await auth.refresh())
          //.then(() => window.location.reload(false))
          .catch((error) => console.log(error));
      })
      .catch(() => history.push({pathname:'/login'}))
  }

  function getUserTimezone(){
    // try to get timezone from localStorage
    let tz = auth.getTimezone()
    if(tz){return tz}
    else {
      // if no timezone is found, guess it and save it to db
      console.log('timezone not found, guessing it...');
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      setOwnerTimezone(tz)
      return tz
    }
  }
  function handleDelete(){
    setDeleteAlert(true)
  }

  function handleTimezoneChange(timezone){
    setOwnerTimezone(timezone)
    setSelectedTimezone(timezone)
  }

  return(
    <Tab.Pane eventKey="account">

      {DEBUG && <button onClick={async () => {try{await auth.refresh()}catch(e){console.info("error: ",e)}}}>refresh session</button>}

      <Row className="mt-3 mb-4">
        <Col>
          <h4>Email</h4>
          <span>{auth.getEmail()}</span>
        </Col>
      </Row>

     <hr style={rulerStyle}/>

      <Row className="mt-3 mb-4">
        <Col>
          <UpgradePath
            featureName="change-profile-pic"
            position="right"
          >
            <ProfilePic/>
          </UpgradePath>
        </Col>
      </Row>

      <hr style={rulerStyle}/>

      <Row>
        <Col>
          <UpgradePath
            featureName="change-banner-pic"
            position="right"
          >
            <Banner/>
          </UpgradePath>
        </Col>
      </Row>
      <hr style={rulerStyle}/>

      <Row className="mt-3 mb-4">
        <Col>
          <FullName/>
        </Col>
      </Row>

      <hr style={rulerStyle}/>

      <Row className="mt-3 mb-4">
        <Col>
          <WelcomeSentence/>
        </Col>
      </Row>

      <hr style={rulerStyle}/>

      <Row className="mt-3 mb-4">
        <Col>
          <h4>{t('account.language')}</h4>
          <span>{t('account.yourLanguage')} </span>
          <ButtonGroup className="d-inline text-center">
            <DropdownButton as={ButtonGroup} variant="link" title={getFlag(i18n.language)} id="bg-nested-dropdown">
              <Dropdown.Item className="text-capitalize d-inline-flex align-items-center gap-2" onClick={() => switchLng('it')}><span role="img" aria-label="italiano" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png" alt="italiano" width="16px"/></span> italiano</Dropdown.Item>
              <Dropdown.Item className="text-capitalize d-inline-flex align-items-center gap-2" onClick={() => switchLng('en')}><span role="img" aria-label="english" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png" alt="english" width="16px"/></span> english</Dropdown.Item>
              <Dropdown.Item className="text-capitalize d-inline-flex align-items-center gap-2" onClick={() => switchLng('es')}><span role="img" aria-label="español" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png" alt="español" width="16px"/></span> español</Dropdown.Item>
              <Dropdown.Item className="text-capitalize d-inline-flex align-items-center gap-2" onClick={() => switchLng('de')}><span role="img" aria-label="deutsch" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png" alt="deutsch" width="16px"/></span> deutsch</Dropdown.Item>
              <Dropdown.Item className="text-capitalize d-inline-flex align-items-center gap-2" onClick={() => switchLng('fr')}><span role="img" aria-label="francais" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png" alt="francais" width="16px"/></span> francais</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </Col>
      </Row>

      <hr style={rulerStyle}/>

      <Row className="my-4">
        <Col>
          <h4>{t('account.timezone')}</h4>
          <div>{t('account.yourTimezone')} </div>
            <TimezonePicker
              absolute={true}
              defaultValue={selectedTimezone}
              onChange={(timezone) => {handleTimezoneChange(timezone)}}
            />
        </Col>
      </Row>

      <hr style={rulerStyle}/>
      
      <Row className="my-4">
        <Col>
          <BookingLink/>  
        </Col>
      </Row>

      <hr style={rulerStyle}/>

      <Accordion className="mt-4">
        <Accordion.Item eventKey="0" style={{background:'var(--color-background-1)'}}>
          <Accordion.Header className="text-danger" eventKey="0">
            Danger Zone
          </Accordion.Header>

          <Accordion.Body eventKey="0">
            <Row>
              <Col xs={12} className="my-3">
                <Row>
                  <Col xs={12} md={8} className="my-auto" style={{color:'var(--color-secondary)'}}>
                    {t('account.dangerous.deleteAccountDescription')}
                  </Col>
                  <Col xs="auto" md={4} className="my-2 my-md-auto float-start float-md-end">
                    <Button block variant='outline-danger' onClick={() => (handleDelete())}>{t('account.dangerous.deleteAccountButton')}</Button>
                    {deleteAlert &&
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete my account"
                      confirmBtnStyle={{border:'none',boxShadow:'none'}}
                      confirmBtnBsStyle="link"
                      cancelBtnBsStyle="primary"
                      title="Are you sure?"
                      onConfirm={async () => deleteAccount()}
                      onCancel={() => setDeleteAlert(false)}
                      reverseButtons={true}
                      focusCancelBtn={true}
                      style={{background:'var(--color-background-1)',color:'var(--color-text)'}}
                    />
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </Tab.Pane>
  );
}

export default AccountPanel;
