import {useEffect} from 'react';

function Zapier () {
    useEffect(() => {

      const calendbookTheme = document.getElementById("root").getAttribute("data-theme")

      // Load JS
      const script = document.createElement("script");
      script.type = "module";
      script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
      document.head.appendChild(script);

      // Load CSS
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
      document.head.appendChild(stylesheet);

    },[]);


  return(

      <zapier-full-experience
        client-id="IOYXBOagDzsf27fwzeAl6NKwxeKz5OTffEHCUndy"
        theme="auto"
        app-search-bar-display="show"
        zap-style="card"
      />
  );
}

export default Zapier;
