function DisabledReason({reason}){
  var color = "var(--color-warning)";
  return(
    <>
      <div 
          className="my-2 d-block event-type-label"
          style={{
            backgroundColor:"var(--color-warning)",
            color:"var(--color-text-negative)",
            borderColor:"var(--color-border-light)"
          }}
      >
        <div className="my-1 align-items-center">
        <i className="fa-solid fa-triangle-exclamation me-2"></i>
          <span>{reason === "memberNeedsTemplate" && "Round Robin Member Needs Template"}</span>
        </div>
      </div>
    </>
  );
}

export default DisabledReason;
