import { useHistory } from 'react-router-dom';
import { useIframeStore } from '../store';
import be from '../BE';
import auth from '../Account/Auth';

function CloseOnboarding({stage}){
    const history = useHistory();
    const setShouldShowNavs = useIframeStore((state) => state.setShouldShowNavs);

    const closeOnboarding = async (_stage) => {
        try{
            await auth.isAuthenticated()
                .then(() => {
                    be.post('Onboarding','/onboarding/close',false,true,true)
                        .then(response => {
                            console.log(`[onboarding][close] onbarding closed at stage: ${_stage}`)
                        })
                        .catch(error => {
                            console.error("[onboarding][close] network error while closing onboarding:",error);
                        })
                })
                .catch((error) => {
                    console.error("[onboarding][close] authentication error while closing onboarding:", error)
                    history.push({pathname:'/login'})
                })
        }catch (error) { 
            console.error("[onboarding][close] functional error while closing onboarding:",error);
        }finally{
            history.push({pathname:'/'})
            // show navbar and footer
            setShouldShowNavs(true);
            document.getElementById("root").style.paddingBottom="500px";
            document.getElementById("root").style.minHeight="1400px";
        }
    }
    return(
        <div className="tw-w-full tw-p-4 tw-flex tw-items-end">
        <a href="#" onClick={() => closeOnboarding(stage)}
            className="tw-ml-auto tw-inline-flex tw-items-center tw-text-lg tw-font-medium">
            <i className="fa-solid fa-xmark"></i>
        </a>
        </div>
    );
}

export default CloseOnboarding;
