import React, {useState, useEffect, createContext} from 'react';

// Creo il context
export const ThemeContext = createContext();
// Creo il provider
export const ThemeProvider = props => {

  const getCurrentTheme = () => {
    return window.document.getElementById('root').getAttribute('data-theme');
  }

  // Creo lo stato
  const [theme,setTheme] = useState('light');

  useEffect(() => {
    setTheme(getCurrentTheme);
  },[]);

  return (
    <ThemeContext.Provider value={[theme,setTheme]}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext
