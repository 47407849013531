import {useEffect} from 'react';
import auth from './Account/Auth';

function Logout(){
  useEffect(() => {
    const logout = async () => {
      await auth.logout(); 
    } 
    logout();
  },[])
  return(
    <p><center>logging out...</center></p>
  );
}
export default Logout;
