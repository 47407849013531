import React from 'react';

function Brand(){
  return(
    <>
      <span className="d-none d-md-block">
        <img src="/img/banner-min.png" alt="calendbook" width='150px' style={{verticalAlign:'baseline'}}/>
      </span>
      <span className="d-block d-md-none">
        <img src="/img/logo-min.png" alt="calendbook" width='40px' style={{border:'2px solid #3a3b3c',borderRadius:'100%'}}/>
      </span>
    </>
  );
}

export default Brand;
