import React, {useState, useEffect, createContext} from 'react';
import auth from './Account/Auth';

// Creo il context
export const UserContext = createContext();

const checkForImpersonation = async () => {
  const agentInfo = auth.getAgent();
  const agentSession = JSON.parse(localStorage.getItem('agentSession'));

  if (agentInfo && agentInfo?.fullName && agentInfo?.email && agentSession && agentSession?.accountId) {
    return {
      isImpersonated: true,
      impersonatingAgent: {
        fullName: agentInfo.fullName,
        email: agentInfo.email,
      }
    };
  } else {
    return {
      isImpersonated: false,
      impersonatingAgent: null
    };
  }
};


// Creo il provider
export const UserProvider = props => {

  // Creo lo stato
  const [user,setUser] = useState(
    {
      logged: false,
      isImpersonated: false,
      impersonatingAgent: null,
    }
  );

  // non posso usare asyn direttamente in useEffect, ma posso creare una arrow
  // function asincrona e chiamarla, per aggiornare lo stato
  // (usato principalmente per avvalorare il context state la prima volta)
  // https://www.robinwieruch.de/react-hooks-fetch-data
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  useEffect(() => {
    const fetchStatus = async () => {
      const result = { logged: false, isImpersonated: false, impersonatingAgent: null };
      await auth.isAuthenticated()
      .then(async () => {
        result.logged = true;
        const impersonationStatus = await checkForImpersonation();
        result.isImpersonated = impersonationStatus.isImpersonated;
        result.impersonatingAgent = impersonationStatus.impersonatingAgent;
      })
      .catch(() => {result.logged = false})
      setUser(result);
    }
    fetchStatus()
  },[]);


  return (
    <UserContext.Provider value={[user,setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext
