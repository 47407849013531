import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';

const frontendFormatter = (number) => {
    console.info('[booking][payment] parsing be price:');
    let result = '';
    if(number !== undefined){
      result = Number(number)/100;
    }
    console.info(result);
    return result;
  };

function Price ({payment}) {
  const { t } = useTranslation(['booking']);

  return(
    <>
      {payment && payment?.amount &&
        <>
          <div className="fw-bold">{t('details.payment')}</div>
          <div style={{color:'var(--color-secondary)'}}>
            {
              payment?.currency && 
              getSymbolFromCurrency(payment?.currency) !== undefined && 
              getSymbolFromCurrency(payment?.currency)
            } 
            {frontendFormatter(payment?.amount)}
          </div>
        </>
      }
    </>
  );
}

export default Price;
