import { useTranslation } from 'react-i18next';

function Notes (props){
  const {t} = useTranslation(['home']);
  const notes = props.notes;

  return (
    <div>
      {notes && 
        <>
          <i className="fas fa-sticky-note me-2"></i>
          <b>{t('upcomings.details.notes')}</b>
          <div>
            {notes}
          </div>
          <br/>
        </>
      }
    </div>
  );
}

export default Notes;
