import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import be from "../../BE";
import auth from "../../Account/Auth";

function Notifications({ setStage }) {
  const { t } = useTranslation(["onboarding"]);
  const history = useHistory();
  const [notifications, setNotifications] = useState("");

  const saveNotifications = async () => {
    const body = {
      provider: notifications,
    };

    try {
      await auth
        .isAuthenticated()
        .then(() => {
          be.post("Onboarding", "/onboarding/notifications", body, true, true)
            .then((response) => {
              console.log("[onboarding][notifications] notifications saved");
            })
            .catch((error) => {
              console.error(
                "[onboarding][notifications] network error while saving notifications:",
                error
              );
            });
        })
        .catch((error) => {
          console.error(
            "[onboarding][notifications] authentication error while saving notifications:",
            error
          );
          history.push({ pathname: "/login" });
        });
    } catch (error) {
      console.error(
        "[onboarding][notifications] functional error while saving notifications:",
        error
      );
    } finally {
      setStage("plan-fit");
    }
  };

  return (
    <>
      <h1 className="tw-mb-4 tw-text-2xl tw-font-extrabold tw-leading-tight tw-tracking-tight tw-text-gray-900 sm:tw-mb-6 tw-dark:text-white">
        {t("notifications.title")}
      </h1>

      <p className="tw-my-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">
        {t("notifications.chooseNotification")}
      </p>

      <ul className="tw-mb-6 tw-gap-6 tw-w-full tw-inline-flex tw-justify-center">
        <li>
          <input
            type="radio"
            id="email"
            name="notifications"
            value="email"
            className="!tw-hidden tw-peer"
            required
          />
          <label
            onClick={() => setNotifications("email")}
            htmlFor="email"
            className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700"
          >
            <span className="tw-w-full">Email</span>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="telegram"
            name="notifications"
            value="telegram"
            className="!tw-hidden tw-peer"
          />
          <label
            onClick={() => setNotifications("telegram")}
            htmlFor="telegram"
            className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700"
          >
            <span className="tw-w-full">Telegram</span>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="whatsapp"
            name="notifications"
            value="whatsapp"
            className="!tw-hidden tw-peer"
          />
          <label
            onClick={() => setNotifications("whatsapp")}
            htmlFor="whatsapp"
            className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700"
          >
            <span className="tw-w-full">Whatsapp</span>
          </label>
        </li>
      </ul>

      <button
        type="button"
        onClick={saveNotifications}
        className="tw-w-full tw-px-5 tw-py-2.5 sm:tw-py-3.5 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary-300 tw-dark:bg-primary-600 tw-dark:hover:bg-primary-700 tw-dark:focus:tw-ring-primary-800"
      >
        {t("notifications.nextBtn")}
      </button>
    </>
  );
}

export default Notifications;
