import React, {useState,useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import {Tab, Row, Col, Button, Card, Accordion} from 'react-bootstrap';
import {FormControl, Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import be from '../../../BE';
import auth from '../../../Account/Auth';

function AgencyName({loading, agency, setAgency}){
  const [processing,setProcessing] = useState(false);
  const [status,setStatus] = useState(0);

  async function setAgencyName(e) {
    e.preventDefault();
    setProcessing(true);
    setStatus(0);
    await auth.isAuthenticated()
      .then(async () => {
        await be.post("agency","/agency/settings/name",{agencyName : agency?.agencyName},true,true,true)
          .then((r) => setStatus(200))
          .catch((e) => {setStatus(e?.response?.status)})
      })
      .catch(error => console.log(error))
    setProcessing(false);
  }

  const handleChange = (e) => {
    setStatus(0);
    let inputText = e.target.value;
    let name = e.target.name;
    setAgency({...agency , [name] : inputText});
  }

  return(
    <>
      <h4>Name</h4>
      <span>set here the name of your agency</span>
      <Form onSubmit={setAgencyName}>
        <InputGroup>
          <FormControl
            type="text"
            name="agencyName"
            id="agency-name-field"
            aria-describedby="agency name"
            value={loading ? "Loading..." : agency?.agencyName}
            disabled={loading}
            onChange={handleChange}
          />
          <Button disabled={loading || processing} type='submit'>
            {processing 
              ? 
                <Spinner size="sm" animation="border" variant="light" /> 
                : 
                <i className="fas fa-long-arrow-alt-right"></i>
            }
          </Button>
        </InputGroup>
      </Form>
      {(status >= 400 && status <= 500) &&
      <p className="text-danger"><small>error</small></p>
      }
      {status === 200 &&
        <p className="text-success"><small>success</small></p>
      }
    </>
  );
}

export default AgencyName
