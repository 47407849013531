function Member({ pic, name, email}){
  return(
    <div className="d-flex align-items-center">
      <div className="d-inline-flex align-center me-2">
        <img
          width="38px"
          height="38px"
          src={pic}
          alt="profile-pic"
          className="rounded-circle"
          style={{
            border:"3px solid var(--color-border-light)"
          }}
        />
      </div>
      <div className="d-inline-block">
        <div>
          {name}
        </div>
        <div className="text-secondary">
          {email}
        </div>
      </div>
    </div>
  );
}

export default Member;
