import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Login from '../../Login';

function CTAButton({redirect, loggedIn,isIframePage}){
  return(
    <>
      {!redirect &&
        <div className="text-center">
          {loggedIn && !isIframePage 
            ? <Link to='/'><Button>Homepage</Button></Link>
            : <Login redirect='/'/>
          }
        </div>
      }
    </>
  );
}

export default CTAButton;
