import OwnerPic from './OwnerPic';
import OwnerName from './OwnerName';
import OwnerBanner from './OwnerBanner';

function OwnerInfo({owner,loading}){
  const hasBanner = () => {return owner?.companyLogo};
  return(
    <>
      {loading

      ?
        <>
          Loading...
        </>
      :
        <>
          {hasBanner() && <OwnerBanner companyLogo={owner?.companyLogo}/>}
          <div style={hasBanner() ? {position:'relative',top:'-30px',left:'2%'} : null}> <OwnerPic pic={owner?.pic} hasBanner={hasBanner}/> </div>
          <div> <OwnerName name={owner?.fullName}/> </div>
        </>
      }
    
    </>
  );
}

export default OwnerInfo;
