import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button';

function DisconnectStripe({stripeUser,setStripeUser,toggleShowDisconnectAlert}){
  const {t} = useTranslation(['integrations']);
  return(
    <div className="mt-2 d-block d-sm-flex align-items-center">

      <div style={{color:'var(--color-secondary)'}} className="me-0 me-sm-auto mb-3 mb-sm-0">
        {t('stripe.dangerZone.disconnect.title')}
      </div>
      
      <Button 
        className="ms-0 ms-sm-auto "
        variant="outline-danger"
        size="sm"
        onClick={toggleShowDisconnectAlert}
      >
        {t('stripe.dangerZone.disconnect.button')}
      </Button>

    </div>
  );
}

export default DisconnectStripe;
