import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ expirationDate }) => {
    // Initialize state
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const updateTimer = () => {
            const now = new Date().getTime();
            const distance = new Date(expirationDate).getTime() - now;

            if (distance < 0) {
                // If the countdown is finished
                clearInterval(timerId);
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            } else {
                // Calculate time remaining
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Update state
                setTimeLeft({ days, hours, minutes, seconds });
            }
        };

        // Update the countdown every second
        const timerId = setInterval(updateTimer, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timerId);
    }, [expirationDate]);

    return (
        <>
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </>
    );
};

export default CountdownTimer;

