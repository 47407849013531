import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import YearGroup from './YearGroup';
import { useTranslation } from 'react-i18next';

function groupDates(dates){
  let result = {};
  let datesArray = Object.keys(dates);
  // TODO: improve performance
  // create a nested object for dates:
  // { year : { month : { day : {} } }
  for(let date of datesArray){
    result[`${date.substring(0,4)}`]={}
  }
  for(let date of datesArray){
    result[`${date.substring(0,4)}`]
          [`${date.substring(5,7)}`]={}
  }
  for(let date of datesArray){
    result[`${date.substring(0,4)}`]
          [`${date.substring(5,7)}`]
          [`${date.substring(8,10)}`]={}
  }
  // insert appointments values into day {}
  for(let date of datesArray){
    let appointments = Object.keys(dates[date])
    for(let appointment of appointments){
      result[date.substring(0,4)]
            [date.substring(5,7)]
            [date.substring(8,10)]
            [`${appointment}`] =  dates[date][appointment]
    }
  }
  return result;
}

// renderizza per ogni anno
function _EventContainer(props){
  const { t } = useTranslation(['home']);
  const pending = props?.pending;
  const dates = props?.state;
  const groupedDates = groupDates(dates);
  const groupedDatesArray = Object.keys(groupedDates);
  return(
    <>
      {groupedDatesArray.length > 0
        ?
          groupedDatesArray.map((year) => 
            <div key={`${year}-container`}>
              <YearGroup key={year} id={year} year={year} yearDates={groupedDates[year]} pending={pending}/>
            </div>
          )
        :
          t('upcomings.empty')  
      }
    </>
  );
}

export default _EventContainer;
