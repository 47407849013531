import React, {useState} from 'react';
import {useWebhooksConnectionsStore} from '../../../store';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../../BE';
import auth from '../../../Account/Auth';

function DeleteWebhook({hook}){
  const [processing,setProcessing] = useState(false)
  const deleteWebhookStore = useWebhooksConnectionsStore((state) => state.deleteWebhook);

  async function deleteWebHook(_hook){
    setProcessing(true)
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('webhook',"/cancelWebhook",{hook:_hook},true)
          .then((response) => {
            deleteWebhookStore(response?.hook)
          })
          .catch((error) => {
            console.log('error in /settings/webhook page: ',error)
          });
      })
      .catch(() => this.props.history.push('/login'))
    setProcessing(false)
  }

  const iconButton = {
    width:35,
    color:"var(--color-text)",
    backgroundColor:"transparent",
    border:"none",
  }

  return(
    <button onClick={() => deleteWebHook(hook)} style={iconButton} disabled={processing}>
      {processing
        ?
          <Spinner size="sm" animation="border"/> 
        :
          <i className="fa-solid fa-trash"></i>
      }
    </button>
  );
}
export default DeleteWebhook;
