import {useTranslation} from 'react-i18next';

function Heading(){
  const {t} = useTranslation(['integrations']);
  return(
    <div className="mb-4">
      <div className="h3 my-3" style={{fontFamily:"Roboto",fontWeight:600}}>{t('telegram.heading.title')}</div>
      <div style={{fontFamily:"Roboto",color:'var(--color-secondary)'}}>{t('telegram.heading.subtitle')}</div>
    </div>
  );
}

export default Heading;
