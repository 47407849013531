import React from 'react';
import Title from './Title';
import Subtitle from './Subtitle';

function TextContainer({taskNumber, name}){

  const componentClass = "d-inline-block mx-2";

  return(
    <div className={componentClass}>
      <Title taskNumber={taskNumber} name={name}/>
      <Subtitle taskNumber={taskNumber} name={name}/>
    </div>
  );

}


export default TextContainer;
