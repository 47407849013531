import React from 'react';
import { useTranslation } from 'react-i18next';

function Progress({completed,total}){
  const { t } = useTranslation(['onboardingChecklist']);

  return(
    <>
      {(completed !== undefined) && (total !== undefined) &&
        <div style={{fontSize:14}}>
          <span className={completed <= 1 ? "text-warning" : "text-success"}>
            {t('progress',{completed : completed, total : total})}
          </span>
          <span className="ms-1">
            {t('completed')}
          </span>
        </div>
      }
    </>
  );

}


export default Progress;
