import React from 'react';
import { useTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TooltipContainer({name,taskNumber}){

  const { t } = useTranslation(['onboardingChecklist']);
  const componentClass = "d-inline-block ms-auto"
  const tooltip = t(`${name}.${taskNumber}.tooltip`)

  return(
    <>
      {tooltip !== ""  &&
        <div className={componentClass}>
          <OverlayTrigger 
            trigger={['hover','click']}
            overlay={<Tooltip id={`tooltip-question-${taskNumber}`}>{tooltip}</Tooltip>}
          >
            <i className="far fa-question-circle"></i>
          </OverlayTrigger>
        </div>
      }
    </>
  );

}


export default TooltipContainer;
