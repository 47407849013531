import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UpgradePath from "../Utilities/UpgradePath/UpgradePath";
import Settings from "./Settings/Settings";
import MemberRow from "./MemberRow";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import CopyInviteLink from "./CopyInviteLink";
import UpgradeModal from "./UpgradeModal";
import "bootstrap/dist/css/bootstrap.min.css";
import auth from "../Account/Auth";
import be from "../BE";

function Team(props) {
  const { t } = useTranslation(["team"]);
  try {
    window.tidioChatApi.show();
  } catch {}

  const [teamStatus, setTeamStatus] = useState({
    viewerRole: "",
    seats: {
      occupied: 0,
      total: 0,
    },
    members: [],
  });

  const [pageLoading, setPageLoading] = useState(false);
  const [invitingMember, setInvitingMember] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);
  const [newMember, setNewMember] = useState("");
  const [showUpgrade, setShowUpgrade] = useState(false);

  const getTeamStatus = async () => {
    setPageLoading(true);
    await auth
      .isAuthenticated()
      .then(async () => {
        await be
          .get("team-manager", "/team/members", {}, true, true)
          .then((response) => {
            setTeamStatus(response);
          })
          .catch((response) => {
            console.log("error in /team page: ", response);
          });
      })
      .catch(() => {});
    setPageLoading(false);
  };

  useEffect(() => {
    document.title = "Calendbook - Team";
    if (!showUpgrade) {
      getTeamStatus();
    }
  }, [showUpgrade]);

  const handleChange = (event) => {
    setNewMember(event.target.value);
  };

  async function inviteMember(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setValidatedForm(true);
      setInvitingMember(true);

      /* be call */
      await auth
        .isAuthenticated()
        .then(async () => {
          await be
            .post(
              "team-manager",
              "/team/invitee",
              { email: newMember },
              true,
              true
            )
            .then((response) => {
              console.log("invited member: ", newMember);
              window.location.reload();
            })
            .catch((response) => {
              console.log("error in inviting member: ", response);
            });
        })
        .catch(() => {});

      setInvitingMember(false);
    }
  }

  async function removeMember(_key) {
    /* be call */
    await auth
      .isAuthenticated()
      .then(async () => {
        await be
          .post("team-manager", "/team/remove", { member: _key }, true, true)
          .then((response) => {
            console.log("removed member: ", _key);
            window.location.reload();
          })
          .catch((response) => {
            console.log("error in removing member: ", response);
          });
      })
      .catch(() => {});
  }

  return (
    <Container className="my-5">
      {pageLoading ? (
        <>Loading...</>
      ) : (
        <>
          <h3 className="tw-text-3xl tw-font-extrabold">{t("title")}</h3>
          <Row></Row>

          <UpgradePath featureName="team-settings" position="right">
            <Settings />
          </UpgradePath>

          <Row className="mb-4">
            <Col xs={12} className="mb-2">
              <b>{t("invite.title")}</b>
              {teamStatus?.invitationLink && (
                <>
                  <b> {t("invite.or")} </b>
                  <CopyInviteLink inviteLink={teamStatus?.invitationLink} />
                </>
              )}
            </Col>

            <Col xs={12} sm={8} md={6} lg={4}>
              <Form
                noValidate
                validated={validatedForm}
                onSubmit={inviteMember}
              >
                <InputGroup hasValidation>
                  <FormControl
                    required
                    type="email"
                    name="invite-member-email"
                    id="invited-member-email"
                    placeholder="team.member@gmail.com"
                    value={newMember}
                    onChange={handleChange}
                    style={{
                      color: "var(--color-text)",
                      backgroundColor: "var(--color-background-2)",
                      borderColor: "var(--color-border-light)",
                    }}
                  />
                  <Button disabled={invitingMember} type="submit">
                    {invitingMember ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      <i className="fas fa-long-arrow-alt-right"></i>
                    )}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {t("invite.invalid")}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form>
            </Col>
            <Col xs={12} sm={4} md={6}>
              {(teamStatus?.canUpgrade || teamStatus?.viewerRole === "owner") &&
                teamStatus?.totalMembers &&
                teamStatus?.members.length > 0 && (
                  <div className="tw-flex tw-my-1">
                    {teamStatus?.teamId && (
                      <UpgradeModal
                        canUpgrade={teamStatus?.canUpgrade}
                        teamId={teamStatus?.teamId}
                        showUpgrade={showUpgrade}
                        setShowUpgrade={setShowUpgrade}
                        getTeamStatus={getTeamStatus}
                      />
                    )}
                  </div>
                )}
            </Col>
          </Row>

          <div className="tw-mt-2 tw-inline-flex tw-gap-2">
            <>
              {teamStatus?.totalMembers && teamStatus?.members.length > 0 && (
                <div className="tw-content-center">
                  <span className="tw-items-center tw-my-auto">
                    <b>{t("totalMembers")} </b>
                    {teamStatus?.totalMembers}
                  </span>
                </div>
              )}
            </>
          </div>
          {teamStatus?.members.length > 0 ? (
            <div className="tw-relative tw-overflow-hidden tw-bg-white tw-shadow-md tw-dark:bg-gray-800 sm:tw-rounded-lg">
              <div className="tw-overflow-x-auto">
                <table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500 tw-dark:text-gray-400">
                  <thead className="tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50 tw-dark:bg-gray-700 tw-dark:text-gray-400">
                    <tr>
                      <th className="tw-px-4 tw-py-3">
                        {t("table.headings.member")}
                      </th>
                      <th className="tw-px-4 tw-py-3">
                        {t("table.headings.plan")}
                      </th>
                      <th className="tw-px-4 tw-py-3">
                        {t("table.headings.role")}
                      </th>
                      <th className="tw-px-4 tw-py-3">
                        {t("table.headings.status")}
                      </th>
                      {teamStatus?.viewerRole !== "member" && (
                        <th className="tw-px-4 tw-py-3">
                          {t("table.headings.actions")}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {teamStatus?.members.map((member) => (
                      <MemberRow
                        key={member?.key}
                        viewerRole={teamStatus?.viewerRole}
                        canPromoteList={teamStatus?.canPromoteList}
                        member={member}
                        removeMember={removeMember}
                        canUpgrade={teamStatus?.canUpgrade}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <>{t("noMembers")}</>
          )}
        </>
      )}
    </Container>
  );
}

export default Team;
