import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';
import {useCalendarConnectionsStore} from '../store';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ChangePermissions from './ChangePermissions';
import auth from '../Account/Auth';
import be from '../BE.js';

function SubCalendars({email,calendars,index,subCalendars}){
  const { t } = useTranslation(['calendarConnections']);
  const [collapsed,setCollapsed] = useState(index === 0 ? true : false);
  const [statusCode,setStatusCode] = useState(0);
  const [loading,setLoading] = useState(false);
  const [nowLoading,setNowLoading] = useState(false);
  //const [loadingPermissions,setLoadingPermissions] = useState(false);
  const setSubCalendarEnabled = useCalendarConnectionsStore((state) => state.setSubCalendarEnabled);

  function CustomToggle({ children, eventKey, collapsed, setCollapsed }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => setCollapsed(!collapsed),);
    return (
      <button
        type="button"
        style={{backgroundColor:'transparent',border:'none',margin:'10px auto 0 auto',display:'flex',color:'var(--color-primary)',fontSize:'12px',width:'100%',textAlign:'center',display:'inline-block'}}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const toggleSubCalendar = async (_index,_subIndex,_email,_id,_enabled) => {
    setLoading(true);
    setNowLoading(_id);
    const body={email:_email,subCalendarId:_id,status:_enabled}
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('calendarhandler','/subcalendars/status',body,true)
          .then((response) => {
            setStatusCode(200);
            setLoading(false);
            setNowLoading("");
            setSubCalendarEnabled(_index,_subIndex,_enabled)
          })
          .catch((error) => {
            setStatusCode(error?.response?.status)
            setLoading(false);
            setNowLoading("");
          })
      })
      .catch(() => this.props.history.push('/login'))
  }

  return (
    <Accordion defaultActiveKey={index === 0 && email || ''}>
      <CustomToggle eventKey={email} collapsed={collapsed} setCollapsed={setCollapsed}>
        <span className="flex">
          <span className="d-inline-flex me-1 mb-2">
            {t('subCalendars')}
          </span>
          <span style={{display:'inline-flex',rotate:collapsed && '90deg',transition:'0.2s',transitionOrigin:'center'}}>
            <i className="fas fa-caret-right" ></i>
          </span>
        </span>
      </CustomToggle>
      <Accordion.Item eventKey={email} style={{border:'none',background:'transparent',color:'var(--color-text)'}}>
        <Accordion.Collapse eventKey={email} style={{padding:'0 7px'}}>
          <div className="d-flex flex-column">
            {subCalendars && subCalendars.map((subCalendar, subIndex) => (
              <div className="mb-1">

                <div key={`${subCalendar?.id}`} className="d-inline-flex">
                  {loading && nowLoading === subCalendar?.id 
                    ?
                      <span style={{width:32,marginRight:8}}>
                        <Spinner animation="border" size="sm" className="d-flex mx-auto"/>
                      </span>
                      :
                      <Form.Check 
                        type="switch"
                        aria-label={`sub-calendar-${subCalendar?.id}`}
                        checked={calendars[index]?.subCalendars[subIndex]?.enabled}
                        onChange={() => toggleSubCalendar(index,subIndex,email,subCalendar?.id,!subCalendar?.enabled)}
                      />
                  }
                  <span>{subCalendar?.displayName}</span>
                </div>
                {/*
                <div>
                  <div>
                  <ChangePermissions
                    mode='check'
                    index={index}
                    email={email}
                    access={subCalendar?.access}
                    calendars={calendars}
                    subIndex={subIndex}
                    subCalendarId={subCalendar?.id}
                    loadingPermissions={loadingPermissions}
                    setLoadingPermissions={setLoadingPermissions}
                  />
                  </div>
                  <div>
                  <ChangePermissions
                    mode='add'
                    index={index}
                    email={email}
                    access={subCalendar?.access}
                    calendars={calendars}
                    subIndex={subIndex}
                    subCalendarId={subCalendar?.id}
                    loadingPermissions={loadingPermissions}
                    setLoadingPermissions={setLoadingPermissions}
                  />
                  </div>
                </div>
                */}
              </div>
            ))}
          </div>
        </Accordion.Collapse>
      </Accordion.Item>
    </Accordion>
  );
}

export default SubCalendars;
