import {useState,useEffect} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import auth from './Account/Auth';

const componentStyle = {
    display:'flex',
    width:350,
    textDecoration:'none',
    border:'1px solid var(--color-border-light)',
    borderRadius:4,
    backgroundColor:'white',
    padding:7,
}

const msLogoStyle = {
    display:'inline-flex',
    marginLeft:3,
    width:21,
    height:21
}

const textStyle = {
    display:'inline-flex',
    marginRight:'auto',
    marginLeft:'auto',
    color:'#3C4043',
    fontSize:14,
    fontWeight:500,
}

function MicrosoftLogin({redirect, callback, setLoggingProvider}){
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        const microsoftLogin = async () => { 
            if (window.location.search) {
                const qs = window.location.search.substring(1)
                const qsParams = qs.split(['&'])
                let qsMsCode = qsParams.find(x => x.startsWith('mscode='))
                if(qsMsCode){
                    try{
                        (setLoggingProvider && setLoggingProvider("microsoft"))
                        setLoading(true);
                        qsMsCode = qsMsCode.substring(7)
                        await auth.login({code:qsMsCode},'/account/microsoft/login')
                            .then(() => {
                                // check on '/onboarding' pathname helps avoiding redirect override
                                // in case auth module needs to bring the user on onboarding page
                                if (redirect && window.location.pathname !== '/onboarding') { window.location.href = redirect }
                                if (callback) { callback() }
                            })
                            .catch(e => {
                                console.log("request catch: error while logging in with microsoft provider: ", e)
                            })
                    }catch(e){
                        console.log("functional catch: error while logging in with microsoft provider: ", e)
                    }finally{
                        setLoading(false);
                        (setLoggingProvider && setLoggingProvider("none"))
                    }
                }
            }
        }
        microsoftLogin();
    },[])

    return(
        <a
            style={componentStyle}
            href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=a6b54902-9538-43da-b3ab-1a575cc386e5&response_type=code&scope=Calendars.ReadWrite+User.Read.All+offline_access+openid+profile"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img style={msLogoStyle} src="/app-assets/img/ms-logo.png"/>
            <span style={textStyle}>Sign in with Microsoft</span>
            {loading && <Spinner animation="border" size="sm" className="my-auto me-1"/>}
        </a>
    );
}

export default MicrosoftLogin;
