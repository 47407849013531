import React from 'react';
import Badge from 'react-bootstrap/Badge';
import './upgrade-path.css';
import auth from '../../Account/Auth';
import features from '../features-list.json';

// Helper function to determine feature access and the next available plan
const getFeatureAccessInfo = (userPlan, featureName, planOrder) => {
    let isLocked = false;
    let nextPlan = 'Upgrade'; // Default message
    const userPlanIndex = planOrder.indexOf(userPlan);

    try {
        const featurePlans = features[featureName];
        isLocked = !featurePlans.includes(userPlan);
        
        if (isLocked) {
            const availablePlans = featurePlans.filter(plan => planOrder.indexOf(plan) > userPlanIndex);
            nextPlan = availablePlans.length > 0 ? availablePlans[0] : nextPlan;
        }
    } catch (e) {
        console.info(`[UpgradePath][${featureName}] Error getting feature access: `, e);
    }

    return { isLocked, nextPlan };
};

// Component to display upgrade paths
const UpgradePath = ({ children, featureName, position, blur, customWidth }) => {
    const userPlan = auth.getRole(); // Fetch the current user's plan
    const planOrder = ["free", "premium", "professional", "LTD"]; // Predefined order of plans
    
    // Function to determine if feature is locked and find the next available plan
    const { isLocked, nextPlan } = getFeatureAccessInfo(userPlan, featureName, planOrder);
    
    const vendorLink = auth.getVendor() || "/upgrade"; // Use the vendor link or default

    // Click event handler (placeholder for actual implementation)
    const handleEventClick = () => {};

    return (
        <div className="upgrade-path-container" style={customWidth && {width:customWidth}}>
            {isLocked && (
                <a href={vendorLink} target="_blank" rel="noopener noreferrer" onClick={handleEventClick}>
                    <Badge className={`text-capitalize upgrade-path-badge badge-position-${position}`}>{nextPlan} Feature</Badge>
                    <div className={blur ? "upgrade-path-overlay upgrade-path-blur" : "upgrade-path-overlay"}></div>
                </a>
            )}
            {children}
        </div>
    );
};



export default UpgradePath;
