import PhoneInput from 'react-phone-number-input'
import {useCreateBookingStore} from '../../../../store';
import './phone-answer.css';

function PhoneAnswer({questionId,required,mode}){
  const setTextAnswer = useCreateBookingStore(state => state?.setTextAnswer);
  const updateTextAnswer = useCreateBookingStore(state => state.updateTextAnswer);
  const surveyAnswers = useCreateBookingStore(state => state?.surveyAnswers);
  const summary = useCreateBookingStore(state => state.summary);

  return(
    <>
      {mode === "reschedule"
        ?
          <PhoneInput
            required={required}
            international
            countryCallingCodeEditable={false}
            placeholder="Enter phone number"
            value={summary?.surveys[questionId]?.answer[0] || null}
            onChange={(number) => updateTextAnswer(number,questionId)}
          />
        :
          <PhoneInput
            required={required}
            international
            countryCallingCodeEditable={false}
            placeholder="Enter phone number"
            value={surveyAnswers[questionId] || null}
            onChange={(number) => setTextAnswer(number,questionId)}
          />
      }
    </>

  );
}

export default PhoneAnswer;
