import React from 'react';
import TimeField from 'react-simple-timefield';
import {Row,Col} from 'react-bootstrap';

function Range({availability,setDayRange,index,wday,disabled,rangeState,timeFormat}){

  return(
    <>           
      <Row className="h-100 py-1" style={{lineHeight:"34px"}}>
        <Col xs={5}>
          <TimeField
            key={`range-start-${index}-start-${wday}`}
            style = {{width:'100%',textAlign:'center',border:'1px solid var(--color-border)',borderRadius:5}}
            className="dark-field text-center h-100"
            value={availability.ranges[wday][index]['start']}
            onChange={(event, time) => {
              let interval = availability.ranges[wday][index]
              let start = time
              let end = interval.end
              let fixedInterval = {start:start,end:end}
              // fixedInterval = utils.checkInterval(availability,wday,fixedInterval)
              setDayRange(wday,index,fixedInterval)
            }}
            colon=":"
            disabled={disabled}
          />
        </Col>

        <span className="text-center mx-0 col-1"> - </span>

        <Col xs={5}>
          <TimeField
            key={`range-end-${index}-start-${wday}`}
            style = {{width:'100%',textAlign:'center',border:'1px solid var(--color-border)',borderRadius:5}}
            className="dark-field text-center h-100"
            value={availability.ranges[wday][index]['end']}
            onChange={(event, time) => {
              let interval = availability.ranges[wday][index]
              let start = interval.start
              let end = time
              let fixedInterval = {start:start,end:end}
              // fixedInterval = utils.checkInterval(availability,wday,fixedInterval)
              setDayRange(wday,index,fixedInterval)
            }}
            colon=":"
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  );
}

export default Range;
