import {useEffect,useState} from 'react';
import {useCreateBookingStore} from '../../store';
import spacetime from 'spacetime';
import Select from 'react-select';
import auth from '../../Account/Auth';
import { isIframe } from '../../Utilities/iframeUtils';

function TimezonePicker({mode,stylingParams}){
  const storeGuest = useCreateBookingStore(state => state.guest);
  const setGuestTimezone = useCreateBookingStore(state => state.setGuestTimezone);
  const [menuIsOpen, setMenuIsOpen] = useState(false); // track menu state
  const [manualSelection, setManualSelection] = useState(false);

  // styling params from url
  const backgroundMenuStyle = stylingParams["timezone-bg"];
  const textColorMenuStyle = stylingParams["timezone-text-color"];
  const backgroundActiveMenuStyle = stylingParams["timezone-active-bg"];

  const selectStyle = {
    control: (baseStyles,state) => ({
      ...baseStyles,
      marginLeft:45,
      marginRight:45,
      width:'calc(100% - 90px)',
      height:44,
      minWidth:200,
      background:
        (isIframe() && backgroundMenuStyle)
          ? backgroundMenuStyle
          : 'var(--color-background-2)',
      color:
        (isIframe() && textColorMenuStyle)
          ? textColorMenuStyle
          : 'var(--color-text)',
      border:'1px solid var(--color-border)',
      borderRadius:25
    }),
    input: (baseStyles,state) => ({
      ...baseStyles,
      color:
        (isIframe() && textColorMenuStyle)
          ? textColorMenuStyle
          : 'var(--color-text)',
      padding:7
    }),
    singleValue: (baseStyles,state) => ({
      ...baseStyles,
      color:
        (storeGuest?.timezone?.value === "etc/universal")
          ? 'var(--color-error)'
          : (isIframe() && textColorMenuStyle)
              ? textColorMenuStyle
              : 'var(--color-text)',
      padding:7
    }),
    placeholder: (baseStyles,state) => ({
      ...baseStyles, 
      padding:7
    }),
    menu: (baseStyles,state) => ({
      ...baseStyles, 
      width:'calc(100% - 160px)',
      margin:'0 80px',
      minWidth:200,
      background:
        (isIframe() && backgroundMenuStyle)
          ? backgroundMenuStyle
          : 'var(--color-background-2)',
      color:
        (isIframe() && textColorMenuStyle)
          ? textColorMenuStyle
          : 'var(--color-text)',
    }),
    option : (baseStyles,state) => ({
      ...baseStyles, 
      background:
        state.isFocused
          ? (isIframe() && backgroundActiveMenuStyle)
              ? backgroundActiveMenuStyle 
              : 'var(--color-primary)'
          : 'var(--color-background-2)',
      color:
        state.isFocused
          ? 'white'
          : 'var(--color-text)'
    })
  }

  const hoursToHHMM = (hours) =>{
    var h = String(Math.trunc(hours)).padStart(2, '0');
    var m = String(Math.abs(Math.round((hours - h) * 60))).padStart(2, '0');
    return h + ':' + m;
  }

  const getTimezoneObject = (key) => {
    let timezone = {};
    if(key !== undefined && key !== null && key !== ""){
      timezone = spacetime.now().goto(key).timezone();
    }else{
      timezone = spacetime.now().timezone();
      // https://stackoverflow.com/questions/76298879/is-etc-unknown-a-valid-timezone
      if(timezone?.name === "Etc/Unknown"){
        timezone = spacetime.now().goto("Etc/Universal").timezone();
      }
    }
    const name = timezone.name.toLowerCase();
    const displayName = name.replace(/["_"]/g, " ").replace(/\b\w/g, l => l.toUpperCase());
    const offset = timezone.current.offset; 
    const displayOffset = hoursToHHMM(offset);
    const label = `(GMT${offset<0?" ":" +"}${displayOffset}) ${displayName}`
    const result = {value: name, label:label};
    return result;
  }

  const timezones = spacetime.timezones();
  const getTimezoneOptions = (_timezones) => {
    let options = [];
    for(let key in _timezones){
      if(!key.includes('gmt') && !key.includes('utc')){
        let option = getTimezoneObject(key)
        options.push(option);
      }
    }
    return options;
  }

  useEffect(() => {
    const storageTimezone = auth.getProperty({
      storageKey: 'userSession',
      path: ['profileObj', 'timezone']
    })

    if(storageTimezone){
      console.info("[booking][timezone] Found user session, loading saved timezone:",auth.getTimezone())
      setGuestTimezone(getTimezoneObject(storageTimezone));
    }else{
      console.info("[booking][timezone] User session not found, guessing timezone:")
      setGuestTimezone(getTimezoneObject())
    }
  },[])

  return(
    <>
      {mode !== "delete" &&
        <div>
          {storeGuest?.timezone &&
            <Select
              placeholder="Type to search"
              styles={selectStyle}
              options={getTimezoneOptions(spacetime.timezones())}
              defaultValue={storeGuest?.timezone}
              value={menuIsOpen ? null : storeGuest?.timezone}
              onChange={setGuestTimezone}
              onMenuOpen={() => setMenuIsOpen(true)} // Add this line
              onMenuClose={() => setMenuIsOpen(false)} // And this line
            />
          }
          {storeGuest?.timezone?.value === "etc/universal" &&
            <p className="d-flex justify-content-center text-danger">
              Select your timezone!
            </p>
          }
        </div>
      }
    </> 
  );
}

export default TimezonePicker;
