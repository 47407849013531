import { useTranslation } from 'react-i18next';

function CurrentFeatures(props) {
  const { t } = useTranslation(["upgrade","common"]);
  const check = <i className="fas fa-check me-2 text-primary"></i>
  const cross = <i className="fas fa-times me-2 text-secondary"></i>

  return(
    <>
      {Object.keys(props.features).map(feature => (
        <div key={`current-plan-${feature}`} className="text-start my-3">
          {props.features[feature] ? check : cross}
          {t(`premium.features.${feature}`)}
        </div>
      ))}
    </>
  );
}

export default CurrentFeatures;
