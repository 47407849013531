function getHoursInt(timeString){
  return Number(timeString.split(':')[0]);
}
function getMinutesInt(timeString){
  return Number(timeString.split(':')[1]);
}
function appendLeadingZeroes(n){
  return (n<=9 ? ("0" + n) : n);
}
// INPUT:   time string like '08:00'
// OUTPUT:  milliseconds with new time set
function getMillisecondsFromString(timeString){
  let time = new Date();
  time.setHours(getHoursInt(timeString),getMinutesInt(timeString),0);
  return time.getTime();
}
// INPUT: milliseconds from Date.getTime()
// OUTPUT: time string
function getStringFromMilliseconds(milliseconds){
  let date = new Date(milliseconds);
  return appendLeadingZeroes(date.getHours()) + ':' + appendLeadingZeroes(date.getMinutes());
}
// INPUT:   milliseconds Date, minutesSpan Int
// OUTPUT:  milliseconds Date with minutes shifted by minutesSpan
function addMinutes(milliseconds,minutesSpan){
  let time = new Date(milliseconds);
  time.setHours(time.getHours(),(time.getMinutes() + minutesSpan));
  return time.getTime();
}

export function checkInterval(availability,wday,interval){
  let index = availability.ranges[wday].length - 1
  let start = getMillisecondsFromString(interval.start)
  let end = getMillisecondsFromString(interval.end)
  let previousEnd = ''
  if (index >=1){
    previousEnd = getMillisecondsFromString(availability.ranges[wday][index-1].end)
  }
  // se la fine è maggiore dell'inizio
  if(end > start){
    // se l'inizio è maggiore della fine precedente
    if(start > previousEnd){
      // aggiorno lo stato
      return interval
    }
    // se l'inizio è minore della fine precedente
    else{
      // allora diventa previousEnd + 15min
      interval.start = getStringFromMilliseconds(addMinutes(previousEnd,15))
      return interval
    }
  }
  // se start supera end allora end diventa : start + 15min
  else {
    interval.end = getStringFromMilliseconds(addMinutes(start,15))
    return interval
  }
}

// se sto aggiungendo un range extra
export function addIntervalCheck(availability,wday,index,interval) {
  let fixedInterval = interval
  if(index === 0){
    // crea il nuovo intervallo, non controllo altro
  } else {
    // prendo l'ora di fine del range precedente e ci aggiungo 15 min
    let previousEnd = getMillisecondsFromString(availability.ranges[wday][index-1].end)
    previousEnd = addMinutes(previousEnd,15)
    previousEnd = getStringFromMilliseconds(previousEnd)
    // se il nuovo start non è mezzanotte
    if(getHoursInt(previousEnd)!==0){
      fixedInterval.start = previousEnd
      // aggiungo all'end 15 min in pù dello start
      let newEnd = getMillisecondsFromString(previousEnd)
      newEnd = addMinutes(newEnd,15)
      newEnd = getStringFromMilliseconds(newEnd)
      // se il nuovo end non è mezzanotte
      if(getHoursInt(newEnd)!==0){
        // crea il nuovo intervallo
        fixedInterval.end = newEnd
      }
    }
  }
  return fixedInterval;
}
