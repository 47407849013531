import React from 'react';
import { useTranslation } from 'react-i18next';
import {Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import _PublicEvent from '../PublicEvent/_PublicEvent';

function _EventTypes(props){
  const {t} = useTranslation(['publicEvents']);
  return(
    <>
      <div style={{fontWeight:900,fontSize:32}}>{t('sessions')}</div>
      {Object.keys(props.eventTypes).length > 0
        ?
          Object.keys(props.eventTypes).map((key) => (
            <Col sm={12} className="m-2 mx-auto" style={{maxWidth:768}} key={'eventType-' + key}>
              <_PublicEvent key={key} id={key} state={props.eventTypes[key]} />
            </Col>
          ))
        :
          <span className="mx-auto"> {t("unavailable")} </span>
      }
    </>
  );
}

export default _EventTypes;
