import { useTranslation } from 'react-i18next';

function Host({hostName,hostMail}){
  const {t} = useTranslation(['home']);
  return (
    <div>
      <i className="fas fa-user me-2"></i> 
      <b>Host:</b>
      <div>
        {hostName}
      </div>
      <div>
        {hostMail}
      </div>
      <br/>
    </div>
  );
}

export default Host;
