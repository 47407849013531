import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import Heading from './Heading';
import be from '../../BE';
import auth from '../../Account/Auth';

const ResponseFeedback = ({ status, message }) => (
  <p className={status === 200 ? 'text-success' : 'text-danger'}>{message}</p>
);

const TelegramIcon = () => (
  <i
    className="fa-brands fa-telegram justify-content-center"
    style={{
      padding:'2px 5px',
      color:'#0088cc',
      background:'var(--color-background-1)',
      fontSize:32,
      border:'1px solid var(--color-border-light)',
      borderRadius:'5px 0 0 5px'
    }}>
  </i>

);

function Telegram(){
  const history = useHistory();
  const { t } = useTranslation(['integrations']);
  const [telegram, setTelegram] = useState('');
  const [status, setStatus] = useState(0);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(()=>{
    const getTelegram = async () => {
      const body = {};
      setLoadingInfo(true);
      try{
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('account-jubilant','/account/telegram',body,true,true)
            .then(response => {
              setInfoResponseStatus(200);
              setTelegram(response?.handle);
            })
            .catch(error => {
              console.error("[integrations][telegram] network error while retrieving info:",error);
              setInfoResponseStatus(error?.response?.status);
            })
        })
        .catch((error) => {
          console.error("[integrations][telegram] authentication error while retireving info:", error)
          history.push({pathname:'/login'})
        })
      }catch (error) { 
        console.error("[integrations][telegram] functional error while retrieving info:",error);
        setInfoResponseStatus(500);
      }finally{
        setLoadingInfo(false);
      }
    }
    getTelegram();
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setStatus(0);
    try{
      await auth.isAuthenticated()
        .then(async () => {
          const body = {
            handle: telegram.replace("https://t.me/","")
          }
          await be.post("account-jubilant","/account/telegram",body,true,true)
            .then((r) => {
              setStatus(200);
            })
            .catch((error) => {
              console.error("[integrations][telegram] network error while saving:",error);
              setStatus(error?.response?.status);
            })
        })
        .catch(error => {
          console.error("[integrations][telegram] authentication error while saving:", error)
          history.push({pathname:'/login'})
        })
    } catch (error) {
      console.error("[integrations][telegram] functional error while saving:",error);
      setStatus(500);
    } finally {
      setProcessing(false)
    }
  }
  return (
    <>
      <Heading/>
      <Form onSubmit={handleSubmit}>
        <InputGroup style={{flexWrap:'nowrap',height:38}}>
          <TelegramIcon />
          <IMaskInput
            type="text"
            value={telegram}
            disabled={loadingInfo || processing}
            mask={'{https://t.me/}username'}
            unmask={true}
            blocks={{ username: { mask: /^[a-zA-Z0-9_]*$/ } }}
            maxLength={32}
            placeholder="Enter your username (e.g. https//t.me/username)"
            onAccept={(tg) => setTelegram(tg)}
            style={{ width: '100%', padding: '6px 12px', border:'1px solid var(--color-border-light)', background:'var(--color-background-1)', color:'var(--color-text)' }}
          />
          <Button variant="outline-primary" disabled={loadingInfo || processing} type='submit'>
            {processing ? <Spinner size="sm" animation="border" variant="light" /> : t("telegram.save")}
          </Button>
          <Button variant="outline-primary" disabled={loadingInfo || processing} onClick={() => window.open('https://t.me/CalendbookBot?start=start', '_blank')}>
            {t("telegram.connect")}
          </Button>
        </InputGroup>
      </Form>
      {status > 400 && status <= 500 && <ResponseFeedback status={status} message={t("telegram.error")} />}
      {status === 200 && <ResponseFeedback status={status} message={t("telegram.success")} />}
    </>
  );
};

export default Telegram;
