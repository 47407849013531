import React from 'react';
import { useTranslation } from 'react-i18next';

function Title({name,taskNumber}){

  const { t } = useTranslation(['onboardingChecklist']);
  const componentClass = "fw-bold"

  return(
    <>
      <div className={componentClass}>
        {t(`${name}.${taskNumber}.title`)}
      </div>
    </>
  );

}


export default Title;
