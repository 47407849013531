import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';

function Duration (){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setDuration = useCreateEventTypeStore((state) => state.setDuration);

  return(
    <Form.Group as={Row} id="event-duration" className="my-3 ms-2 me-2">
      <Form.Label column sm={2}>{t('general.duration')}</Form.Label>
      <Col sm={10} lg={6}>
        <div>
          <Form.Check className="duration mx-0 ps-0 pe-1 col-3" inline type="radio" label="15" id="15" name="duration" value="15" onChange={(e) => setDuration(e.target.value)} defaultChecked={general?.duration === "15"}/>
          <Form.Check className="duration mx-0 ps-0 pe-1 col-3" inline type="radio" label="30" id="30" name="duration" value="30" onChange={(e) => setDuration(e.target.value)} defaultChecked={general?.duration === "30"}/>
          <Form.Check className="duration mx-0 ps-0 pe-1 col-3" inline type="radio" label="45" id="45" name="duration" value="45" onChange={(e) => setDuration(e.target.value)} defaultChecked={general?.duration === "45"}/>
          <Form.Check className="duration mx-0 ps-0 pe-0 col-3" inline type="radio" label="60" id="60" name="duration" value="60" onChange={(e) => setDuration(e.target.value)} defaultChecked={general?.duration === "60"}/>
        </div>
      </Col>
    </Form.Group>
  );
}

export default Duration;
