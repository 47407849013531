import React, { useState } from "react";
import { useEffect } from "react";
import { initFlowbite } from "flowbite";
import { Form, Spinner, Badge } from "react-bootstrap";
import auth from "../Account/Auth";
import be from "../BE";

const MemberRow = ({
  canUpgrade,
  member,
  canPromoteList,
  viewerRole,
  removeMember,
}) => {
  const [selectedRole, setSelectedRole] = useState(member.role);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initFlowbite();
  }, []);

  const handleChangeRole = async (newRole) => {
    setIsLoading(true);
    const body = {
      key: member.key,
      role: newRole,
    };
    try {
      await auth
        .isAuthenticated()
        .then(async () => {
          await be
            .post("team-manager", "/team/members/roles", body, true, true)
            .then((response) => {
              setSelectedRole(newRole);
            })
            .catch((response) => {
              console.error("[team] error changing user role: ", response);
            });
        })
        .catch(() => {});
    } catch (error) {
      console.error("Error updating member role:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const canChangeRole = () => {
    if (member?.status === "pending") {
      return false;
    }
    if (auth.getUsername() !== member?.key) {
      if (member?.role !== "owner") {
        if (
          (viewerRole === "owner" || viewerRole === "admin") &&
          canPromoteList.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getPlanColor = (_role) => {
    switch (_role) {
      case "LTD":
        return "green";
      case "professional":
        return "blue";
      case "premium":
        return "orange";
      case "free":
        return "gray";
      default:
        return "gray";
    }
  };

  return (
    <tr className="tw-border-b tw-dark:border-gray-600 hover:tw-bg-gray-100 tw-dark:hover:bg-gray-700">
      {/*--- user info ---*/}
      <th
        scope="row"
        className="tw-px-4 tw-py-2 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap tw-dark:text-white hover:tw-text-primary-500 tw-cursor-pointer"
      >
        <div className="tw-flex tw-items-center">
          <div className="tw-inline-flex tw-align-center tw-me-2">
            <img
              width="38px"
              height="38px"
              src={member?.pic}
              alt="profile-pic"
              className="tw-rounded-full"
              style={{ border: "3px solid var(--color-border-light)" }}
            />
          </div>
          <div className="tw-inline-block">
            <div>{member?.name}</div>
            <div className="text-secondary">{member?.email}</div>
          </div>
        </div>
      </th>

      {/*--- plan ---*/}
      <td className="tw-text-capitalize tw-align-middle">
        {member?.plan && (
          <span
            className={`tw-bg-${getPlanColor(
              member?.plan
            )}-100 tw-text-${getPlanColor(
              member?.plan
            )}-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded dark:tw-bg-blue-900 dark:tw-text-blue-300`}
          >
            {member?.plan}
          </span>
        )}
      </td>

      {/*--- change/view role ---*/}
      <td className="align-middle">
        {canChangeRole() ? (
          <>
            <button
              disabled={isLoading}
              id={`${member?.key}-role-button`}
              data-dropdown-toggle={`${member?.key}-role-dropdown`}
              className="tw-w-full tw-text-gray-900 tw-bg-gray-100 hover:tw-bg-gray-50 tw-font-medium tw-rounded-lg tw-text-sm tw-px-4 tw-py-1.5 tw-text-center tw-inline-flex tw-items-center dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-800"
              type="button"
            >
              {selectedRole}
              {isLoading ? (
                <Spinner
                  className="tw-ms-auto"
                  as="span"
                  animation="border"
                  size="sm"
                />
              ) : (
                <i className="fa-solid fa-angle-down tw-ms-auto"></i>
              )}
            </button>
            <div
              id={`${member?.key}-role-dropdown`}
              className="tw-z-10 hidden tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded-lg tw-shadow tw-w-44 dark:tw-bg-gray-700"
            >
              <ul
                className="tw-py-2 tw-text-sm tw-text-gray-700 dark:tw-text-gray-200"
                aria-labelledby={`${member?.key}-role-button`}
              >
                {canPromoteList.map((role) => (
                  <li key={`option-role-${member.key}-${role}`}>
                    <span
                      onClick={() => handleChangeRole(role)}
                      className="tw-block tw-px-4 tw-py-1 hover:tw-bg-gray-100 dark:hover:tw-bg-gray-600 dark:hover:tw-text-white"
                    >
                      {role}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <span className="tw-px-4">{selectedRole}</span>
        )}
      </td>

      {/*--- connection status ---*/}
      <td className="">
        {member?.status === "connected" ? (
          <i className="fas fa-check-circle text-success tw-px-4"></i>
        ) : (
          <i className="fas fa-times-circle text-warning tw-px-4"></i>
        )}
        {member?.status}
      </td>

      {/*--- remove member ---*/}
      {member?.role !== "owner" &&
        (viewerRole === "owner" || viewerRole === "admin") && (
          <td className="text-center align-middle">
            <button
              style={{ border: "none", background: "transparent" }}
              onClick={() => removeMember(member?.key)}
            >
              <i className="fas fa-user-minus text-danger me-2"></i>
            </button>
          </td>
        )}
    </tr>
  );
};

export default MemberRow;
