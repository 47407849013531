import React, {useEffect,useState} from 'react'; 
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../store';
import {Form,Row,Col} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import auth from '../../Account/Auth';
import be from '../../BE';

function Location (){
  const {t} = useTranslation(['eventCreator']);
  const general = useCreateEventTypeStore(state => state.general);
  const setLocation = useCreateEventTypeStore((state) => state.setLocation);
  const [connectionStatus,setConnectionStatus] = useState('checking')
  const [connectLink,setConnectLink] = useState('#')

  useEffect(() => {
    /* This check is needed to convert event types with old location to new notation*/
    if(typeof(general?.location) === 'string'){
      console.info("[etype-creator][general] location type is 'string', converting to obj...")
      const location = general?.location;
      let partialState = {}
      partialState = {"type" : "custom", "value" : location}
      setLocation(partialState)
      console.info("[etype-creator][general] location converted: ", partialState)
    }
    // re-check location connection every time an event type is opened
    checkConnectionStatus(general?.location?.type)
  },[]);
  
  const handleChange = (event)  => {
    const target = event.target;
    let value = '';
    value = event.target.value
    let partialState = {};
    if (general?.location?.type === 'custom'){
      partialState = {"type" : "custom", "value" : value}
    }else{
      partialState = value;
    }
    setLocation(partialState);
  }

  const checkConnectionStatus = async (_locationType) => {
    switch(_locationType){
      case 'zoom':
        setConnectionStatus('checking');
        await auth.isAuthenticated()
          .then(async () => {
            await be.get('integrations', '/zoom/userProfile', false, true)
              .then((response) => {
                if (Object.keys(response).length > 0) {
                  setConnectionStatus('connected');
                } else {
                  setConnectionStatus('disconnected');
                  setConnectLink('/integrations/#zoom');
                }
              })
              .catch((e) => {
                console.info("[location][zoom] Error fetching user profile:", e);
                setConnectionStatus('disconnected');
                setConnectLink('/integrations/#zoom'); // Set fallback link in case of error
              });
          })
          .catch((e) => {
            console.info("[location][zoom] Authentication error:", e);
            setConnectionStatus('disconnected');
            setConnectLink('/integrations/#zoom'); // Set fallback link in case of authentication error
          });
      break;
      case 'googleMeet':
        setConnectionStatus('checking');
        await auth.isAuthenticated()
          .then(async () => {
            await be.post('calendarhandler', "/stopLight", { calendar: auth.getEmail() }, true)
              .then((response) => {
                if (response.calendarStatus) {
                  setConnectionStatus('connected');
                } else {
                  setConnectionStatus('disconnected');
                  setConnectLink('/calendarconnections');
                }
              })
              .catch((e) => {
                console.info("[location][gmeet] Error fetching google calendar connection:", e);
                setConnectionStatus('disconnected');
                setConnectLink('/calendarconnections'); // Set fallback link in case of error
              });
          })
          .catch((e) => {
            console.info("[location][gmeet] Authentication error:", e);
            setConnectionStatus('disconnected');
            setConnectLink('/calendarconnections'); // Set fallback link in case of authentication error
          });
      break;
    }
  }

  const handleLocationTypeChange = (event) => {
    const target = event.target;
    const name = target.name;
    let partialState = {}
    if (name === 'custom'){
      partialState = {"type" : name, "value" : ""}
    } else if (name === 'googleMeet'){
      partialState = {"type" : name, "value" : "Google Meet"}
      checkConnectionStatus(name)
    } else if (name === 'zoom'){
      partialState = {"type" : name, "value" : "Zoom"}
      checkConnectionStatus(name)
    }
    setLocation(partialState);
  }

  const locationDisplayName = {
    custom: <i name="custom" className="far fa-keyboard me-2 align-bottom" style={{fontSize:22}}></i>,
    googleMeet: <img src="/app-assets/svg/google-meet-logo.svg" className="me-2 align-bottom" alt="Google Meet" width='24px'/>,
    zoom: <img src="/app-assets/svg/zoom-logo.svg" className="me-2 align-bottom" alt="Zoom" width='24px'/>
  }

  return(
    <Form.Group as={Row} controlId="event-location" className="my-3 ms-2 me-2">
      <Form.Label column sm={2}>{t('general.location')}</Form.Label>
      <Col sm={10} lg={6}>
        <InputGroup style={{flexWrap:'inherit'}}>
          <DropdownButton
            variant="outline-primary"
            id="location-dropdown"
            title={locationDisplayName[general?.location?.type]}
          >
            <Dropdown.Item onClick={handleLocationTypeChange} name="custom">
              <img name="custom" src="/app-assets/svg/keyboard-icon.svg" className="me-2" alt="Custom" width='24px'/>
              Custom
            </Dropdown.Item>

            <Dropdown.Item onClick={handleLocationTypeChange} name="googleMeet">
              <img name="googleMeet" src="/app-assets/svg/google-meet-logo.svg" className="me-2" alt="Google Meet" width='24px'/>
              Google Meet
            </Dropdown.Item>

            {/*ONLY AVAILABLE FOR PROD*/
              window.location.hostname === 'www.calendbook.com' &&
                <Dropdown.Item onClick={handleLocationTypeChange} name="zoom">
                <img name="zoom" src="/app-assets/svg/zoom-logo.svg" className="me-2" alt="Zoom" width='24px'/>
                Zoom
              </Dropdown.Item>
            }
          </DropdownButton>
          <Form.Control
            className="dark-field"
            name="location"
            value={
              general?.location?.type !== 'custom' 
                ? `${general?.location?.value} (${connectionStatus})` 
                : `${general?.location?.value}`
            }
            onChange={handleChange}
            maxLength={100}
            disabled={general?.location?.type !== 'custom'}
          />
          {general?.location?.type !== 'custom' && connectionStatus === 'disconnected' &&
      <Link to={{pathname: connectLink}}>
            <Button>Connect</Button>
      </Link>
          }
        </InputGroup>
      </Col>
    </Form.Group>
  );
}

export default Location;
