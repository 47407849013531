import auth from   '../../../Account/Auth';

function ProfilePic({pic}){
  // using calendbook profile pic if not set in stripe
  const calendbookPic = auth.getPic()
  return(
    <>
      <img className="profile-pic" src={pic ? pic : calendbookPic} alt="user-pic" width="60px" height="60px"></img>
      <img src="/app-assets/img/stripe-logo.png" className="stripe-logo" alt="Zoom" width="20px" height="20px"/>
    </>
  );
}

export default ProfilePic;

