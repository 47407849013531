import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WeekView from "./WeekView";
import AvailabilityMock from "../../Mocks/EventTypeCreator/Availability";
import be from "../../BE";
import auth from "../../Account/Auth";

function GeneralAvailabilities({ setStage }) {
  const { t } = useTranslation(["onboarding"]);
  const history = useHistory();
  const [availability, setAvailability] = useState(AvailabilityMock);

  const saveGeneralAvailabilities = async () => {
    const body = {
      availability: {
        days: availability?.days,
        ranges: availability?.ranges,
      },
    };

    try {
      await auth
        .isAuthenticated()
        .then(() => {
          be.post(
            "Onboarding",
            "/onboarding/availabilities/dayrange",
            body,
            true,
            true
          )
            .then((response) => {
              console.log(
                "[onboarding][general-availabilities] general availabilities saved"
              );
            })
            .catch((error) => {
              console.error(
                "[onboarding][general-availabilities] network error while saving general availabilities:",
                error
              );
            });
        })
        .catch((error) => {
          console.error(
            "[onboarding][general-availabilities] authentication error while saving general availabilities:",
            error
          );
          history.push({ pathname: "/login" });
        });
    } catch (error) {
      console.error(
        "[onboarding][general-availabilities] functional error while saving general availabilities:",
        error
      );
    } finally {
      setStage("event-advanced-availabilities");
    }
  };

  const toggleDayAvailability = (_day) => {
    const updatedDays = {
      ...availability.days,
      [_day]: !availability.days[_day],
    };
    const updatedRanges = { ...availability.ranges };
    if (updatedRanges[_day].length === 0 && updatedDays[_day]) {
      let interval = { start: "09:00", end: "19:00" };
      updatedRanges[_day].push(interval);
    }
    setAvailability({
      ...availability,
      days: updatedDays,
      ranges: updatedRanges,
    });
  };

  const setDayRange = (_day, _index, _interval) => {
    const updatedRanges = { ...availability.ranges };
    if (updatedRanges[_day].length === 0 && !availability.days[_day]) {
      updatedRanges[_day] = [_interval];
      setAvailability({
        ...availability,
        days: { ...availability.days, [_day]: true },
        ranges: updatedRanges,
      });
    } else {
      updatedRanges[_day][_index] = _interval;
      setAvailability({ ...availability, ranges: updatedRanges });
    }
  };

  const deleteDayRange = (templateId, _day, _index) => {
    const updatedRanges = { ...availability.ranges };
    updatedRanges[_day].splice(_index, 1);
    const updatedDays = { ...availability.days };
    if (updatedRanges[_day].length === 0) {
      updatedDays[_day] = false;
    }
    setAvailability({
      ...availability,
      days: updatedDays,
      ranges: updatedRanges,
    });
  };

  return (
    <>
      <h1 className="tw-mb-4 tw-text-2xl tw-font-extrabold tw-leading-tight tw-tracking-tight tw-text-gray-900 sm:tw-mb-6 tw-dark:text-white">
        {t("event.title")}
      </h1>

      <p className="tw-mb-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">
        {t("event.generalAvailability")}
      </p>

      <WeekView
        availability={availability}
        toggleDayAvailability={(day) => toggleDayAvailability(day)}
        setDayRange={(day, index, interval) =>
          setDayRange(day, index, interval)
        }
        deleteDayRange={(day, index) =>
          deleteDayRange(availability, day, index)
        }
      />

      <button
        type="button"
        onClick={saveGeneralAvailabilities}
        className="tw-my-6 tw-w-full tw-px-5 tw-py-2.5 sm:tw-py-3.5 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary-300 tw-dark:bg-primary-600 tw-dark:hover:bg-primary-700 tw-dark:focus:tw-ring-primary-800"
      >
        {t("event.nextBtn2")}
      </button>
    </>
  );
}

export default GeneralAvailabilities;
