export const RefundIta = `
CalendBook.com Refund Policy

Last Updated: 4th December 2023

Welcome to CalendBook.com. We are committed to customer satisfaction and compliance with EU consumer protection laws. This policy outlines the conditions under which refunds may be requested and processed.

Eligibility for Refunds:
14-Day Cooling-Off Period: In compliance with EU consumer rights, you have a 14-day cooling-off period to request a full refund, starting from the day of purchase. This is applicable for both one-time purchases and subscription-based services.
Non-conformity with Description: If the software significantly deviates from the description provided or does not function as advertised, you may request a refund within 30 days of purchase.
Technical Issues: If you encounter technical issues that prevent the proper use of our software and our team is unable to resolve the issue within a reasonable timeframe, you are eligible for a refund.
Subscription Cancellations: For subscription services, you may cancel at any time. However, refunds are only provided for the current billing cycle if the request is made within the 14-day cooling-off period.

Exclusions from Refunds:
Beyond Cooling-Off Period: No refunds will be issued for requests made beyond the 14-day cooling-off period, unless the product is non-functional or significantly different from its description.
Misuse or Unauthorized Use: Refunds will not be granted if the product was misused, or if issues arise due to unauthorized or improper use of the software.

Process for Requesting a Refund:
Contact Us: To initiate a refund, please contact our support team on the Live Chat at Calendbook.com. Provide your email and a detailed reason for the refund request.
Review Process: Our team will review your request and may contact you for additional information. Please allow up to 30 days for us to process your request.
Refund Issuance: If your refund is approved, it will be processed, and a credit will automatically be applied to your original method of payment, within a certain number of days.
`
