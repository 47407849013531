import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function OwnerName(props){
  return(
    <>
      <div className="h2"> {props.name} </div>
    </>
  );
}

export default OwnerName;
