import React from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap';
import auth from '../Account/Auth';

import 'bootstrap/dist/css/bootstrap.min.css';

function LifetimeMessage(){
  const { t } = useTranslation(['navbar','common']);
  const staticVendor = "/upgrade";
  let vendor = auth.getVendor();
  if(vendor === undefined || vendor === null || vendor === ""){
    vendor = staticVendor;
  }

  return(
    <>
        <Navbar bg="warning">
          <Nav className="text-dark d-block mx-3">
            <div className="d-block d-md-inline-block">{t('lifetimeMessage.deal')}<Link className="" to={{pathname: vendor }} target="_blank" rel="noreferrer"><b className="mx-1">{t('lifetimeMessage.dealLink')}</b></Link></div>
            <div className="d-block d-md-inline-block">{t('lifetimeMessage.code')}<Link className="" to={{pathname: '/settings#subscription'}} target="_blank" rel="noreferrer"><b className="mx-1">{t('lifetimeMessage.redeemLink')}</b></Link></div>
          </Nav>
        </Navbar>
    </>
  );
}

export default LifetimeMessage;
