import Name from './Name';
import Description from './Description';
import DateTime from './DateTime';
import Location from './Location';
import Payment from './Payment';

function Event({name,description,datetime,duration,timezone,location,isPaidEvent,paidEvent}){
  return(
    <div className="text-start col col-12 col-md-10 mx-auto my-5">
      <Name name={name}/>
      <Description description={description}/>
      <DateTime datetime={datetime} duration={duration} timezone={timezone}/>
      <Location location={location}/>
      <Payment isPaidEvent={isPaidEvent} paidEvent={paidEvent}/>
    </div>
  );
}

export default Event;
