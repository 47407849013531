import Form from 'react-bootstrap/Form';

function RobinType({robinType, setRobinType}) {
    const handleRobinTypeChange = (e,type) => {
        const isChecked = e.target.checked
        if(isChecked && type === 'equal_availability_distribution'){
            setRobinType('equal_availability_distribution');
        }else if(isChecked && type === 'maximized_availability_distribution'){
            setRobinType('maximized_availability_distribution');
        }
    }
    return(
        <div className="tw-my-4">
            <div><b>Robin Type</b></div>
            <Form.Check
                checked={robinType === 'equal_availability_distribution'}
                type="radio"
                label="Equal Availability Distribution"
                name="robin-type"
                required
                onChange={(e) => handleRobinTypeChange(e,'equal_availability_distribution')}
            />
            <Form.Check
                checked={robinType === 'maximized_availability_distribution'}
                type="radio"
                label="Maximized Availability Distribution"
                name="robin-type"
                required
                onChange={(e) => handleRobinTypeChange(e,'maximized_availability_distribution')}
            />
        </div>
    );
}

export default RobinType;
