import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const componentStyle = {
  color:"var(--color-text)"
}

function Name({name,isPaidEvent}){
  return(
    <>
      <span className="h5" style={componentStyle}>{isPaidEvent && '💵'} {name} </span>
    </>
  );
}

export default Name;
