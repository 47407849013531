import {useState} from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import auth from '../../../../Account/Auth';
import be from '../../../../BE';

const buttonStyle={
  padding:0,
  marginRight:20,
  background:'transparent',
  border:'none'
}

function EventApproval ({bookingId}){
  const [loadingAccept,setLoadingAccept] = useState(false);
  const [loadingReject,setLoadingReject] = useState(false);
  const history = useHistory();

  const acceptEvent = async (_bookingId) => {
    try{
      setLoadingAccept(true); 
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('jubilant-booking',`/booking/accept/${_bookingId}`,false,true,true)
            .then(response => window.location.href = '/')
            .catch(error => console.log(error));  
        })
    }catch(e){
      console.error(e);
      history.push('/login');
    }finally{
      setLoadingAccept(false);
    }
  }

  const rejectEvent = async (_bookingId) => {
    try{
      setLoadingReject(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('jubilant-booking',`/booking/reject/${_bookingId}`,false,true,true)
            .then(response => window.location.href = '/')
            .catch(error => console.log(error));  
        })
    }catch(e){
      console.error(e);
      history.push('/login');
    }finally{
      setLoadingReject(false);
    }
  }

  return(
    <div className="d-inline-flex">
      <button style={buttonStyle} onClick={() => acceptEvent(bookingId)} disabled={loadingAccept || loadingReject}>
        <span style={{color:'var(--bs-success)'}}>
          {loadingAccept
            ?<Spinner size="sm" className="me-1"/>
            :<i className="fa-solid fa-circle-check me-1"></i>
          }              
          Accept
        </span>
      </button>

      <button style={buttonStyle} onClick={() => rejectEvent(bookingId)} disabled={loadingAccept || loadingReject}>
        <span style={{color:'var(--bs-danger)'}}>
          {loadingReject
            ?<Spinner size="sm" className="me-1"/>
            :<i className="fa-solid fa-circle-xmark me-1"></i>
          }
          Reject
        </span>
      </button>
    </div>
  );
}

export default EventApproval;
