import * as Sentry from "@sentry/react";
import {useState,useEffect,useContext} from 'react';
import { useMediaQuery } from 'react-responsive'
import UserContext from '../../UserContext'
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useCreateBookingStore} from '../../store';
import {useTranslation} from 'react-i18next';
import {GoogleLogin} from '@react-oauth/google';
import Placeholder from 'react-bootstrap/Placeholder';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Surveys from './Surveys/_Surveys';
import spacetime from 'spacetime';
import be from '../../BE';
import auth from '../../Account/Auth';
import Login from '../../Login';
import './summary.css';
import { isIframe } from '../../Utilities/iframeUtils';

function Summary({username,eventType,mode,urlBookingId,setBookingStep,stylingParams}){
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  let history = useHistory();
  const {t,i18n} = useTranslation(['booking'])
  const [user,setUser] = useContext(UserContext);
  const storeDetails = useCreateBookingStore(state => state.details);
  const storeGuest = useCreateBookingStore(state => state.guest);
  const setGuestTime = useCreateBookingStore(state => state.setGuestTime);
  const setGuestName = useCreateBookingStore(state => state.setGuestName);
  const setGuestEmail = useCreateBookingStore(state => state.setGuestEmail);
  const setGuestNotes = useCreateBookingStore(state => state.setGuestNotes);
  const setGuestReason = useCreateBookingStore(state => state.setGuestReason);
  const storeSummary = useCreateBookingStore(state => state.summary);
  const setSummary = useCreateBookingStore(state => state.setSummary);
  const setSurveyAnswers = useCreateBookingStore(state => state.setSurveyAnswers);
  const storeAnswers = useCreateBookingStore(state => state.surveyAnswers);
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [loadingInfo,setLoadingInfo] = useState(false);
  const [submitResponseStatus,setSubmitResponseStatus] = useState(0);
  const [loadingSubmit,setLoadingSubmit] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(()=>{
    // styling params from url
    const backgroundStyle = stylingParams["summary-bg"];
    const textColorStyle = stylingParams["summary-text-color"];
    const textColorActiveStyle = stylingParams["summary-active-text-color"];

    // --- STYLING OPTIONS ---
    // valid only when the page is in Iframe
    if(isIframe()){
      // Create a new style element
      const style = document.createElement('style');
      style.type = 'text/css';

      // Start building the CSS string to inject
      let styleString = "\n/*--- Summary Iframe Custom Colors ---*/\n";

      // Choosen datetime
      styleString += `
        .summary{
          color: ${textColorStyle};
        }

        .chosen-datetime-label{
          color: ${textColorActiveStyle};
        }

        .reset-datetime{
          color: ${textColorStyle};
          background: ${backgroundStyle}
        }

        .reset-datetime:hover{
          color: ${textColorActiveStyle};
          border-color: ${textColorActiveStyle};
        }

        .summary input,
        .summary textarea{
          background: ${backgroundStyle};
          color: ${textColorStyle};
        }

        .summary input:disabled,
        .summary textarea:disabled{
          background: ${backgroundStyle};
        }

        .summary input:focus,
        .summary textarea:focus{
          color: ${textColorStyle};
          background: ${backgroundStyle};
          border-color; ${textColorActiveStyle}
        }

        .summary-submit,
        .summary-submit:disabled,
        .summary-sumbit:hover{
          color: ${textColorStyle} !important;
          background-color: ${textColorActiveStyle} !important;
          border-color: ${textColorActiveStyle} !important;
        }
      `
      // Set the generated CSS as the innerHTML of the style element
      style.innerHTML = styleString;

      // Append the style element to the head of the document
      document.head.appendChild(style);
    }

    const getSummary = (_username,_event,_time) => {
      let body = {
        ownerUsername: _username,
        eventKey: _event,
        bookingDateTime: _time,
      }
      if (mode === "reschedule") body.bookingId = urlBookingId;
      setLoadingInfo(true);
      be.get('jubilant-booking','/booking/summary',body,false,true)
        .then(response => {
          // save response to state
          console.info("[booking][getSummary] Success:");
          console.log(response);
          setInfoResponseStatus(200);
          setLoadingInfo(false);
          setSummary(response)
        })
        .catch(error => {
          console.info("[booking][getSummary] Error:");
          console.error(error);
          Sentry.addBreadcrumb({
            category: "booking",
            message: `Failed to get summary info for: ${body?.eventKey}`,
            level: "error",
            data: body
          })
          Sentry.captureException(error,(scope) => {
            scope.setTransactionName(`[booking][summary] error while getting summary info`);
            return scope;
          });
          setInfoResponseStatus(error?.response?.status);
          setLoadingInfo(false);
          toast.error(`Error Fetching Booking Summary`)
        })
    } 
    const getUserSessionStatus = () => {
      console.info("[getUserSessionStatus] entering the function...")
      auth.isAuthenticated()
        .then(() => {
          console.info("[getUserSessionStatus] setting as logged true...")
          setUser({logged:true})
        })
        .catch(() => {
          console.info("[getUserSessionStatus] setting as logged false...")
          setUser({logged:false})
        })
    }

    mode !== "delete" && getSummary(username,eventType,storeGuest?.time)
    getUserSessionStatus()
  },[storeGuest?.time])

  function getUserLanguage(){
    return  i18n.language.substring(0,2);
  }

  function formatDate(date){
    // Date formatting options
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // Getting date object from db
    var dateFormatted = new Date(date);
    // Format: weekday, monthname daynumber, year
    dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
    // To uppercase string
    dateFormatted = dateFormatted.toString();

    return dateFormatted;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mode === "book"){
      let body = {
        bookingId: storeSummary?.booking_id,
        bookingDateTime: storeGuest?.time,
        ownerUsername: username,
        eventKey: eventType,
        answers:storeAnswers,
        notes: storeGuest?.notes,
        guest: {
          guestName: storeGuest?.name,
          guestEmail: storeGuest?.email,
          guestTimezone: spacetime.now().goto(storeGuest?.timezone?.value).timezone().name
        },
        satellite: storeGuest?.satellite
      }
      if(searchParams.get("paid")){body.paid = searchParams.get("paid")}
      setLoadingSubmit(true);
      await be.post('jubilant-booking','/booking',body,user?.logged,true,true)
        .then((response) => {
          console.info('[booking][submit] Success:');
          console.log(response);
          setSubmitResponseStatus(response?.status)
          setLoadingSubmit(false);
          // reset summary and answers status
          setSummary({});
          setSurveyAnswers({});
          if(response?.status === 201){
            // Redirect to stripe payment page
            window.open(response?.data?.state,"_blank");
          } else if(response?.status === 200){
            // Redirect to confirmation page
            const confirmationState = {
              owner: storeDetails?.owner?.fullName,
              event: storeDetails?.event?.name,
              description: storeDetails?.event?.description,
              datetime: storeGuest?.time,
              duration: storeDetails?.event?.duration,
              timezone: spacetime.now().goto(storeGuest?.timezone?.value).timezone().name,
              redirectionUrl: storeDetails?.event?.redirectionUrl,
              location: storeDetails?.event?.location,
              needsApproval: storeDetails?.event?.needsApproval
            }
            history.push({
              pathname: `/operations/confirm/${storeSummary?.booking_id}`,
              state: confirmationState
            });
          }
        }) 
        .catch(error => {
          console.info('[booking][submit] Error:');
          console.error(error);
          Sentry.addBreadcrumb({
            category: "booking",
            message: `Failed to book event: ${body?.eventKey}`,
            level: "error",
            data: body
          })
          Sentry.captureException(error,(scope) => {
            scope.setTransactionName(`[booking][summary] error while booking event`);
            return scope;
          });
          setSubmitResponseStatus(error?.response?.status)
          setLoadingSubmit(false);
          toast.error(`Error Booking Event`)
        });
    }

    if(mode === "reschedule"){
      const body = {
        bookingId: urlBookingId,
        bookingDateTime: storeGuest?.time,
        answers: storeSummary?.surveys,
        reason: storeGuest?.reason,
        notes: storeGuest?.notes,
      }
      setLoadingSubmit(true);
      await be.patch('jubilant-booking','/booking',body,false,true)
        .then((response) => {
          console.info('[booking][submit] Success:');
          console.log(response);
          setSubmitResponseStatus(response?.status)
          setLoadingSubmit(false);
          // reset summary and answers status
          setSummary({});
          setSurveyAnswers({});
          toast.success(`Rescheduled`)
          // Redirect to confirmation page
          const confirmationState = {
            owner: storeDetails?.owner?.fullName,
            event: storeDetails?.event?.name,
            description: storeDetails?.event?.description,
            datetime: storeGuest?.time,
            duration: storeDetails?.event?.duration,
            timezone: spacetime.now().goto(storeGuest?.timezone?.value).timezone().name,
            redirectionUrl: storeDetails?.event?.redirectionUrl,
            location: storeDetails?.event?.location
          }
          history.push({
            pathname: `/operations/confirm/${storeSummary?.booking_id}`,
            state: confirmationState
          });
        }) 
        .catch(error => {
          console.info('[booking][submit] Error:');
          console.error(error);
          Sentry.addBreadcrumb({
            category: "booking",
            message: `Failed to reschedule event: ${body?.eventKey}`,
            level: "error",
            data: body
          })
          Sentry.captureException(error,(scope) => {
            scope.setTransactionName(`[booking][summary] error while rescheduling event`);
            return scope;
          });
          setSubmitResponseStatus(error?.response?.status)
          setLoadingSubmit(false);
          toast.error(`Error Rescheduling Event`)
        });
    }

    if(mode === "delete"){
      const body = {
        bookingId: urlBookingId,
        reason: storeGuest?.reason,
      }
      setLoadingSubmit(true);
      await be.del('jubilant-booking','/booking',body,false,true)
        .then((response) => {
          console.info('[booking][delete] Success:');
          console.log(response);
          setSubmitResponseStatus(response?.status)
          setLoadingSubmit(false);
          toast.success(`Deleted`)
          // Redirect to confirmation page
          const confirmationState = {
            deleted:true,
            owner: storeDetails?.owner?.fullName,
            event: storeDetails?.event?.name,
            description: storeDetails?.event?.description,
            datetime: storeGuest?.time,
            duration: storeDetails?.event?.duration,
            timezone: spacetime.now().goto(storeGuest?.timezone?.value).timezone().name,
            redirectionUrl: storeDetails?.event?.redirectionUrl,
            location: storeDetails?.event?.location
          }
          history.push({
            pathname: `/operations/confirm/${urlBookingId}`,
            state: confirmationState
          });
        }) 
        .catch(error => {
          console.info('[booking][delete] Error:');
          console.error(error);
          Sentry.addBreadcrumb({
            category: "booking",
            message: `Failed to reschedule event: ${body?.eventKey}`,
            level: "error",
            data: body
          })
          Sentry.captureException(error,(scope) => {
            scope.setTransactionName(`[booking][summary] error while rescheduling event`);
            return scope;
          });
          setSubmitResponseStatus(error?.response?.status)
          setLoadingSubmit(false);
          toast.error(`Error Cancelling Event`)
        });
    }
  }

  return(
    <>
      {loadingInfo
        ?
          <div className="summary">
            Loading...
          </div>
          :
          <div className="summary">
            {storeGuest?.time &&
            <div>
              <div className="chosen-datetime-label">{t('final.chosen')}</div>
              <div className="summary-header">
                <div className="chosen-datetime text-capitalize">
                  {formatDate(storeGuest?.time)},
                  {storeGuest?.timeFormat === "24h"
                    ? <> {spacetime(storeGuest?.time).format('{time-24}')}</>
                    : <> {spacetime(storeGuest?.time).format('{time}')}</>
                  }
                </div>
                {mode !== "delete" &&
                <div className="summary-controls d-flex ms-auto">
                  {isMobile && <button className="summary-step-back" onClick={() => setBookingStep(3)}> <i className="fa-solid fa-arrow-left-long"></i> </button>}
                  <button className="reset-datetime" onClick={() => {setGuestTime("");setBookingStep(2)}}>{t('final.chooseAnother')}</button>
                </div>
                }
              </div>
              <hr className="summary-ruler"/>
              <Form onSubmit={handleSubmit}>
                {(mode === "book" || mode === "reschedule") &&
                <>

                  {user.logged 

                    ? 
                      <>
                        {auth.getFullName()}, {t('final.loggedMessage')}
                        {
                          setGuestName(auth.getFullName())
                        }
                        {
                          setGuestEmail(auth.getEmail())
                        }
                      </>
                      : 
                      <>
                        <Login 
                          callback={() => {
                            auth.isAuthenticated()
                              .then(() => {
                                console.info("[getUserSessionStatus] setting as logged true...")
                                setUser({logged:true})
                              })
                              .catch(() => {
                                console.info("[getUserSessionStatus] setting as logged false...")
                                setUser({logged:false})
                              })
                          }}
                        />
                      </>

                  }

                  <Row className="summary-attendee-details">
                    <Form.Group as={Col} md="12" xl="6">
                      <Form.Label>{t('final.name')}</Form.Label>
                      <Form.Control type="text" required disabled={user.logged} value={storeGuest?.name} onChange={(e) => setGuestName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="12" xl="6">
                      <Form.Label>{t('final.email')}</Form.Label>
                      <Form.Control  type="email" required disabled={user.logged} value={storeGuest?.email} onChange={(e) => setGuestEmail(e.target.value)}/>
                    </Form.Group>
                  </Row>

                  <Surveys mode={mode}/>

                  <Form.Group>
                    <Form.Label>{t('final.notes')}</Form.Label>
                    <Form.Control as="textarea" rows="3" name="notes" placeholder={t('final.notesPlaceholder')} onChange={(e) => setGuestNotes(e.target.value)}/>
                  </Form.Group>
                </>
                }
                {(mode === "reschedule" || mode === "delete") && 
                  <Form.Control required as="textarea" rows="2" name="reason" placeholder={t('final.reason')} onChange={(e) => setGuestReason(e.target.value)}/>
                }
                {mode === "book" && storeDetails?.event?.isPaidEvent &&
                  <p>
                    {t('final.paidEvent.termsText')} <a href="/stripe-terms" target="_blank"> {t('final.paidEvent.termsLink')}</a>
                  </p>
                }
                <div className="d-grid gap-2">
                  <Button type="submit" disabled={loadingSubmit} className="summary-submit text-capitalize mt-3">{loadingSubmit ? <Spinner animation="border" size="sm"/> : t(`final.${mode}`)}</Button>
                </div>
              </Form>
            </div>
            }
          </div>
      }

    </>
  );
}

export default Summary;
