import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import auth from '../Account/Auth';


function UserPic(){

  return(
    <>
      <img src={auth.getPic()} width="40px" className="img-fluid rounded-circle" alt="user-pic" />
    </>
  );
}

export default UserPic;
