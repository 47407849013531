import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import be from '../../../../BE';
import auth from '../../../../Account/Auth';

const frontendFormatter = (number) => {
  console.info('[upcomings][price] parsing be price:');
  let result = '';
  if(number !== undefined){
    result = Number(number)/100;
  }
  console.info(result);
  return result;
};

// TODO: controllare errori, re-rendering
async function deleteEvent (bookingId){
  await auth.isAuthenticated()
    .then(() => {
      const body = { bookingid : bookingId }
      be.del('booking','/booked',body,true)
        .then(response => window.location.href = '/')
        .catch(error => console.log(error));  
    })
    .catch(() => this.props.history.push('/login'));
}

function Delete (props){
  const { t, i18n } = useTranslation(['common','home']);
  const [deleteAlert,setDeleteAlert] = useState(false)
  let history = useHistory();

  return(
    <>
      <button
        onClick={() => setDeleteAlert(true)}
        style={{ padding:0, marginRight:10, border : "none", background : "transparent", color : "var(--color-text)", textDecoration:'underline'}}
      >

        <i class="fas fa-trash me-1"></i> {t('home:upcomings.details.delete')}

      </button>

      {deleteAlert &&
      <SweetAlert
        warning
        showCancel
        confirmBtnText={t("yes")}
        cancelBtnText={t("cancel")}
        confirmBtnStyle={{border:'none',boxShadow:'none'}}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="secondary"
        title={t("areYouSure")}
        //onConfirm={() => deleteEvent(props.bookingid)}
        onConfirm={() => history.push({pathname:`/operations/delete/${props.bookingid}`})}
        onCancel={() => setDeleteAlert(false)}
        style={{background:'var(--color-background-1)',color:'var(--color-text)'}}
      >
        {props?.amount && props?.currency &&
          <>
            {getSymbolFromCurrency(props?.currency)} {frontendFormatter(props?.amount)} Payment will be refunded
          </>
        }
      </SweetAlert>
      }
    </>
  );
}

export default Delete;
