import React, {useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup'
import be from '../../../BE';
import auth from '../../../Account/Auth';

function WelcomeSentence(){
  const {t} = useTranslation(['settings']);
  const [welcomeSentence, setWelcomeSentence] = useState(auth.getFullName());
  const [loadingInfo,setLoadingInfo] = useState(false)
  const [infoResponseStatus,setInfoResponseStatus] = useState(0);
  const [processing,setProcessing] = useState(false)
  const [status, setStatus] = useState(0);

  useEffect(()=>{
    const getWelcomeSentence = async () => {
      const body = {};
      setLoadingInfo(true);
      await auth.isAuthenticated()
        .then(async () => {
          await be.get('account-jubilant','/account/settings/sentence',body,true,true)
            .then(response => {
              console.info("[settings][welcomeSentence] Success: ");
              console.log(response?.status);
              setInfoResponseStatus(200);
              setLoadingInfo(false);
              setWelcomeSentence(response?.status);
            })
            .catch(error => {
              console.info("[settings][welcomeSentence] Unable to fetch: ");
              console.error(error);
              setInfoResponseStatus(error?.response?.status);
              setLoadingInfo(false);
            })
        })
        .catch((e) => {console.log(e)})
    }
    getWelcomeSentence();
  },[])

  const responseFeedbackStyle = {
    fontSize:14
  }

  const handleChange = (e) => {
    setStatus(0);
    let inputText = e.target.value;
    if (inputText.length > 800) {
      inputText = inputText.slice(0, 800); // Truncate the input to the maxLength
    }
    setWelcomeSentence(inputText);
  }

  const isValidWelcomeSentence = () => {
    //const regex = /^(\p{L}+ ?)*\p{L}+$/gu
    //return regex.test(welcomeSentence)
    return true;
  }

  async function setWelcomeSentenceBE(e) {
    if(!isValidWelcomeSentence()){
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setProcessing(true);
      setStatus(0);
      await auth.isAuthenticated()
        .then(async () => {
          await be.post("account-jubilant","/account/settings/sentence",{sentence : welcomeSentence},true,true)
            .then((r) => setStatus(200))
            .catch((e) => {setStatus(e?.response?.status)})
        })
        .catch(error => console.log(error))
      setProcessing(false);
    }
  }

  return(
    <>
      <h4>{t("bio.title")}</h4>
      <p>{t("bio.subtitle")}</p>
      <Form noValidate validated={isValidWelcomeSentence()} onSubmit={setWelcomeSentenceBE}>
        <InputGroup hasValidation>
          <FormControl
            as="textarea"
            rows={3}
            type="text"
            max="800"
            name="welcome-sentence"
            id="welcome-sentence-field"
            aria-describedby="welcome-sentence"
            value={loadingInfo ? "Loading..." : welcomeSentence}
            disabled={loadingInfo}
            onChange={handleChange}
            isInvalid={!isValidWelcomeSentence()}
          />
          <Button disabled={loadingInfo || processing} type='submit'>
            {processing 
              ? 
                <Spinner size="sm" animation="border" variant="light" /> 
                : 
                <i className="fas fa-long-arrow-alt-right"></i>
            }
          </Button>
          <Form.Control.Feedback type="invalid">
            <span>{t("bio.invalid.message")}</span>
            <br/>
            <span>{t("bio.invalid.hint")}</span> 
          </Form.Control.Feedback>
        </InputGroup>
        <div>
          <small style={{float:'right',marginRight:42,color:'var(--color-secondary)'}}>
            {welcomeSentence && welcomeSentence.length
              ? welcomeSentence.length
              : 0
            }
            /800
          </small>
        </div>
      </Form>
      {(status > 400 && status <= 500) &&
      <p className="text-danger" style={responseFeedbackStyle}>{t("bio.error")}</p>
      }
      {status === 200 &&
        <p className="text-success" style={responseFeedbackStyle}>{t("bio.success")}</p>
      }
    </>
  );
}

export default WelcomeSentence;
