import React, { useRef } from 'react';
import { Container, Row, Col, Button, Card, Accordion} from 'react-bootstrap';
import { FaRocket, FaPuzzlePiece, FaUserShield } from 'react-icons/fa';
import Pricing from '../Pricing';
import './agency-landing-page.css'

function LandingPage({openCheckout}){
  const upgradeSectionRef = useRef(null);
  const scrollToUpgradeSection = () => {upgradeSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};

  const UpgradeSection = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <Pricing openCheckout={props?.openCheckout}/>
    </div>
  ));

  const HeroSection = () => (
    <Container className="text-center my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="display-4">Revolutionize Your Agency's Operations</h1>
          <p className="lead">Dive into a world where account management meets unparalleled efficiency, customization, and automation. "Agency" is not just a tool; it's your partner in redefining how your agency operates and thrives in the digital age.</p>
          <p>Embrace the future of agency management today and see how we transform challenges into opportunities, setting you miles apart from the competition.</p>
          <a className="btn btn-success" onClick={scrollToUpgradeSection}>Upgrade Now</a>
        </Col>
      </Row>
    </Container>
  );

  const ProblemSolution = () => (
    <Container className="problem-solution-content">
      <h2 className="problem-solution-header">Streamline Your Workflow Like Never Before</h2>
      <p className="problem-solution-text problem-solution-animation">
        Do constant account switches and cumbersome management tasks drain your team's productivity? "Agency" is here to revolutionize the way your agency operates. Our platform empowers you to manage multiple company accounts with unprecedented ease, ensuring seamless transitions, comprehensive customization, and powerful automation at your fingertips.
      </p>
      <p className="problem-solution-text problem-solution-animation">
        Embrace the future of agency account management. Say goodbye to the inefficiencies holding you back and hello to a world where your potential is limitless.
      </p>
      <center><Button className="btn-success" onClick={scrollToUpgradeSection}>Discover "Agency" Today</Button></center>
    </Container>
  );

  const HowItWorks = () => (
    <Container className="my-5">
      <h2 className="text-center mb-4">Getting Started with "Agency" is Easy</h2>
      <Row>
        <Col md={4} className="mb-3">
          <Card className="step-animate step-1">
            <Card.Body>
              <Card.Title>1. Sign Up</Card.Title>
              <Card.Text>Begin by creating your agency account. It's quick, easy, and you'll be up and running in no time.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="step-animate step-2">
            <Card.Body>
              <Card.Title>2. Add Accounts</Card.Title>
              <Card.Text>Easily add and manage multiple company accounts from a single dashboard. No more hassle of logging in and out.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card className="step-animate step-3">
            <Card.Body>
              <Card.Title>3. Customize & Automate</Card.Title>
              <Card.Text>Customize each account with unique settings, automate tasks, and take advantage of advanced integrations.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <p className="text-center mt-4">With "Agency", you can focus more on growing your business and less on the logistics of account management.</p>
    </Container>
  );

  const USPSection = () => (
    <Container className="usp-section my-5">
      <h2 className="usp-title">Why "Agency" is Your Best Choice</h2>
      <Row>
        <Col md={4} className="text-center">
          <div className="usp-icon"><FaRocket size="3em" color="#ffbf69" /></div>
          <h3>Speed & Efficiency</h3>
          <p>Launch projects faster with our streamlined account management system, designed for efficiency.</p>
        </Col>
        <Col md={4} className="text-center">
          <div className="usp-icon"><FaPuzzlePiece size="3em" color="#ffbf69" /></div>
          <h3>Unmatched Customization</h3>
          <p>Custom tailor every aspect of your account for a truly personalized experience that stands out.</p>
        </Col>
        <Col md={4} className="text-center">
          <div className="usp-icon"><FaUserShield size="3em" color="#ffbf69" /></div>
          <h3>Privacy & Security</h3>
          <p>Your data's security is our top priority, ensuring your information is protected at every level.</p>
        </Col>
      </Row>
    </Container>
  );

  const PricingStrategy = () => (
    <Container className="pricing-section my-5">
      <Card className="pricing-card">
        <Card.Body>
          <div className="special-offer">Limited Time Offer</div>
          <Card.Title className="mb-4">Exclusive Introductory Pricing</Card.Title>
          <Card.Text>
            <p>Unlock the full potential of "Agency" at an unbeatable price. </p>
            <p className="card-highlight">Now starting only $8,2/month</p> {/* Example price, adjust as needed */}
            <p>for a limited time.</p>
          </Card.Text>
          <Button variant="primary" onClick={scrollToUpgradeSection}>Get Started</Button>
          <Card.Text className="mt-3">
            Don't miss this opportunity to enhance your agency's efficiency and customization capabilities at a fraction of the cost.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );

  const FAQSection = () => (
    <Container className="faq-section my-5">
      <h2 className="faq-header text-center">Frequently Asked Questions</h2>
      <Accordion defaultActiveKey="0" className="faq-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>What makes "Agency" different from other account management tools?</Accordion.Header>
          <Accordion.Body>
            Unlike other tools, "Agency" is specifically designed to empower agencies managing multiple company accounts with seamless switching, advanced customization, and automation features, setting a new standard in efficiency and control.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>How does the pricing model work?</Accordion.Header>
          <Accordion.Body>
            Our pricing is simple and transparent, with no hidden fees. Starting at just $99/month, you get full access to all features. This introductory rate is part of our commitment to making advanced account management affordable for agencies of all sizes.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Can I cancel my subscription at any time?</Accordion.Header>
          <Accordion.Body>
            Absolutely. We believe in the value of our service, but if you decide it's not right for you, you can cancel your subscription without penalty.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>What if I’m not sure about subscribing without a trial period?</Accordion.Header>
          <Accordion.Body>
            We understand the importance of making informed decisions, especially without a trial period. That's why we're committed to providing detailed resources, customer success stories, and 24/7 support to ensure you have all the information and assistance you need to see how "Agency" can revolutionize your account management. Our flexible subscription options and dedicated support are designed to make your transition as smooth as possible, ensuring you can confidently invest in "Agency" to drive your agency's success.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>How secure is "Agency"?</Accordion.Header>
          <Accordion.Body>
            Security is our top priority. We employ the latest encryption and security practices to ensure your data and your clients' data is always protected.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>What kind of support can I expect?</Accordion.Header>
          <Accordion.Body>
            Our dedicated support team is available 24/7 to assist with any questions or issues you may encounter. Exceptional customer service is a cornerstone of our business.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );

  return (
    <div>
      <HeroSection />
      <ProblemSolution />
      <HowItWorks />
      <PricingStrategy /> 
      <USPSection />
      <UpgradeSection ref={upgradeSectionRef} openCheckout={openCheckout}/>
      <FAQSection />
    </div>
  );
}

export default LandingPage;
