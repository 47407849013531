import Delete from './Delete';
import Reschedule from './Reschedule';
import Guest from './Guest';
import Host from './Host';
import Location from './Location';
import Price from './Price';
import Notes from './Notes';
import Surveys from './Surveys/_Surveys';
import EventApproval from './EventApproval';

const componentStyle = {
  fontSize:16,
  fontWeight:300,
}

function Details (props){
  const details = props?.state;

      console.log(props)
  return(
    <span style={componentStyle}>

      <Guest guestName={details?.guestname} guestMail={details?.guestmail}/>

      {details?.assignedMember &&
        <Host hostName={details?.assignedMember?.name} hostMail={details?.assignedMember?.email}/>
      }

      <Location location={details?.location} gCalInfo={details?.GCalInfo}/>

      <Price amount={details?.paidEvent?.amount} currency={details?.paidEvent?.currency}/> 

      <Surveys answers={details?.answers} bookingId={details?.bookingid} etype={details?.eventname}/>

      <Notes notes={details?.notes}/>


      {details?.action === "on_wait" && details?.bookingPendingReason === "pending_confirmation" &&
        <EventApproval bookingId={details?.bookingid}/>
      }

      {details?.action === "on_wait" && details?.bookingPendingReason === "pending_payment" &&
        <u>Get paid by your guest to confirm the event</u>
      }

      {(details?.action === "booking" || details?.action === "reschedule") &&
        <>
          <Delete bookingid={details?.bookingid} amount={details?.paidEvent?.amount} currency={details?.paidEvent?.currency}/>
          <Reschedule bookingid={details?.bookingid}/>
        </>
      }
     
    </span>
  );
}

export default Details;
