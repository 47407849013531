import {useTranslation} from 'react-i18next';
import {useCreateEventTypeStore} from '../../../../store';
import Form from 'react-bootstrap/Form';

function Required({required,order,id}){
  const {t} = useTranslation(['eventCreator']);
  const setQuestionRequired = useCreateEventTypeStore((state) => state.setQuestionRequired);

  return(
    <div className="my-2">
      <Form.Check 
        type="checkbox"
        name="required-check"
        id={`required-check-${id}`}
        label={t('surveys.required')}
        onChange={() => setQuestionRequired(id)}
        checked={required}
        style={{'float':'right'}}
      />
    </div>
  );
}

export default Required;
