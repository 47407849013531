import React from 'react';
import { useTranslation } from 'react-i18next';
import {Nav} from 'react-bootstrap';

function AccountTab(){
  const { t } = useTranslation(['settings']);
  return(
    <Nav.Item>
      <Nav.Link eventKey="account" style={{fontSize:14}} className="text-center text-md-start">
        <i style={{width:20}} className="fas fa-user text-center me-2"></i>{t('account.panelName')}
      </Nav.Link>
    </Nav.Item>
  );
}

export default AccountTab;
