import React, {useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

function Templates({
  member,
  templates = [],
  selectedTemplate,
  disabled,
  loadingTemplates,
  handleTemplateSelection,
  getMemberTemplates
}) {

  useEffect(() => {
    if(!selectedTemplate)
    getMemberTemplates(member)
  },[])

const selectStyle = {
    control: (baseStyles,state) => ({
      ...baseStyles,
      width:'100%',
      minWidth:100,
      minHeight:31,
      background:'var(--color-background-2)',
      color:'var(--color-text)',
      border:'1px solid var(--color-border)'
    }),
    singleValue: (baseStyles,state) => ({
      ...baseStyles,
      color:'var(--color-text)'
    }),
    menu: (baseStyles,state) => ({
      ...baseStyles, 
      width:'100%',
      minWidth:200,
      background:'var(--color-background-2)',
      color:'var(--color-text)'
    }),
    option : (baseStyles,state) => ({
      ...baseStyles, 
      background: state.isFocused ? 'var(--color-primary)' : 'var(--color-background-2)',
      color: state.isFocused ? 'white' : 'var(--color-text)'
    })
  ,}

  const Button = window.calendbookUI.Button;

  return (
    <div className="tw-flex tw-flex-col tw-ml-auto tw-w-44">
      <Form.Label className="tw-text-sm tw-text-gray-500">availability template</Form.Label>
      <Select
        styles={selectStyle}
        value={{label:selectedTemplate, value:selectedTemplate}}
        options={templates.map(template => ({ label: template, value: template }))}
        onMenuOpen={() => getMemberTemplates(member)}
        onChange={(template) => handleTemplateSelection(template.value,member)}
        isLoading={loadingTemplates}
        placeholder="Select a template"
      />
    </div>
  );
}

export default Templates;

