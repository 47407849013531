import React from 'react';
import { useTranslation } from 'react-i18next';
import {Nav} from 'react-bootstrap';

function NotificationsTab(){
  const { t } = useTranslation(['settings']);
  return(
    <Nav.Item>
      <Nav.Link eventKey="notifications" style={{fontSize:14}} className="text-center text-md-start">
        <i style={{width:20}} className="fa-solid fa-bell text-center me-2"></i>{t('notifications.panelName')}
      </Nav.Link>
    </Nav.Item>
  );
}

export default NotificationsTab;
