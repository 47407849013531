import React, {useState} from 'react';
import {useWebhooksConnectionsStore} from '../../../store';
import {useTranslation} from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import be from '../../../BE';
import auth from '../../../Account/Auth';

function TestWebhook({hook}){
  const {t} = useTranslation(['integrations','common']);
  const [processing,setProcessing] = useState(false)
  const deleteWebhookStore = useWebhooksConnectionsStore((state) => state.deleteWebhook);

  async function testWebHook(_hook){
    setProcessing(true)
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('webhook',"/webHookTester",{hook:_hook},true)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log('error in /settings/webhook page: ',error)
          });
      })
      .catch(() => this.props.history.push('/login'))
    setProcessing(false)
  }

  const testButton = {
    minWidth:45.85,
    marginRight:10,
    marginTop:'auto',
    marginBottom:'auto'
  }

  const sendTestTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t('webhooks.sendTestTooltip')}
    </Tooltip>
  );

  return(
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      overlay={sendTestTooltip}
    >
    <Button onClick={() => testWebHook(hook)} clasName="me-2" style={testButton} size="sm" disabled={processing}>
      {processing
        ?
          <Spinner size="sm" animation="border"/> 
        :
          <span>{t('webhooks.sendTestButton')}</span>

      }
    </Button>
    </OverlayTrigger>
  );
}
export default TestWebhook;
