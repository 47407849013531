import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

function CopyInviteLink({ inviteLink }) {
  const { t } = useTranslation(["team"]);
  const [copied, setCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Button size="sm" variant="light" onClick={copyLink}>
      <b className="me-1"> {t("invite.copyLink")} </b>
      {copied ? (
        <i className="far fa-check-circle"></i>
      ) : (
        <i className="fa-solid fa-copy"></i>
      )}
    </Button>
  );
}

export default CopyInviteLink;
