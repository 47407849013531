import React,{useContext} from 'react';
import ThemeContext from '../../../ThemeContext';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DeleteWebhook from './DeleteWebhook';
import TestWebhook from './TestWebhook';
import partners from '../partners-list.js';

const getProvider = (_url,_supportedProviders) =>  {
  let fullHostname = _url.split('/')[2]
  let urlComponents = fullHostname.split('.')
  let provider = urlComponents[urlComponents.length -2]
  provider = checkProvider(provider,_supportedProviders)
  return provider
}

const checkProvider = (_provider, _supportedProviders) => {
  if(_supportedProviders.includes(_provider)){
    return _provider
  } else {
    return 'standard'
  }
}

function WebhookCard({hook,hookName,url}){

  const [theme] = useContext(ThemeContext)
  const supportedProviders = Object.keys(partners)
  const provider  = getProvider(url,supportedProviders)

  const componentStyle={
    overflow:'hidden',
    maxWidth:'60ch',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    border:'1px solid',
    borderRadius:'15px',
    margin:'10px 0px',
    padding:'3px 10px',
    fontSize:14,
    borderColor:partners[provider][theme].border,
    color:partners[provider][theme].text,
    backgroundColor:partners[provider][theme].background,
  }

  return(
    <Card className="my-2" style={{color:'var(--color-text)',background:'var(--color-background-1)'}}>
      <Card.Body>
        <Row>
          <Col xs={8}>
            <Card.Title className="text-capitalize">
              {hookName}
            </Card.Title>

            <Card.Subtitle className="mb-2" style={componentStyle}>
              {url}
            </Card.Subtitle>

          </Col>

          <Col xs={4} className="d-flex justify-content-end">
            <TestWebhook hook={hook}/>
            <DeleteWebhook hook={hook}/>
          </Col>
        </Row>
      </Card.Body>
    </Card>

  );

}
export default WebhookCard;
