import React from 'react';
import {useHistory} from "react-router";
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next';

function ConnectionAlert (props){

  const history = useHistory();
  const { t } = useTranslation(['home']);
  
  const componentStyle = {
    maxWidth:400,
    margin: "20px 0",
    border: "1px solid var(--color-border)",
    borderRadius: 10
  }

  return(
    <Alert variant="warning" style={componentStyle}>
      <Alert.Heading>{t('connectionAlert.title')}</Alert.Heading>
      <p>
        {t('connectionAlert.subtitle')}
      </p>
      <div className="d-flex justify-content-end">
        <Button onClick={() => history.push({pathname:'/calendarconnections'})} variant="outline-primary">
          {t('connectionAlert.button')}
        </Button>
      </div>
    </Alert>

  );
}

export default ConnectionAlert;
