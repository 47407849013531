import Badge from 'react-bootstrap/Badge';

const componentStyle={
  fontSize:20,
  fontWeight:300,
  whiteSpace:'nowrap',
  maxWidth:'100%',
  display:'block',
  overflow:'hidden',
  '-o-text-overflow':'ellipsis',
  textOverflow:'ellipsis'
}

const pillStyle = {
  fontSize: '0,7em'
}

function Guest ({name,amount,currency, pendingReason, action}){
  return(
    <span style={componentStyle}>
      {name}
      {amount && currency && <span className="ms-1">💸</span>}
      {action === "on_wait" && pendingReason && 
        <small className="ms-1 d-inline-flex align-middle" style={pillStyle}>
          <Badge pill bg="warning">{pendingReason.replaceAll("_"," ")}</Badge>
        </small>
      } 
    </span>
  );
}

export default Guest;
