import React from 'react';
import {Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import OwnerName from './OwnerName';
import OwnerPic from './OwnerPic';
import OwnerWelcome from './OwnerWelcome';

function _OwnerInfo(props){
  return(
    <>
      {Object.keys(props?.ownerInfo).length > 0  &&
        <Col>
          <OwnerPic pic={props?.ownerInfo?.pic}/>
          <OwnerName name={props?.ownerInfo?.name}/>
          <OwnerWelcome sentence={props?.ownerInfo?.sentence}/>
        </Col>
      }
    </>
  );
}

export default _OwnerInfo;
