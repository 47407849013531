import {useTranslation} from 'react-i18next';
import spacetime from 'spacetime';
import { getTimeZones } from "@vvo/tzdb";
import i18n from '../../i18n';

function getTimezoneLongName(timezone){
  let result = '';
  try{
    let timezoneData = getTimeZones().find(tz => tz?.name === timezone);
    if (timezoneData){
      result = timezoneData?.alternativeName;
    }else{
      console.info('[booking][confirmation] Error parsing timezone long name, timezoneData is not valid, returning to standard timezone country.');
      result = timezone;
    }
  }catch(e){
    console.info('[booking][confirmation] Error parsing timezone long name: ',e);
    if (timezone !== null || timezone !== undefined || timezone !== ''){
      result = timezone;
    }
  }finally{
    return result;
  }
}
function getUserLanguage(){
  return  i18n.language.substring(0,2);
}

function isAmpm(){
  if(getUserLanguage() === "it"){return false}
  return true
}

function formatDate(date){
  // Date formatting options
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // Getting date object from db
  var dateFormatted = new Date(date);
  // Format: weekday, monthname daynumber, year
  dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
  // To uppercase string
  dateFormatted = dateFormatted.toString();

  return dateFormatted;
}
function formatTime(date, duration){
  let time = spacetime(date, );
  let start = ''
  isAmpm() ? start = time.format('{time}') : start = time.format('{time-24}')
  time = time.add(duration,'minutes');
  let end = ''
  isAmpm() ? end = time.format('{time}') : end = time.format('{time-24}')
  return `${start} - ${end}`
}
function DateTime({datetime,duration,timezone}){
  const {t} = useTranslation(['booking']);
  const displayTime = formatTime(datetime, duration)
  const displayDate = formatDate(datetime)
  return(
    <div className="my-3 d-flex align-items-center">
      
      <div className="event-info-icon">
        <i className="fa-solid fa-clock"></i>
      </div>

      <div>
        <div className="mx-3" style={{fontSize:12,color:'var(--color-secondary)'}}>{t('confirmed.datetime')}</div>
        <div>
          <span className="mx-3 text-capitalize d-block d-sm-inline">{displayDate},</span>
          <span className="ms-3 ms-sm-0 me-2 d-block d-sm-inline">{displayTime}</span>
          <span style={{color:'var(--color-secondary)'}} className="ms-3 ms-sm-2 d-block d-sm-inline">({getTimezoneLongName(timezone)})</span>
        </div>
      </div>
      
    </div>
  );
}

export default DateTime;
