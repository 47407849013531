import { useContext } from 'react';
import ThemeContext from '../../../ThemeContext';

function PartnerCard({partnerName,partnerData}){
    const [theme] = useContext(ThemeContext)
    const componentStyle = {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        width : "100%",
        minWidth : 150,
        minHeight : 75,
        border : `1px solid ${partnerData[theme]["border"]}`,
        borderRadius : "10px",
        color : partnerData[theme]["text"],
        backgroundColor : partnerData[theme]["background"],
        textTransform : "capitalize",
        fontFamily : "Roboto",
        fontWeight : "900",
        letterSpacing : "-0.050em",
        fontSize : 25 
    }

    return(
        <a href={partnerData.link} className="d-flex w-100 text-decoration-none" target="_blank" rel="noopener noreferrer">
            <div className="my-2 mx-0 mx-lg-2" style={componentStyle}>
                {partnerName}
            </div>
        </a>
    );
}

export default PartnerCard;
