import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateEventTypeStore } from '../../store';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import RobinType from './RobinType';
import Members from './Members';
import SelectMembers from './SelectMembers';
import SelectedMembers from './SelectedMembers';
import auth from '../../Account/Auth';
import be from '../../BE';
import RoundRobinMock from '../../Mocks/EventTypeCreator/RoundRobin';

function RoundRobin(){
    const history = useHistory();
    const editKey = useCreateEventTypeStore(state => state?.editKey);
    const general = useCreateEventTypeStore(state => state?.general);
    const roundRobin = useCreateEventTypeStore((state) => state.roundRobin);
    const setRoundRobin = useCreateEventTypeStore((state) => state.setRoundRobin);
    const robinType = useCreateEventTypeStore(state => state?.roundRobin?.config?.robinType);
    const getRobinMembers = useCreateEventTypeStore(state => state.getRobinMembers);
    const setRobinMembers = useCreateEventTypeStore(state => state.setRobinMembers);
    const setBookingType = useCreateEventTypeStore((state) => state?.setBookingType);
    const setRobinType = useCreateEventTypeStore((state) => state?.setRobinType);
    const [loadingMembers, setLoadingMembers] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [isEditing,setIsEditing] = useState(editKey ? true : false)

    let members = getRobinMembers();

    const transformRoundRobinMembers = (roundRobin) => {
        if (!roundRobin || typeof roundRobin !== 'object') {
            return [];
        }

        return Object.entries(roundRobin).reduce((result, [key, member]) => {
            if (key !== 'robinType' && key !== 'config' && key !== 'availability_pool' && member && typeof member === 'object') {
                const {
                    name = "Unknown",
                    email = "user@domain.com",
                    pic = "https://example.com/default-pic.png",
                    priority = 2,
                    availability_template = ""
                } = member;

                result.push({
                    username: key,
                    name,
                    email,
                    pic,
                    priority,
                    availability_template
                });
            }
            return result;
        }, []);
    }

    const handleRoundRobinCheckbox = (e) => {
        const isChecked = e.target.checked;
        if(isChecked){
            setBookingType('round_robin');
        }else{
            // if i'm in create or edit mode, reset the state and mode
            // so if i re-enable the round robin i get a clean environement
            setBookingType('standard');
            setRoundRobin(RoundRobinMock);
        }
    }

    return(
        <>
            <Card style={{ color: 'var(--text-color)', background: "var(--color-background-1)" }} className="mb-2">
                <Card.Header className="h3">
                    Round Robin
                </Card.Header>
                <Card.Body>

                    {/* team not enabled */}
                    {!general?.isTeam &&
                        <p>
                            You need to enable Team Mode Checkbox under 'General' to use Team Features 
                        </p>
                    }

                    {/* team enabled */}
                    {general?.isTeam &&
                        <div>
                            <Col sm={10} lg={6}>
                                <Form.Check
                                    type="checkbox"
                                    label="enable round robin for this event"
                                    name="is-round-robin-checkbox"
                                    checked={general?.bookingType === 'round_robin'}
                                    onChange={handleRoundRobinCheckbox}
                                />
                            </Col>
                        </div>
                    }

                    {/* round robin enabled & members request loading */}
                    {/*
                    {general?.bookingType === 'round_robin' && loadingMembers  &&
                        <div>
                            loading team members...
                        </div>
                    }
                    */}

                    {/* round robin enabled but members not available */}
                    {/*
                    {general?.bookingType === 'round_robin' && !loadingMembers && members && members.length <= 0  &&

                        <div>
                            You don't have any team member. To use a team feature, manage your team in &nbsp;
                            <a href="/team" target="_blank" style={{textDecoration:'underline',color:'var(--color-primary)'}}>team dashboard.</a>
                        </div>
                    }
                    */}

                    {/* round robin enabled & members available */}
                    {general?.bookingType === 'round_robin' &&
                        <div>
                            <RobinType robinType={robinType} setRobinType={setRobinType}/>
                            {/*
                                need to change:
                                new component: members selection (SelectMembers)
                                new component: members list (SelectedMembers)

                                so i can better distinguish what happens and what to render in each component
                                - maybe i need to use global state and readapt etype saving manually by selecting the fields accordingly
                            */}
                            <SelectMembers/>
                            <SelectedMembers members={transformRoundRobinMembers(roundRobin)} />
                            {/*<Members members={members} setMembers={setMembers} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} isEditing={isEditing}/>*/}
                        </div>
                    }

                </Card.Body>
            </Card>
        </>
    );
}

export default RoundRobin;
