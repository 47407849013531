import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Edit({id}){
  return(
    <>
      <Link
        to={{
          pathname: '/eventeditor',
          state: {id}
        }}
      >
        <button className="icon-button">
          <i className="fas fa-pencil-alt"></i>
        </button>
      </Link>
    </>
  );
}

export default Edit;
