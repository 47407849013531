import {useState} from 'react';
import RemoveCalendar from './RemoveCalendar';
import ChangePermissions from './ChangePermissions';
import SubCalendars from './SubCalendars';
import Syncronize from './Syncronize';
import './calendar.css';

function Calendar({index,fullName,email,pic,access,calendars,setCalendars,sync}){
  const [loadingPermissions,setLoadingPermissions] = useState(false);

  return(
    <div className="calendar-container">

     <div style={{display:'flex',alignItems:'center',marginBottom:10}}>
       <img width="32px" height="32px" src={pic} alt="profile-pic" className="rounded-circle me-2"/>
       <div style={{display:'flex',flexDirection:'column'}}>
         <span style={{fontWeight:500}}>{fullName}</span>
         <small style={{color:'var(--color-secondary)'}}>{email} {calendars[index]?.stopLight ? '✅' : '⚠️'}</small>
       </div>
       <RemoveCalendar email={email}/>
     </div>
      <ChangePermissions
        mode='check'
        index={index}
        email={email}
        access={access}
        calendars={calendars}
        setCalendars={setCalendars}
        loadingPermissions={loadingPermissions}
        setLoadingPermissions={setLoadingPermissions}
      />
      <ChangePermissions
        mode='add'
        index={index}
        email={email}
        access={access}
        calendars={calendars}
        setCalendars={setCalendars}
        loadingPermissions={loadingPermissions}
        setLoadingPermissions={setLoadingPermissions}
      />
      {/*<Syncronize index={index} email={email} sync={sync} />*/}
      <SubCalendars index={index} email={email} calendars={calendars} subCalendars={calendars[index]?.subCalendars}/>
    </div>
  );
}

export default Calendar;
