import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import auth from '../../Account/Auth';
import be from '../../BE';

const Proration = ({ show, onHide, prorationDetails, priceId, quantity }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  // Reset states when the modal is opened
  useEffect(() => {
    if (show) {
      setConfirmLoading(false);
      setConfirmSuccess(false);
      setConfirmError('');
    }
  }, [show]);

  const handleConfirm = async () => {
    setConfirmLoading(true);
    setConfirmSuccess(false);
    setConfirmError('');

    const body = {
      priceId: prorationDetails?.priceId,
      quantity: prorationDetails?.quantity,
    };

    try {
      await auth.isAuthenticated();
      const response = await be.patch("payment-jubilant", "/subscriptions", body, true, true);
      console.log(response);
      setConfirmSuccess(true);
      setTimeout(() => {
        onHide(); // Close modal after 3 seconds
      }, 3000);
    } catch (error) {
      console.error(error);
      setConfirmError('Unable to Confirm');
    } finally {
      setConfirmLoading(false);
    }
  };

  // Determine the button variant based on the state
  const getButtonVariant = () => {
    if (confirmError) return "danger"; // Red if there's an error
    if (confirmSuccess) return "success"; // Green if confirmed
    return "primary"; // Blue if not yet clicked
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Subscription Change Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {prorationDetails && 
          <div>
            {prorationDetails?.action === "charge"
              ?
                <div>You will be now charged <strong>{prorationDetails?.amount / 100} {prorationDetails?.currency}</strong> for the update.</div>
              :
                <div>You will now get a <strong>{prorationDetails?.amount / 100} {prorationDetails?.currency}</strong> credit for the update. The credit will be used in your next billing.</div>
            }
            {Number(prorationDetails?.quantity) &&
              <small className="mt-3">Once confirmed, you will have {Number(prorationDetails?.quantity)} {Number(prorationDetails?.quantity) === 1 ? <>seat</> : <>seats</>}.</small> 
            }
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={confirmLoading}>Cancel</Button>
        <Button 
          variant={getButtonVariant()} 
          onClick={handleConfirm} 
          disabled={confirmLoading}>
          {confirmLoading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : confirmSuccess ? (
            'Confirmed!'
          ) : (
            confirmError || 'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Proration;

