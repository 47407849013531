import {useTranslation} from 'react-i18next';
import ProfilePic from './ProfilePic';
import FullName from './FullName';
import Email from './Email';
import Dashboard from './Dashboard';
import './user-info.css';

function UserInfo({stripeUser}){
  const {t} = useTranslation(['integrations']);
  return(
    <>
      {stripeUser &&
        <div className="mt-4">
          <div className="user-info">
            <ProfilePic pic={stripeUser?.picUrl}/>
            <div className="user-details">
              <FullName fullName={stripeUser?.displayName}/>
              <Email email={stripeUser?.email}/>
            </div>
          </div>
          <p>{t('stripe.userInfo.fee')}</p>
          <Dashboard url={stripeUser?.dashboardUrl}/>
        </div>
      }
    </>
  )
}

export default UserInfo;

