import { useEffect,useState } from 'react';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import auth from '../../Account/Auth';

const DEBUG = (window.location.hostname !== 'www.calendbook.com');
const TEST_TOKEN = "test_2ee936d492e84b8c70ff282cacf";
const LIVE_TOKEN = "live_f9e2fd79f6f00aa1b9f8a4f55ec";

function Checkout({priceId,customerId}){
  const {i18n,t} = useTranslation(['integrations']);
  const [loading, setLoading] = useState(false);
  const [paddle, setPaddle] = useState(null);
  const getUserLanguage = () => {try{return i18n.language.substring(0,2)}catch(e){return 'en'}}

  var createNestedObject = function( base, names ) {
    // Usage:
    // createNestedObject( window, ["shapes", "triangle", "points"] );
    // Now window.shapes.triangle.points is an empty object, ready to be used.
    for( var i = 0; i < names.length; i++ ) {
      base = base[ names[i] ] = base[ names[i] ] || {};
    }
  };

  const sleep = (ms) => {
    /* usage: await sleep(3000); */
    return new Promise((resolve) => setTimeout(resolve,ms));
  }
  
  useEffect(() => {
    initializePaddle(
      {
        environment: (DEBUG ? 'sandbox' : 'production'),
        checkout: {
          settings: {
            displayMode:"overlay",
            theme:'light',
            locale:getUserLanguage()
          }
        },
        token: (DEBUG ? TEST_TOKEN : LIVE_TOKEN),
        eventCallback: async function(data) {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("[upgrade] checkout completed, updating user profile...");
              setLoading(true)
              await sleep(5000)
              window.location.replace("/integrations#whatsapp");
              window.location.reload();
              break;
            default:
          }
        } 
      })
      .then((paddleInstance) => {
        if(paddleInstance) {setPaddle(paddleInstance)}
      });
  }, []);

  const openCheckout = () => {
    let checkoutObj = {
      items: [
        {
          priceId: priceId,
          quantity: 1
        }
      ]
    }
    if(customerId){
      createNestedObject(checkoutObj,["customer","id"]);
      checkoutObj['customer']['id'] = customerId;
    }else if(auth.getEmail()){
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = auth.getEmail();
    }else{
      createNestedObject(checkoutObj,["customer","email"]);
      checkoutObj['customer']['email'] = "your@email.com";
    }
    paddle?.Checkout.open(checkoutObj);
  }

  return(
    <Button size="sm" onClick={openCheckout} disabled={loading}>{t('whatsapp.checkout')} {loading && <Spinner size="sm"/>}</Button>
  );
}

export default Checkout;
