import {useTranslation} from 'react-i18next';

function Credits({credits}){
  const { t } = useTranslation(['integrations']);

  let messageColor = 'var(--color-text))';
  if(Number(credits) <=  10) { messageColor = 'var(--color-error)'; }

  return(
    <>
      <div style={{fontSize:20,fontWeight:700,color:messageColor}}>{t('whatsapp.credits.left',{credits : credits})}</div>
      <div>{t('whatsapp.credits.upgrade')}</div>
    </>
  );
}

export default Credits;
