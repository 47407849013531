import {useEffect, useState} from 'react';
import Heading from './Heading';
import ConnectSalesForza from './ConnectSalesForza';
import DisconnectSalesForza from './DisconnectSalesForza';
import UserInfo from './UserInfo/UserInfo';
import be from '../../BE';
import auth from '../../Account/Auth';

function SalesForza(){
  const [salesForzaUser,setSalesForzaUser] = useState({})
  const [loading,setLoading] = useState(false)

 useEffect(() => {
    console.info("[SalesForza] loading user info")
    const checkConnectionStatus = async () => {
      setLoading(true)
        await auth.isAuthenticated()
        .then(async () => {
          await be.get('account-jubilant','/salesforza',false,true,true)
          .then(response => {
            setSalesForzaUser(response);
          })
          .catch((e) => {
            console.info("[integrations][SalesForza] Error fetching user profile:",e);
          });
        })
        .catch((e) => {
          console.info("[integrations][SalesForza] Error authenticating user:",e);
        });
      setLoading(false)
    }
    checkConnectionStatus()
  },[])

  const isConnected = (_obj) => {
    return Object.keys(_obj).length > 0 && _obj?.email
  }

   return(
    <>
      <Heading/>
      {loading
        ?
          <>Loading...</>
        :
          <>
            {isConnected(salesForzaUser) && <UserInfo salesForzaUser={salesForzaUser}/>}
            {isConnected(salesForzaUser) && <DisconnectSalesForza salesForzaUser={salesForzaUser} setSalesForzaUser={setSalesForzaUser}/>}
            {!isConnected(salesForzaUser) && <ConnectSalesForza setSalesForzaUser={setSalesForzaUser}/>}
          </>
      }
    </>
  );}

export default SalesForza;
