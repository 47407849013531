function ProfilePic({pic}){
  return(
    <>
      {pic && <img className="profile-pic" src={pic} alt="user-pic" width="60px" height="60px"></img>}
    </>
  );
}

export default ProfilePic;

