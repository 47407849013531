import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useCreateEventTypeStore} from '../../../store';
import be from '../../../BE';
import auth from '../../../Account/Auth';

function Templates(){
  const history = useHistory();
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState(null);
  const selectedTemplate = useCreateEventTypeStore(state => state.availability.template);
  const setSelectedTemplate = useCreateEventTypeStore(state => state.setSelectedTemplate);
  const setAvailability = useCreateEventTypeStore(state => state.setAvailability);

  const Button = window.calendbookUI.Button;

  useEffect(()=>{
    const getAvailabilityTemplates = async () => {
      setLoadingTemplates(true);
      try{
        await auth.isAuthenticated()
          .then(async () => {
            await be.get('jubilant-events','/events/templates/availabilities',false,true,true)
              .then(response => {
                setTemplates(response);
              })
              .catch(error => {
                console.error("[settings][availabilities] network error while retrieving info:",error);
              })
          })
          .catch((error) => {
            console.error("[settings][availabilities] authentication error while retireving info:", error)
            history.push({pathname:'/login'})
          })
      }catch (error) { 
        console.error("[settings][availabilities] functional error while retrieving info:",error);
      }finally{
        setLoadingTemplates(false);
      }
    }
    getAvailabilityTemplates();
  },[])

  const handleTemplateSelection = (e,_key) => {
    e.preventDefault();
    setAvailability(templates[_key])
    setSelectedTemplate(_key);
  }

  return(
    <div className="tw-ms-4">
      Templates
      <div className="tw-my-2">
        {loadingTemplates 
          ? 
            <>
              loading...
            </> 
            : 
            <div className="tw-overflow-auto tw-inline-flex tw-gap-2 tw-items-center">
              {templates && Object.keys(templates).map(templateKey => (
                <Button
                  key={templateKey}
                  radius="rounded"
                  color="primary"
                  outline={(templateKey === selectedTemplate) ? false : true}
                  onClick={(e) => handleTemplateSelection(e,templateKey)}
                  buttonText={templateKey}
                  size="xs"
                />
              ))}
              <Button
                radius="rounded"
                color="primary"
                outline
                iconStyle="plus"
                iconOnly
                onClick={(e) => {e.preventDefault(); window.open('/settings#availabilities',"_blank")}}
              />
            </div>
        }
      </div>
    </div>
  );
}

export default Templates;
