import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { useCalendarConnectionsStore } from '../store';
import auth from '../Account/Auth';
import be from '../BE';

function RemoveCalendar({email,index}){
  const { t } = useTranslation(['calendarConnections']);
  const deleteCalendar= useCalendarConnectionsStore((state) => state.deleteCalendar);
  const [statusCode,setStatusCode] = useState(0);
  const [loading,setLoading] = useState(false);
  const disconnectCalendar = async (_email) => {
    setStatusCode(0);
    setLoading(true);
    await auth.isAuthenticated()
      .then(async () => {
        await be.post('calendarhandler',"/deleteCalendar",{email:_email},true)
        .then((response) => {
          setStatusCode(200);
          deleteCalendar(_email);
          setLoading(false);
        })
        .catch((error) => {
          setStatusCode(error?.response?.status)
          setLoading(false);
        });
      })
      .catch(() => this.props.history.push('/login'))
  }
  return(
    <div className="ms-auto">
      <Button 
        variant={
          (statusCode >= 400 && statusCode <= 500) 
          ? 'outline-danger' 
          : 'outline-secondary'
        } 
        size="sm"
        style={{minWidth:50}}
        onClick={() => disconnectCalendar(email)}
      >
        {loading
          ?
            <Spinner animation="border" size="sm"/>
          :
          <>
            <i className="fas fa-user-times me-0 me-md-2"></i>
            <span className="d-none d-md-inline-block">{t('disconnectCalendarBtn')}</span> 
          </>
        }
      </Button>
    </div>
  );
}

export default RemoveCalendar;
