import {useCreateEventTypeStore} from '../../../store';
import {useTranslation} from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Hop(){
  const {t} = useTranslation(['eventCreator']);
  const availability = useCreateEventTypeStore(state => state.availability);
  const setTimeslotHop = useCreateEventTypeStore((state) => state.setTimeslotHop);

    return(
        <Form.Group as={Row} controlId="event-booking-limit" className="mx-2">
            <Form.Label column sm={2} className="mt-auto">{t('availability.timeslotHop.title')}</Form.Label>
            <Col sm={10} lg={6}>
                <Row>
                    <Row><small style={{color:'var(--color-secondary)'}}>{t('availability.timeslotHop.hint')}</small></Row>
                    <Col xs={5}>
                        <Form.Select className="dark-field text-center" aria-label="Booking Limit Unit" value={availability?.hop || "15"} onChange={(e) => setTimeslotHop(e.target.value)}>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                        </Form.Select>
                    </Col>
                    <Col xs={2} className="d-flex justify-content-center my-auto" style={{color:'var(--color-secondary)'}}>{t('availability.timeslotHop.minutes')}</Col>
                </Row>
            </Col>
        </Form.Group>
);
}

export default Hop;
