import React from "react";
import { useTranslation } from "react-i18next";

const BentoGrid = () => {
  const { i18n, t } = useTranslation(["landing"]);

  const boxes = [
    {
      id: 1,
      imgSrc: "img/notifications-feature.png",
      alt: "Communication Platforms",
      title: t("bento.notifications.title"),
      description: t("bento.notifications.description"),
    },
    {
      id: 2,
      imgSrc: "img/accept-reject-feature.png",
      alt: "Scheduling",
      title: t("bento.scheduling.title"),
      description: t("bento.scheduling.description"),
    },
    {
      id: 3,
      imgSrc: "img/integrations-feature.png",
      alt: "Automation Tools",
      title: t("bento.integrations.title"),
      description: t("bento.integrations.description"),
    },
    {
      id: 4,
      imgSrc: "img/leads-feature.png",
      alt: "Leads Management",
      title: t("bento.leads.title"),
      description: t("bento.leads.description"),
    },
    {
      id: 5,
      imgSrc: "img/payments-feature.png",
      alt: "Earn Money",
      title: t("bento.earn.title"),
      description: t("bento.earn.description"),
      additionalClasses:
        "md:tw-col-span-2 lg:tw-col-start-1 lg:tw-row-start-2 lg:tw-col-span-2",
    },
  ];

  return (
    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 tw-p-6 tw-w-full">
      {boxes.map((box) => (
        <div
          style={{ background: "var(--color-background-1)" }}
          key={box.id}
          className={`tw-relative tw-border tw-border-gray-400 tw-rounded-xl tw-shadow-lg tw-transform tw-transition tw-duration-300  hover:tw-scale-105 lg:hover:tw-scale-110 hover:tw-z-10 hover:tw-shadow-2xl tw-overflow-hidden ${
            box.additionalClasses || ""
          }`}
        >
          {/* Image Section */}
          <div className="tw-relative tw-w-full tw-h-64 tw-overflow-hidden tw-rounded-t-xl">
            {/* Image */}
            <img
              src={box.imgSrc}
              alt={box.alt}
              className="tw-w-full tw-h-64 tw-object-cover"
            />
          </div>

          {/* Text Content */}
          <div className="tw-p-4 tw-border-t tw-border-t-gray-400">
            <h3 className="tw-text-base tw-font-semibold tw-mb-1">
              {box.title}
            </h3>
            <p className="tw-text-xs">{box.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BentoGrid;
