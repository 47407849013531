import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import be from '../../BE';
import auth from '../../Account/Auth';

function DisconnectSalesForza({salesForzaUser,setSalesForzaUser}){
  const {t} = useTranslation(['integrations']);
  const [loading,setLoading] = useState(false)

  const disconnectSalesForza = async () => {
    console.info("[salesforza] disconnecting salesforza user account")
    setLoading(true)
    await auth.isAuthenticated()
      .then(async () => {
        await be.del('account-jubilant','/salesforza/',false,true,true)
          .then(response => {
            // if 200 ok set user obj to {}
            setSalesForzaUser({})
            console.log(response)
          });
      });
    setLoading(false)
    }

  return(
    <div className="mt-4">
      <div className="mb-3">
        {t('salesforza.disconnect.title')}
      </div>
      
      <Button 
        variant="outline-danger"
        size="sm"
        disabled={loading}
        onClick={disconnectSalesForza}
      >
        {t('salesforza.disconnect.button')}
        {loading && 
          <Spinner
            animation="border"
            size="sm"
            className="ms-2"/>
        }
      </Button>
    </div>
  );
}

export default DisconnectSalesForza;
