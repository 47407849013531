import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import spacetime from 'spacetime';
import i18n from '../../../i18n';

const componentStyle={
  fontSize:16,
  fontWeight:700,
}

function getUserLanguage(){
  return  i18n.language.substring(0,2);
}

function setAmpm(){
  if(getUserLanguage() === "en"){return true}
  if(getUserLanguage() === "it"){return false}
  return true
}

function getSpacetimeObject(time){
  let hours = time.split(':')[0];
  let minutes = time.split(':')[1];
  let now = spacetime.now();
  now = now.hour(hours)
  now = now.minute(minutes)
  return now;
}

function formatTime(time){
  let spacetime = getSpacetimeObject(time); 
  let displayTime = spacetime.format('{time-24}')
  if(setAmpm()){displayTime = spacetime.format('{time}')}
  return displayTime;
}

function getEndTime(time,duration){
  let spacetime = getSpacetimeObject(time)
  spacetime = spacetime.add(duration,'minutes');
  return spacetime.format('{time-24}');

}

function Time (props){
  const startTime = props.time;
  const duration = props.duration;
  const endTime = getEndTime(startTime,duration);
  const displayStartTime = formatTime(startTime);
  const displayEndTime = formatTime(endTime);


  return(
    <span style={componentStyle}>
      {displayStartTime} <span className="d-none d-md-inline-block"> - {displayEndTime}</span>
    </span>
  );
}

export default Time;
