import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import WeekView from './WeekView';
import Hop from './Hop';
import BookingLimit from './BookingLimit';
import Notice from './Notice';
import LastAvailable from './LastAvailable';
import Approval from './Approval';
import Calendar from './Calendar';
import Tab from 'react-bootstrap/Tab';
import be from '../../../BE';
import auth from '../../../Account/Auth';
import AvailabilityMock from '../../../Mocks/EventTypeCreator/Availability';
import toast from 'react-hot-toast';
import UpgradePath from '../../../Utilities/UpgradePath/UpgradePath'; 


function AvailabilitiesPanel(){
  const history = useHistory();
  const {t} = useTranslation(['eventCreator']);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [newTemplate, setNewTemplate] = useState({name:'', availability:AvailabilityMock});
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const CBButton = window.calendbookUI.Button;

  useEffect(()=>{
    const getAvailabilityTemplates = async () => {
      setLoadingTemplates(true);
      try{
        await auth.isAuthenticated()
          .then(async () => {
            await be.get('jubilant-events','/events/templates/availabilities',false,true,true)
              .then(response => {
                setTemplates(response);

                if (Object.keys(response).length === 0) {
                  setCreatingTemplate(true);
                }
                else {
                  setSelectedTemplate(Object.keys(response)[0]);
                }
              })
              .catch(error => {
                console.error("[settings][availabilities] network error while retrieving info:",error);
              })
          })
          .catch((error) => {
            console.error("[settings][availabilities] authentication error while retireving info:", error)
            history.push({pathname:'/login'})
          })
      }catch (error) { 
        console.error("[settings][availabilities] functional error while retrieving info:",error);
      }finally{
        setLoadingTemplates(false);
      }
    }
    getAvailabilityTemplates();
  },[])

  // the updateTemplateState function is used to update the state of either newTemplate
  // or a specific template within the templates object based on the presence of templateId.
  // If templateId is null, it updates newTemplate; otherwise,
  // it updates the specified template in templates.
  const updateTemplateState = (templateId, updater) => {
    if (templateId) {
      setTemplates((prevTemplates) => {
        const updatedTemplate = updater(prevTemplates[templateId]);
        return {
          ...prevTemplates,
          [templateId]: updatedTemplate,
        };
      });
    } else {
      setNewTemplate((prevTemplate) => {
        return updater(prevTemplate);
      });
      // setNewTemplate((prevTemplate) => updater(prevTemplate));
    }
  };

  const toggleDayAvailability = (templateId, _day) => {
    updateTemplateState(templateId, (template) => {
      const updatedDays = templateId
        ? { ...template.days, [_day]: !template.days[_day] }
        : { ...template.availability.days, [_day]: !template.availability.days[_day] };

      const updatedRanges = templateId
        ? { ...template.ranges }
        : { ...template.availability.ranges };

      if (updatedRanges[_day].length === 0 && updatedDays[_day]) {
        let interval = { start: '09:00', end: '19:00' };
        updatedRanges[_day].push(interval);
      }

      return templateId
        ? { ...template, days: updatedDays, ranges: updatedRanges }
        : { ...template, availability: { ...template.availability, days: updatedDays, ranges: updatedRanges } };
    });
  };

  const setDayRange = (templateId, _day, _index, _interval) => {
    updateTemplateState(templateId, (template) => {
      const updatedRanges = templateId
        ? { ...template.ranges }
        : { ...template.availability.ranges };

      if (updatedRanges[_day].length === 0 && (!templateId ? !template.availability.days[_day] : !template.days[_day])) {
        updatedRanges[_day] = [_interval];
        return templateId
          ? { ...template, days: { ...template.days, [_day]: true }, ranges: updatedRanges }
          : { ...template, availability: { ...template.availability, days: { ...template.availability.days, [_day]: true }, ranges: updatedRanges } };
      } else {
        updatedRanges[_day][_index] = _interval;
        return templateId
          ? { ...template, ranges: updatedRanges }
          : { ...template, availability: { ...template.availability, ranges: updatedRanges } };
      }
    });
  };

  const deleteDayRange = (templateId, _day, _index) => {
    updateTemplateState(templateId, (template) => {
      const updatedRanges = templateId
        ? { ...template.ranges }
        : { ...template.availability.ranges };

      updatedRanges[_day].splice(_index, 1);

      const updatedDays = templateId
        ? { ...template.days }
        : { ...template.availability.days };

      if (updatedRanges[_day].length === 0) {
        updatedDays[_day] = false;
      }

      return templateId
        ? { ...template, days: updatedDays, ranges: updatedRanges }
        : { ...template, availability: { ...template.availability, days: updatedDays, ranges: updatedRanges } };
    });
  };

  const setTimeslotHop = (templateId, _hop) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, hop: _hop }
      : { ...template, availability: { ...template.availability, hop: _hop } });
  };

  const setBookingLimitQuantity = (templateId, _quantity) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, bookingLimit: { ...template.bookingLimit, quantity: _quantity } }
      : { ...template, availability: { ...template.availability, bookingLimit: { ...template.availability.bookingLimit, quantity: _quantity } } });
  };

  const setBookingLimitUnit = (templateId, _unit) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, bookingLimit: { ...template.bookingLimit, unit: _unit } }
      : { ...template, availability: { ...template.availability, bookingLimit: { ...template.availability.bookingLimit, unit: _unit } } });
  };

  const setNoticeQuantity = (templateId, _quantity) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, notice: { ...template.notice, quantity: _quantity } }
      : { ...template, availability: { ...template.availability, notice: { ...template.availability.notice, quantity: _quantity } } });
  };

  const setNoticeUnit = (templateId, _unit) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, notice: { ...template.notice, unit: _unit } }
      : { ...template, availability: { ...template.availability, notice: { ...template.availability.notice, unit: _unit } } });
  };

  const setLastAvailabilityDate = (templateId, _date) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, lastAvailability: { ...template.lastAvailability, date: _date } }
      : { ...template, availability: { ...template.availability, lastAvailability: { ...template.availability.lastAvailability, date: _date } } });
  };

  const setLastAvailabilityTime = (templateId, _time) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, lastAvailability: { ...template.lastAvailability, time: _time } }
      : { ...template, availability: { ...template.availability, lastAvailability: { ...template.availability.lastAvailability, time: _time } } });
  };

  const setApproval = (templateId, _needsApproval) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, needsApproval: _needsApproval }
      : { ...template, availability: { ...template.availability, needsApproval: _needsApproval } });
  };

  const setCalendar = (templateId, _calendar) => {
    updateTemplateState(templateId, (template) => templateId
      ? { ...template, calendar: _calendar }
      : { ...template, availability: { ...template.availability, calendar: _calendar } });
  };


  const handleTemplateCreation = () => {
    setCreatingTemplate(true);
    setSelectedTemplate(null);
    setNewTemplate({ name: '', availability: AvailabilityMock });
  }

  const handleNewTemplateName = (e) => {
    const { value } = e.target;
    if (creatingTemplate) {
      setNewTemplate(obj => ({ ...obj, name: value.replace(/ /g, '') }));
    } else {
      setTemplates(prevTemplates => ({
        ...prevTemplates,
        [selectedTemplate]: {
          ...prevTemplates[selectedTemplate],
          newName: value.replace(/ /g, '')
        }
      }));
    }
  };

  const handleTemplateSelection = (_key) => {
    setCreatingTemplate(false);
    setSelectedTemplate(_key);
  }

  const saveTemplate = async (e, templateId = null) => {
    e.preventDefault();
    setSaving(true);
    try {
      await auth.isAuthenticated()
        .then(async () => {
          const templateToSave = templateId ? 
            {
              name: templateId,
              ...(templates[templateId].newName ? { newName: templates[templateId].newName } : {}),
              availability: (() => {
                const { newName, ...availability } = templates[templateId];
                return availability;
              })()
            } : 
            newTemplate;
          await be.post("jubilant-events", "/events/templates/availabilities", templateToSave, true, true, true)
            .then((r) => {
              const savedTemplateName = templateToSave.name || newTemplate.name;
              const newTemplateKey = savedTemplateName.replace(/ /g, '');
              setTemplates((prevTemplates) => ({
                ...prevTemplates,
                [newTemplateKey]: templateToSave.availability || templateToSave
              }));
              setSelectedTemplate(newTemplateKey);
              console.log(`${templateToSave?.name} template saved successfully`);
              toast.success(`Template Saved`)
            })
            .catch((error) => {
              console.error("[settings][availabilities] network error while saving template:", error);
            });
        })
        .catch(error => {
          console.error("[settings][availabilities] authentication error while saving template:", error);
        });
    } catch (error) {
      console.error("[settings][availabilities] network error while saving template:", error);
    } finally {
      setSaving(false);
      setCreatingTemplate(false);
    }
  };

  const deleteTemplateState = (templateId) => {
    setTemplates((prevTemplates) => {
      
      const { [templateId]: _, ...remainingTemplates } = prevTemplates;
      // Set selectedTemplate to the first available template or null if none remain
      const newSelectedTemplate = Object.keys(remainingTemplates)[0] || null;

      if (!newSelectedTemplate) { 
        setCreatingTemplate(true); 
        setSelectedTemplate(null); 
        setNewTemplate({ name: '', availability: AvailabilityMock });
      }
      else { 
        setCreatingTemplate(false);
        setSelectedTemplate(newSelectedTemplate); 
      }
      

      return remainingTemplates;
    });
  };

  const deleteTemplate = async (e, templateId = null) => {
    e.preventDefault();
    setDeleting(true);
    try {
      await auth.isAuthenticated()
        .then(async () => {
          const templateToDelete = {name: templateId}; 
          await be.del("jubilant-events", "/events/templates/availabilities", templateToDelete, true, true, true)
            .then((r) => {
              console.log(`${templateToDelete?.name} template deleted successfully`);
              deleteTemplateState(templateId);
              toast.success('Template deleted successfully', {
                icon: '🗑️',
              });
            })
            .catch((error) => {
              console.error("[settings][availabilities] network error while deleting template:", error);
            });
        })
        .catch(error => {
          console.error("[settings][availabilities] authentication error while deleting template:", error);
        });
    } catch (error) {
      console.error("[settings][availabilities] network error while deleting template:", error);
    } finally {
      setDeleting(false);
    }

  }

  return(
    <Tab.Pane eventKey="availabilities">
      <div className="tw-flex tw-flex-col tw-gap-4">
        <h2>Availability Templates</h2>
        <div>

          {loadingTemplates 
            ? 
              <>
                loading...
              </> 
              : 
              <div className="tw-flex tw-gap-2 tw-items-center tw-overflow-auto">
    
                {templates && Object.keys(templates).map(templateKey => (
                  <CBButton
                    key={templateKey}
                    radius="rounded"
                    color="primary"
                    outline={creatingTemplate || (templateKey !== selectedTemplate)}
                    onClick={() => handleTemplateSelection(templateKey)}
                    buttonText={templateKey}
                  />
                ))}
                <CBButton
                  radius="rounded"
                  color="primary"
                  outline={!creatingTemplate}
                  iconStyle="plus"
                  iconOnly
                  onClick={handleTemplateCreation}
                />
              </div>
          }

          {!loadingTemplates && creatingTemplate && (
           
            <form onSubmit={(e) => saveTemplate(e)}>

              <div className="tw-mb-6">
                <label htmlFor="default-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-white">
                  Template Name
                </label>
                <input
                  required
                  onChange={handleNewTemplateName}
                  type="text"
                  id="default-input"
                  className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:tw-ring-blue-500 tw-dark:focus:tw-border-blue-500"
                />
              </div>
              <WeekView availability={newTemplate?.availability} toggleDayAvailability={(day) => toggleDayAvailability(null, day)} setDayRange={(day, index, interval) => setDayRange(null, day, index, interval)} deleteDayRange={(day, index) => deleteDayRange(null, day, index)} />
              <div className="advanced-rules-separator"><span>Advanced rules</span></div>
              <UpgradePath 
                featureName="event-timeslot-hop"
                position="right">
                <Hop availability={newTemplate.availability} setTimeslotHop={(hop) => setTimeslotHop(null, hop)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-booking-limit"
                position="right">
                <BookingLimit availability={newTemplate.availability} setBookingLimitQuantity={(quantity) => setBookingLimitQuantity(null, quantity)} setBookingLimitUnit={(unit) => setBookingLimitUnit(null, unit)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-notice"
                position="right">
                <Notice availability={newTemplate.availability} setNoticeQuantity={(quantity) => setNoticeQuantity(null, quantity)} setNoticeUnit={(unit) => setNoticeUnit(null, unit)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-last-availability"
                position="right">
                <LastAvailable availability={newTemplate.availability} setLastAvailabilityDate={(date) => setLastAvailabilityDate(null, date)} setLastAvailabilityTime={(time) => setLastAvailabilityTime(null, time)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-approval"
                position="right">
                <Approval availability={newTemplate.availability} setApproval={(needsApproval) => setApproval(null, needsApproval)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-calendar"
                position="right">
                <Calendar availability={newTemplate.availability} setCalendar={(calendar) => setCalendar(null,setCalendar)} />
              </UpgradePath>
              <div className="tw-flex tw-justify-end">
                <CBButton buttonText={saving ? <>Save Template...</> : <>Save Template</>} color="primary" size="sm" radius="rounded" type="submit" />
              </div>
            </form>
          )}

          {!loadingTemplates && !creatingTemplate && selectedTemplate && (
            <form onSubmit={(e) => saveTemplate(e, selectedTemplate)}>
              <div className="tw-mb-6">
                <label htmlFor="default-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-dark:text-white">
                  Template Name
                </label>
                <div className="tw-inline-flex tw-items-center tw-gap-4">
                  <input
                    required
                    value={templates[selectedTemplate]?.newName || selectedTemplate} // Prefill with template key if name is not set
                    onChange={handleNewTemplateName}
                    type="text"
                    id="default-input"
                    className="tw-min-w-80 tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:tw-ring-blue-500 tw-dark:focus:tw-border-blue-500"
                  />
                  <div className="tw-flex tw-justify-end">
                    <CBButton buttonText="Save Template" color="primary" size="sm" radius="rounded" type="submit" />
                  </div>
                  {/* <CBButton buttonText={deleting ? <>Delete Template...</> : <>Delete Template</>} color="red" size="xs" radius="rounded" onClick={async (e) => await deleteTemplate(e,selectedTemplate)}/> */}
                  <CBButton 
                    buttonText={
                      <div className="tw-inline-flex tw-items-center">
                        <i className="far fa-trash-alt tw-mr-2"></i>
                        {deleting ? 'Deleting...' : 'Delete'}
                      </div>
                    } 
                    color="red" 
                    size="xs" 
                    radius="rounded" 
                    onClick={async (e) => await deleteTemplate(e, selectedTemplate)}
                  />
                </div>
              </div>
              <WeekView availability={templates[selectedTemplate]} toggleDayAvailability={(day) => toggleDayAvailability(selectedTemplate, day)} setDayRange={(day, index, interval) => setDayRange(selectedTemplate, day, index, interval)} deleteDayRange={(day, index) => deleteDayRange(selectedTemplate, day, index)} />
              <div className="advanced-rules-separator"><span>Advanced rules</span></div>
              <UpgradePath 
                featureName="event-timeslot-hop"
                position="right">
                <Hop availability={templates[selectedTemplate]} setTimeslotHop={(hop) => setTimeslotHop(selectedTemplate, hop)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-booking-limit"
                position="right">
                <BookingLimit availability={templates[selectedTemplate]} setBookingLimitQuantity={(quantity) => setBookingLimitQuantity(selectedTemplate, quantity)} setBookingLimitUnit={(unit) => setBookingLimitUnit(selectedTemplate, unit)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-notice"
                position="right">
                <Notice availability={templates[selectedTemplate]} setNoticeQuantity={(quantity) => setNoticeQuantity(selectedTemplate, quantity)} setNoticeUnit={(unit) => setNoticeUnit(selectedTemplate, unit)} />
              </UpgradePath>
              <UpgradePath 
              featureName="event-last-availability"
              position="right">
                <LastAvailable availability={templates[selectedTemplate]} setLastAvailabilityDate={(date) => setLastAvailabilityDate(selectedTemplate, date)} setLastAvailabilityTime={(time) => setLastAvailabilityTime(selectedTemplate, time)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-approval"
                position="right">
                <Approval availability={templates[selectedTemplate]} setApproval={(needsApproval) => setApproval(selectedTemplate, needsApproval)} />
              </UpgradePath>
              <UpgradePath 
                featureName="event-calendar"
                position="right">
                <Calendar availability={templates[selectedTemplate]} setCalendar={(calendar) => setCalendar(selectedTemplate, calendar)}/>
              </UpgradePath>
              <div className="tw-flex tw-justify-end">
                <CBButton buttonText={saving ? <>Save Template ...</> : <>Save Template</>} color="primary" size="sm" radius="rounded" type="submit" />
              </div>
            </form>
          )}
        </div>
      </div>
    </Tab.Pane>
  );
}

export default AvailabilitiesPanel;
