import React, {useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Proration from './Proration';
import {useTranslation} from 'react-i18next';
import auth from '../../../Account/Auth';
import be from '../../../BE';

const DEBUG = (window.location.hostname === 'localhost' || window.location.hostname === 'dev.calendbook.com');
const TEST_YEARLY_PROFESSIONAL = "pri_01hrc18ywbwns9mev9kqk54e03";
const TEST_MONTHLY_PROFESSIONAL = "pri_01hrc188kzp7m8x2paw3x6chwq";
const LIVE_YEARLY_PROFESSIONAL = "pri_01hrc1cdmrhmnz9t7xpz1jp15y";
const LIVE_MONTHLY_PROFESSIONAL = "pri_01hrc1bfh4v2whzkvy9d4db067"

function Subscription({loadingSubscription,  subscription, setSubscriptionsLoading, getSubscriptions }) {
  const {t,i18n} = useTranslation(['booking'])
  const [loadingSeatsDecreasePreview,setLoadingSeatsDecreasePreview] = useState(false)
  const [loadingSeatsIncreasePreview,setLoadingSeatsIncreasePreview] = useState(false)
  const [loadingUpgradePreview, setLoadingUpgradePreview] = useState(false)
  const [showProrationModal, setShowProrationModal] = useState(false);
  const [prorationDetails, setProrationDetails] = useState(null); // New state for proration details

  const user={
    profilePic : auth.getPic(),
    name : auth.getFullName(),
    email : auth.getEmail(),
    plan : auth.getRole(),
  }

  const formatDate = (date) => {
    const getUserLanguage = () => {try{return i18n.language.substring(0,2)}catch(e){return 'en'}}
    // Date formatting options
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // Getting date object from db
    var dateFormatted = new Date(date);
    // Format: weekday, monthname daynumber, year
    dateFormatted = dateFormatted.toLocaleDateString(getUserLanguage(), options);
    // To uppercase string
    dateFormatted = dateFormatted.toString();

    return dateFormatted;
  }

  const areAllFieldsPresent = (obj) => {
    return Object.values(obj).every(value => value !== null && value !== undefined);
  }

  const handleProrationModalClose = async () => {
    setShowProrationModal(false);
    setProrationDetails(null);
    setSubscriptionsLoading(true);
    await auth.refresh();
    await getSubscriptions();
  };

  const getUpgradePriceId = (_cadency) => {
    switch(_cadency){
      case "year":
        if(DEBUG){return TEST_YEARLY_PROFESSIONAL}
        else {return LIVE_YEARLY_PROFESSIONAL}
        break;
      case "month":
        if(DEBUG){return TEST_MONTHLY_PROFESSIONAL}
        else {return LIVE_MONTHLY_PROFESSIONAL}
        break;
      default:
        return LIVE_YEARLY_PROFESSIONAL
    }    
  }

  const seatsPreview = async (_priceId,_seats,_action) => {
    const seatsNumber = Number(_seats)
    if(seatsNumber){
      let previewSeatsNumber = seatsNumber
      if(_action === "increase"){ 
        setLoadingSeatsIncreasePreview(true)
        previewSeatsNumber += 1 
      }
      if(_action === "decrease"){
        setLoadingSeatsDecreasePreview(true)
        previewSeatsNumber -= 1
      }  
      if(_action === "upgrade"){
        setLoadingUpgradePreview(true)
      }  
      let body = {
        priceId: _priceId,
        quantity: previewSeatsNumber
      }
      await auth.isAuthenticated()
        .then(
          await be.get("payment-jubilant","/subscriptions/preview",body,true,true)
          .then((response) => {
            setLoadingSeatsIncreasePreview(false)
            setLoadingSeatsDecreasePreview(false)
            setLoadingUpgradePreview(false)
            const updateObj = {
              action: response?.action,
              amount: response?.amount,
              currency: response?.currency_code,
              priceId: _priceId,
              quantity: previewSeatsNumber
            }
            if(areAllFieldsPresent(updateObj)){
              setProrationDetails(updateObj);
              setShowProrationModal(true);
            }
            console.log(response);
          })
          .catch(error => {
            setLoadingSeatsIncreasePreview(false)
            setLoadingSeatsDecreasePreview(false)
            setLoadingUpgradePreview(false)
            console.log(error);
          })
        )
        .catch(error => {
          setLoadingSeatsIncreasePreview(false)
          setLoadingSeatsDecreasePreview(false)
          setLoadingUpgradePreview(false)
          console.log(error);
        })
    }

  }

  return (
    <>
      {/* Proration Modal */}
      <Proration
        show={showProrationModal}
        onHide={handleProrationModalClose}
        prorationDetails={prorationDetails}
      />

      <div className="tw-inline-flex tw-w-full">
        {/* Subscription */}
        <div className="tw-w-8/12 tw-p-5 tw-my-5 tw-mx-auto tw-rounded-lg tw-shadow-md tw-bg-white tw-flex tw-flex-col tw-gap-3">
          {/* User Information */}
          {/*
  <div className="tw-flex tw-items-center tw-gap-3 tw-mb-5">
    {user?.profilePic && (
      <img
        src={user?.profilePic}
        alt="Profile"
        className="tw-w-12 tw-h-12 tw-rounded-full tw-object-cover"
      />
    )}
    <div>
      <div className="tw-font-semibold tw-text-lg">{user?.name}</div>
      <div className="tw-text-sm tw-text-gray-500">{user?.email}</div>
    </div>
  </div>
  */}
          {/* Loading Subscription Skeleton */}
          {loadingSubscription &&
            <div className="tw-animate-pulse"> Loading your Subscription... </div>
          }
          <div className="tw-inline-flex">
            {/* Subscription Plan */}
            {subscription?.planName && (
              <div className="tw-mb-2">
                <div>
                  <h3 className="tw-capitalize tw-text-xl tw-font-bold">{subscription?.planName} <i className="tw-text-green-600 fa-solid fa-circle-check"></i> </h3>
                  {subscription?.amount && subscription?.currency && subscription?.billingCycle && (
                    <div className="tw-text-gray-500">
                      <small className="tw-capitalize">
                        {Number(subscription?.amount) / 100}{subscription?.currency}/
                        {subscription?.billingCycle} &times; seat
                      </small>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Available Seats */}
            {subscription?.availableSeats && (
              <div className="tw-ms-auto tw-my-auto">
                <div className="tw-flex tw-items-center tw-mt-1">
                  <span class="tw-bg-gray-100 tw-text-gray-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-1 tw-rounded dark:tw-bg-gray-700 dark:tw-text-gray-300">
                    {/*

                    <button
                      className={`tw-px-2 tw-me-1 tw-font-black tw-rounded ${
            loadingSeatsDecreasePreview ||
            loadingSeatsIncreasePreview ||
            Number(subscription?.availableSeats) === 1
              ? 'tw-cursor-not-allowed tw-opacity-50'
              : 'hover:tw-bg-gray-400 tw-cursor-pointer'
          }`}
                      onClick={() =>
                          seatsPreview(subscription?.priceId, subscription?.availableSeats, 'decrease')
                      }
                      disabled={
                        loadingSeatsDecreasePreview ||
                          loadingSeatsIncreasePreview ||
                          Number(subscription?.availableSeats) === 1
                      }
                    >
                      {loadingSeatsDecreasePreview ? <Spinner size="sm" /> : '-'}
                    </button>

*/}

                    {subscription?.availableSeats && Number(subscription?.availableSeats) === 1
                      ?
                        <>
                          <i className="tw-text-xs fa-solid fa-user tw-me-0.5"></i>{subscription?.availableSeats} Seat
                        </>
                        :
                        <>
                          <i className="tw-text-xs fa-solid fa-users tw-me-0.5"></i>{subscription?.availableSeats} Seats
                        </>
                    }

                    {/*

                    <button
                      className={`tw-px-2 tw-ms-1 tw-font-black tw-rounded ${
            loadingSeatsDecreasePreview || loadingSeatsIncreasePreview
              ? 'tw-cursor-not-allowed tw-opacity-50'
              : 'hover:tw-bg-gray-400 tw-cursor-pointer'
          }`}
                      onClick={() =>
                          seatsPreview(subscription?.priceId, subscription?.availableSeats, 'increase')
                      }
                      disabled={loadingSeatsDecreasePreview || loadingSeatsIncreasePreview}
                    >
                      {loadingSeatsIncreasePreview ? <Spinner size="sm" /> : '+'}
                    </button>
                    */}

                  </span>

                </div>
              </div>
            )}
          </div>

          {/* Next Payment Date */}
          {subscription?.nextPaymentDate && (
            <div className="">
              <div className="tw-font-medium">Next Payment</div>
              <div className="tw-capitalize tw-text-gray-500">
                <small>{formatDate(subscription?.nextPaymentDate)}</small>
              </div>
            </div>
          )}

          {/*<hr class="tw-h-px tw-my-2 tw-bg-gray-400 tw-border-0 dark:tw-bg-gray-700"/>*/}

          <div className="tw-inline-flex tw-mt-auto">
            {/* Scheduled Cancellation or Manage Subscription */}
            {subscription?.scheduledCancelAt ? (
              <div className="tw-mb-4">
                <div className="tw-font-medium">Scheduled Cancellation:</div>
                <div className="tw-capitalize tw-text-gray-500 tw-mt-1">
                  <small>{formatDate(subscription?.scheduledCancelAt)}</small>
                </div>
              </div>
            ) : (
              <div className="tw-flex tw-flex-row sm:tw-flex-row tw-gap-4 tw-mt-4 tw-justify-start">
                {subscription?.cancelSubscriptionUrl && (
                  <a
                    href={subscription?.cancelSubscriptionUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-py-2 tw-px-4 tw-text-sm tw-rounded tw-bg-gray-400 tw-text-white tw-font-semibold hover:tw-bg-gray-600 tw-transition-colors tw-text-center"
                  >
                    Cancel Subscription
                  </a>
                )}
                {subscription?.updatePaymentMethodUrl && (
                  <a
                    href={subscription?.updatePaymentMethodUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-py-2 tw-px-4 tw-text-sm tw-rounded tw-bg-black tw-text-white tw-font-semibold hover:tw-bg-primary-500 tw-transition-colors tw-text-center"
                  >
                    Update Payment Method
                  </a>
                )}
              </div>
            )}

            {/* Customer Portal */}
            <div className="tw-inline-flex tw-text-xs tw-ms-auto tw-items-end">
              <a href="https://customer-portal.paddle.com/cpl_01hg8rb7vrvnqgm34sry730a82" target="_blank" rel="nooperner noreferrer"> Invoices on Customer Portal</a>
              <span>
                <svg className="tw-ms-1 tw-w-4 tw-h-4 tw-my-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                </svg>
              </span>
            </div>
          </div>

        </div>

        {!loadingSubscription && user?.plan === "premium" &&
        <div className="tw-w-4/12 tw-p-5 tw-my-5 tw-ms-3 tw-rounded-lg tw-shadow-md tw-bg-gradient-to-b tw-from-[#ff5828] tw-to-[#993518] tw-flex tw-flex-col tw-gap-3">
          <h3 className="tw-text-xl tw-font-bold tw-text-white">Professional Plan</h3>
          <ul className="tw-my-2 lg:tw-mb-0 tw-space-y-4 tw-text-sm">
            <li className="tw-flex tw-space-x-2.5">
              {/* Icon */}
              <svg className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <span className="tw-leading-tight tw-text-gray-100">Zapier & Webhooks</span>
            </li>
            <li className="tw-flex tw-space-x-2.5">
              {/* Icon */}
              <svg className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <span className="tw-leading-tight tw-text-gray-100">Advanced Availability</span>
            </li>
            <li className="tw-flex tw-space-x-2.5">
              {/* Icon */}
              <svg className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <span className="tw-leading-tight tw-text-gray-100">Accept/Reject Schedulings</span>
            </li>
            <li className="tw-flex tw-space-x-2.5">
              {/* Icon */}
              <svg className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <span className="tw-leading-tight tw-text-gray-100">Whatsapp & Telegram Notifications</span>
            </li>
            <li className="tw-flex tw-space-x-2.5">
              {/* Icon */}
              <svg className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <span className="tw-leading-tight tw-text-gray-100">Unlimited Leads</span>
            </li>
          </ul>
          <button
            className="tw-mt-2 tw-py-2 tw-px-4 tw-text-sm tw-rounded tw-bg-black tw-text-white hover:tw-text-black tw-ease-in tw-font-semibold hover:tw-bg-white disabled:tw-text-gray-500 disabled:tw-bg-gray-700 tw-transition-colors tw-duration-300 tw-flex tw-items-center tw-justify-between"
            onClick={() => seatsPreview(getUpgradePriceId(subscription?.billingCycle), subscription?.availableSeats, 'upgrade')}
            disabled={loadingUpgradePreview}
          >
            <div className="tw-flex tw-items-center">
              {loadingUpgradePreview && <Spinner size="sm" />}
            </div>
            <div className="tw-flex tw-grow tw-justify-center">
              <div>
                {subscription?.billingCycle === "year" &&
                <>
                  <span>Upgrade at $5/mo</span>
                  <div>
                    <small>billed annually</small>
                  </div>
                </>
                }
                {subscription?.billingCycle === "month" &&
                  <>
                    <span>Upgrade at $10/mo</span>
                    <div>
                      <small>billed monthly</small>
                    </div>
                  </>
                }
              </div>
            </div>
          </button>
        </div>
        }
      </div>
    </>
  );
}

export default Subscription;
