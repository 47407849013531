import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../UserContext";
import Account from "./Account";
import Agent from "./Agent";
import Join from "./Join";
import "./agency.css";
import auth from "../Account/Auth";
import be from "../BE";

function Agency() {
  try {
    window.tidioChatApi.show();
  } catch {}
  const [user, setUser] = useContext(UserContext);
  const [activeAccountId, setActiveAccountId] = useState(null);
  const [switchingToAgent, setSwitchingToAgent] = useState(false);
  const [switchingToAccount, setSwitchingToAccount] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    document.title = "Calendbook - Agency";
    if (
      !auth.getProperty({
        storageKey: "userSession",
        path: ["profileObj", "agencyId"],
      })
    ) {
      history.push("/upgrade#agency");
    } else {
      // check if an agent session already exists
      const agentSession = JSON.parse(localStorage.getItem("agentSession"));
      if (agentSession && agentSession.accountId) {
        setActiveAccountId(agentSession.accountId);
      }

      async function fetchData() {
        try {
          await auth
            .isAuthenticated()
            .then(async () => {
              await be
                .get("agency", "/agency/accounts", false, true, true)
                .then((response) => {
                  setData(response);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch(() => history.push({ pathname: "/login" }));
        } catch (error) {
          console.error("Error fetching data:", error);
          setError(error);
        }
      }

      fetchData();
    }
  }, []);

  const switchToMainAgent = async () => {
    setSwitchingToAgent(true);
    localStorage.removeItem("agentSession");
    await auth.refresh();
    setActiveAccountId(null);
    setUser((prevState) => ({
      ...prevState,
      isImpersonated: false,
      impersonatingAgent: null,
    }));
    setSwitchingToAgent(false);
  };

  const switchToAccount = async (accountId) => {
    const agentSession = { accountId };
    setSwitchingToAccount(true);
    localStorage.setItem("agentSession", JSON.stringify(agentSession));
    setActiveAccountId(accountId);
    await auth.refresh();
    setUser((prevState) => ({
      ...prevState,
      isImpersonated: true,
      impersonatingAgent: auth.getAgent(),
    }));
    setSwitchingToAccount(false);
  };

  const deleteAccount = async (accountId) => {
    setDeletingAccount(accountId);
    try {
      await auth
        .isAuthenticated()
        .then(async () => {
          await be
            .del("agency", "/agency/accounts", { accountId }, true, true)
            .then((response) => {
              const accountExists = data.accounts.some(
                (account) => account.accountId === accountId
              );
              setData((prevData) => ({
                ...prevData,
                accounts: prevData.accounts.filter(
                  (account) => account.accountId !== accountId
                ),
                agencyOccupied: accountExists
                  ? prevData.agencyOccupied - 1
                  : prevData.agencyOccupied,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => history.push({ pathname: "/login" }));
    } catch (error) {
      console.error("Error deleting account:", error);
      setError(error);
    }
    setDeletingAccount("");
  };

  if (error) {
    return <div className="loading">An error occurred while loading data.</div>;
  }

  if (!data) {
    return <div className="loading">Loading...</div>;
  }

  if (Object.keys(data).length === 0) {
    return (
      <div className="loading">
        <i
          className="fa-solid fa-user-tie d-block my-5"
          style={{ fontSize: 100 }}
        ></i>
        Oops, you don't have any agency, to get one go to{" "}
        <a href="/upgrade#agency">upgrade to agency page</a>!
      </div>
    );
  }

  return (
    <div className="agency">
      {data?.agencyName && data?.description && data?.profilePic && (
        <div className="header">
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <img
              src={data?.profilePic || "/app-assets/img/standard-user-pic.png"}
              alt={data?.agencyName || "Agency Pic"}
              className="agency-account-image"
            />
            <h1>{data?.agencyName} - Agency Dashboard </h1>
            <i
              className="fas fa-cog text-center ms-auto"
              style={{ fontSize: 18, cursor: "pointer" }}
              onClick={() => history.push("/settings#agency")}
            ></i>
          </div>
          <small>{data?.description}</small>
        </div>
      )}

      {data?.agent && <div className="seats-info">Current Agent</div>}

      {data?.agent && (
        <Agent
          {...data.agent}
          onSwitch={switchToMainAgent}
          isSwitching={switchingToAgent}
          isActive={activeAccountId === null}
        />
      )}

      {data?.agencySeats && (
        <div className="seats-info">
          {" "}
          Seats Occupied: {data.agencyOccupied}/{data.agencySeats}{" "}
        </div>
      )}

      {data?.accounts &&
        data?.accounts.map((account) => (
          <Account
            key={account.accountId}
            accountId={account.accountId}
            name={account.name}
            email={account.email}
            profilePic={account.profilePic}
            isActive={account.accountId === activeAccountId}
            switchingToAccount={
              switchingToAccount && account.accountId === activeAccountId
            }
            switchToAccount={() => switchToAccount(account.accountId)}
            deleteAccount={deleteAccount}
            deletingAccount={deletingAccount}
          />
        ))}

      {data?.joinLink && <Join joinLink={data.joinLink} />}
    </div>
  );
}

export default Agency;
