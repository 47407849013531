import {useCreateEventTypeStore} from '../../../../../store';
import Form from 'react-bootstrap/Form';
import Delete from './Delete';

function Field({fieldContent,order,index,questionId,fieldId,existingFields,editing}){
  const surveys = useCreateEventTypeStore(state => state.surveys);
  const setFieldContent = useCreateEventTypeStore((state) => state.setFieldContent);
  const componentStyle = {
    background : 'var(--color-background-2)',
    borderColor : 'var(--color-border)',
    color : 'var(--color-text)'
  }

  const canDelete = () => {
    if(!editing) return true;
    else if(editing && existingFields.includes(fieldId)) return false;
    else if(editing && !existingFields.includes(fieldId)) return true;
  }

  const shouldDisable = () => {
    if(editing && existingFields.includes(fieldId)) return true;
    else return false;
  }

  return(
    <div className="d-inline-flex">
      <Form.Control
        required
        disabled={shouldDisable()}
        type="text"
        value={fieldContent}
        style={componentStyle}
        onChange={(e) => {setFieldContent(e.target.value,questionId,fieldId)}}
      />
      {canDelete() && <Delete order={order} index={index} questionId={questionId} fieldId={fieldId}/>}
    </div>
  );
}

export default Field;
